/* globals document */
import React, { Component } from 'react';
import {
    CSVLink,
} from 'react-csv';
import { styles } from '../peStyles';
import { DownloadForm } from './download-form';
import './download-view.css';
import { Alert } from '../alert/Alert';

export class DownloadView extends Component {
    componentDidUpdate() {
        /* what follows is is greatly breaking through the react abstraction
        (by using document.getElementById) can't use <CSVDownload> because
        it doesnt' seem to let use set the filename (need to verify product
        importance of that). can't use `ref` callback, because <CSVLink>
        doesn't expose the dom element we want any further investment here...
        should instead be in not putting this CSV through js memory space which
        means not using `react-csv` */
        const node = document.getElementById('csv-download-link');
        if (node) {
            // SUPER HACKY: something strange is going on with the timing
            // of the download. Without this timeout, the component only
            // downloads the raw html of the current page. We could spend
            // more time investigating, or we could look for a better
            // solution than react-csv entirely.
            const { showSuccessAlert } = this.props;
            this.setTimeout(() => {
                node.click();
                showSuccessAlert();
            }, 1000);
        }
    }

    componentWillUnmount() {
        this.clearTimeout();
    }

    setTimeout(cb, ms) {
        this.clearTimeout();
        this.timeoutId = setTimeout(cb, ms);
    }

    clearTimeout() {
        if (typeof this.timeoutId === 'number') clearTimeout(this.timeoutId);
    }

    render() {
        const {
            defaultStartDate,
            defaultEndDate,
            csv,
            onDownload,
            timezone,
            interval,
        } = this.props;
        const hasData = !!csv && Object.keys(csv).length > 0;

        return (
            <div style={styles.peDarkBlue} className='downloads container'>
                <DownloadForm
                    defaultStartDate={defaultStartDate}
                    defaultEndDate={defaultEndDate}
                    onDownload={onDownload}
                    timezone={timezone}
                    interval={interval}
                />
                {/* we are loading the CSV through memory - this is a bad idea
                    for anything other than a trivial amount of data, but for
                    the sake of speed replicating what exists today  */}
                {hasData ? (
                    <CSVLink
                        key={csv.id}
                        data={csv.content}
                        id='csv-download-link'
                        filename={csv.filename}
                    />
                ) : null}
                <div className="justify-content-md-left">
                    <Alert type='download' />
                </div>
            </div>
        );
    }
}
