import React from 'react';
import {
    connect,
} from 'react-redux';
import {
    Router,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import {
    LoginPage,
} from './LoginPage';
import {
    AUTH_STATUS,
} from '../models/user/reducers';
import {
    NotReady,
} from '../utils/async';
import {
    history,
} from '../utils/history';

const AuthenticateUser = connect(
    state => ({
        authenticationStatus: state.user.authenticationStatus,
    }),
)(({
    children,
    authenticationStatus,
}) => {
    if (authenticationStatus === AUTH_STATUS.PENDING) return <NotReady />;
    if (authenticationStatus === AUTH_STATUS.AUTHENTICATED) return children;
    return (
        <Router history={history}>
            <Switch>
                <Route
                    path="/login"
                    component={LoginPage}
                />
                <Redirect from="*" to="/login" />
            </Switch>
        </Router>
    );
});

export {
    AuthenticateUser,
};
