import { VPPApiClient } from '../../VPPApiClient';
import {
    NyleError,
} from '../../utils/errors';
import {
    getHourFloor,
    FIVE_MINUTES_MS,
} from '../../utils/time';

export class VPPPowerDAO {
    static inject() { return [VPPApiClient]; }

    constructor(httpClient) {
        this.queryServer = async (url, message, desiredStatus = 200) => {
            const {
                body,
                status,
            } = await httpClient.get(url);
            if (status !== desiredStatus) throw new NyleError(message);
            return body;
        };

        this.getPower = ({ vppId }) => httpClient.get(`./vpps/${vppId}/power?ts=${Date.now()}`).then(res => res.body);

        this.getPowerHistory = async ({
            vppId,
            startTS,
            endTS,
            average = false,
        }) => {
            if (average) {
                // compute average power by fetching 5 minute energy
                // and converting to instantaneous power data
                const energyHistory = await this.getEnergyHistory({
                    vppId,
                    startTS,
                    endTS,
                    interval: 'five-minutes',
                });
                return VPPPowerDAO.formatAveragePowerTimeseries(energyHistory);
            }
            const result = await this.queryServer(
                `./vpps/${vppId}/power/${startTS}-${endTS}`,
                'Failed to fetch power history',
            );
            return result || [];
        };

        this.getBaselineEnergy = async ({
            vppId,
            startTS,
            endTS,
        }) => {
            const url = `./vpps/${vppId}/aggregates/baseline?lowerTS=${getHourFloor(startTS)}&upperTS=${endTS}`;
            const result = await this.queryServer(url, 'Failed to fetch baseline energy');
            return result || [];
        };

        this.getEnergyHistory = async ({
            vppId,
            startTS,
            endTS,
            tz = 'UTC',
            interval = 'sixty-minutes',
            asCSV = false,
        }) => {
            const url = `./vpps/${vppId}/aggregates/energy?scope=actualkWh,targetkWh&lowerTS=${startTS}&upperTS=${endTS}&tz=${tz}&interval=${interval}`;
            let result;
            if (asCSV) {
                result = await httpClient.get(url, { accept: 'application/nyle.energy+csv' });
                if (result.status !== 200) throw new NyleError('Failed to fetch energy history CSV');
                return result.body;
            }
            result = await this.queryServer(url, 'Failed to fetch energy history');
            return result || [];
        };

        this.getEnergyHistoryAsCSV = params => this.getEnergyHistory({
            ...params,
            asCSV: true,
        });

        this.getPowerHistoryAsCSV = async ({
            vppId,
            startTS,
            endTS,
            tz = 'UTC',
        }) => {
            const url = `./vpps/${vppId}/power/${startTS}-${endTS}?tz=${tz}`;
            const result = await httpClient.get(url, { accept: 'application/nyle.power+csv' });
            if (result.status !== 200) throw new NyleError('Failed to fetch power history CSV');
            return result.body;
        };

        this.setTargetKW = ({ vppId, targetKW, previousTargetKW }) => httpClient.patch(`./vpps/${vppId}/power/now`, {
            targetKW,
            previousTargetKW,
        });

        this.setMode = ({ vppId, mode }) => httpClient.patch(`./vpps/${vppId}/mode`, {
            mode,
        });
    }

    static formatAveragePowerTimeseries(series) {
        const {
            interpolatedSeries,
            next,
        } = series.reduce(({
            /* eslint-disable no-shadow */
            interpolatedSeries,
            next,
            /* eslint-enable */
        }, item) => {
            if (next && next.at === item.bucket) {
                return {
                    interpolatedSeries,
                    next: {
                        ...next,
                        actualKW: next.actualKW + ((item.actualkWh || 0) * 12),
                        targetKW: next.targetKW + ((item.targetkWh || 0) * 12),
                    },
                };
            }
            if (next) {
                interpolatedSeries.push(next);
                interpolatedSeries.push({
                    ...next,
                    at: (next.at + FIVE_MINUTES_MS) - 1,
                });
            }
            return {
                interpolatedSeries,
                next: {
                    at: item.bucket,
                    actualKW: (item.actualkWh || 0) * 12,
                    targetKW: (item.targetkWh || 0) * 12,
                },
            };
        }, {
            interpolatedSeries: [],
            next: null,
        });
        interpolatedSeries.push(next);
        interpolatedSeries.push({
            ...next,
            at: (next.at + FIVE_MINUTES_MS) - 1,
        });
        return interpolatedSeries;
    }
}
