import {
    container,
} from './container';
import {
    User,
} from '../user/user';
import { Auth0Interface } from '../user/auth0-interface';

const authenticator = new Auth0Interface();
const user = new User(authenticator);
container.registerInstance('Authenticator', authenticator);
container.registerInstance('User', user);

export {
    container,
};
