/* globals window */
import {
    VPPApiClient,
} from '../../VPPApiClient';
import {
    NyleError,
} from '../../utils/errors';

export class DeviceDAO {
    static inject() {
        return [VPPApiClient];
    }

    constructor(http) {
        const getQuery = async ({
            url,
            message,
            desiredStatus = 200,
        }) => {
            const {
                body,
                status,
            } = await http.get(url);
            if (status !== desiredStatus) throw new NyleError(message);
            return body;
        };

        const getDevices = async ({
            searchString,
            vppId = null,
            productType = null,
            productVersion = null,
            firmwareVersion = null,
            connectionStatus = null,
            deploymentStatus = null,
            installedBefore = null,
            installedAfter = null,
        }, operatorVPP) => {
            let baseUrl = `/${getUrlPrefix(operatorVPP)}/devices`;
            if (!useAdminRoute(operatorVPP)) baseUrl = `${baseUrl}/search`;
            const queryParts = [];
            const queryParams = {
                search: searchString,
                vppId,
                productType,
                productVersion,
                firmwareVersion,
                connectionStatus,
                deploymentStatus,
                installedBefore,
                installedAfter,
            };
            Object.keys(queryParams).forEach((key) => {
                const value = queryParams[key];
                if (value) queryParts.push(`${key}=${encodeURIComponent(value)}`);
            });
            // Limit results to 500 when filtered, 20 otherwise
            queryParts.push(`limit=${encodeURIComponent(queryParts.length > 0 ? 500 : 20)}`);
            const query = queryParts.join('&');
            const url = `${baseUrl}${query.length ? `?${query}` : ''}`;
            const body = await this.getQuery({
                url,
                message: 'Failed to fetch devices',
            });
            return (body || {}).results || [];
        };

        const getUserDevices = async () => {
            const url = '/devices';
            const body = await this.getQuery({
                url,
                message: 'Failed to fetch user devices',
            });
            return body;
        };

        const getDevice = async (deviceId, vppId = null) => {
            const url = `/${getUrlPrefix(vppId)}/devices/${deviceId}`;
            const body = await this.getQuery({
                url,
                message: 'Failed to fetch device',
            });
            return body;
        };

        const getShadow = async (deviceId, vppId = null) => {
            const url = `/${getUrlPrefix(vppId)}/devices/${deviceId}/shadow`;
            const body = await this.getQuery({
                url,
                message: 'Failed to fetch device shadow',
            });
            return body;
        };

        const setDeviceDebugInterval = async ({
            deviceId,
            vppId = null,
            debugInterval = 0,
        }) => {
            const url = `/${getUrlPrefix(vppId)}/devices/${deviceId}${useAdminRoute(vppId) ? '' : '/debug'}`;
            const res = await http.patch(url, {
                debugInterval,
            });
            if (res.status >= 400) throw new NyleError('Failed to modify debug interval');
        };

        const getFWDeviceType = (deviceType) => {
            switch (deviceType) {
                default: return deviceType;
            }
        };

        const getFirmwareOptions = async (deviceType) => {
            const url = `/admin/devices/firmware/${getFWDeviceType(deviceType)}`;
            const body = await this.getQuery({
                url,
                message: 'Failed to fetch firmware files',
            });
            return body;
        };

        const pushFirmware = async ({
            deviceId,
            deviceType,
            version,
        }) => {
            const url = `/admin/devices/${deviceId}/firmware/push`;
            const requestBody = {
                deviceType: getFWDeviceType(deviceType),
                firmwareVersion: version,
            };
            const {
                status,
            } = await http.patch(url, requestBody);
            if (status !== 204) throw new NyleError('Failed to push firmware');
        };

        const callRPC = async ({
            vppId = null,
            deviceId,
            method,
            args = null,
        }) => {
            const url = `/${getUrlPrefix(vppId)}/devices/${deviceId}/rpc`;
            const requestBody = {
                body: {
                    method,
                    src: deviceId,
                },
            };
            if (args) { requestBody.body.args = args; }
            const {
                body,
                status,
            } = await http.patch(url, requestBody);
            if (status !== 200) throw new NyleError('Failed to call RPC');
            return body.result;
        };

        const rebootDevice = async ({
            deviceId,
            vppId = null,
        }) => {
            if (useAdminRoute(vppId)) {
                const res = await callRPC({
                    deviceId,
                    method: 'Sys.Reboot',
                });
                return res;
            }
            const {
                status,
                body,
            } = await http.patch(`/vpps/${vppId}/devices/${deviceId}/reboot`);
            if (status !== 200) throw new NyleError('Failed to reboot device');
            return body.result;
        };

        const acknowledgeOffline = async (deviceId) => {
            const url = `/admin/devices/${deviceId}/ack-offline`;
            const {
                status,
            } = await http.patch(url);
            if (status !== 204) throw new NyleError('Failed to acknowledge offline device');
        };

        const updateCustomerNotes = async ({
            deviceId,
            customerNotes,
        }) => {
            const {
                status,
            } = await http.patch(`/admin/devices/${deviceId}/notes`, {
                customerNotes,
            });
            if (status !== 200) throw new NyleError('Failed to update customer notes');
        };

        const updateDeviceVpp = async ({
            deviceId,
            vppId,
            currentVppId = null,
        }) => {
            const {
                status,
            } = await http.patch(`/${getUrlPrefix(currentVppId)}/devices/${deviceId}/vpp`, { vppId });
            if (status !== 200) throw new NyleError('Failed to update device VPP');
        };

        const setUserSetting = async ({
            deviceId,
            vppId = null,
            setting,
            value,
        }) => {
            const url = `/${getUrlPrefix(vppId)}/devices/${deviceId}/settings/${setting}`;
            const {
                status,
            } = await http.patch(url, { value });
            if (status !== 200) throw new NyleError(`Failed to update ${setting} for device ID: ${deviceId}`);
        };

        const getEnergyData = async ({
            deviceId,
            startTS,
            endTS,
            interval = 'five-minutes',
            vppId = null,
        }) => {
            const url = `/${getUrlPrefix(vppId)}/devices/${deviceId}/energy`;
            const queryString = `?lowerTS=${startTS}&upperTS=${endTS}&interval=${interval}`;
            const body = await this.getQuery({
                url: `${url}${queryString}`,
                message: 'Failed to fetch energy data',
            });
            return body || [];
        };

        const getPowerData = async ({
            deviceId,
            startTS,
            endTS,
            vppId = null,
        }) => {
            const url = `/${getUrlPrefix(vppId)}/devices/${deviceId}/power`;
            const queryString = `?lowerTS=${startTS}&upperTS=${endTS}`;
            const body = await this.getQuery({
                url: `${url}${queryString}`,
                message: 'Failed to fetch power data',
            });
            return body || [];
        };

        const getDebugData = async ({
            deviceId,
            startTS,
            endTS,
            vppId = null,
        }) => {
            const url = `/${getUrlPrefix(vppId)}/devices/${deviceId}/debug`;
            const queryString = `?lowerTS=${startTS}&upperTS=${endTS}`;
            const body = await this.getQuery({
                url: `${url}${queryString}`,
                message: 'Failed to fetch debug data',
            });
            return body || [];
        };

        const getConnectionsData = async ({
            deviceId,
            startTS,
            endTS,
            vppId = null,
        }) => {
            const url = `/${getUrlPrefix(vppId)}/devices/${deviceId}/connections`;
            const queryString = `?lowerTS=${startTS}&upperTS=${endTS}`;
            const body = await this.getQuery({
                url: `${url}${queryString}`,
                message: 'Failed to fetch connections data',
            });
            return body || [];
        };

        const createDeviceAlert = async ({
            vppId,
            hasOemRole,
            deviceId,
            alertType,
            message,
            displayToUser,
            displayName,
        }) => {
            const url = hasOemRole ? `/${getUrlPrefix(vppId)}/alerts` : 'admin/alerts';
            const {
                status,
            } = await http.post(url, {
                deviceId,
                alertType,
                message,
                displayToUser,
                displayName,
            });
            if (status !== 201) throw new NyleError('Failed to create device issue');
        };

        const getDeviceAlerts = async ({
            vppId,
            hasOemRole,
            userIsDeviceOwner,
            deviceId,
            typeSearch,
            showResolved,
            displayToUserOnly,
        }) => {
            const baseUrl = hasOemRole || userIsDeviceOwner ? `/${getUrlPrefix(vppId)}/alerts` : 'admin/alerts';
            const queryParts = [];
            if (deviceId) queryParts.push(`deviceId=${deviceId}`);
            if (typeSearch) queryParts.push(`q=${typeSearch}`);
            if (showResolved) queryParts.push('showResolved=true');
            if (displayToUserOnly) queryParts.push('displayToUser=true');
            const query = queryParts.join('&');
            const body = await this.getQuery({
                url: `${baseUrl}${query.length ? `?${query}` : ''}`,
                message: 'Failed to fetch device alerts',
            });
            return body;
        };

        const resolveDeviceAlert = async ({
            vppId,
            hasOemRole,
            deviceId,
            alertType,
        }) => {
            const url = hasOemRole
                ? `/${getUrlPrefix(vppId)}/devices/${deviceId}/alerts/${alertType}/resolve`
                : `admin/devices/${deviceId}/alerts/${alertType}/resolve`;
            const {
                status,
            } = await http.patch(url);
            if (status !== 200) throw new NyleError(`Failed to resolve alert of type ${alertType} for ${deviceId}`);
        };

        const unresolveDeviceAlert = async ({
            vppId,
            hasOemRole,
            deviceId,
            alertType,
        }) => {
            const url = hasOemRole
                ? `/${getUrlPrefix(vppId)}/devices/${deviceId}/alerts/${alertType}/unresolve`
                : `admin/devices/${deviceId}/alerts/${alertType}/unresolve`;
            const {
                status,
            } = await http.patch(url, {
                deviceId,
                alertType,
            });
            if (status !== 200) throw new NyleError(`Failed to unresolve alert of type ${alertType} for ${deviceId}`);
        };

        const acknowledgeDeviceAlert = async ({
            vppId,
            hasOemRole,
            deviceId,
            alertType,
        }) => {
            const url = hasOemRole
                ? `/${getUrlPrefix(vppId)}/devices/${deviceId}/alerts/${alertType}/acknowledge`
                : `admin/devices/${deviceId}/alerts/${alertType}/acknowledge`;
            const {
                status,
            } = await http.patch(url);
            if (status !== 200) throw new NyleError(`Failed to resolve alert of type ${alertType} for ${deviceId}`);
        };

        const updateDeviceAlert = async ({
            vppId,
            hasOemRole,
            deviceId,
            alertType,
            message,
            displayToUser,
            displayName,
        }) => {
            const url = hasOemRole
                ? `/${getUrlPrefix(vppId)}/devices/${deviceId}/alerts/${alertType}/update`
                : `admin/devices/${deviceId}/alerts/${alertType}/update`;
            const {
                status,
            } = await http.patch(url, {
                message,
                displayToUser,
                displayName,
            });
            if (status !== 200) throw new NyleError(`Failed to update alert of type ${alertType} for ${deviceId}`);
        };

        const getUsersForDevice = async ({
            deviceId,
        }) => {
            const url = `/admin/devices/${deviceId}/users`;
            const body = await this.getQuery({
                url,
                message: 'Failed to fetch users for device',
            });
            return body;
        };

        const setDeviceParent = async ({
            deviceId,
            parent,
        }) => {
            const url = `/admin/devices/${deviceId}/parent`;
            const { status } = await http.patch(url, {
                parent,
            });
            if (status !== 200) throw new NyleError(`Failed to update parent device for device ID: ${deviceId}`);
        };

        const getUrlPrefix = vppId => (useAdminRoute(vppId) ? 'admin' : `vpps/${vppId}`);

        const useAdminRoute = (vppId) => {
            if (window.location.pathname.includes('admin')) return true;
            return typeof vppId !== 'string';
        };

        const updateUninstalledAt = async ({
            deviceId,
            uninstalledAt,
            vppId,
        }) => {
            const url = `${getUrlPrefix(vppId)}/devices/${deviceId}/uninstalled`;
            const { status } = await http.patch(url, { uninstalledAt });
            if (status !== 204) throw new NyleError('Failed to update uninstalledAt');
        };

        const clearUninstalledAt = async ({
            deviceId,
            vppId,
        }) => {
            const url = `${getUrlPrefix(vppId)}/devices/${deviceId}/uninstalled`;
            const { status } = await http.delete(url);
            if (status !== 204) throw new NyleError('Failed to clear uninstalledAt');
        };

        const updateFaultyDeviceFlag = async ({
            deviceId,
            faulty,
        }) => {
            const url = `admin/devices/${deviceId}/faulty`;
            const { status } = await http.patch(url, { faulty });
            if (status !== 204) throw new NyleError('Failed to update faulty device flag');
        };

        Object.defineProperties(this, {
            getQuery: {
                value: getQuery,
                writable: false,
            },
            getDevices: {
                value: getDevices,
                writable: false,
            },
            getUserDevices: {
                value: getUserDevices,
                writable: false,
            },
            getDevice: {
                value: getDevice,
                writable: false,
            },
            getShadow: {
                value: getShadow,
                writable: false,
            },
            getFirmwareOptions: {
                value: getFirmwareOptions,
                writable: false,
            },
            pushFirmware: {
                value: pushFirmware,
                writable: false,
            },
            setDeviceDebugInterval: {
                value: setDeviceDebugInterval,
                writable: false,
            },
            callRPC: {
                value: callRPC,
                writable: false,
            },
            rebootDevice: {
                value: rebootDevice,
                writable: false,
            },
            acknowledgeOffline: {
                value: acknowledgeOffline,
                writable: false,
            },
            updateCustomerNotes: {
                value: updateCustomerNotes,
                writable: false,
            },
            updateDeviceVpp: {
                value: updateDeviceVpp,
                writable: false,
            },
            setUserSetting: {
                value: setUserSetting,
                writable: false,
            },
            getEnergyData: {
                value: getEnergyData,
                writable: false,
            },
            getPowerData: {
                value: getPowerData,
                writable: false,
            },
            getDebugData: {
                value: getDebugData,
                writable: false,
            },
            getConnectionsData: {
                value: getConnectionsData,
                writable: false,
            },
            createDeviceAlert: {
                value: createDeviceAlert,
                writable: false,
            },
            getDeviceAlerts: {
                value: getDeviceAlerts,
                writable: false,
            },
            resolveDeviceAlert: {
                value: resolveDeviceAlert,
                writable: false,
            },
            unresolveDeviceAlert: {
                value: unresolveDeviceAlert,
                writable: false,
            },
            acknowledgeDeviceAlert: {
                value: acknowledgeDeviceAlert,
                writable: false,
            },
            updateDeviceAlert: {
                value: updateDeviceAlert,
                writable: false,
            },
            getUsersForDevice: {
                value: getUsersForDevice,
                writable: false,
            },
            setDeviceParent: {
                value: setDeviceParent,
                writable: false,
            },
            updateUninstalledAt: {
                value: updateUninstalledAt,
                writable: false,
            },
            clearUninstalledAt: {
                value: clearUninstalledAt,
                writable: false,
            },
            updateFaultyDeviceFlag: {
                value: updateFaultyDeviceFlag,
                writable: false,
            },
        });
    }
}
