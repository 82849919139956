import {
    VPPApiClient,
} from '../../VPPApiClient';
import {
    NyleError,
} from '../../utils/errors';

function formatVPPConfig(queryRes) {
    // eslint-disable-next-line no-param-reassign
    if (!queryRes.name) queryRes.name = queryRes.vppId;
    return queryRes;
}

export class VPPConfigDAO {
    static inject() { return [VPPApiClient]; }

    constructor(httpClient) {
        this.get = async ({
            vppId,
        }) => {
            const {
                body,
                status,
            } = await httpClient.get(`./vpps/${vppId}/?ts=${Date.now()}`);
            if (status !== 200) {
                throw new NyleError('VPP Not Found');
            }
            return formatVPPConfig(body);
        };
    }
}
