import React from 'react';
import {
    css,
} from '@emotion/core';

import {
    AlertCard,
} from './components/device-alert-card';
import {
    NewAlertForm,
} from './components/new-device-alert';
import './device-alerts.css';

const DeviceAlertsView = ({
    displayedAlerts,
    showNewAlertForm,
    errorMessage,
    createAlert,
    updateAlert,
    alertTypes,
    alertTypeSearch,
    handleAlertTypeSearchChange,
    showAcknowledgedAlerts,
    toggleShowAcknowledgedAlerts,
    showResolvedAlerts,
    toggleShowResolvedAlerts,
    showOnlyDisplayToUser,
    toggleShowOnlyDisplayToUser,
    toggleShowNewAlertForm,
    resolveAlert,
    unresolveAlert,
    acknowledgeAlert,
    singleDeviceView,
    hideErrorMessage,
    saving,
    isDeviceOwner,
}) => {
    displayedAlerts.sort((a, b) => b.lastSeenAt - a.lastSeenAt);
    return (
        <div>
            <div css={styles.headerContainer}>
                <h2 css={styles.header}>Alerts</h2>
                {
                    singleDeviceView && !isDeviceOwner ? (
                        <ToggleNewAlert
                            formToggled={showNewAlertForm}
                            onClick={toggleShowNewAlertForm}
                            disabled={saving}
                        />
                    ) : null
                }
            </div>
            {errorMessage && (
                <div className="alert alert-warning" role="alert">
                    {errorMessage}
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={hideErrorMessage}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )}
            {showNewAlertForm && (
                <NewAlertForm
                    onSave={newAlert => createAlert(newAlert)}
                    alertTypes={alertTypes}
                    saving={saving}
                />
            )}
            <SearchBar
                alertTypeSearch={alertTypeSearch}
                handleSearchChange={handleAlertTypeSearchChange}
                showAcknowledgedAlerts={showAcknowledgedAlerts}
                toggleShowAcknowledged={toggleShowAcknowledgedAlerts}
                showResolvedAlerts={showResolvedAlerts}
                toggleShowResolved={toggleShowResolvedAlerts}
                toggleShowNewAlertForm={toggleShowNewAlertForm}
                showOnlyDisplayToUser={showOnlyDisplayToUser}
                toggleShowOnlyDisplayToUser={toggleShowOnlyDisplayToUser}
                showFilters={!isDeviceOwner}
            />
            {displayedAlerts.map((alert) => {
                const key = `${alert.deviceId}-${alert.alertType}`;
                if (!singleDeviceView) {
                    return (
                        <AlertCard
                            key={key}
                            {...alert}
                            resolveAlert={() => resolveAlert(alert)}
                            unresolveAlert={() => unresolveAlert(alert)}
                            acknowledgeAlert={() => acknowledgeAlert(alert)}
                            showDeviceId={true}
                            allowEdit={false}
                        />
                    );
                }
                return (
                    <EditableAlertCard
                        key={key}
                        {...alert}
                        resolveAlert={() => resolveAlert(alert)}
                        unresolveAlert={() => unresolveAlert(alert)}
                        acknowledgeAlert={() => acknowledgeAlert(alert)}
                        showDeviceId={false}
                        onSave={newAlert => updateAlert(newAlert)}
                        saving={saving}
                        allowEdit={!isDeviceOwner}
                        showOptions={!isDeviceOwner}
                    />
                );
            })}
        </div>
    );
};

class EditableAlertCard extends React.Component {
    state = {
        editing: false,
    };

    render() {
        const {
            editing,
        } = this.state;
        const {
            allowEdit = true,
            showOptions = true,
            ...props
        } = this.props;
        if (editing) {
            return (
                <NewAlertForm
                    {...this.props}
                    editingExistingAlert={true}
                    toggleEditing={() => this.setState({
                        editing: false,
                    })}
                />
            );
        }

        return (
            <AlertCard
                {...props}
                showOptions={showOptions}
                allowEdit={allowEdit}
                toggleEditing={() => this.setState({
                    editing: true,
                })}
            />
        );
    }
}

const ToggleNewAlert = ({
    formToggled = false,
    onClick,
    disabled,
}) => (
    <button
        className="btn btn-secondary"
        css={styles.newAlert}
        onClick={onClick}
        disabled={disabled}
    >
        <i className={`fa fa-${formToggled ? 'close' : 'plus'}`} />
        {formToggled ? ' Cancel' : ' New Alert'}
    </button>
);

const SearchBar = ({
    alertTypeSearch,
    handleSearchChange,
    showAcknowledgedAlerts,
    toggleShowAcknowledged,
    showResolvedAlerts,
    toggleShowResolved,
    showOnlyDisplayToUser,
    toggleShowOnlyDisplayToUser,
    showFilters,
}) => (
    <React.Fragment>
        <div className="input-group">
            <input
                className="form-control"
                type="text"
                value={alertTypeSearch}
                onChange={(event) => {
                    event.preventDefault();
                    handleSearchChange(event.target.value);
                }}
                placeholder="Search alerts..."
            />
        </div>
        <div className="input-group row no-gutters" css={styles.searchBarToggles}>
            {showFilters && (
                <>
                    <label className="form-check-label" css={styles.filtersLabel}>
                        Filters:&nbsp;&nbsp;
                    </label>
                    <div className="form-check form-check-inline">
                        <input
                            type="checkbox"
                            aria-label="Toggle showing acknowledged alerts"
                            checked={showAcknowledgedAlerts}
                            onChange={toggleShowAcknowledged}
                        />
                        <label className="form-check-label">
                            &nbsp;Acknowledged
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            type="checkbox"
                            aria-label="Toggle showing resolved alerts"
                            checked={showResolvedAlerts}
                            onChange={toggleShowResolved}
                        />
                        <label className="form-check-label">
                            &nbsp;Resolved
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            type="checkbox"
                            aria-label="Toggle showing alerts visible to device owner"
                            checked={showOnlyDisplayToUser}
                            onChange={toggleShowOnlyDisplayToUser}
                        />
                        <label className="form-check-label">
                            &nbsp;Visible to device owner
                        </label>
                    </div>
                </>
            )}
        </div>
    </React.Fragment>
);

const styles = {
    headerContainer: css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: .5em;
    `,
    header: css`
        flex: 1;
        margin: 0;
    `,
    nav: css`
        flex: 0;
        padding: 0 !important;
    `,
    searchBarToggles: css`
        margin-bottom: 1em;
        margin-top: 0.25em;
    `,
    filtersLabel: css`
        font-weight: bold;
    `,
};

export {
    DeviceAlertsView,
};
