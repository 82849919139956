import {
    NOW,
} from '../../utils/windows';
import {
    asMS,
} from '../../utils/time';

const DEFAULT_DURATIONS = {
    FASTER: 'PT6M',
    NOT_FASTER: 'PT6H',
    ENERGY_ONE_HOUR: 'P1D',
    ENERGY_FIVE_MIN: 'PT3H',
};

const ENERGY_BUCKETS = {
    FIVE_MINUTES: 'five-minutes',
    ONE_HOUR: 'sixty-minutes',
};
const ENERGY_BUCKETS_MS = {
    [ENERGY_BUCKETS.FIVE_MINUTES]: asMS('PT5M'),
    [ENERGY_BUCKETS.ONE_HOUR]: asMS('PT1H'),
};

const DISPLAY_INTERVALS = {
    FASTER: ['PT6M', 'PT15M', 'PT1H'],
    NOT_FASTER: ['PT1H', 'PT3H', 'PT6H', 'PT12H', 'P1D'],
    ENERGY_FIVE_MIN: ['PT3H', 'PT6H', 'PT12H', 'P1D'],
    ENERGY_ONE_HOUR: ['PT12H', 'P1D', 'P2D', 'P7D'],
};

const DISPLAY_TYPES = {
    POWER: 'power',
    ENERGY: 'energy',
};

const getEnergyIntervalId = ({
    direction,
    anchor,
    duration,
    bucketSize,
}) => `${direction}-${anchor}-${duration}-${bucketSize}`;

const getWindowBounds = (performanceDisplay) => {
    const {
        anchor,
        direction,
        duration,
    } = performanceDisplay;
    let windowDuration = asMS(duration);
    if (direction === 'center') {
        return {
            startTS: anchor - (windowDuration / 2),
            endTS: anchor + (windowDuration / 2),
        };
    }
    if (direction === 'end') {
        windowDuration *= -1;
    }
    const ts0 = anchor === NOW ? Date.now() : anchor;
    const ts1 = ts0 + windowDuration;
    return {
        startTS: Math.min(ts0, ts1),
        endTS: Math.max(ts0, ts1),
    };
};

export {
    DEFAULT_DURATIONS,
    ENERGY_BUCKETS,
    ENERGY_BUCKETS_MS,
    DISPLAY_INTERVALS,
    DISPLAY_TYPES,
    getEnergyIntervalId,
    getWindowBounds,
};
