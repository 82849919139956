import React from 'react';
import {
    DateRange,
} from './date-range';
import {
    PRODUCT_TYPES,
    CONNECTION_STATUS_SETTINGS,
} from '../config';
import {
    EARLIEST_DATE,
} from '../../../models/devices/reducers';
import { DeviceSearchDropdown } from './DeviceSearchDropdown';
import { FirmwareVersionInput } from './FirmwareVersionInput';
import { FixedRangeButton } from './FixedRangeButton';

const AdvancedSearch = ({
    selectProductType,
    productType = 'all',
    productVersion = 'all',
    firmwareVersion,
    handleFirmwareVersionChange,
    handleSubmitSearch,
    vpps,
    vppFilter = 'all',
    selectVPPFilter,
    connectionStatus,
    deploymentStatus,
    selectConnectionFilter,
    filterByInstalled,
    toggleFilterByInstallDate,
    installedAfter,
    installedBefore,
    setInstallationRange,
    clearAllFilters,
    showVppFilter,
}) => (
    <div className="container-fluid devices-search__container">
        <DeviceSearchDropdown
            label="Connection status: "
            value={CONNECTION_STATUS_SETTINGS.find(item => (
                item.value.connectionStatus === connectionStatus
                && item.value.deploymentStatus === deploymentStatus
            ))}
            options={CONNECTION_STATUS_SETTINGS}
            onSelect={item => selectConnectionFilter(item.value)}
            getDisplayName={item => item.name}
        />
        {showVppFilter && (
            <DeviceSearchDropdown
                label="VB: "
                value={vppFilter || 'all'}
                options={['all', ...vpps, 'no-vpp']}
                filter={true}
                onSelect={item => selectVPPFilter(item)}
                getDisplayName={item => (item === 'all' ? '(show all)' : item)}
            />
        )}
        <DeviceSearchDropdown
            label="Device Type: "
            value={PRODUCT_TYPES.find(item => item.type === productType
                && item.version === productVersion)}
            options={PRODUCT_TYPES}
            onSelect={item => selectProductType(item)}
            getDisplayName={item => item.name}
        />
        <FirmwareVersionInput
            firmwareVersion={firmwareVersion}
            handleFirmwareVersionChange={handleFirmwareVersionChange}
            handleSubmitSearch={handleSubmitSearch}
        />
        <div className="row align-items-center">
            <input
                type="checkbox"
                checked={filterByInstalled}
                onChange={toggleFilterByInstallDate}
            />
            <h5 className='dtwp__inline'>Installed between</h5>
            <DateRange
                disabled={!filterByInstalled}
                startTS={installedAfter}
                setStartDate={date => setInstallationRange(date, installedBefore)}
                endTS={installedBefore}
                setEndDate={date => setInstallationRange(installedAfter, date)}
                dividerText="and"
                shortcutButtonTitle="Reset"
                shortcutButtonHandler={() => {
                    setInstallationRange(EARLIEST_DATE, Date.now());
                }}
                additionalButtons={(
                    <div className="btn-group devices-search__fixed-range-buttons">
                        <FixedRangeButton
                            numDays={1}
                            label="Past day"
                            setRange={setInstallationRange}
                            disabled={!filterByInstalled}
                        />
                        <FixedRangeButton
                            numDays={3}
                            label="Past 3 days"
                            setRange={setInstallationRange}
                            disabled={!filterByInstalled}
                        />
                        <FixedRangeButton
                            numDays={7}
                            label="Past week"
                            setRange={setInstallationRange}
                            disabled={!filterByInstalled}
                        />
                    </div>
                )}
            />
        </div>
        <div className="row">
            <button
                className="btn btn-outline-dark"
                onClick={clearAllFilters}
            >
                Clear all filters
            </button>
        </div>
    </div>
);

export {
    AdvancedSearch,
};
