/* globals window */
import React from 'react';
import './page-header.css';

class PageHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hash: window.location.hash,
        };
    }

    componentDidMount() {
        window.addEventListener('hashchange', () => this.readHash(), false);
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', () => this.readHash(), false);
    }

    readHash() {
        this.setState({
            hash: window.location.hash,
        });
    }

    render() {
        const {
            vbName,
        } = this.props;
        const {
            hash,
        } = this.state;
        const {
            title,
            showVBName = false,
        } = getPageTitle(hash, vbName);
        if (!title) return null;
        return (
            <div className="page-header">
                <div className="page-header__element-container">
                    <h1>
                        {title}
                        {showVBName && (
                            <small className="page-header__subheader">{vbName}</small>
                        )}
                    </h1>
                </div>
            </div>
        );
    }
}

const getPageTitle = (hash, vbName) => {
    const hashParts = hash.split('/');
    const page = hashParts[1];
    const secondaryRoute = hashParts[2];
    switch (page) {
        case 'operator': {
            return {
                title: vbName,
                showVBName: false,
            };
        }
        case 'peaks': {
            return {
                title: 'Peaks',
                showVBName: true,
            };
        }
        case 'download': {
            return {
                title: 'Download',
                showVBName: true,
            };
        }
        case 'api': {
            return {
                title: 'API',
                showVBName: false,
            };
        }
        case 'devices': {
            if (secondaryRoute) {
                return {
                    title: secondaryRoute,
                };
            }
            return {
                title: 'Devices',
            };
        }
        case 'vpps': {
            if (secondaryRoute === 'new') {
                return {
                    title: 'Create a new VB',
                };
            }
            if (secondaryRoute) {
                return {
                    title: `Details for ${secondaryRoute}`,
                };
            }
            return {
                title: 'VBs',
            };
        }
        case 'users': {
            if (secondaryRoute === 'new') {
                return {
                    title: 'Add a user',
                };
            }
            if (secondaryRoute) {
                return {
                    title: 'User details',
                };
            }
            return {
                title: 'Users',
            };
        }
        default: {
            return {
                title: '',
            };
        }
    }
};

export {
    PageHeader,
};
