import React, {
    useState,
    useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
    Button,
} from '../admin/devices/components/form';
import {
    AsyncValue,
    ASYNC_STATUS,
} from '../utils/async';
import {
    updateUninstalledAt,
    clearUninstalledAt,
} from '../models/devices/actions';
import {
    DateTimePicker,
} from '../lib/react-widgets';

function humanReadableDate(timestamp) {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()}, ${date.toLocaleTimeString()}`;
}

function UninstalledAtView({
    currentUninstalledAt,
    updateUninstalledAtStatus,
    update,
    clear,
}) {
    const [editing, setEditing] = useState(false);
    const [newUninstalledAt, setNewUninstalledAt] = useState(currentUninstalledAt);
    const isWaiting = updateUninstalledAtStatus === ASYNC_STATUS.WAITING;

    // if we just finished saving, disable editing
    useEffect(() => {
        if (!isWaiting) setEditing(false);
    }, [isWaiting]);

    // if the currentUninstalledAt gets updated we should make sure
    // newUninstalledAt is kept up to date
    useEffect(() => {
        setNewUninstalledAt(currentUninstalledAt);
    }, [currentUninstalledAt]);

    return (
        <div className='row'>
            <div className='col'>Uninstalled At</div>
            <div className='col'>
                <UninstalledAtCell
                    currentUninstalledAt={currentUninstalledAt}
                    newUninstalledAt={newUninstalledAt}
                    setNewUninstalledAt={setNewUninstalledAt}
                    editing={editing}
                    isWaiting={isWaiting}
                />
            </div>
            <div className='col'>
                <UninstalledAtButtons
                    editing={editing}
                    toggleEditing={() => setEditing(prev => !prev)}
                    isUninstalled={Boolean(currentUninstalledAt)}
                    markUninstalled={() => update(Date.now())}
                    save={() => update(newUninstalledAt)}
                    clear={clear}
                    isWaiting={isWaiting}
                />
            </div>
        </div>
    );
}

function UninstalledAtCell({
    currentUninstalledAt,
    newUninstalledAt,
    setNewUninstalledAt,
    editing,
    isWaiting,
}) {
    if (!currentUninstalledAt) return null;

    if (editing) {
        return (
            <div>
                <DateTimePicker
                    value={new Date(newUninstalledAt)}
                    onChange={newDate => setNewUninstalledAt(newDate.getTime())}
                    showTimezone={false}
                    disabled={isWaiting}
                />
            </div>
        );
    }

    return humanReadableDate(currentUninstalledAt);
}

function UninstalledAtButtons({
    editing,
    toggleEditing,
    isUninstalled,
    markUninstalled,
    save,
    clear,
    isWaiting,
}) {
    if (!isUninstalled) {
        return (
            <Button
                title='Mark as uninstalled'
                onClick={markUninstalled}
                className="btn-outline-dark btn-sm"
                disabled={isWaiting}
            />
        );
    }

    if (!editing) {
        return (
            <div className='btn-group'>
                <Button
                    title='Edit'
                    onClick={toggleEditing}
                    className='btn-outline-dark btn-sm'
                    disabled={isWaiting}
                />
                <Button
                    title='Clear'
                    onClick={clear}
                    className='btn-outline-dark btn-sm'
                    disabled={isWaiting}
                />
            </div>
        );
    }

    return (
        <div className='btn-group'>
            <Button
                title='Save'
                onClick={save}
                className='btn-outline-dark btn-sm'
                disabled={isWaiting}
            />
            <Button
                title='Cancel'
                onClick={toggleEditing}
                className='btn-outline-dark btn-sm'
                disabled={isWaiting}
            />
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    const { deviceId } = ownProps;
    const deviceState = state.devices.devices[deviceId];

    const {
        uninstalledAt: currentUninstalledAt,
        status: updateUninstalledAtStatus,
        message: updateUninstalledAtMessage,
    } = AsyncValue.unwrap(deviceState, 'uninstalledAt');

    return {
        currentUninstalledAt,
        updateUninstalledAtStatus,
        updateUninstalledAtMessage,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    const { deviceId } = ownProps;
    return {
        update: uninstalledAt => dispatch(updateUninstalledAt({
            deviceId,
            uninstalledAt,
        })),
        clear: () => dispatch(clearUninstalledAt({
            deviceId,
        })),
    };
}

const UninstalledAt = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UninstalledAtView);

export {
    UninstalledAt,
};
