import React from 'react';

import {
    FuturePeakEventContainer,
} from '../containers/future-peak-event-container';

export const NewFuturePeakEvent = props => (
    <FuturePeakEventContainer {...{
        ...props,
        editing: true,
        peakId: null,
    }}
    />
);
