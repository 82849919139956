import React from 'react';

const SearchBar = ({
    searchString,
    handleSubmitSearch,
    handleSearchChange,
    showAdvancedSearch,
    toggleAdvancedSearch,
    minChars = 3,
}) => {
    const showMinCharWarning = searchString.length > 0 && searchString.length < minChars;
    return (
        <div>
            <div className="input-group">
                <input
                    className='form-control'
                    type="text"
                    value={searchString}
                    onChange={(event) => {
                        event.preventDefault();
                        handleSearchChange(event.target.value);
                    }}
                    onKeyPress={(event) => {
                        if (event.key === 'Enter' && !showMinCharWarning) handleSubmitSearch();
                    }}
                    placeholder="Search..."
                />
                <div className="input-group-append">
                    <button
                        className="btn btn-outline-dark"
                        onClick={(event) => {
                            event.preventDefault();
                            handleSubmitSearch();
                        }}
                        disabled={showMinCharWarning}
                    >
                        Search
                    </button>
                    <button
                        className="btn btn-outline-dark"
                        onClick={(event) => {
                            event.preventDefault();
                            toggleAdvancedSearch();
                        }}
                    >
                        {showAdvancedSearch ? 'Hide filters' : 'More filters'}
                    </button>
                </div>
            </div>
            {showMinCharWarning && (
                <p style={{ paddingTop: 5 }}>
                    {`Search term must be at least ${minChars} characters`}
                </p>
            )}
        </div>
    );
};

export {
    SearchBar,
};
