import React from 'react';
import {
    connect,
} from 'react-redux';
import PropTypes from 'prop-types';
import {
    SideNav,
} from './containers/side-navigation';
import {
    PageHeader,
} from './containers/page-header';
import {
    logOutUser,
} from './models/user/actions';
import {
    updateActiveVpp,
} from './models/vpps/actions';

class NyleLayoutView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false,
        };
    }

    render() {
        const {
            children,
            isViewStatic,
            resizeFor4K,
            user,
            vpp,
            vbName,
            logout,
            appId,
            setActiveVPP,
            navMenu,
            currentApp,
        } = this.props;
        const {
            nyleApp: { // for admin pages, which still pulls user from context
                user: userFromContext,
            },
        } = this.context;
        const {
            showMenu,
        } = this.state;

        const classNames = [];
        if (isViewStatic) classNames.push('app-view--static');
        if (resizeFor4K) classNames.push('faster-than-realtime');
        return (
            <div
                id="app"
                className={classNames.join(' ')}
                data-app-id={appId}
                data-vpp-id={vpp.toString()}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <SideNav
                    vpp={user ? vpp : null}
                    user={user || userFromContext}
                    logout={logout}
                    setActiveVPP={setActiveVPP}
                    showMenu={showMenu}
                    onNavigate={() => this.setState({ showMenu: false })}
                    toggleMenu={() => this.setState({ showMenu: !showMenu })}
                    navMenu={navMenu}
                    currentApp={currentApp}
                />
                <div className="app__scrollable-section app__main-scroll-area">
                    <div className="app__container-with-footer">
                        <PageHeader
                            vbName={vbName || null}
                        />
                        <main>
                            {children}
                        </main>
                    </div>
                </div>
            </div>
        );
    }

    static contextTypes = {
        nyleApp: PropTypes.object,
    }
}

const mapStateToProps = (state) => {
    const {
        user,
        vpps: {
            activeVpp: vpp,
        },
    } = state;
    const isViewStatic = state.view ? state.view.static : false;
    return {
        isViewStatic,
        user,
        vpp,
        vbName: (state.vpps.vpps[vpp] || {}).name,
    };
};

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logOutUser()),
    setActiveVPP: vppId => dispatch(updateActiveVpp(vppId)),
});

const NyleLayout = connect(
    mapStateToProps,
    mapDispatchToProps,
)(NyleLayoutView);

export {
    NyleLayout,
};
