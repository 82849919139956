import React, { Component } from 'react';
import moment from 'moment';
import 'moment-timezone';
import {
    Form,
    FormGroup,
    Button,
    Label,
} from 'reactstrap';
import {
    DateTimePicker,
} from '../lib/react-widgets';

// Form validation, called for each change/update
const validate = ({
    startDate,
    endDate,
}) => {
    const errors = {};
    const now = new Date();

    if (!startDate) {
        errors.startDate = 'Please select a starting date/time.';
    }

    if (!endDate) {
        errors.endDate = 'Please select an ending date/time.';
    }

    if (startDate > now) {
        errors.startDate = 'Start date/time cannot be in the future';
    }

    if (startDate >= endDate) {
        const msg = 'Start date/time must be before end date/time.';
        errors.startDate = msg;
        errors.endDate = msg;
    }

    return errors;
};

export class DownloadForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: props.defaultStartDate,
            endDate: props.defaultEndDate,
            timezone: props.timezone || 'UTC',
            downloadType: props.downloadType || 'energy',
            interval: props.interval || 'sixty-minutes',
        };
    }

    getStartDate() {
        const { startDate } = this.state;
        return startDate.toDate();
    }

    getEndDate() {
        const { endDate } = this.state;
        return endDate.toDate();
    }

    formatDatetime(date, tz = null) {
        const { timezone: timeZone } = this.state;
        const timezone = tz || timeZone || 'UTC';
        const tzAwareDatetime = moment.tz(date, tz || timeZone || 'UTC');
        return tzAwareDatetime.format(`MMM D, YYYY h:mm A [(${timezone})]`);
    }

    handleEnergyIntervalChange(event) {
        const interval = event.target.value || 'sixty-minutes';
        this.setState({
            interval,
        });
    }

    handleTimezoneChange(event) {
        const timezone = event.target.value || 'UTC';
        this.setState({
            timezone,
        });
    }

    handleSetDownloadType(event) {
        const downloadType = event.target.value || 'energy';
        this.setState({
            downloadType,
        });
    }

    render() {
        const {
            startDate,
            endDate,
            timezone,
            downloadType,
            interval,
        } = this.state;

        const errors = validate({
            startDate,
            endDate,
        });
        const anyErrors = Object.keys(errors).length > 0;

        const {
            onDownload,
            timezone: timezoneFromProps,
        } = this.props;

        return (
            <div style={{ width: '100%' }}>
                <Form onSubmit={e => e.preventDefault()}>
                    <FormGroup className={`${errors.startDate ? 'has-error' : ''}`}>
                        <Label>
                            <span>From start date & time</span>
                            <DateTimePicker
                                format={val => this.formatDatetime(val)}
                                value={startDate}
                                onChange={date => this.setState({
                                    startDate: date,
                                })}
                            />
                            {errors.startDate && <div className="help-block">{errors.startDate}</div>}
                        </Label>
                    </FormGroup>
                    <FormGroup className={`${errors.endDate ? 'has-error' : ''}`}>
                        <Label>
                            <span>To end date & time</span>
                            <DateTimePicker
                                format={val => this.formatDatetime(val)}
                                value={endDate}
                                onChange={date => this.setState({
                                    endDate: date,
                                })}
                            />
                            {errors.endDate && <div className="help-block">{errors.endDate}</div>}
                        </Label>
                    </FormGroup>
                    {
                        timezoneFromProps && timezoneFromProps !== 'UTC'
                            ? (
                                <div className='form-group'>
                                    <label htmlFor='timezone' className='label--radio'>Show timestamps in:</label>
                                    <div className='form-check form-check-inline'>
                                        <input
                                            className='form-check-input'
                                            name='timezone'
                                            id='vpp-local-tz'
                                            type='radio'
                                            checked={timezone === timezoneFromProps}
                                            onChange={e => this.handleTimezoneChange(e)}
                                            value={timezoneFromProps}
                                        />
                                        <label className='form-check-label' htmlFor='vpp-local-tz'>{timezoneFromProps}</label>
                                    </div>
                                    <div className='form-check form-check-inline'>
                                        <input
                                            className='form-check-input'
                                            name='timezone'
                                            id='vpp-utc-tz'
                                            type='radio'
                                            checked={timezone !== timezoneFromProps}
                                            onChange={e => this.handleTimezoneChange(e)}
                                            value='UTC'
                                        />
                                        <label className='form-check-label' htmlFor='vpp-utc-tz'>UTC</label>
                                    </div>
                                </div>
                            )
                            : null
                    }
                    <div className='form-group'>
                        <label htmlFor='download-type' className='label--radio'>Download as:</label>
                        <div id='download-type' className='form-check form-check-inline'>
                            <input
                                className='form-check-input'
                                name='download-type'
                                id='vpp-energy-download-type'
                                type='radio'
                                checked={downloadType !== 'power'}
                                onChange={e => this.handleSetDownloadType(e)}
                                value='energy'
                            />
                            <label className='form-check-label' htmlFor='vpp-energy-download-type'>Energy</label>
                        </div>
                        <div className='form-check form-check-inline'>
                            <input
                                className='form-check-input'
                                name='download-type'
                                id='vpp-power-download-type'
                                type='radio'
                                checked={downloadType === 'power'}
                                onChange={e => this.handleSetDownloadType(e)}
                                value='power'
                            />
                            <label className='form-check-label' htmlFor='vpp-power-download-type'>Power</label>
                        </div>
                    </div>
                    {
                        downloadType === 'energy'
                            ? (
                                <div className='form-group'>
                                    <label htmlFor='energy-interval' className='label--radio'>Interval:</label>
                                    <div id='energy-interval' className='form-check form-check-inline'>
                                        <input
                                            className='form-check-input'
                                            name='interval'
                                            id='vpp-five-minute-energy-interval'
                                            type='radio'
                                            checked={interval === 'five-minutes'}
                                            onChange={e => this.handleEnergyIntervalChange(e)}
                                            value='five-minutes'
                                        />
                                        <label className='form-check-label' htmlFor='vpp-five-minute-energy-interval'>Five minute</label>
                                    </div>
                                    <div className='form-check form-check-inline'>
                                        <input
                                            className='form-check-input'
                                            name='interval'
                                            id='vpp-fifteen-minute-energy-interval'
                                            type='radio'
                                            checked={interval === 'fifteen-minutes'}
                                            onChange={e => this.handleEnergyIntervalChange(e)}
                                            value='fifteen-minutes'
                                        />
                                        <label className='form-check-label' htmlFor='vpp-fifteen-minute-energy-interval'>Fifteen minute</label>
                                    </div>
                                    <div className='form-check form-check-inline'>
                                        <input
                                            className='form-check-input'
                                            name='interval'
                                            id='vpp-sixty-minutes-energy-interval'
                                            type='radio'
                                            checked={interval === 'sixty-minutes'}
                                            onChange={e => this.handleEnergyIntervalChange(e)}
                                            value='sixty-minutes'
                                        />
                                        <label className='form-check-label' htmlFor='vpp-sixty-minutes-energy-interval'>One hour</label>
                                    </div>
                                </div>
                            )
                            : null
                    }
                    <Button
                        action="submit"
                        onClick={() => onDownload({
                            startDate,
                            endDate,
                            tz: timezone,
                            type: downloadType,
                            interval,
                        })}
                        disabled={anyErrors}
                        color="primary"
                    >
                        Download
                    </Button>
                </Form>
            </div>
        );
    }
}
