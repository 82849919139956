import React from 'react';
import {
    TextInput,
    Button,
} from './form';

class CustomerNotes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            notes: props.customerNotes,
        };
    }

    render() {
        const {
            customerNotes,
            onSave,
        } = this.props;
        const {
            editing,
            notes,
        } = this.state;
        return (
            <div style={{ paddingTop: '0.5em' }}>
                {editing
                    ? (
                        <TextInput
                            value={notes}
                            onChange={(val) => {
                                this.setState({ notes: val });
                            }}
                            onSave={async () => {
                                await onSave(notes);
                                this.setState({ editing: false });
                            }}
                            onCancel={() => {
                                this.setState({
                                    editing: false,
                                    notes: customerNotes,
                                });
                            }}
                        />
                    )
                    : (
                        <p className="italic">
                            {customerNotes || '(Click "Edit" to enter customer notes)'}
                            {' '}
                            <Button title="Edit" onClick={() => this.setState({ editing: true })} />
                        </p>
                    )
                }
            </div>
        );
    }
}

export {
    CustomerNotes,
};
