import React from 'react';
import { connect } from 'react-redux';
import { OperatorPageView } from './operator-page-view';
import {
    VPPState,
} from './vpp-state/vpp-state';
import {
    ActivePeakEventContainer,
} from '../peak-scheduler/active-peak-banner/container';
import {
    Alert,
} from '../alert/Alert';
import {
    fetchPowerHistory,
    fetchEnergyHistory,
    fetchBaselineEnergy,
    fetchVppState,
    fetchDeviceCount,
    startPollingVppState,
    stopPollingVPPState,
    getVppModeHistory,
} from '../models/vpps/actions';
import {
    shouldShowBaseline,
} from '../models/vpps/selectors';
import {
    INITIAL_VPP_STATE,
} from '../models/vpps/reducers';
import {
    createAlert as createAlertMessage,
} from '../models/alerts/actions';
import {
    getEnergyIntervalId,
    DISPLAY_TYPES,
} from '../models/vpps/utils';
import {
    getPeaksInGraphRange,
} from '../models/peaks/selectors';

class OperatorPageContainer extends React.Component {
    componentDidMount() {
        const {
            onMount,
            performanceDisplay: {
                displayType,
            },
            vppId,
        } = this.props;
        onMount(vppId, displayType === DISPLAY_TYPES.POWER);
    }

    componentWillUnmount() {
        const {
            onUnmount,
        } = this.props;
        onUnmount();
    }

    render() {
        const {
            vppId,
            activePeakEventId,
            currentSetPointKW,
            nextPeakEvent,
            pastPeaks,
            powerHistory,
            baselineEnergy,
            energyHistory,
            modeHistory,
            deviceCounts,
            performanceDisplay,
            fetchingData,
            showBaseline,
            resizeFor4K,
            isFasterThanRealtimeDemo,
            createAlert,
            value,
        } = this.props;

        return (
            <div className="container">
                <VPPState
                    currentSetPointKW={currentSetPointKW}
                    nextPeakEvent={nextPeakEvent}
                />
                <Alert type="operator" />
                {activePeakEventId
                    ? (
                        <div className="row">
                            <ActivePeakEventContainer
                                key={activePeakEventId}
                                eventId={activePeakEventId}
                            />
                        </div>
                    ) : null
                }
                <OperatorPageView
                    currentSetPointKW={currentSetPointKW}
                    deviceCounts={deviceCounts}
                    powerHistory={powerHistory}
                    energyHistory={energyHistory}
                    baselineEnergy={baselineEnergy}
                    showBaseline={showBaseline}
                    modeHistory={modeHistory}
                    fetchingData={fetchingData}
                    createAlert={args => createAlert(args)}
                    resizeFor4K={resizeFor4K}
                    performanceDisplay={performanceDisplay}
                    isFasterThanRealtimeDemo={isFasterThanRealtimeDemo}
                    value={value}
                    pastPeaks={pastPeaks}
                    vppId={vppId}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {
        activeVpp: vpp,
    } = state.vpps;
    if (!state.vpps.vpps[vpp]) {
        return {
            ...props,
            performanceDisplay: INITIAL_VPP_STATE.performanceDisplay,
        };
    }
    const {
        power: {
            powerHistory,
            currentSetPointKW,
        },
        energy,
        peak: {
            activePeakEventId,
            nextPeakEvent,
        },
        baselineEnergy,
        modeHistory,
        loading,
        deviceCounts,
        performanceDisplay,
        isFasterThanRealtimeDemo,
        value,
        resizeFor4K,
    } = state.vpps.vpps[vpp];

    return {
        vppId: vpp,
        powerHistory,
        baselineEnergy,
        energyHistory: energy[getEnergyIntervalId(performanceDisplay)] || [],
        modeHistory,
        activePeakEventId,
        nextPeakEvent,
        pastPeaks: getPeaksInGraphRange(state, vpp),
        currentSetPointKW,
        deviceCounts,
        performanceDisplay,
        isFasterThanRealtimeDemo,
        fetchingData: loading,
        showBaseline: shouldShowBaseline(state, vpp),
        resizeFor4K,
        value,
        ...props,
    };
};

const mapDispatchToProps = dispatch => ({
    onMount: (vppId, pollPower) => {
        dispatch(stopPollingVPPState());
        if (pollPower) {
            dispatch(fetchPowerHistory(vppId));
        } else {
            dispatch(fetchEnergyHistory(vppId));
        }
        dispatch(fetchBaselineEnergy(vppId));
        dispatch(fetchVppState(vppId));
        dispatch(fetchDeviceCount(vppId));
        dispatch(getVppModeHistory(vppId));
        dispatch(startPollingVppState(vppId));
    },
    onUnmount: () => {
        dispatch(stopPollingVPPState());
    },
    createAlert: params => dispatch(createAlertMessage(params)),
});

const OperatorPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(OperatorPageContainer);

export {
    OperatorPage,
};
