// Pulled this out of react-csv lib here, https://github.com/react-csv/react-csv/blob/master/src/core.js,
// because we can't pass the react-csv component into the plotly modebar
/* eslint-disable no-undef */
export const isSafari = () => /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);

export const isJson = (array => (Array.isArray(array) && array.every(
    row => (typeof row === 'object' && !(row instanceof Array)),
)));

export const jsonHeaders = (array => Array.from(
    array.map(json => Object.keys(json))
        .reduce((a, b) => new Set([...a, ...b]), []),
));

export const json2arrays = (json, headers) => {
    const hdrs = headers || jsonHeaders(json);

    // allow headers to have custom labels, defaulting to having the header data key be the label
    let headerLabels = hdrs;
    let headerKeys = hdrs;
    if (isJson(hdrs)) {
        headerLabels = hdrs.map(header => header.label);
        headerKeys = hdrs.map(header => header.key);
    }

    const data = json.map(object => headerKeys.map(header => getHeaderValue(header, object)));
    return [headerLabels, ...data];
};

export const getHeaderValue = (property, obj) => {
    const foundValue = property
        .replace(/\[([^\]]+)]/g, '.$1')
        .split('.')
        .reduce((acc, p, i, arr) => {
            // if at any point the nested keys passed do not exist,
            // splice the array so it doesnt keep reducing
            if (acc[p] !== undefined) {
                return acc[p];
            }
            arr.splice(1);
            return arr;
        }, obj);
    // if at any point the nested keys passed do not exist then looks for key `property`
    // in object obj
    const foundProperty = (property in obj) ? obj[property] : '';
    return (foundValue === undefined) ? foundProperty : foundValue;
};

// Depending on if the individual data element for a field is either empty or an object
// we'll want to handle it a bit differently than if it were a single value
export const dataElementParser = (element) => {
    if (typeof element === 'undefined' || element === null) {
        return '';
    }
    if (typeof element === 'object') {
        return JSON.stringify(element).replaceAll(',', ' | ');
    }
    return element;
};

export const joiner = ((data, separator = ',', enclosingCharacter = '"') => data
    .filter(e => e)
    .map(
        row => row
            .map(element => dataElementParser(element))
            .map(column => `${enclosingCharacter}${column}${enclosingCharacter}`)
            .join(separator),
    )
    .join('\n'));

export const json2csv = ((data, headers, separator, enclosingCharacter) => joiner(
    json2arrays(data, headers), separator, enclosingCharacter,
));

export const buildURI = (data, uFEFF, headers, separator, enclosingCharacter) => {
    const csv = json2csv(data, headers, separator, enclosingCharacter);
    const type = isSafari() ? 'application/csv' : 'text/csv';
    const blob = new Blob([uFEFF ? '\uFEFF' : '', csv], { type });
    const dataURI = `data:${type};charset=utf-8,${uFEFF ? '\uFEFF' : ''}${csv}`;

    const URL = window.URL || window.webkitURL;

    return (typeof URL.createObjectURL === 'undefined')
        ? dataURI
        : URL.createObjectURL(blob);
};
