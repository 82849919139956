import {
    takeEvery,
    put,
    call,
    select,
} from 'redux-saga/effects';
import { container } from '../../../dependency-injection';
import { DeviceDAO } from '../device-dao';
import {
    FETCH_DEVICE_ALERTS,
    FETCH_DEVICE_ALERTS_SUCCESS,
    FETCH_DEVICE_ALERTS_FAILURE,
    RESOLVE_DEVICE_ALERT,
    RESOLVE_DEVICE_ALERT_FAILURE,
    UNRESOLVE_DEVICE_ALERT,
    UNRESOLVE_DEVICE_ALERT_FAILURE,
    ACKNOWLEDGE_DEVICE_ALERT,
    ACKNOWLEDGE_DEVICE_ALERT_FAILURE,
    CREATE_DEVICE_ALERT,
    CREATE_DEVICE_ALERT_SUCCESS,
    CREATE_DEVICE_ALERT_FAILURE,
    UPDATE_DEVICE_ALERT,
    UPDATE_DEVICE_ALERT_SUCCESS,
    UPDATE_DEVICE_ALERT_FAILURE,
    SET_DEVICE_ALERT_FILTER,
} from './actions';
import {
    userHasRole,
} from '../../user/selectors';
import { NO_VPP } from '../../vpps/reducers';

function* handler() {
    yield takeEvery([
        FETCH_DEVICE_ALERTS,
        SET_DEVICE_ALERT_FILTER,
    ], handleFetchAlerts);
    yield takeEvery(RESOLVE_DEVICE_ALERT, handleResolveAlert);
    yield takeEvery(UNRESOLVE_DEVICE_ALERT, handleUnresolveAlert);
    yield takeEvery(ACKNOWLEDGE_DEVICE_ALERT, handleAcknowledgeAlert);
    yield takeEvery(CREATE_DEVICE_ALERT, handleCreateAlert);
    yield takeEvery(UPDATE_DEVICE_ALERT, handleUpdateAlert);
}

function* handleFetchAlerts(action = {}) {
    const devicesClient = container.get(DeviceDAO);
    const {
        deviceId,
        key,
        value,
    } = action.payload || {};
    const state = yield select();
    const hasOemRole = userHasRole(state, 'oem');
    const userIsDeviceOwner = state.user.devices.includes(deviceId);
    let vppId = state.vpps.activeVpp;
    if (vppId === NO_VPP) {
        const device = state.devices.deviceList.find(d => d.deviceId === deviceId)
            || { vpp: NO_VPP.toString() };
        vppId = device.vpp;
    }
    const filters = {
        ...state.devices.alerts.filters,
    };

    if (key) {
        filters[key] = value;
    }
    try {
        const alerts = yield call(devicesClient.getDeviceAlerts, {
            vppId,
            hasOemRole,
            userIsDeviceOwner,
            deviceId,
            ...filters,
        });
        yield put({
            type: FETCH_DEVICE_ALERTS_SUCCESS,
            payload: {
                alerts,
            },
        });
    } catch (err) {
        yield put({
            type: FETCH_DEVICE_ALERTS_FAILURE,
        });
    }
}

function* handleResolveAlert(action) {
    const devicesClient = container.get(DeviceDAO);
    const {
        deviceId,
        alertType,
    } = action.payload;
    const state = yield select();
    const hasOemRole = userHasRole(state, 'oem');
    const vppId = state.vpps.activeVpp;
    try {
        yield call(devicesClient.resolveDeviceAlert, {
            vppId,
            hasOemRole,
            deviceId,
            alertType,
        });
        yield call(handleFetchAlerts);
    } catch (err) {
        yield put({
            type: RESOLVE_DEVICE_ALERT_FAILURE,
        });
    }
}

function* handleUnresolveAlert(action) {
    const devicesClient = container.get(DeviceDAO);
    const {
        deviceId,
        alertType,
    } = action.payload;
    const state = yield select();
    const hasOemRole = userHasRole(state, 'oem');
    const vppId = state.vpps.activeVpp;
    try {
        yield call(devicesClient.unresolveDeviceAlert, {
            vppId,
            hasOemRole,
            deviceId,
            alertType,
        });
        yield call(handleFetchAlerts);
    } catch (err) {
        yield put({
            type: UNRESOLVE_DEVICE_ALERT_FAILURE,
        });
    }
}

function* handleAcknowledgeAlert(action) {
    const devicesClient = container.get(DeviceDAO);
    const {
        deviceId,
        alertType,
    } = action.payload;
    const state = yield select();
    const hasOemRole = userHasRole(state, 'oem');
    const vppId = state.vpps.activeVpp;
    try {
        yield call(devicesClient.acknowledgeDeviceAlert, {
            vppId,
            hasOemRole,
            deviceId,
            alertType,
        });
        yield call(handleFetchAlerts);
    } catch (err) {
        yield put({
            type: ACKNOWLEDGE_DEVICE_ALERT_FAILURE,
        });
    }
}

function* handleCreateAlert(action) {
    const devicesClient = container.get(DeviceDAO);
    const {
        deviceId,
        alertType,
        message,
        displayToUser,
        displayName,
    } = action.payload;
    const state = yield select();
    const hasOemRole = userHasRole(state, 'oem');
    const vppId = state.vpps.activeVpp;
    try {
        yield call(devicesClient.createDeviceAlert, {
            vppId,
            hasOemRole,
            deviceId,
            alertType: alertType.replace(/ /g, '_'),
            message,
            displayToUser,
            displayName,
        });
        yield put({
            type: CREATE_DEVICE_ALERT_SUCCESS,
        });
        yield call(handleFetchAlerts);
    } catch (err) {
        yield put({
            type: CREATE_DEVICE_ALERT_FAILURE,
        });
    }
}

function* handleUpdateAlert(action) {
    const devicesClient = container.get(DeviceDAO);
    const {
        deviceId,
        alertType,
        message,
        displayToUser,
        displayName,
    } = action.payload;
    const state = yield select();
    const hasOemRole = userHasRole(state, 'oem');
    const vppId = state.vpps.activeVpp;
    try {
        yield call(devicesClient.updateDeviceAlert, {
            vppId,
            hasOemRole,
            deviceId,
            alertType,
            message,
            displayToUser,
            displayName,
        });
        yield put({
            type: UPDATE_DEVICE_ALERT_SUCCESS,
        });
        yield call(handleFetchAlerts);
    } catch (err) {
        yield put({
            type: UPDATE_DEVICE_ALERT_FAILURE,
        });
    }
}

export {
    handler,
};
