import React from 'react';
import {
    connect,
} from 'react-redux';
import {
    Link,
} from 'react-router-dom';
import {
    ErrorMessage,
} from '../../admin/devices/components';
import {
    fetchUsersForDevice,
    setDeviceErrorMessage,
} from '../../models/devices/actions';
import {
    ASYNC_STATUS,
} from '../../utils/async';

class UsersForDeviceView extends React.Component {
    componentDidMount() {
        const {
            getUsers,
            deviceId,
        } = this.props;
        getUsers(deviceId);
    }

    render() {
        const {
            users: {
                data: users,
                loading,
                message,
            },
        } = this.props;
        if (loading === ASYNC_STATUS.WAITING) return <p><i>Loading users...</i></p>;
        if (loading === ASYNC_STATUS.FAILURE) return <ErrorMessage errorMessage={message} />;
        if (!users.length) return <p>No users found</p>;

        return (
            <ul className="list-group">
                {users.map(item => (
                    <li className="list-group-item" key={item.user_id}>
                        <Link to={`/users/${item.user_id}`}>
                            {item.email}
                        </Link>
                    </li>
                ))}
            </ul>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {
        deviceId,
    } = props;
    const {
        users,
    } = state.devices.devices[deviceId];

    return {
        deviceId,
        users,
    };
};

const mapDispatchToProps = dispatch => ({
    getUsers: deviceId => dispatch(fetchUsersForDevice(deviceId)),
    showErrorMessage: (deviceId, message) => dispatch(setDeviceErrorMessage({
        deviceId,
        message,
    })),
});

const UsersForDevice = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UsersForDeviceView);

export {
    UsersForDevice,
};
