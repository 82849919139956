import React from 'react';
import { DateTimePicker } from '../../../../lib/react-widgets';
import {
    NOW,
} from '../../../../utils/windows';

const AnchorPicker = ({
    anchor,
    handleChange,
    handleFailedChange,
}) => {
    const isAnchorNow = anchor === NOW;
    const anchorDate = isAnchorNow ? new Date() : new Date(anchor);
    const handleChangeWrapper = (date) => {
        if (!date) {
            handleFailedChange('You must enter a valid date.');
        } else if (date !== NOW && date > new Date()) {
            handleFailedChange('You cannot pick a date in the future.');
        } else {
            handleChange(date);
        }
    };
    return (
        <div className='dtwp__row dtwp__anchor'>
            <DateTimePicker
                value={anchorDate}
                onChange={date => handleChangeWrapper(date)}
                showTimezone={true}
            />
            <span>or</span>
            <button
                type="button"
                className={`btn btn-primary dtwp__btn-now ${isAnchorNow ? 'active' : ''}`}
                onClick={() => handleChange(NOW)}
            >
                Now
            </button>
        </div>
    );
};

export {
    AnchorPicker,
};
