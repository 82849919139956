/* global document, Event */
import React from 'react';
import { connect } from 'react-redux';
import './LoginPage.css';
import {
    checkAuthToken,
    logInUser,
} from '../models/user/actions';

class LoginPageComponent extends React.Component {
    componentDidMount() {
        const { checkAuth } = this.props;
        checkAuth();
        // this page needs a body level class to change the document background
        document.body.classList.add('route-login');

        // This is a utility added for integration testing the reporting lambda
        setTimeout(() => {
            const e = new Event('export', {
                bubbles: true,
            });
            document.body.dispatchEvent(e);
        }, 1000);
    }

    componentWillUnmount() {
        document.body.classList.remove('route-login');
    }

    render() {
        const { login } = this.props;
        return (
            <div className="login__container">
                <div className='login__hero'>
                    <div className='login__by-block'>
                        <img src="/assets/img/nyle-logo.png" alt="Nyle" />
                    </div>
                    <button
                        id='login-btn'
                        className="btn btn-primary btn-lg btn-block"
                        onClick={() => login()}
                    >
                        Log In
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => props;

const mapDispatchToProps = dispatch => ({
    checkAuth: () => dispatch(checkAuthToken()),
    login: () => dispatch(logInUser()),
});

const LoginPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoginPageComponent);

export {
    LoginPage,
};
