import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    SettingsView,
} from './components/settings-view';
import './index.css';
import {
    updatePerformanceDisplay,
} from '../../../models/vpps/actions';
import {
    ENERGY_BUCKETS,
    DISPLAY_TYPES,
} from '../../../models/vpps/utils';
import {
    shouldShowPower,
} from '../../../models/vpps/selectors';
import {
    INITIAL_VPP_STATE,
} from '../../../models/vpps/reducers';
import {
    NOW,
} from '../../../utils/windows';
import {
    formatTabValues,
    DISPLAY_TABS,
} from './utils';

class Container extends Component {
    constructor(props) {
        super(props);
        this.timeoutId = null;
        this.state = {
            message: null,
        };

        this.updateWindow = this.updateWindow.bind(this);
    }

    updateWindow(performanceDisplay) {
        const {
            updateWindow,
            vppId,
        } = this.props;
        updateWindow(vppId, performanceDisplay);
    }

    handleWindowChange = (duration) => {
        this.updateWindow({ duration });
    };

    handleAnchorChange = (newAnchor) => {
        const newState = {
            anchor: typeof newAnchor === 'string' ? newAnchor : newAnchor.getTime(),
        };
        if (newAnchor === NOW) {
            newState.direction = 'end';
        }
        this.updateWindow(newState);
    };

    handleDirectionChange = (direction) => {
        const {
            anchor,
        } = this.props;
        if (anchor === NOW && direction !== 'end') return;
        this.updateWindow({ direction });
    };

    handleDisplayTypeChange = ({ displayType, bucketSize }) => {
        this.updateWindow({
            displayType,
            bucketSize,
        });
    };

    handleFlashMessage = (message) => {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.setState({ message });
        this.timeoutId = setTimeout(() => {
            this.setState({ message: null });
            this.timeoutId = null;
        }, 1000 * 3);
    };

    displayTypeIsActive = (value) => {
        const {
            displayType,
            bucketSize,
        } = this.props;
        if (value === DISPLAY_TABS.ENERGY_ONE_HOUR) {
            return displayType === DISPLAY_TYPES.ENERGY
                && bucketSize === ENERGY_BUCKETS.ONE_HOUR;
        }
        if (value === DISPLAY_TABS.ENERGY_FIVE_MINUTES) {
            return displayType === DISPLAY_TYPES.ENERGY
                && bucketSize === ENERGY_BUCKETS.FIVE_MINUTES;
        }
        if (value === DISPLAY_TABS.POWER) {
            return displayType === DISPLAY_TYPES.POWER;
        }
        return false;
    };

    render() {
        const {
            handleClose,
            anchor,
            duration,
            direction,
            displayType,
            displayIntervals,
            canViewPower,
        } = this.props;

        const {
            message,
        } = this.state;

        return (
            <SettingsView {...{
                anchor,
                windows: formatTabValues(displayIntervals, duration),
                displayTypes: [DISPLAY_TABS.ENERGY_ONE_HOUR, DISPLAY_TABS.ENERGY_FIVE_MINUTES]
                    .concat(canViewPower ? [DISPLAY_TABS.POWER] : [])
                    .map(value => ({
                        value,
                        active: this.displayTypeIsActive(value),
                    })),
                direction,
                displayType,
                canViewPower,
                handleClose,
                handleAnchorChange: this.handleAnchorChange,
                handleDirectionChange: this.handleDirectionChange,
                handleWindowChange: this.handleWindowChange,
                handleDisplayTypeChange: this.handleDisplayTypeChange,
                handleFlashMessage: this.handleFlashMessage,
                message,
            }}
            />
        );
    }
}

const mapStateToProps = (state, props) => {
    const { activeVpp } = state.vpps;
    const { vppId } = props;
    const vpp = vppId || activeVpp;
    const {
        performanceDisplay,
    } = state.vpps.vpps[vpp] || INITIAL_VPP_STATE;

    return {
        vppId: vpp,
        canViewPower: shouldShowPower(state, vpp),
        ...props,
        ...performanceDisplay,
    };
};

const mapDispatchToProps = dispatch => ({
    updateWindow: (vppId, performanceDisplay) => {
        dispatch(updatePerformanceDisplay({
            vppId,
            performanceDisplay,
        }));
    },
});

const SettingsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Container);

export {
    SettingsContainer,
};
