/* globals window */
import React from 'react';
import {
    Nav,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
} from 'reactstrap';
import {
    css,
} from '@emotion/core';
import {
    Icon,
} from '../lib/font-awesome';
import './side-navigation.css';
import {
    AUTH_STATUS,
} from '../models/user/reducers';
import {
    history,
} from '../utils/history';

const SideNav = ({
    vpp,
    user,
    logout,
    setActiveVPP,
    showMenu,
    onNavigate = () => {},
    toggleMenu,
    navMenu,
    currentApp = 'operator',
}) => {
    const transformClass = `nav__sidebar--${showMenu ? 'show' : 'hide'}`;
    return (
        <div
            css={navStyles.container}
            className={`nav__sidebar ${transformClass}`}
        >
            <div
                className="nav__container app__scrollable-section d-flex"
                id="nav"
                css={navStyles.scrollableSection}
            >
                <div className="nav__body app__container-with-footer">
                    <NyleLogo operator={currentApp === 'operator'} />
                    {navMenu(onNavigate)}
                    <ProfileMenu
                        logout={logout}
                        user={user}
                        activeVpp={vpp}
                        setActiveVPP={setActiveVPP}
                        onNavigate={onNavigate}
                        currentApp={currentApp}
                    />
                    <div
                        onClick={toggleMenu}
                        className="d-lg-none nav__hamburger"
                        style={showMenu ? {
                            right: '10px',
                        } : null}
                    >
                        <Icon hint={showMenu ? 'times' : 'bars'} />
                    </div>
                </div>
            </div>
        </div>
    );
};

const navStyles = {
    container: css`
        flex: none;
    `,
    scrollableSection: css`
        padding: 0 1.5rem 0;
    `,
};

const capitalize = name => `${name.slice(0, 1).toUpperCase()}${name.slice(1)}`;

const ProfileMenu = ({
    logout,
    user,
    activeVpp,
    setActiveVPP,
    onNavigate,
    currentApp,
}) => {
    const isLoggedIn = user.authenticationStatus === AUTH_STATUS.AUTHENTICATED;
    if (!isLoggedIn) return null;
    const menuItems = [];

    if (user.vpps && Object.keys(user.vpps).length > 1 && currentApp === 'operator') {
        let primaryVB;
        const nonPrimaryVBs = Object.keys(user.vpps).filter((vppId) => {
            if (user.vpps[vppId].primary) {
                primaryVB = vppId;
                return false;
            }
            return true;
        }).sort(alphabetical);
        const vbSelection = (
            <React.Fragment key='vb-selection'>
                <DropdownItem header>Virtual battery</DropdownItem>
                {[primaryVB, ...nonPrimaryVBs].map(vppId => (
                    <DropdownItem
                        onClick={() => {
                            setActiveVPP(vppId);
                            onNavigate();
                        }}
                        className={`dropdown-list ${activeVpp === vppId ? 'active' : ''}`}
                        key={vppId}
                    >
                        {vppId}
                    </DropdownItem>
                ))}
                <DropdownItem divider />
            </React.Fragment>
        );
        menuItems.push(vbSelection);
    }

    if (user.admin && ['admin', 'operator'].includes(currentApp)) {
        let toggle = 'admin';
        if (currentApp === 'admin') toggle = 'operator';
        const page = currentApp === 'admin' ? '#/operator' : 'admin';
        const adminToggle = (
            <DropdownItem
                key='admin-toggle'
                className='dropdown-list'
                onClick={() => window.location.replace(`${window.location.origin}/${page}/`)}
            >
                {`${capitalize(toggle)} Dashboard`}
            </DropdownItem>
        );
        menuItems.push(adminToggle);
    }

    menuItems.push((
        <DropdownItem key='log-out' onClick={() => logout()} className='dropdown-list'>Log out</DropdownItem>
    ));

    return (
        <UncontrolledDropdown
            nav
            className="app__footer-element"
            direction="up"
            css={profileStyles.container}
        >
            <DropdownToggle
                nav
                caret
                className="nav__link"
                css={profileStyles.toggle}
            >
                <Icon hint="user" className="nav__link-icon" />
                <span css={profileStyles.toggleText}>Profile</span>
            </DropdownToggle>
            <DropdownMenu css={profileStyles.dropdownMenu}>
                {menuItems}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

const profileStyles = {
    container: css`
        flex: 1;
    `,
    toggle: css`
        display: flex;
        align-items: center;
        padding: 0;
        &:after {
            position: absolute;
            right: .5rem;
        }
    `,
    dropdownMenu: css`
        width: 100%;
    `,
    toggleText: css`
        flex: 1;
    `,
};

const NyleLogo = ({
    operator,
}) => (
    <img
        src="/assets/img/nyle-logo.png"
        alt="Nyle Systems"
        className="d-inline-block align-top nav__logo page-header"
        width={177}
        onClick={() => {
            if (operator) history.push('/operator');
            else window.location.replace(`${window.location.origin}/#/operator`);
        }}
    />
);

const NavigationLink = ({
    title,
    to,
    iconHint = 'info',
    imageName,
    onNavigate,
    external,
}) => {
    const activeClass = window.location.hash.includes(to) ? 'active' : '';
    const icon = imageName
        ? (
            <img
                src={`/assets/img/module-logos/${imageName}.png`}
                alt={title}
                className="nav__link-icon"
            />
        )
        : <Icon hint={iconHint} className="nav__link-icon" />;
    return (
        <span
            className={`nav__link ${activeClass}`}
            onClick={() => {
                if (external) {
                    window.location.href = to;
                    return;
                }
                history.push(to);
                onNavigate();
            }}
        >
            {icon}
            {title}
        </span>
    );
};

const SubLink = ({
    title,
    to,
    onNavigate,
}) => (
    <span
        className={`nav__link ${window.location.hash.includes(to) ? 'active' : ''}`}
        onClick={() => {
            history.push(to);
            onNavigate();
        }}
    >
        {title}
    </span>
);

const alphabetical = (a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) return -1;
    if (a.toLowerCase() > b.toLowerCase()) return 1;
    return 0;
};

export {
    SideNav,
    Nav,
    NavigationLink,
    SubLink,
    ProfileMenu,
    NyleLogo,
};
