import React from 'react';
import { BAUD_RATES } from '../utils/constants';
import {
    ActionRow,
    DropdownRow,
    InputRow,
} from './settings-rows';

export const ModbusSettings = ({
    device,
    updateUserSetting,
}) => {
    const { user } = device;
    return (
        <div>
            <h2>Modbus TCP Settings</h2>
            <table className="table device-detail-table">
                <tbody>
                    <ActionRow
                        handler={() => updateUserSetting('modbus.TCP.enable', !(user.modbus?.TCP.enable ?? false))}
                        title='Enabled'
                        value={user.modbus?.TCP.enable ? 'True' : 'False'}
                        buttonTitle={user.modbus?.TCP.enable ? 'Disable' : 'Enable'}
                        buttonStyleClass='btn-outline-dark'
                    />
                    <DropdownRow
                        label='Mode'
                        value={user.modbus?.TCP.mode}
                        handler={value => updateUserSetting('modbus.TCP.mode', Number(value))}
                        data={[1, 2]}
                    />
                    <InputRow
                        title='Port'
                        value={user.modbus?.TCP.port}
                        handler={value => updateUserSetting('modbus.TCP.port', Number(value))}
                        inputProps={{
                            type: 'number',
                            min: 0,
                            max: 65535,
                        }}
                        buttonStyleClass='btn-outline-dark'
                    />
                    <InputRow
                        title='Device Id'
                        value={user.modbus?.TCP.deviceId}
                        handler={value => updateUserSetting('modbus.TCP.deviceId', Number(value))}
                        inputProps={{
                            type: 'number',
                            min: 0,
                            max: 16,
                        }}
                        buttonStyleClass='btn-outline-dark'
                    />
                    <InputRow
                        title='IP Address'
                        value={user.modbus?.TCP.ipaddress}
                        handler={value => updateUserSetting('modbus.TCP.ipaddress', value)}
                        buttonStyleClass='btn-outline-dark'
                    />
                    <InputRow
                        title='Control Interval'
                        value={user.modbus?.TCP.ctrlInterval}
                        handler={value => updateUserSetting('modbus.TCP.ctrlInterval', Number(value))}
                        inputProps={{
                            type: 'number',
                            min: 1000,
                            max: 20000,
                        }}
                        buttonStyleClass='btn-outline-dark'
                    />
                </tbody>
            </table>
            <h2>Modbus RTU Settings</h2>
            <table className="table device-detail-table">
                <tbody>
                    <ActionRow
                        handler={() => updateUserSetting('modbus.RTU.enable', !(user.modbus?.RTU.enable ?? false))}
                        title='Enabled'
                        value={user.modbus?.RTU.enable ? 'True' : 'False'}
                        buttonTitle={user.modbus?.RTU.enable ? 'Disable' : 'Enable'}
                        buttonStyleClass='btn-outline-dark'
                    />
                    <DropdownRow
                        label='Mode'
                        value={user.modbus?.RTU.mode}
                        handler={value => updateUserSetting('modbus.RTU.mode', Number(value))}
                        data={[1, 2]}
                    />
                    <InputRow
                        title='Device Id'
                        value={user.modbus?.RTU.deviceId}
                        handler={value => updateUserSetting('modbus.RTU.deviceId', Number(value))}
                        inputProps={{
                            type: 'number',
                            min: 1,
                            max: 247,
                        }}
                        buttonStyleClass='btn-outline-dark'
                    />
                    <DropdownRow
                        label='BAUD Rate'
                        value={user.modbus?.RTU.baudRate}
                        handler={value => updateUserSetting('modbus.RTU.baudRate', Number(value))}
                        data={BAUD_RATES}
                    />
                    <InputRow
                        title='Control Interval'
                        value={user.modbus?.RTU.ctrlInterval}
                        handler={value => updateUserSetting('modbus.RTU.ctrlInterval', Number(value))}
                        inputProps={{
                            type: 'number',
                            min: 1000,
                            max: 20000,
                        }}
                        buttonStyleClass='btn-outline-dark'
                    />
                </tbody>
            </table>
        </div>
    );
};
