import moment from 'moment-timezone';
import {
    getCurrentTimezone,
} from '../utils/time';

const ONE_HOUR_MS = 1000 * 60 * 60;

const makeReadableTime = (ms) => {
    const hours = ms / ONE_HOUR_MS;
    if (hours >= 1) return `${Math.round(hours)} hours`;
    const minutes = hours * 60;
    if (minutes >= 1) return `${Math.round(minutes)} minutes`;
    return `${Math.round(minutes * 60)} seconds`;
};

const formatTimestamp = (ts, dateFormat = 'M/D/YYYY,') => {
    const timezone = getCurrentTimezone();
    return moment(ts).format(`${dateFormat} h:mm:ss A [${timezone}]`);
};

export {
    makeReadableTime,
    formatTimestamp,
};
