import {
    translateWindowToRange,
    toWindowId,
} from '../../utils/windows';
import {
    getWindowBounds as getOperatorGraphBounds,
} from '../vpps/selectors';

import {
    COMPLETED_PEAKS_WINDOW_KEY,
    SCHEDULED_PEAKS_WINDOW_KEY,
} from './reducers';

const getActiveVppPeaks = (state, vppId) => state.peaks[vppId || state.vpps.activeVpp] || {};

function getDisplayedPeaks({
    completed,
}) {
    const intervalKey = completed ? COMPLETED_PEAKS_WINDOW_KEY : SCHEDULED_PEAKS_WINDOW_KEY;
    return (state) => {
        const peaks = getActiveVppPeaks(state);
        const displayedWindow = peaks[intervalKey];
        if (!displayedWindow) return null;
        const displayedWindowId = toWindowId(displayedWindow);
        const displayedPeakIds = peaks.intervals[displayedWindowId];
        if (!displayedPeakIds) return null;
        const displayedPeaks = displayedPeakIds.map(peakId => peaks.peaks[peakId]);
        displayedPeaks.sort(completed
            ? (a, b) => b.startTS - a.startTS
            : (a, b) => a.startTS - b.startTS);
        return displayedPeaks;
    };
}

const getDisplayedCompletedPeaks = getDisplayedPeaks({
    completed: true,
});

const getDisplayedScheduledPeaks = getDisplayedPeaks({
    completed: false,
});

function getWindowAsBounds(state, completed = true) {
    const interval = getCurrentWindow(state, completed);
    if (!interval) {
        return {
            lowerTS: null,
            upperTS: null,
        };
    }
    return translateWindowToRange(interval);
}

function getCurrentWindow(state, completed = true) {
    const peaks = getActiveVppPeaks(state);
    return peaks[completed ? COMPLETED_PEAKS_WINDOW_KEY : SCHEDULED_PEAKS_WINDOW_KEY];
}

function getPeaksInRange(state, {
    lowerTS,
    upperTS,
    vppId,
}) {
    const { peaks } = getActiveVppPeaks(state, vppId);
    if (!peaks) return [];
    return Object.values(peaks).filter(({
        startTS,
        endTS,
    }) => startTS <= upperTS && endTS >= lowerTS);
}

function getPeaksInGraphRange(state, vppId) {
    const {
        startTS: lowerTS,
        endTS: upperTS,
    } = getOperatorGraphBounds(state, vppId);
    const peaks = getPeaksInRange(state, {
        lowerTS,
        upperTS,
        vppId,
    });
    if (!state.peaks[vppId]) return [];
    const {
        activePeak,
    } = state.peaks[vppId];
    if (activePeak) peaks.push(activePeak);
    return peaks.map(({
        startTS,
        endTS,
        type,
    }) => ({
        startTS,
        endTS,
        type,
    }));
}

export {
    getCurrentWindow,
    getDisplayedCompletedPeaks,
    getDisplayedScheduledPeaks,
    getWindowAsBounds,
    getPeaksInRange,
    getPeaksInGraphRange,
};
