import moment from 'moment';
import {
    timeDay,
} from 'd3-time';
import queryString from 'query-string';

const ALL_TIME = 'ALL_TIME';
const NOW = 'NOW';

const ONE_WEEK = 'P-7D';
const TWO_WEEKS = 'P-14D';
const ONE_MONTH = 'P-30D';

function translateWindowToRange({
    anchor,
    duration,
    padStartMS = 0,
}) {
    if (duration === ALL_TIME) {
        return {
            lowerTS: null,
            upperTS: null,
        };
    }
    const parsedDuration = moment.duration(duration);
    const anchorMoment = moment(anchor === NOW ? Date.now() : anchor);
    const otherMoment = anchorMoment.clone().add(parsedDuration);
    return {
        lowerTS: Math.min(anchorMoment, otherMoment) - padStartMS,
        upperTS: Math.max(anchorMoment, otherMoment),
    };
}

const toWindowId = ({
    duration,
    anchor,
    vppId = null,
    bucket = null,
}) => {
    if (!vppId && !bucket) return `${anchor.valueOf()}-${duration}`;
    if (!bucket) return `${vppId}-${anchor.valueOf()}-${duration}`;
    return `${vppId}-${anchor.valueOf()}-${duration}-${bucket}`;
};

const moveWindow = (forward = true) => ({
    duration,
    anchor,
    vppId,
}) => {
    const method = forward ? 'add' : 'subtract';
    const parsedDuration = moment.duration(duration);
    const anchorMoment = moment(anchor);
    anchorMoment[method](parsedDuration);
    return {
        duration,
        anchor: anchorMoment.toDate(),
        vppId,
    };
};

const nextWindow = moveWindow();
const previousWindow = moveWindow(false);

const anchorCeil = (
    anchor,
    unit = timeDay,
) => unit.ceil(anchor === NOW ? Date.now() : anchor);

const anchorFloor = (
    anchor,
    unit = timeDay,
) => unit.floor(anchor === NOW ? Date.now() : anchor);

const getAnchorFromQueryString = (location) => {
    const {
        anchor: anchorString = NOW,
    } = queryString.parse(location.search);
    if (anchorString === NOW) return anchorFloor(NOW);
    const anchor = moment(anchorString);
    return anchor.toDate();
};

const getDurationFromQueryString = (location, {
    _default = ONE_WEEK,
    max = null,
} = {}) => {
    const {
        duration = _default,
    } = queryString.parse(location.search);
    const mDuration = moment.duration(duration);
    if (!mDuration.isValid()) {
        return _default;
    }
    if (max && Math.abs(mDuration) > Math.abs(moment.duration(max))) {
        return _default;
    }
    return duration;
};

export {
    ALL_TIME,
    ONE_WEEK,
    TWO_WEEKS,
    ONE_MONTH,
    NOW,
    translateWindowToRange,
    toWindowId,
    nextWindow,
    previousWindow,
    anchorCeil,
    anchorFloor,
    getAnchorFromQueryString,
    getDurationFromQueryString,
};
