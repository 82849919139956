/* globals window */
import queryString from 'query-string';
import {
    getParsedQueryString,
} from '../../utils/history';

function getActiveVppFromUser(user) {
    const desiredVpp = getVPPFromQueryString();
    if (desiredVpp && canAccessVPP({
        user,
        vppId: desiredVpp,
    })) return desiredVpp;
    return getPrimaryVPPFromUser(user.vpps);
}

function getVPPFromQueryString() {
    const parsedQueryString = queryString.parse(window.location.search);
    const parsedHashQueryString = getParsedQueryString();
    return parsedQueryString.vpp || parsedHashQueryString.vpp || null;
}

function canAccessVPP({
    user,
    vppId,
}) {
    if (user.admin) return true;
    const isOperator = (user.vpps[vppId] || {}).role === 'operator';
    return isOperator;
}

function getPrimaryVPPFromUser(vpps) {
    const primaryVpp = Object.keys(vpps).find(vpp => vpps[vpp].primary);
    return primaryVpp;
}

export {
    getActiveVppFromUser,
};
