import React from 'react';
import {
    connect,
} from 'react-redux';

import {
    showWindow,
    nextWindow,
    previousWindow,
} from '../../models/peaks/actions';
import {
    getCurrentWindow,
    getDisplayedCompletedPeaks,
    getWindowAsBounds,
} from '../../models/peaks/selectors';
import {
    shouldShowPeakValues,
} from '../../models/user/selectors';

class _DonePeaksContainer extends React.Component {
    componentDidMount() {
        const {
            onMount,
            vppId,
        } = this.props;
        onMount({
            vppId,
        });
    }

    render() {
        const {
            render,
            nextPage,
            previousPage,
            interval,
            showPeakValues,
        } = this.props;

        return render({
            ...this.props,
            nextPage: () => nextPage(interval),
            previousPage: () => previousPage(interval),
            showPeakValues,
        });
    }
}

const mapStateToProps = state => ({
    interval: getCurrentWindow(state),
    ...getWindowAsBounds(state),
    peaks: getDisplayedCompletedPeaks(state),
    vppId: state.vpps.activeVpp,
    showPeakValues: shouldShowPeakValues(state),
});

const mapDispatchToProps = dispatch => ({
    onMount: ({
        vppId,
    }) => dispatch(showWindow({
        vppId,
    })),
    nextPage: ({
        vppId,
        anchor,
        duration,
    }) => dispatch(nextWindow({
        vppId,
        anchor,
        duration,
    })),
    previousPage: ({
        vppId,
        anchor,
        duration,
    }) => dispatch(previousWindow({
        vppId,
        anchor,
        duration,
    })),
});

const DonePeaksContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_DonePeaksContainer);

export {
    DonePeaksContainer,
};
