import React from 'react';
import {
    colors,
} from '../styles/colors';
import {
    Chart,
} from './chart';

const PowerChart = ({
    actualPowerSeries,
    actualPowerColor = colors.primaryBlue,
    baselinePowerSeries,
    baselinePowerColor = colors.orange,
    xDomain,
    yDomain,
    tickvals,
    settingsHandler = () => {},
    adjustForLargeScreen = false,
    showBaseline,
    shadedRegions = [],
    showSettings,
    includeShadedData,
}) => {
    const data = [
        Object.assign({
            type: 'line',
            name: 'Actual',
            marker: {
                color: actualPowerColor,
            },
        }, actualPowerSeries),
    ];
    if (showBaseline) {
        data.push(Object.assign({
            type: 'line',
            name: 'Baseline',
            mode: 'lines',
            marker: {
                color: baselinePowerColor,
            },
        }, baselinePowerSeries));
    }

    const yAxisTitle = 'Power (kW)';

    const sharedProps = {
        xDomain,
        tickvals,
        settingsHandler,
        adjustForLargeScreen,
        shadedRegions,
        showSettings,
        includeShadedData,
    };

    return (
        <Chart
            {...sharedProps}
            data={data}
            yDomain={yDomain}
            yAxisTitle={yAxisTitle}
        />
    );
};

export {
    PowerChart,
};
