/* global window */
import React, {
    useEffect,
    useState,
} from 'react';
import {
    connect,
} from 'react-redux';
import DropdownList from 'react-widgets/lib/DropdownList';
import {
    css,
} from '@emotion/core';

import {
    Button,
} from '../../admin/devices/components/form';
import {
    updateDeviceVpp,
} from '../../models/devices/actions';
import {
    listVpps,
    userIsNyleAdmin,
} from '../../models/user/selectors';

import {
    ASYNC_STATUS,
    AsyncValue,
} from '../../utils/async';
import {
    history,
} from '../../utils/history';

const EditVppView = ({
    isNyleAdmin,
    vpp,
    status,
    vpps,
    onSubmit,
    destinationOnChange = '/devices',
    message,
}) => {
    const [waiting, setWaiting] = useState(false);
    const [editing, setEditing] = useState(false);
    const [newVpp, setNewVpp] = useState(vpp);
    useEffect(() => {
        setNewVpp(vpp);
    }, [vpp]);
    useEffect(() => {
        if (destinationOnChange && waiting && status === ASYNC_STATUS.SUCCESS) {
            // Because of the way our URLs are structured, changing a
            // device's VB causes the device to become unaccessible
            // until we swap out the active VB. Redirecting is a hack
            // to avoid propogating a bunch of errors through the UI.
            // We alert in order to tell the user what's about to happen.
            // eslint-disable-next-line no-alert
            window.alert('Success! You will be redirected back to the current virtual battery\'s device search');
            history.replace(destinationOnChange);
            return;
        }
        setWaiting(status === ASYNC_STATUS.WAITING);
    }, [status]);
    let value;
    if (editing) {
        value = (
            <DropdownList
                id="alertType"
                filter
                data={vpps}
                value={newVpp}
                onChange={setNewVpp}
                allowCreate={isNyleAdmin ? 'onFilter' : false}
                onCreate={(val) => {
                    onSubmit(val);
                    setEditing(false);
                }}
                messages={{
                    createOption: props => `Move to VB "${props.searchTerm}"`,
                }}
            />
        );
    } else if (waiting) {
        value = (
            <img
                alt="Loading..."
                css={styles.spinner}
                src='/assets/img/Spinner.gif'
            />
        );
    } else {
        value = vpp || '[none]';
    }

    const buttons = editing
        ? (
            <div
                className="btn-group"
                css={styles.buttons}
            >
                <Button
                    title="Save"
                    onClick={() => {
                        onSubmit(newVpp);
                        setEditing(false);
                    }}
                    className="btn-outline-dark btn-sm"
                />
                <Button
                    title="Cancel"
                    onClick={() => {
                        setEditing(false);
                    }}
                    className="btn-outline-dark btn-sm"
                />
            </div>
        )
        : (
            <Button
                title="Edit"
                onClick={() => setEditing(true)}
                className="btn-outline-dark btn-sm"
                disabled={waiting}
            />
        );

    return (
        <div className="row">
            <div className="col">Virtual Battery</div>
            <div className="col">{value}</div>
            <div className="col">{(isNyleAdmin || vpps.length > 1) ? buttons : null}</div>
            {
                message ? (
                    <div
                        css={[
                            styles.hint,
                            styles.errorMessage,
                        ]}
                        className="col-12"
                    >
                        {message}
                    </div>
                ) : null
            }
            {
                (isNyleAdmin && editing) ? (
                    <div
                        css={[
                            styles.hint,
                        ]}
                        className="col-12"
                    >
                        To move this device a VB not in the above list, enter the VB ID directly.
                    </div>
                ) : null
            }
        </div>
    );
};

const styles = {
    buttons: css`
        height: 2.429em;
    `,
    spinner: css`
        height: 2em;
    `,
    hint: css`
        text-align: center;
        padding-top: 0 !important;
        margin-top: -.5em;
    `,
    errorMessage: css`
        color: red;
    `,
};

function mapStateToProps(state, ownProps) {
    const device = state.devices.devices[ownProps.deviceId];
    return {
        isNyleAdmin: userIsNyleAdmin(state),
        vpps: listVpps(state),
        ...AsyncValue.unwrap(device, 'vpp'),
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        onSubmit: vppId => dispatch(updateDeviceVpp({
            deviceId: ownProps.deviceId,
            vppId,
        })),
    };
}

const EditVpp = connect(
    mapStateToProps,
    mapDispatchToProps,
)(EditVppView);

export {
    EditVpp,
};
