const getAlertsForDevice = (state, deviceId) => {
    const {
        alerts = {},
    } = state.devices.alerts;
    if (!deviceId) return Object.values(alerts);
    return Object.values(alerts).filter(item => item.deviceId === deviceId);
};

export {
    getAlertsForDevice,
};
