import {
    HANDLE_NO_USER,
    FETCH_USER_SUCCESS,
} from './actions';

const AUTH_STATUS = {
    AUTHENTICATED: 'authenticated',
    PENDING: 'authenticating',
    NOT_AUTH: 'not-authenticated',
};

const INITIAL_STATE = {
    authenticationStatus: AUTH_STATUS.PENDING,
    roles: [],
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_USER_SUCCESS: {
            const {
                user,
            } = action.payload;
            // eslint-disable-next-line no-shadow
            const roles = Object.keys(user.vpps || {}).reduce((roles, vppId) => {
                const vpp = user.vpps[vppId];
                if (vpp.role) return [...roles, `${vppId}:${vpp.role}`];
                if (vpp.roles) return [...roles, ...vpp.roles.map(r => `${vppId}:${r}`)];
                return roles;
            }, []);
            return {
                ...state,
                authenticationStatus: AUTH_STATUS.AUTHENTICATED,
                ...user,
                roles,
            };
        }
        case HANDLE_NO_USER: {
            return {
                ...state,
                authenticationStatus: AUTH_STATUS.NOT_AUTH,
            };
        }
        default: return state;
    }
};

export {
    reducer,
    AUTH_STATUS,
};
