import React from 'react';
import {
    css,
} from '@emotion/core';
import './DeviceDetails.css';
import {
    CustomerNotes,
    UserSettings,
    DeviceSettings,
    ConnectionStatusRow,
    ErrorMessage,
} from './components';
import {
    EditVpp,
} from '../../devices/edit-vpp';
import {
    UninstalledAt,
} from '../../devices/UninstalledAt';
import { ModbusSettings } from './components/modbus-settings';
import { CTA2045Settings } from './components/cta2045-settings';
import { BacnetSettings } from './components/bacnet-settings';
import AlertSettings from './components/AlertSettings';

const styles = {
    deviceDetailsTable: css`
        max-width: 750px;
        margin-bottom: 32px;
        & > .row {
            border-bottom: 1px solid #ccc;
            margin: 0;
        }
        & > .row > div {
            padding: .75em 0;
        }
        & > .row > div:first-of-type {
            padding-left: 0;
        }
        & > .row > div:last-child {
            padding-right: 0;
        }
    `,
    errorWrapper: css`
        margin-top: 8px;
    `,
};

const DeviceDetails = ({
    alertOptions,
    device,
    acknowledgeOffline,
    editCustomerNotes,
    toggleDebugging,
    rebootDevice,
    updateUserSetting,
    changeDeviceVPP,
    errorMessage,
    isFaultyDevice,
    setFaultyDeviceFlag,
    getFaultyDeviceFlag,
    userHasAdminAccess = false,
    userHasOemAccess = false,
    updateDeviceParent,
    plcConnectivity,
    compRunTime,
    isDeviceOwner,
}) => {
    const isDebugging = device.debugInterval > 0;

    // the conditions are all the same for now but they are seperate
    // in case the conditions change in the future
    const showModbusSettings = ['NyleComm'].includes(device.productType) && userHasAdminAccess;
    const showCTA2045Settings = ['Nyle', 'NyleComm'].includes(device.productType);
    const showBacNetSettings = ['NyleComm'].includes(device.productType);
    const showAlertSettings = ['NyleComm'].includes(device.productType);

    return (
        <div>
            {userHasAdminAccess && (
                <CustomerNotes
                    customerNotes={device.customerNotes}
                    onSave={editCustomerNotes}
                />
            )}
            <div css={styles.deviceDetailsTable}>
                <ConnectionStatusRow
                    device={device}
                    onAcknowledgeOffline={acknowledgeOffline}
                    rebootDevice={rebootDevice}
                    plcConnectivity={plcConnectivity}
                    compRunTime={compRunTime}
                    showActions={!isDeviceOwner}
                />
                {!isDeviceOwner && (
                    <>
                        <EditVpp
                            deviceId={device.deviceId}
                            onSave={changeDeviceVPP}
                        />
                        <UninstalledAt deviceId={device.deviceId} />
                    </>
                )}
                <div css={styles.errorWrapper}>
                    <ErrorMessage errorMessage={errorMessage} />
                </div>
            </div>
            {!isDeviceOwner && (
                <DeviceSettings
                    isDebugging={isDebugging}
                    toggleDebugging={toggleDebugging}
                    device={device}
                    isFaultyDevice={isFaultyDevice}
                    setFaultyDeviceFlag={setFaultyDeviceFlag}
                    getFaultyDeviceFlag={getFaultyDeviceFlag}
                    userHasAdminAccess={userHasAdminAccess}
                    userHasOemAccess={userHasOemAccess}
                    updateDeviceParent={updateDeviceParent}
                />
            )}
            <UserSettings
                device={device}
                updateUserSetting={updateUserSetting}
            />
            {showModbusSettings
                && (
                    <ModbusSettings
                        device={device}
                        updateUserSetting={updateUserSetting}
                    />
                )
            }
            {showCTA2045Settings
                && (
                    <CTA2045Settings
                        device={device}
                        updateUserSetting={updateUserSetting}
                    />
                )
            }
            {showBacNetSettings
                && (
                    <BacnetSettings
                        device={device}
                        updateUserSetting={updateUserSetting}
                    />
                )
            }
            {showAlertSettings
                && (
                    <AlertSettings
                        alertOptions={alertOptions}
                        device={device}
                        getDeviceShadow={getFaultyDeviceFlag}
                        updateUserSetting={updateUserSetting}
                    />
                )
            }
        </div>
    );
};

export default DeviceDetails;
