import React, { useState } from 'react';
import {
    DeviceAlerts,
} from './alerts/device-alerts';
import {
    SearchBar,
} from './components';
import '../../operator/vpp-operator-chart/settings/index.css';
import './devices-page-view.css';
import { AdvancedSearch } from './components/AdvancedSearch';
import { DeviceList } from './components/DeviceList';

const DevicesPageView = ({
    devices,
    message,
    searchString,
    handleSubmitSearch,
    handleSearchChange,
    selectProductType,
    productType,
    productVersion,
    firmwareVersion,
    handleFirmwareVersionChange,
    vpps,
    vppFilter,
    selectVPPFilter,
    connectionStatus,
    deploymentStatus,
    selectConnectionFilter,
    filterByInstalled,
    toggleFilterByInstallDate,
    installedAfter,
    installedBefore,
    setInstallationRange,
    clearAllFilters,
    activeVpp,
    shouldShowSearch,
}) => {
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

    const searchColumn = (
        <>
            {shouldShowSearch && (
                <SearchBar
                    searchString={searchString}
                    handleSubmitSearch={handleSubmitSearch}
                    handleSearchChange={handleSearchChange}
                    showAdvancedSearch={showAdvancedSearch}
                    toggleAdvancedSearch={() => setShowAdvancedSearch(prevState => !prevState)}
                />
            )}
            <p>{message}</p>
            {showAdvancedSearch
                ? (
                    <AdvancedSearch
                        selectProductType={selectProductType}
                        productType={productType}
                        productVersion={productVersion}
                        firmwareVersion={firmwareVersion}
                        handleFirmwareVersionChange={handleFirmwareVersionChange}
                        handleSubmitSearch={handleSubmitSearch}
                        vpps={vpps}
                        vppFilter={vppFilter}
                        selectVPPFilter={selectVPPFilter}
                        connectionStatus={connectionStatus}
                        deploymentStatus={deploymentStatus}
                        selectConnectionFilter={selectConnectionFilter}
                        filterByInstalled={filterByInstalled}
                        toggleFilterByInstallDate={toggleFilterByInstallDate}
                        installedAfter={installedAfter}
                        installedBefore={installedBefore}
                        setInstallationRange={setInstallationRange}
                        clearAllFilters={clearAllFilters}
                        showVppFilter={!activeVpp}
                    />
                )
                : null
            }
            <DeviceList devices={devices} />
        </>
    );
    if (activeVpp) {
        return (
            <div>{searchColumn}</div>
        );
    }
    return (
        <div className="row admin__container">
            <div className="col-6 no-left-padding">
                {searchColumn}
            </div>
            <div className="col-6 no-right-padding">
                <DeviceAlerts singleDeviceView={false} />
            </div>
        </div>
    );
};

export {
    DevicesPageView,
};
