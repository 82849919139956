import React, {
    useEffect,
} from 'react';
import {
    connect,
} from 'react-redux';
import {
    Graph,
    StackedGraph,
} from './base-graphs';
import {
    colors,
} from '../../styles/colors';
import {
    fetchDeviceGraphData,
} from '../../models/devices/actions';
import {
    userIsNyleAdmin,
    userHasRole,
} from '../../models/user/selectors';
import {
    formatHeatmaps,
} from './mode-series';
import {
    withDateRange,
} from '../date-range';

const PowerGraphView = ({
    powerData,
    getData,
    startTS,
    endTS,
    debugData = [],
    connectionsData = [],
    showModeSeries,
    debugConfig,
    deviceType,
    productType,
    legend,
}) => {
    const nyleCSVData = React.useMemo(() => debugData.filter(data => data.meter !== undefined)
        .map(filteredData => ({
            timestamp: filteredData.timestamp,
            ...filteredData.meter,
        })), [debugData]);

    useEffect(() => {
        getData(showModeSeries);
    }, [startTS, endTS]);
    const graphData = [];
    const isCommercial = productType === 'NyleComm';

    switch (deviceType) {
        case 'wh1':
            // we only want to support 3 phase power for Nyle Commercial
            if (productType === 'NyleComm') {
                // for nyle we capture three phase power data from their debug logs
                // by first filtering debug logs for logs with meter data and then
                // three seperate objects, one for each phase
                const { phase1Data, phase2Data, phase3Data } = debugData
                    .filter(entry => entry?.meter != null)
                    .reduce((acc, entry) => {
                        const at = entry.timestamp;
                        acc.phase1Data.push({
                            at,
                            kW: entry.meter.phase1power,
                        });
                        acc.phase2Data.push({
                            at,
                            kW: entry.meter.phase2power,
                        });
                        acc.phase3Data.push({
                            at,
                            kW: entry.meter.phase3power,
                        });
                        return acc;
                    }, {
                        phase1Data: [],
                        phase2Data: [],
                        phase3Data: [],
                    });
                graphData.push(formatTrace({
                    data: phase1Data,
                    name: 'Phase 1 Power',
                    color: colors.primaryBlue,
                }));
                graphData.push(formatTrace({
                    data: phase2Data,
                    name: 'Phase 2 Power',
                    color: colors.secondaryBlue,
                }));
                graphData.push(formatTrace({
                    data: phase3Data,
                    name: 'Phase 3 Power',
                    color: colors.red,
                }));
            } else {
                graphData.push(formatTrace({
                    data: powerData,
                    name: 'Power',
                    color: colors.primaryBlue,
                }));
            }
            break;
        default:
            graphData.push(formatTrace(powerData));
            break;
    }

    if (showModeSeries) {
        const {
            modeData,
            modeScales,
        } = formatHeatmaps({
            debugData,
            debugConfig,
            connectionsData,
            endTS,
        });
        return (
            <div>
                <StackedGraph
                    exportData={productType === 'NyleComm' ? nyleCSVData : powerData}
                    legend={legend}
                    upperChartData={graphData}
                    lowerChartData={modeData}
                    xDomain={[startTS, endTS]}
                    yAxisTitleUpper={isCommercial ? 'Power (Watts)' : 'Power (kW)'}
                />
                {modeScales}
            </div>
        );
    }
    return (
        <Graph
            exportData={powerData}
            legend={legend}
            data={graphData}
            xDomain={[startTS, endTS]}
            yAxisTitleUpper={isCommercial ? 'Power (Watts)' : 'Power (kW)'}
        />
    );
};

function formatTrace({
    data,
    name,
    color = colors.primaryBlue,
}) {
    return {
        x: data.map(o => o.at),
        y: data.map(o => o.kW),
        type: 'line',
        name,
        marker: {
            color,
        },
    };
}

const mapStateToProps = (state, props) => {
    const {
        deviceId,
    } = props;
    const {
        powerData,
        graphRange,
        debugData,
        connectionsData,
        device: {
            debugConfig,
        },
    } = state.devices.devices[deviceId];
    return {
        powerData,
        debugData,
        connectionsData,
        showModeSeries: userHasRole(state, 'oem') || userIsNyleAdmin(state),
        debugConfig,
        ...graphRange,
    };
};

const mapDispatchToProps = (dispatch, props) => ({
    getData: (showModeSeries) => {
        dispatch(fetchDeviceGraphData({
            deviceId: props.deviceId,
            dataType: 'power',
        }));
        dispatch(fetchDeviceGraphData({
            deviceId: props.deviceId,
            dataType: 'connections',
        }));
        if (showModeSeries) {
            dispatch(fetchDeviceGraphData({
                deviceId: props.deviceId,
                dataType: 'debug',
            }));
        }
    },
});

const PowerGraph = withDateRange(connect(
    mapStateToProps,
    mapDispatchToProps,
)(PowerGraphView));

export {
    PowerGraph,
};
