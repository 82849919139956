import React from 'react';
import {
    DropdownRow,
    InputRow,
} from './settings-rows';

// TODO: Find way to get sepoint from unit so theres no discrepancy

const UserSettings = ({
    device,
    updateUserSetting,
}) => {
    const header = (<h2>User Settings</h2>);
    switch (device.deviceType) {
        case 'wh1': {
            const { user } = device;
            return (
                <div>
                    {header}
                    <table className="table device-detail-table">
                        <tbody>
                            <InputRow
                                title='Setpoint'
                                value={user.setpoint}
                                handler={value => updateUserSetting('setpoint', Number(value))}
                                inputProps={{
                                    type: 'number',
                                    min: 100,
                                    max: 140,
                                }}
                                buttonStyleClass='btn-outline-dark'
                            />
                            {device.productType === 'NyleComm'
                                && (
                                    <InputRow
                                        title='Leaving Water Setpoint'
                                        value={user.leavingWaterSetpoint}
                                        handler={value => updateUserSetting('leavingWaterSetpoint', Number(value))}
                                        inputProps={{
                                            type: 'number',
                                            min: 0,
                                        }}
                                        buttonStyleClass='btn-outline-dark'
                                    />
                                )
                            }
                            <DropdownRow
                                label='Mode'
                                value={user.mode}
                                handler={value => updateUserSetting('mode', value)}
                                data={['Normal', 'Vacation', 'Party']}
                            />
                        </tbody>
                    </table>
                </div>
            );
        }
        case 'ev0':
        default: {
            return null;
        }
    }
};

export {
    UserSettings,
};
