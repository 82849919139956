import React from 'react';
import {
    dateFormatter,
} from '../../utils';
import {
    getCurrentTimezone,
} from '../../../utils/time';
import {
    PastPeakEvent,
} from './past-peak-event';
import {
    PeakEventViewWrapper,
} from '../../components/peak-event-view-wrapper';
import {
    PeakListPaginator,
} from '../../components/peak-list-paginator';

export const PastPeakEventList = ({
    events,
    lowerTS,
    upperTS,
    nextPage,
    previousPage,
    showPeakValues = false,
}) => {
    const timezone = getCurrentTimezone();
    // typeof events === 'undefined' || events === null could be simplified to
    // events == null, but this is more explicit
    const noPeaksMessage = (
        <div className='row'>
            <div className='col-12 peak-scheduler__peak'>
                {`${typeof events === 'undefined' || events === null ? 'Fetching' : 'No'} peaks scheduled between ${dateFormatter(lowerTS)} and ${dateFormatter(upperTS)}`}
            </div>
        </div>
    );
    const columnHeaders = [
        'Peak State',
        `Start (${timezone})`,
        `End (${timezone})`,
    ];
    if (showPeakValues) columnHeaders.push('Performance');
    return (
        <div className="peak-scheduler__peak-list">
            <PeakEventViewWrapper
                columns={columnHeaders}
                className='peak-scheduler__peak--header'
            />
            {events && events.length
                ? events.map(e => (
                    <PastPeakEvent key={e.startTS} {...e} showPeakValues={showPeakValues} />
                ))
                : noPeaksMessage
            }
            <div className='row justify-content-center peak-scheduler__pagination-container'>
                <PeakListPaginator
                    lowerTS={lowerTS}
                    upperTS={upperTS}
                    next={nextPage}
                    previous={previousPage}
                    paginateForward={false}
                />
            </div>
        </div>
    );
};
