import React from 'react';
import {
    schemeTableau10 as defaultColorScale,
    schemePaired as defaultColorScale2,
} from 'd3-scale-chromatic';
import {
    get as _get,
} from 'lodash';
import './mode-series.css';

/*
Heatmap discrete color scales map percentage ranges of the data to specific colors.
The goal is to create a scale where each state maps to the middle of the range
for its color. E.g. color 0 should cover values [0, 0.5], color 1 should cover [0.5, 1.5],
colors 2 should cover [1.5, 2.5], ..., color n-1 should cover [(n-1) - 0.5, n-1]
(where n is the number of colors)
*/
const getColorscale = (allColors, numModes) => allColors.reduce((result, color, k) => {
    const denominator = 2 * numModes;
    return result.concat([
        [Math.max(0, (2 * k) - 1) / denominator, color],
        [Math.min(denominator, (2 * k) + 1) / denominator, color],
    ]);
}, []);

const getPemStateId = (state, modes) => {
    const index = modes.indexOf(state);
    return index >= 0 ? index : modes.length;
};

const getColorsWithDefault = (colors, i, numModes) => {
    const defaultScale = i % 2 === 0 ? defaultColorScale : defaultColorScale2;
    return (colors || []).concat(defaultScale).slice(0, numModes).concat('#c4c4c4');
};

const formatHeatmaps = ({
    debugData,
    debugConfig = {},
    connectionsData,
    endTS,
}) => {
    const modeData = [];
    const modeScales = [];
    formatPemStateDataHeatmap({
        debugData,
        debugConfig,
        modeData,
        modeScales,
        endTS,
    });
    formatConnectionsDataHeatmap({
        connectionsData,
        modeData,
        modeScales,
        endTS,
    });
    return {
        modeData,
        modeScales: (
            <div>
                {modeScales}
            </div>
        ),
    };
};

const formatPemStateDataHeatmap = ({
    debugData = [],
    debugConfig = {},
    modeData = [],
    modeScales = [],
    endTS,
}) => {
    const {
        modes: modeKeys = [],
    } = debugConfig;
    modeKeys.forEach((key, i) => {
        const config = debugConfig[`@mode/${key}`];
        const {
            debugPath,
            name = '',
            values = [],
            colors: colorsFromConfig,
        } = config;
        const timestamps = [];
        const modes = [];
        const modeSeries = [];
        const allModes = values.slice();
        debugData.forEach((d) => {
            const mode = _get(d, debugPath);
            if (mode == null) return;
            timestamps.push(d.timestamp);
            modes.push(mode);
            modeSeries.push(getPemStateId(mode, allModes));
        });
        const dataHasUnknown = modeSeries.includes(allModes.length);
        if (debugData.length > 0) {
            // Add virtual obs at end of window to make single data points display correctly
            modeSeries.push(modeSeries[modeSeries.length - 1]);
            timestamps.push(endTS || Date.now());
            // Extra values are added to make sure color scale stays consistent:
            // need to guarantee that min and max values are present (kind of a hack)
            modeSeries.push(0);
            timestamps.push(timestamps[timestamps.length - 1] + 0.1);
            modeSeries.push(allModes.length);
            timestamps.push(timestamps[timestamps.length - 1] - 0.1);
        }
        // heatmap will consider x values as the edges of each cell if x has an extra value
        // see https://plotly.com/javascript/reference/heatmap/
        timestamps.push(endTS || Date.now());
        const colors = getColorsWithDefault(colorsFromConfig, i, allModes.length);
        const colorscale = getColorscale(colors, allModes.length);
        // If we've encountered an unknown mode in the actual data series,
        // add a gray 'Unknown' bar to the legend
        if (dataHasUnknown) {
            allModes.push('Unknown');
            colors.push('#c4c4c4');
        }
        modeData.push({
            z: [modeSeries],
            x: timestamps,
            type: 'heatmap',
            name,
            colorscale,
            showscale: false,
            hoverinfo: 'skip',
            // hover labels are causing infinite recursion https://github.com/plotly/plotly.js/issues/1640
            // hoverinfo: 'text',
            text: [modes],
        });
        modeScales.push((
            <ModeScale
                modes={allModes}
                colors={colors}
                title={name}
            />
        ));
    });
};

const formatConnectionsDataHeatmap = ({
    connectionsData = [],
    modeData = [],
    modeScales = [],
    endTS,
}) => {
    const name = 'Online';
    const allPossibleTextValues = ['Offline', 'Online'];
    const numericToTextMap = {
        0: 'Offline',
        1: 'Online',
    };
    const timestampPath = 'at';
    const dataPath = 'connected';
    const colorsFromConfig = ['#e15759', '#b2df8a'];

    const timestamps = [];
    const numericSeries = [];
    const textSeries = [];
    let dataHasUnknown = false;

    connectionsData.forEach((d) => {
        let data = _get(d, dataPath);
        data = Number(!!data); // change virtual-connected to connected
        const timestamp = _get(d, timestampPath);
        timestamps.push(timestamp);
        numericSeries.push(data);
        const textValue = _get(numericToTextMap, data);
        if (textValue) {
            textSeries.push(textValue);
        } else {
            textSeries.push('Unknown');
            dataHasUnknown = true;
        }
    });
    if (connectionsData.length > 0) {
        // Add virtual obs at end of window to make single data points display correctly
        numericSeries.push(numericSeries[numericSeries.length - 1]);
        timestamps.push(endTS || Date.now());
        // Extra values are added to make sure color scale stays consistent:
        // need to guarantee that min and max values are present (kind of a hack)
        numericSeries.push(0);
        timestamps.push(timestamps[timestamps.length - 1] + 0.1);
        numericSeries.push(allPossibleTextValues.length);
        timestamps.push(timestamps[timestamps.length - 1] - 0.1);
    }
    // heatmap will consider x values as the edges of each cell if x has an extra value
    // see https://plotly.com/javascript/reference/heatmap/
    timestamps.push(endTS || Date.now());
    const colors = getColorsWithDefault(colorsFromConfig,
        modeScales.length, allPossibleTextValues.length);
    const colorscale = getColorscale(colors, allPossibleTextValues.length);
    // If we've encountered an unknown mode in the actual data series,
    // add a gray 'Unknown' bar to the legend
    if (dataHasUnknown) {
        allPossibleTextValues.push('Unknown');
        colors.push('#c4c4c4');
    }
    modeData.push({
        z: [numericSeries],
        x: timestamps,
        type: 'heatmap',
        name,
        colorscale,
        showscale: false,
        hoverinfo: 'skip',
        // hover labels are causing infinite recursion https://github.com/plotly/plotly.js/issues/1640
        // hoverinfo: 'text',
        text: [textSeries],
    });
    modeScales.push((
        <ModeScale
            modes={allPossibleTextValues}
            colors={colors}
            title={name}
        />
    ));
};

const ModeScale = ({
    modes,
    colors,
    title,
}) => (
    <div className="row mode-series__scale">
        <div className="col-auto">
            <p>
                {title}
                :&nbsp;
            </p>
        </div>
        <div className="col">
            <div className="row justify-content-center">
                {modes.map((state, i) => {
                    const color = colors[i];
                    return (
                        <div className="col-xs text-center" key={state}>
                            <div
                                style={{ backgroundColor: color }}
                                className="mode-series__scale-colorbar"
                            >
                                &nbsp;
                            </div>
                            <small><p className="mode-series__scale-label">{state}</p></small>
                        </div>
                    );
                })}
            </div>
        </div>
    </div>
);

export {
    formatHeatmaps,
};
