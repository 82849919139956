import React from 'react';
import {
    connect,
} from 'react-redux';

import {
    DateTimePicker,
} from '../lib/react-widgets';
import {
    setDeviceGraphRange,
} from '../models/devices/actions';

const DateRangeView = ({
    startTS,
    endTS,
    setGraphRange,
    showInvalidDateError = () => {},
    dividerText = 'to',
    additionalButtons = null,
    disabled = false,
}) => (
    <div className="row" style={{ padding: '10px 0px' }}>
        <div className="col-sm">
            <DateTimePicker
                value={new Date(startTS)}
                onChange={(date) => {
                    if (!date) return void showInvalidDateError('start');
                    setGraphRange({
                        startTS: date.getTime(),
                        endTS,
                    });
                }}
                disabled={disabled}
                style={{ minWidth: 225 }}
            />
        </div>
        <div className="col-xs" style={{ paddingTop: 8 }}>
            <span>{dividerText}</span>
        </div>
        <div className="col-sm">
            <DateTimePicker
                value={new Date(endTS)}
                onChange={(date) => {
                    if (!date) return void showInvalidDateError('end');
                    setGraphRange({
                        startTS,
                        endTS: date.getTime(),
                    });
                }}
                disabled={disabled}
                style={{ minWidth: 200 }}
            />
        </div>
        <div className="col-xs">
            <button
                onClick={() => {
                    setGraphRange({
                        startTS,
                        endTS: Date.now(),
                    });
                }}
                disabled={disabled}
                className="btn btn-outline-dark"
            >
                Now
            </button>
        </div>
        {additionalButtons
            ? (
                <div className="col-xs">
                    {additionalButtons}
                </div>
            ) : null
        }
    </div>
);

const mapStateToProps = (state, props) => {
    const {
        deviceId,
    } = props;
    const {
        graphRange,
    } = state.devices.devices[deviceId] || {};
    return {
        ...graphRange,
    };
};

const mapDispatchToProps = (dispatch, props) => ({
    setGraphRange: ({
        startTS,
        endTS,
    }) => dispatch(setDeviceGraphRange({
        deviceId: props.deviceId,
        startTS,
        endTS,
    })),
});

const DateRange = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DateRangeView);

function withDateRange(Child) {
    return ({
        deviceId,
        showInvalidDateError,
        ...props
    }) => (
        <>
            <DateRange
                deviceId={deviceId}
                showInvalidDateError={showInvalidDateError}
            />
            <Child {...props} deviceId={deviceId} />
        </>
    );
}

export {
    withDateRange,
};
