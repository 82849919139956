import React from 'react';

import {
    TabPicker,
} from './tab-picker';
import {
    DirectionPicker,
} from './direction-picker';
import {
    AnchorPicker,
} from './anchor-picker';
import {
    formatTimestamp,
    DISPLAY_TABS,
} from '../utils';
import {
    NOW,
} from '../../../../utils/windows';
import {
    ENERGY_BUCKETS,
    DISPLAY_TYPES,
} from '../../../../models/vpps/utils';

const Message = ({ message }) => (
    <div className='dtwp__message'>{message}</div>
);

const SettingsView = ({
    anchor,
    direction,
    displayTypes,
    handleClose,
    handleAnchorChange,
    handleDirectionChange,
    handleWindowChange,
    handleDisplayTypeChange,
    message,
    handleFlashMessage,
    windows,
}) => (
    <div className='dtwp dtwp--expanded'>
        <button type='button' className='btn btn-link dtwp__toggle' onClick={handleClose}>
            <i className='fa fa-close' aria-hidden='true' />
        </button>
        <h4>Display</h4>
        <TabPicker
            name='display-types'
            values={displayTypes}
            handleChange={(value) => {
                if (value === DISPLAY_TABS.ENERGY_ONE_HOUR) {
                    handleDisplayTypeChange({
                        displayType: DISPLAY_TYPES.ENERGY,
                        bucketSize: ENERGY_BUCKETS.ONE_HOUR,
                    });
                } else if (value === DISPLAY_TABS.ENERGY_FIVE_MINUTES) {
                    handleDisplayTypeChange({
                        displayType: DISPLAY_TYPES.ENERGY,
                        bucketSize: ENERGY_BUCKETS.FIVE_MINUTES,
                    });
                } else {
                    handleDisplayTypeChange({
                        displayType: DISPLAY_TYPES.POWER,
                    });
                }
            }}
        />
        <hr />
        <h4>Window</h4>
        <TabPicker
            values={windows}
            handleChange={handleWindowChange}
            name='windows'
            formatTabLabel={formatTimestamp}
        />
        <DirectionPicker
            direction={direction}
            disableStartAndCenter={anchor === NOW}
            handleChange={handleDirectionChange}
            handleFailedChange={handleFlashMessage}
        />
        <AnchorPicker
            anchor={anchor}
            handleChange={handleAnchorChange}
            handleFailedChange={handleFlashMessage}
        />
        { message ? <Message message={message} /> : null }
    </div>
);

export {
    SettingsView,
};
