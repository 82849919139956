const FETCH_DEVICES = 'devices/FETCH_DEVICES';
const FETCH_DEVICES_SUCCESS = 'devices/FETCH_DEVICES_SUCCESS';
const FETCH_DEVICES_FAILURE = 'devices/FETCH_DEVICES_FAILURE';
const fetchDevices = (operatorVPP = null, searchString) => ({
    type: FETCH_DEVICES,
    payload: {
        operatorVPP,
        searchString,
    },
});

const FETCH_USER_DEVICES = 'devices/FETCH_USER_DEVICES';
const FETCH_USER_DEVICES_SUCCESS = 'devices/FETCH_USER_DEVICES_SUCCESS';
const FETCH_USER_DEVICES_FAILURE = 'devices/FETCH_USER_DEVICES_FAILURE';
const fetchUserDevices = () => ({
    type: FETCH_USER_DEVICES,
    payload: {},
});

const FETCH_VPP_LIST = 'devices/FETCH_VPP_LIST';
const FETCH_VPP_LIST_SUCCESS = 'devices/FETCH_VPP_LIST_SUCCESS';
const fetchVPPList = () => ({
    type: FETCH_VPP_LIST,
});

const UPDATE_PRODUCT_TYPE_AND_VERSION = 'devices/UPDATE_PRODUCT_TYPE_AND_VERSION';
const updateProduct = ({
    productType,
    productVersion,
    searchString,
}) => ({
    type: UPDATE_PRODUCT_TYPE_AND_VERSION,
    payload: {
        productType,
        productVersion,
        searchString,
    },
});
const UPDATE_FIRMWARE_FILTER = 'devices/UPDATE_FIRMWARE_FILTER';
const updateFirmwareFilter = firmwareVersion => ({
    type: UPDATE_FIRMWARE_FILTER,
    payload: {
        firmwareVersion,
    },
});
const UPDATE_VPP_FILTER = 'devices/UPDATE_VPP_FILTER';
const updateVPPFilter = (vppId, searchString) => ({
    type: UPDATE_VPP_FILTER,
    payload: {
        vppId,
        searchString,
    },
});
const UPDATE_CONNECTION_STATUS_FILTER = 'devices/UPDATE_CONNECTION_STATUS_FILTER';
const updateConnectionStatusFilter = ({
    connectionStatus,
    deploymentStatus,
    searchString,
}) => ({
    type: UPDATE_CONNECTION_STATUS_FILTER,
    payload: {
        connectionStatus,
        deploymentStatus,
        searchString,
    },
});
const SET_FILTER_BY_INSTALL_DATE = 'devices/SET_FILTER_BY_INSTALL_DATE';
const setFilterByInstallDate = (filterByInstalled, searchString) => ({
    type: SET_FILTER_BY_INSTALL_DATE,
    payload: {
        filterByInstalled,
        searchString,
    },
});
const UPDATE_INSTALL_DATE_FILTER = 'devices/UPDATE_INSTALL_DATE_FILTER';
const updateInstallDateFilter = ({
    installedAfter,
    installedBefore,
    searchString,
}) => ({
    type: UPDATE_INSTALL_DATE_FILTER,
    payload: {
        installedAfter,
        installedBefore,
        searchString,
    },
});
const CLEAR_ALL_FILTERS = 'devices/CLEAR_ALL_FILTERS';
const clearFilters = () => ({
    type: CLEAR_ALL_FILTERS,
});

const FETCH_DEVICE = 'devices/FETCH_DEVICE';
const FETCH_DEVICE_SUCCESS = 'devices/FETCH_DEVICE_SUCCESS';
const FETCH_DEVICE_FAILURE = 'devices/FETCH_DEVICE_FAILURE';
const fetchDevice = deviceId => ({
    type: FETCH_DEVICE,
    payload: {
        deviceId,
    },
});

const GET_DEVICE_SHADOW = 'devices/GET_DEVICE_SHADOW';
const GET_DEVICE_SHADOW_SUCCESS = 'devices/GET_DEVICE_SHADOW_SUCCESS';
const GET_DEVICE_SHADOW_FAILURE = 'devices/GET_DEVICE_SHADOW_FAILURE';
const getShadow = deviceId => ({
    type: GET_DEVICE_SHADOW,
    payload: {
        deviceId,
    },
});

const REBOOT_DEVICE = 'devices/REBOOT_DEVICE';
const REBOOT_DEVICE_SUCCESS = 'devices/REBOOT_DEVICE_SUCCESS';
const REBOOT_DEVICE_FAILURE = 'devices/REBOOT_DEVICE_FAILURE';
const rebootDevice = deviceId => ({
    type: REBOOT_DEVICE,
    payload: {
        deviceId,
    },
});

const LIST_RPCS = 'devices/LIST_RPCS';
const LIST_RPCS_SUCCESS = 'devices/LIST_RPCS_SUCCESS';
const LIST_RPCS_FAILURE = 'devices/LIST_RPCS_FAILURE';

const CALL_RPC = 'devices/CALL_RPC';
const CALL_RPC_SUCCESS = 'devices/CALL_RPC_SUCCESS';
const CALL_RPC_FAILURE = 'devices/CALL_RPC_FAILURE';

const TOGGLE_DEVICE_DEBUGGING = 'devices/TOGGLE_DEVICE_DEBUGGING';
const TOGGLE_DEVICE_DEBUGGING_FAILURE = 'devices/TOGGLE_DEVICE_DEBUGGING_FAILURE';
const toggleDeviceDebugging = deviceId => ({
    type: TOGGLE_DEVICE_DEBUGGING,
    payload: {
        deviceId,
    },
});

const UPDATE_USER_SETTING = 'devices/UPDATE_USER_SETTING';
const UPDATE_USER_SETTING_FAILURE = 'devices/UPDATE_USER_SETTING_FAILURE';
const updateUserSetting = ({
    deviceId,
    setting,
    value,
}) => ({
    type: UPDATE_USER_SETTING,
    payload: {
        deviceId,
        setting,
        value,
    },
});

const FETCH_DEVICE_GRAPH_DATA = 'devices/FETCH_DEVICE_GRAPH_DATA';
const fetchDeviceGraphData = ({
    deviceId,
    dataType,
}) => ({
    type: FETCH_DEVICE_GRAPH_DATA,
    payload: {
        deviceId,
        dataType,
    },
});
const FETCH_DEVICE_ENERGY_DATA_SUCCESS = 'devices/FETCH_DEVICE_ENERGY_DATA_SUCCESS';
const FETCH_DEVICE_ENERGY_DATA_FAILURE = 'devices/FETCH_DEVICE_ENERGY_DATA_FAILURE';
const FETCH_DEVICE_POWER_DATA_SUCCESS = 'devices/FETCH_DEVICE_POWER_DATA_SUCCESS';
const FETCH_DEVICE_POWER_DATA_FAILURE = 'devices/FETCH_DEVICE_POWER_DATA_FAILURE';
const FETCH_DEVICE_DEBUG_DATA_SUCCESS = 'devices/FETCH_DEVICE_DEBUG_DATA_SUCCESS';
const FETCH_DEVICE_DEBUG_DATA_FAILURE = 'devices/FETCH_DEVICE_DEBUG_DATA_FAILURE';
const FETCH_DEVICE_CONNECTIONS_DATA_SUCCESS = 'devices/FETCH_DEVICE_CONNECTIONS_DATA_SUCCESS';
const FETCH_DEVICE_CONNECTIONS_DATA_FAILURE = 'devices/FETCH_DEVICE_CONNECTIONS_DATA_FAILURE';

const SET_DEVICE_GRAPH_RANGE = 'devices/SET_DEVICE_GRAPH_RANGE';
const setDeviceGraphRange = ({
    startTS,
    endTS,
    dataType,
    deviceId,
}) => ({
    type: SET_DEVICE_GRAPH_RANGE,
    payload: {
        startTS,
        endTS,
        dataType,
        deviceId,
    },
});

const SET_DEVICE_ERROR_MESSAGE = 'devices/SET_DEVICE_ERROR_MESSAGE';
const setDeviceErrorMessage = ({
    message,
    deviceId,
}) => ({
    type: SET_DEVICE_ERROR_MESSAGE,
    payload: {
        message,
        deviceId,
    },
});

const ACKNOWLEDGE_DEVICE_OFFLINE = 'devices/ACKNOWLEDGE_DEVICE_OFFLINE';
const ACKNOWLEDGE_DEVICE_OFFLINE_FAILURE = 'devices/ACKNOWLEDGE_DEVICE_OFFLINE_FAILURE';
const acknowledgeDeviceOffline = deviceId => ({
    type: ACKNOWLEDGE_DEVICE_OFFLINE,
    payload: {
        deviceId,
    },
});

const UPDATE_CUSTOMER_NOTES = 'devices/UPDATE_CUSTOMER_NOTES';
const UPDATE_CUSTOMER_NOTES_FAILURE = 'devices/UPDATE_CUSTOMER_NOTES_FAILURE';
const updateCustomerNotes = ({
    deviceId,
    customerNotes,
}) => ({
    type: UPDATE_CUSTOMER_NOTES,
    payload: {
        deviceId,
        customerNotes,
    },
});

const UPDATE_DEVICE_VPP = 'devices/UPDATE_DEVICE_VPP';
const UPDATE_DEVICE_VPP_SUCCESS = 'devices/UPDATE_DEVICE_VPP_SUCCESS';
const UPDATE_DEVICE_VPP_FAILURE = 'devices/UPDATE_DEVICE_VPP_FAILURE';
const updateDeviceVpp = ({
    deviceId,
    vppId,
}) => ({
    type: UPDATE_DEVICE_VPP,
    payload: {
        deviceId,
        vppId,
    },
});

const UPDATE_FAULTY_DEVICE_FLAG = 'devices/UPDATE_FAULTY_DEVICE_FLAG';
const UPDATE_FAULTY_DEVICE_FLAG_FAILURE = 'devices/UPDATE_FAULTY_DEVICE_FLAG_FAILURE';
const updateFaultyDeviceFlag = ({
    deviceId,
    faulty,
}) => ({
    type: UPDATE_FAULTY_DEVICE_FLAG,
    payload: {
        deviceId,
        faulty,
    },
});

const FETCH_USERS_FOR_DEVICE = 'devices/FETCH_USERS_FOR_DEVICE';
const FETCH_USERS_FOR_DEVICE_SUCCESS = 'devices/FETCH_USERS_FOR_DEVICE_SUCCESS';
const FETCH_USERS_FOR_DEVICE_FAILURE = 'devices/FETCH_USERS_FOR_DEVICE_FAILURE';
const fetchUsersForDevice = deviceId => ({
    type: FETCH_USERS_FOR_DEVICE,
    payload: {
        deviceId,
    },
});

const UPDATE_DEVICE_PARENT = 'devices/UPDATE_DEVICE_PARENT';
const UPDATE_DEVICE_PARENT_SUCCESS = 'devices/UPDATE_DEVICE_PARENT_SUCCESS';
const UPDATE_DEVICE_PARENT_FAILURE = 'devices/UPDATE_DEVICE_PARENT_FAILURE';
const updateDeviceParent = (deviceId, parent) => ({
    type: UPDATE_DEVICE_PARENT,
    payload: {
        deviceId,
        parent,
    },
});

const UPDATE_UNINSTALLED_AT = 'devices/UPDATE_UNINSTALLED_AT';
const UPDATE_UNINSTALLED_AT_SUCCESS = 'devices/UPDATE_UNINSTALLED_AT_SUCCESS';
const UPDATE_UNINSTALLED_AT_FAILURE = 'devices/UPDATE_UNINSTALLED_AT_FAILURE';
const updateUninstalledAt = ({
    deviceId,
    uninstalledAt,
}) => ({
    type: UPDATE_UNINSTALLED_AT,
    payload: {
        deviceId,
        uninstalledAt,
    },
});

const CLEAR_UNINSTALLED_AT = 'devices/CLEAR_UNINSTALLED_AT';
const CLEAR_UNINSTALLED_AT_SUCCESS = 'devices/CLEAR_UNINSTALLED_AT_SUCCESS';
const CLEAR_UNINSTALLED_AT_FAILURE = 'devices/CLEAR_UNINSTALLED_AT_FAILURE';
const clearUninstalledAt = ({ deviceId }) => ({
    type: CLEAR_UNINSTALLED_AT,
    payload: { deviceId },
});

export {
    FETCH_DEVICES,
    FETCH_DEVICES_SUCCESS,
    FETCH_DEVICES_FAILURE,
    fetchDevices,
    FETCH_USER_DEVICES,
    FETCH_USER_DEVICES_SUCCESS,
    FETCH_USER_DEVICES_FAILURE,
    fetchUserDevices,
    FETCH_VPP_LIST,
    FETCH_VPP_LIST_SUCCESS,
    fetchVPPList,
    UPDATE_PRODUCT_TYPE_AND_VERSION,
    updateProduct,
    UPDATE_FIRMWARE_FILTER,
    updateFirmwareFilter,
    UPDATE_VPP_FILTER,
    updateVPPFilter,
    UPDATE_CONNECTION_STATUS_FILTER,
    updateConnectionStatusFilter,
    SET_FILTER_BY_INSTALL_DATE,
    setFilterByInstallDate,
    UPDATE_INSTALL_DATE_FILTER,
    updateInstallDateFilter,
    CLEAR_ALL_FILTERS,
    clearFilters,
    FETCH_DEVICE,
    FETCH_DEVICE_SUCCESS,
    FETCH_DEVICE_FAILURE,
    fetchDevice,
    GET_DEVICE_SHADOW,
    GET_DEVICE_SHADOW_SUCCESS,
    GET_DEVICE_SHADOW_FAILURE,
    getShadow,
    REBOOT_DEVICE,
    REBOOT_DEVICE_SUCCESS,
    REBOOT_DEVICE_FAILURE,
    rebootDevice,
    LIST_RPCS,
    LIST_RPCS_SUCCESS,
    LIST_RPCS_FAILURE,
    CALL_RPC,
    CALL_RPC_SUCCESS,
    CALL_RPC_FAILURE,
    TOGGLE_DEVICE_DEBUGGING,
    TOGGLE_DEVICE_DEBUGGING_FAILURE,
    toggleDeviceDebugging,
    UPDATE_USER_SETTING,
    UPDATE_USER_SETTING_FAILURE,
    updateUserSetting,
    FETCH_DEVICE_GRAPH_DATA,
    fetchDeviceGraphData,
    FETCH_DEVICE_ENERGY_DATA_SUCCESS,
    FETCH_DEVICE_POWER_DATA_SUCCESS,
    FETCH_DEVICE_DEBUG_DATA_SUCCESS,
    FETCH_DEVICE_CONNECTIONS_DATA_SUCCESS,
    FETCH_DEVICE_ENERGY_DATA_FAILURE,
    FETCH_DEVICE_POWER_DATA_FAILURE,
    FETCH_DEVICE_DEBUG_DATA_FAILURE,
    FETCH_DEVICE_CONNECTIONS_DATA_FAILURE,
    SET_DEVICE_GRAPH_RANGE,
    setDeviceGraphRange,
    SET_DEVICE_ERROR_MESSAGE,
    setDeviceErrorMessage,
    ACKNOWLEDGE_DEVICE_OFFLINE,
    ACKNOWLEDGE_DEVICE_OFFLINE_FAILURE,
    acknowledgeDeviceOffline,
    UPDATE_CUSTOMER_NOTES,
    UPDATE_CUSTOMER_NOTES_FAILURE,
    updateCustomerNotes,
    UPDATE_DEVICE_VPP,
    UPDATE_DEVICE_VPP_SUCCESS,
    UPDATE_DEVICE_VPP_FAILURE,
    updateDeviceVpp,
    UPDATE_FAULTY_DEVICE_FLAG,
    UPDATE_FAULTY_DEVICE_FLAG_FAILURE,
    updateFaultyDeviceFlag,
    FETCH_USERS_FOR_DEVICE,
    FETCH_USERS_FOR_DEVICE_SUCCESS,
    FETCH_USERS_FOR_DEVICE_FAILURE,
    fetchUsersForDevice,
    UPDATE_DEVICE_PARENT,
    UPDATE_DEVICE_PARENT_SUCCESS,
    UPDATE_DEVICE_PARENT_FAILURE,
    updateDeviceParent,
    UPDATE_UNINSTALLED_AT,
    UPDATE_UNINSTALLED_AT_SUCCESS,
    UPDATE_UNINSTALLED_AT_FAILURE,
    updateUninstalledAt,
    CLEAR_UNINSTALLED_AT,
    CLEAR_UNINSTALLED_AT_SUCCESS,
    CLEAR_UNINSTALLED_AT_FAILURE,
    clearUninstalledAt,
};
