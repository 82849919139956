import moment from 'moment-timezone';

const ONE_MINUTE_MS = 1000 * 60;
const FIVE_MINUTES_MS = ONE_MINUTE_MS * 5;
const FIFTEEN_MINUTES_MS = ONE_MINUTE_MS * 15;
const ONE_HOUR_MS = ONE_MINUTE_MS * 60;
const ONE_DAY_MS = ONE_HOUR_MS * 24;

const getIntervalFloor = (ts, interval) => Math.floor(ts / interval) * interval;
const getHourFloor = timestamp => getIntervalFloor(timestamp, ONE_HOUR_MS);
const getFiveMinuteFloor = timestamp => getIntervalFloor(timestamp, FIVE_MINUTES_MS);

const getDurationFromMs = (intervalInMs) => {
    let remainder = intervalInMs;
    const hours = Math.floor(remainder / ONE_HOUR_MS);
    remainder %= ONE_HOUR_MS;
    const minutes = Math.floor(remainder / ONE_MINUTE_MS);
    remainder %= ONE_MINUTE_MS;
    const seconds = Math.floor(remainder / 1000);
    return `PT${hours}H${minutes}M${seconds}S`;
};

const asMS = duration => moment.duration(duration).asMilliseconds();

const getCurrentTimezone = () => moment().tz(moment.tz.guess()).zoneName();

export {
    ONE_HOUR_MS,
    ONE_MINUTE_MS,
    FIFTEEN_MINUTES_MS,
    FIVE_MINUTES_MS,
    ONE_DAY_MS,
    getHourFloor,
    getFiveMinuteFloor,
    getDurationFromMs,
    asMS,
    getCurrentTimezone,
};
