import React from 'react';
import { StatusCard } from './card';
import './vpp-state.css';

const genericTooltipText = 'This is an estimate based on energy usage inferred from real-time'
  + ' power messages. The final values used in our offline reports may be different.';

const VPPMetrics = (props) => {
    const { deviceCounts, value } = props;

    return (
        <div className="row metrics-container">
            <div className="col-md-6 metrics-card__container">
                <StatusCard
                    title="Avg. Peak Reduction"
                    leftHeader="Past month"
                    leftValue={toFixed(value.averagePeakValue, 'kW')}
                    rightHeader="Per device"
                    rightValue={toFixed(value.avgPeakValuePerDevice, 'kW', 2)}
                    tooltipText={[
                        'Average kW-reduction value for all peak events over the last 30 days. The kW-reduction value for a single peak event is the average hourly kW reduction.',
                        genericTooltipText,
                    ]}
                    tooltipId="peak"
                />
            </div>
            <div className="col-md-6 metrics-card__container">
                <StatusCard
                    title="Devices"
                    leftHeader="Total"
                    leftValue={withNA(deviceCounts.totalDevices)}
                    rightHeader="Seen in the last 24hr"
                    rightValue={withNA(deviceCounts.devicesSeen)}
                />
            </div>
        </div>
    );
};

const toFixed = (val, unit, numDecimals = 0) => {
    if (typeof val !== 'number' || Number.isNaN(val) || val === Infinity) {
        return 'N/A';
    }
    const rounded = val.toFixed(numDecimals);
    return unit === '$' ? `$${rounded}` : `${rounded} ${unit}`;
};

const withNA = val => (typeof val === 'number' ? val : 'N/A');

export { VPPMetrics };
