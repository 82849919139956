export const styles = {
    backgroundImage: {
        flex: 1,
        alignSelf: 'stretch',
        width: null,
        height: null,
    },
    peDarkBlue: {
        color: '#125d91',
    },
    peMedBlue: {
        color: '#007cc3',
    },
    peLtBlue1: {
        color: '#00a7e0',
    },
    peLtBlue2: {
        color: '#00a7e0',
    },
};
