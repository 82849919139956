/* globals document */
import React from 'react';
import {
    Plot,
    Plotly,
} from '../../lib/plotly';
import { buildURI } from './csv-utils';
import {
    findClosestIndex,
} from './utils';
import {
    getCurrentTimezone,
} from '../../utils/time';
import {
    icon as DownloadIcon,
} from '../../charts/download-icon';

const Graph = ({
    data,
    yAxisTitle,
    xDomain,
    legend,
}) => (
    <Plot
        data={data}
        useResizeHandler={true}
        config={{
            displaylogo: false,
            displayModeBar: true,
            modeBarButtons: [['toImage']],
        }}
        style={{
            width: '100%',
        }}
        layout={{
            margin: {
                t: 30,
                b: 60,
                l: 50,
                r: 50,
            },
            xaxis: {
                fixedrange: true,
                range: xDomain,
                type: 'date',
                title: `Time (${getCurrentTimezone()})`,
                ticks: 'outside',
                showline: true,
            },
            yaxis: {
                fixedrange: true,
                title: yAxisTitle,
                ticks: 'outside',
                showline: true,
            },
            font: {
                size: 11,
            },
            height: 450,
            legend,
        }}
    />
);

const verticalCursor = {
    type: 'line',
    yref: 'paper',
    x0: 0,
    y0: 0,
    x1: 0,
    y1: 1,
    fillcolor: '#d3d3d3',
    opacity: 0.2,
};

const mainChartHeight = 525;
const lowerChartHeight = 75;
const buffer = 0.02;

const StackedGraph = ({
    exportData,
    xDomain,
    upperChartData,
    lowerChartData,
    yAxisTitleUpper,
    legend,
}) => {
    const [grid, setGrid] = React.useState(document.getElementById('stacked-device-chart') ?? undefined);
    const [closestPointsCache, setClosestPointsCache] = React.useState({});
    const [subplots, setSubPlots] = React.useState(['xy']);
    const [subplotYAxes, setSubplotYAxes] = React.useState({});
    const [data, setData] = React.useState([]);
    const [height, setHeight] = React.useState(0);
    const [modeBarButtons, setModeBarButtons] = React.useState(['toImage']);
    const [toggleDownload, setToggleDownload] = React.useState(0);
    // Toggle function passed into plotly modebar button to flip value of
    // toggleDownload and trigger a csv download of the chart data
    const toggle = () => setToggleDownload(curr => curr + 1);

    // populate the chart
    React.useEffect(() => {
        setHeight(mainChartHeight + (lowerChartHeight * lowerChartData.length));
        const updateData = [
            ...upperChartData.map(series => ({
                ...series,
                xaxis: 'x',
                yaxis: 'y',
            })),
            ...lowerChartData.map((series, i) => {
                setSubPlots(curr => [...curr, `xy${i + 2}`]);
                return {
                    ...series,
                    xaxis: 'x',
                    yaxis: `y${i + 2}`,
                };
            }),
        ];
        updateData.forEach((trace, i) => {
            if (!closestPointsCache[i]) {
                setClosestPointsCache(curr => Object.assign(curr, { i: {} }));
            }
        });
        lowerChartData.forEach((trace, i) => {
            const index = i + 2;
            const posIndex = lowerChartData.length - i;
            // posIndex counts distance in subplots from x axis so 0 is the bottom chart
            const axesUpdate = {
                title: trace.name,
                fixedrange: true,
                domain: [
                    ((lowerChartHeight * (posIndex - 1)) / height) + buffer,
                    ((lowerChartHeight * posIndex) / height) - buffer,
                ],
                zeroline: false,
                showgrid: false,
                autotick: true,
                ticks: '',
                showticklabels: false,
            };
            const axesKey = `yaxis${index}`;
            setSubplotYAxes(curr => Object.assign(curr, { [axesKey]: axesUpdate }));
            setData(updateData);
        });
    }, [upperChartData, lowerChartData]);

    React.useEffect(() => {
        const x = upperChartData[0].x[0];
        const gd = document.getElementById('stacked-device-chart');
        // need to set x value of the cursor inside the graph or else data does
        // not show up until hover
        if (gd) {
            setGrid(grid);
            Plotly.relayout(gd, {
                'shapes[0].x0': x,
                'shapes[0].x1': x,
            });
        }
    }, [upperChartData]);

    // In order to show/not show the download button only when there
    // is data to download, we need to assemble the shape of the
    // modebar buttons array outside of the plotly component, as
    // it seems as only the data section of the component re-renders
    // on update.
    React.useEffect(() => {
        if (exportData && (exportData.length > 0)) {
            const buttons = [
                {
                    name: 'Download CSV',
                    icon: DownloadIcon,
                    click: toggle,
                },
                'toImage',
            ];
            setModeBarButtons(buttons);
        }
    }, [exportData, xDomain]);

    // Something in react-plotly or the plotly lib is blocking re-renders of the modebar
    // component on the graph, so instead of passing in a function with the state values
    // (which are not updating in the plotly component), we'll just pass in a function that
    // increments a toggle value. We add that val to our dependency array for this useEffect
    // and then assume that if it changes, we need to trigger a download.
    React.useEffect(() => {
        // when we switch between graphs the toggleDownload state resets
        // with the component re-render, so we'll only trigger a download if the
        // toggleDownload val is greater than the intial state
        if (exportData && (exportData.length > 0) && toggleDownload > 0) {
            // We'll pull our CSV keys from the first object in the data array
            const csvKeys = Object.keys(exportData[0]);
            // The graphs that will have a download option all have
            // different data shapes and different keys for their
            // timestamp field, so we need to check to see which
            // key we should be attempting to access
            let timeKey;
            /* eslint-disable no-param-reassign */
            if (csvKeys.includes('at')) {
                timeKey = 'at';
                exportData.forEach((d) => {
                    const date = new Date(d.at);
                    d.at = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.toLocaleTimeString()}`;
                });
            }
            if (csvKeys.includes('timestamp')) {
                timeKey = 'timestamp';
                exportData.forEach((d) => {
                    const date = new Date(d.timestamp);
                    d.timestamp = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.toLocaleTimeString()}`;
                });
            }
            if (csvKeys.includes('bucket')) {
                timeKey = 'bucket';
                exportData.forEach((d) => {
                    const date = new Date(d.bucket);
                    d.bucket = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.toLocaleTimeString()}`;
                });
            }
            /* eslint-enable no-param-reassign */
            // Map those keys to the expected header object for the csv builder
            const csvHeaders = csvKeys.map(k => ({
                label: k,
                key: k,
            }));
            // We'll grab the chart type to append to the filename here
            const chartType = yAxisTitleUpper.split(' ')[0];
            // create our download URI
            const dataUri = buildURI(exportData, true, csvHeaders, ',', '"');
            // To ensure unique filenames, the convention will be to
            // join the timestamp field for the first data element with
            // the timestamp field for the last data element
            const fileNameStartDate = exportData[0][timeKey];
            const fileNameEndDate = exportData[exportData.length - 1][timeKey];
            const fileName = `${fileNameStartDate}-${fileNameEndDate}-${chartType}.csv`;

            // To complete the download, we create a link element,
            // set the href to our uri and give it a download attribute.
            // We then simulate a click to start the download, before
            // removing the element to cleanup.
            const element = document.createElement('a');
            element.setAttribute('href', dataUri);
            element.setAttribute('download', fileName);
            document.body.appendChild(element);
            element.click();
            element.remove();
        }
    }, [toggleDownload]);

    return (
        <Plot
            divId='stacked-device-chart'
            data={data}
            useResizeHandler={true}
            config={{
                displaylogo: false,
                displayModeBar: true,
                modeBarButtons: [modeBarButtons],
            }}
            style={{
                width: '100%',
                height: '100%',
            }}
            onHover={(hoverData) => {
                if (!grid) return;
                const point = hoverData.points[0];
                // point: the point that was hovered on (point in a particular trace)
                if (typeof point === 'undefined') return;
                // return early with a lot of data to minimize crashing
                if (!grid.data || !point.data.x
                    || (grid.data.length > 2 && point.data.x.length > 1200)) return;
                const index = point.pointIndex[1] || point.pointIndex;
                // point.pointIndex: index of the hovered point within that trace's
                // data array.
                // For heatmap, pointIndex is [y, x] while for line/bar traces
                // it's just x.
                const x = point.data.x[index]; // x value of the hovered point
                // for each trace, find index of nearest x value to the hovered point
                const points = grid.data.map((trace, curveNumber) => {
                    const getPointNumber = () => {
                        if (curveNumber === point.curveNumber) return index;
                        if (closestPointsCache[curveNumber][x]) {
                            return closestPointsCache[curveNumber][x];
                        }
                        return findClosestIndex(x, trace.x);
                    };
                    const pointNumber = getPointNumber();
                    if (!closestPointsCache[curveNumber][x]) {
                        closestPointsCache[curveNumber][x] = pointNumber;
                    }
                    // for heatmap, pointNumber must be an array [y, z]
                    return {
                        curveNumber,
                        pointNumber: grid.data[curveNumber].type === 'heatmap'
                            ? [0, pointNumber]
                            : pointNumber,
                    };
                });
                if (!points.length) return;
                // hover on points on all subplots to show all tooltips
                Plotly.Fx.hover(grid, points, subplots);
                // move vertical cursor line to the hovered x value
                Plotly.relayout(grid, {
                    'shapes[0].x0': x,
                    'shapes[0].x1': x,
                });
            }}
            layout={{
                margin: {
                    t: 40,
                    b: 75,
                    l: 50,
                    r: 50,
                },
                grid: {
                    rows: subplots.length,
                    columns: 1,
                    subplots,
                },
                yaxis: {
                    title: yAxisTitleUpper,
                    fixedrange: true,
                    domain: [((height - mainChartHeight) / height) + buffer, 1],
                },
                ...subplotYAxes,
                xaxis: {
                    range: xDomain,
                    fixedrange: true,
                    type: 'date',
                    title: `Time (${getCurrentTimezone()})`,
                    ticks: 'outside',
                    showline: true,
                },
                shapes: [
                    verticalCursor,
                ],
                height,
                legend: {
                    orientation: 'h',
                    xanchor: 'center',
                    x: 0.5,
                    yanchor: 'bottom',
                    y: 1.05,
                    ...legend,
                },
            }}
        />
    );
};

export {
    Graph,
    StackedGraph,
};
