import React from 'react';
import { Link } from 'react-router-dom';

const DeviceList = ({ devices }) => {
    const deviceList = devices.slice();
    deviceList.sort((obj1, obj2) => {
        if (obj1.deviceId < obj2.deviceId) return -1;
        if (obj1.deviceId > obj2.deviceId) return 1;
        return 0;
    });
    return (
        <ul className="list-group">
            {deviceList.map(item => (
                <li className="list-group-item" key={item.deviceId}>
                    <Link to={`/devices/${item.deviceId}`}>
                        {item.deviceId}
                    </Link>
                </li>
            ))}
        </ul>
    );
};

export {
    DeviceList,
};
