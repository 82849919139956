import React from 'react';
import { connect } from 'react-redux';
import {
    PeakEventViewWrapper,
} from '../../components/peak-event-view-wrapper';
import {
    PeakAction,
} from '../../components/peak-action';
import {
    getHumanReadablePeakState,
    dateFormatter,
} from '../../utils';
import {
    updateHistory,
} from '../../../utils/history';
import {
    FIFTEEN_MINUTES_MS,
    getDurationFromMs,
} from '../../../utils/time';
import {
    updatePerformanceDisplay,
} from '../../../models/vpps/actions';

const PastPeakEventComponent = ({
    prePositionTS,
    startTS,
    endTS,
    state,
    values,
    showPeakValues = false,
    updatePowerHistoryWindow,
    vppId,
}) => {
    const columns = [
        getHumanReadablePeakState({ state }) || 'Completed',
        dateFormatter(startTS),
        dateFormatter(endTS),
    ];
    if (showPeakValues) columns.push(<InlinePeakValue values={values} />);
    return (
        <PeakEventViewWrapper
            columns={columns}
            actions={(
                <div className="peak-scheduler__peak__actions">
                    <PeakAction
                        handler={() => {
                            updatePowerHistoryWindow({
                                vppId,
                                startTS: prePositionTS - FIFTEEN_MINUTES_MS,
                                endTS: endTS + FIFTEEN_MINUTES_MS,
                            });
                            updateHistory({
                                pathname: '/operator',
                            });
                        }}
                        faIconClass="eye"
                        tooltipText="View on operator page"
                    />
                </div>
            )}
        />
    );
};

const InlinePeakValue = ({
    values,
}) => {
    const averagekWSaved = values.reduce((m, n) => m + n.kWSaved, 0) / values.length;
    return (
        <span>
            {'Average kW saved: '}
            {averagekWSaved.toFixed(2)}
        </span>
    );
};

const mapStateToProps = (state, props) => ({
    vppId: state.vpps.activeVpp,
    ...props,
});

const mapDispatchToProps = dispatch => ({
    updatePowerHistoryWindow: ({
        startTS,
        endTS,
        vppId,
    }) => {
        dispatch(updatePerformanceDisplay({
            vppId,
            performanceDisplay: {
                anchor: Number(startTS),
                duration: getDurationFromMs(Number(endTS) - Number(startTS)),
                direction: 'start',
            },
        }));
    },
});

const PastPeakEvent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PastPeakEventComponent);

export {
    PastPeakEvent,
};
