import React from 'react';
import {
    PastPeakEventList,
} from './components/past-peak-event-list';

export const DonePeaks = ({
    lowerTS,
    upperTS,
    peaks,
    refresh,
    nextPage,
    previousPage,
    showPeakValues = false,
}) => (
    <div className="peak-scheduler--done-peaks">
        <h2>Past Peaks</h2>
        <PastPeakEventList
            events={peaks}
            refresh={refresh}
            lowerTS={lowerTS}
            upperTS={upperTS}
            nextPage={nextPage}
            previousPage={previousPage}
            showPeakValues={showPeakValues}
        />
    </div>
);
