import React, { Fragment } from 'react';
import { css } from '@emotion/core';
import {
    ActionRow,
    ParentRow,
    ChildRow,
} from './settings-rows';

const DeviceSettings = ({
    isDebugging,
    toggleDebugging,
    device,
    isFaultyDevice: displayedFaultyDeviceStatus,
    setFaultyDeviceFlag,
    getFaultyDeviceFlag,
    userHasOemAccess,
    userHasAdminAccess,
    updateDeviceParent,
}) => {
    const faultyStatus = getBooleanFaultyDeviceStatus(displayedFaultyDeviceStatus);
    return (
        <div css={styles.deviceSettings}>
            <h2>Device Settings</h2>
            <table className="table device-detail-table">
                <tbody>
                    <ActionRow
                        title="Debugging"
                        value={isDebugging ? 'On' : 'Off'}
                        buttonTitle={isDebugging ? 'Disable' : 'Enable'}
                        buttonStyleClass={`btn-${isDebugging ? 'dark' : 'warning'}`}
                        handler={toggleDebugging}
                        disabled={!userHasOemAccess && !userHasAdminAccess}
                    />
                    <tr>
                        <td>Device type</td>
                        <td>{getReadableDeviceType(device)}</td>
                        <td />
                    </tr>
                    <tr>
                        <td>Firmware version</td>
                        <td>{device.firmwareVersion}</td>
                        <td />
                    </tr>
                    <ParentRow device={device} onSubmit={updateDeviceParent} />
                    <ChildRow device={device} />
                    <DeviceTypeSpecificSettings device={device} />
                    {userHasAdminAccess && (
                        <ActionRow
                            title="Bad install"
                            value={displayedFaultyDeviceStatus}
                            buttonTitle={faultyStatus ? 'Remove bad install flag' : 'Mark as bad install'}
                            buttonStyleClass={`btn-${faultyStatus ? 'dark' : 'warning'}`}
                            handler={() => setFaultyDeviceFlag(!faultyStatus)}
                            onMount={getFaultyDeviceFlag}
                        />
                    )}
                </tbody>
            </table>
        </div>
    );
};

const DeviceTypeSpecificSettings = ({ device }) => {
    switch (device.deviceType) {
        case 'wh1': {
            const { user } = device;
            let mixingValve = 'Unknown';
            if (user.mixing_valve === true) mixingValve = 'Yes';
            else if (user.mixing_valve === false) mixingValve = 'No';
            return (
                <tr>
                    <td>Mixing valve</td>
                    <td>{mixingValve}</td>
                    <td />
                </tr>
            );
        }
        case 'ev0': {
            const { tou } = device;
            return (
                <Fragment>
                    <tr>
                        <td>Time of Use</td>
                        <td>
                            {getTOUTime(tou.startHour, tou.startMin)}
                            {' to '}
                            {getTOUTime(tou.startHour, tou.startMin, tou.duration * 1000)}
                            {` (${tou.type})`}
                        </td>
                        <td />
                    </tr>
                    <tr>
                        <td>Opted out of TOU</td>
                        <td>
                            {tou.opt_out
                                ? `Yes (at ${(new Date(tou.opt_out_ts)).toLocaleString('en-US')})`
                                : 'No'
                            }
                        </td>
                        <td />
                    </tr>
                </Fragment>
            );
        }
        default: {
            return null;
        }
    }
};

const getTOUTime = (hour, minute, addedDuration = 0) => {
    let d = new Date();
    d.setUTCHours(hour);
    d.setMinutes(minute);
    d = new Date(d.getTime() + addedDuration);
    const [time, meridian] = d.toLocaleTimeString().split(' ');
    return `${time.split(':').slice(0, 2).join(':')} ${meridian}`;
};

const getReadableDeviceType = ({
    deviceType,
    productType,
    productVersion,
}) => {
    switch (deviceType) {
        case 'wh1': return `${productType || 'Mello'} ${productVersion || '1'}`;
        case 'ev0': return 'EV';
        case 'mm0': return 'Mello Meter';
        default: return `${productType || 'Unknown'}`;
    }
};

const getBooleanFaultyDeviceStatus = displayed => displayed.startsWith('Yes');

export {
    DeviceSettings,
};

const styles = {
    deviceSettings: css`
        margin-bottom: 32px;
    `,
};
