import React from 'react';
import { connect } from 'react-redux';
import {
    DeviceAlertsView,
} from './device-alerts-view';
import {
    getAlertTypes,
    getDeviceEventDecoder,
} from '../config';
import {
    fetchDeviceAlerts,
    setDeviceAlertFilter,
    resolveDeviceAlert,
    unresolveDeviceAlert,
    acknowledgeDeviceAlert,
    createDeviceAlert,
    updateDeviceAlert,
    updateErrorMessage,
} from '../../../models/devices/alerts/actions';
import {
    getAlertsForDevice,
} from '../../../models/devices/alerts/selectors';
import {
    ASYNC_STATUS,
} from '../../../utils/async';

class DeviceAlertsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            alertTypeSearch: '',
            showAcknowledgedAlerts: true,
            showNewAlertForm: false,
        };
    }

    componentDidMount() {
        const {
            getAlerts,
            deviceId,
            isDeviceOwner,
        } = this.props;

        const options = { deviceId };

        if (isDeviceOwner) {
            options.key = 'displayToUserOnly';
            options.value = true;
        }

        getAlerts(options);
    }

    getVisibleAlertsAlerts() {
        const {
            alerts,
        } = this.props;
        const {
            alertTypeSearch,
            showAcknowledgedAlerts,
        } = this.state;
        if (showAcknowledgedAlerts && !alertTypeSearch) return [...alerts];
        return alerts.filter(({
            acknowledgedAt,
            alertType,
            decodedAlertType,
            message,
            deviceId,
        }) => {
            if (!showAcknowledgedAlerts && acknowledgedAt > 0) return false;
            if ((decodedAlertType || alertType).toLowerCase().includes(alertTypeSearch)) {
                return true;
            }
            if (message && message.includes(alertTypeSearch)) return true;
            if (deviceId.includes(alertTypeSearch)) return true;
            return false;
        });
    }

    render() {
        const {
            alertTypeSearch,
            showAcknowledgedAlerts,
            showNewAlertForm,
        } = this.state;
        const {
            deviceId,
            deviceType,
            productType,
            singleDeviceView,
            showResolved,
            displayToUserOnly,
            errorMessage,
            saving,
            getAlerts,
            updateFilter,
            resolveAlert,
            unresolveAlert,
            acknowledgeAlert,
            createAlert,
            updateAlert,
            setErrorMessage,
            isDeviceOwner,
        } = this.props;
        const displayedAlerts = this.getVisibleAlertsAlerts();
        return (
            <DeviceAlertsView
                displayedAlerts={displayedAlerts}
                showNewAlertForm={showNewAlertForm}
                saving={saving === ASYNC_STATUS.WAITING}
                errorMessage={errorMessage}
                createAlert={(params) => {
                    createAlert({
                        deviceId,
                        ...params,
                    });
                    this.setState({
                        showNewAlertForm: false,
                    });
                }}
                updateAlert={params => updateAlert({
                    deviceId,
                    ...params,
                })}
                alertTypes={getAlertTypes({
                    deviceType,
                    productType,
                })}
                alertTypeSearch={alertTypeSearch}
                handleAlertTypeSearchChange={search => this.setState({
                    alertTypeSearch: search.toLowerCase(),
                })}
                showAcknowledgedAlerts={showAcknowledgedAlerts}
                toggleShowAcknowledgedAlerts={() => {
                    this.setState({
                        showAcknowledgedAlerts: !showAcknowledgedAlerts,
                    }, () => getAlerts(deviceId));
                }}
                showResolvedAlerts={showResolved}
                toggleShowResolvedAlerts={() => {
                    updateFilter('showResolved', !showResolved);
                }}
                showOnlyDisplayToUser={displayToUserOnly}
                toggleShowOnlyDisplayToUser={() => {
                    updateFilter('displayToUserOnly', !displayToUserOnly);
                }}
                toggleShowNewAlertForm={() => this.setState({
                    showNewAlertForm: !showNewAlertForm,
                })}
                resolveAlert={resolveAlert}
                unresolveAlert={unresolveAlert}
                acknowledgeAlert={acknowledgeAlert}
                singleDeviceView={singleDeviceView}
                hideErrorMessage={() => setErrorMessage('')}
                isDeviceOwner={isDeviceOwner}
            />
        );
    }
}

const mapStateToProps = (state, props) => {
    const {
        message,
        saving,
        filters,
    } = state.devices.alerts;
    const {
        deviceId,
    } = props;

    const isDeviceOwner = state.user.devices.includes(deviceId);

    const alertList = getAlertsForDevice(state, deviceId);
    const decoder = getDeviceEventDecoder(deviceId ? state.devices.devices[deviceId].device : {});

    return {
        alerts: alertList.map(decoder),
        errorMessage: message,
        saving,
        isDeviceOwner,
        ...filters,
        ...props,
    };
};

const mapDispatchToProps = dispatch => ({
    getAlerts: options => dispatch(fetchDeviceAlerts(options)),
    updateFilter: (key, value) => dispatch(setDeviceAlertFilter(key, value)),
    resolveAlert: params => dispatch(resolveDeviceAlert(params)),
    unresolveAlert: params => dispatch(unresolveDeviceAlert(params)),
    acknowledgeAlert: params => dispatch(acknowledgeDeviceAlert(params)),
    createAlert: params => dispatch(createDeviceAlert(params)),
    updateAlert: params => dispatch(updateDeviceAlert(params)),
    setErrorMessage: message => dispatch(updateErrorMessage(message)),
});

const DeviceAlerts = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DeviceAlertsComponent);

export {
    DeviceAlerts,
};
