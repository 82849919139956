/* globals window */
import {
    all,
    call,
    takeEvery,
    takeLatest,
    put,
    select,
} from 'redux-saga/effects';
import {
    delay,
} from 'redux-saga';
import {
    translateWindowToRange,
} from '../../utils/windows';
import {
    PeakSchedulerDAO,
} from './peak-scheduler-dao';
import {
    container,
} from '../../dependency-injection/container';
import {
    getActiveVppId,
} from '../vpps/selectors';
import {
    toPeakId,
} from './utils';
import {
    PEAKS_SHOW_WINDOW,
    PEAKS_REFRESH_WINDOW,
    fetchSucceeded,
    fetchFailed,
    GET_PEAK_EVENT_SUCCESS,
    CREATE_PEAK_EVENT,
    UPDATE_PEAK_EVENT,
    DELETE_PEAK_EVENT,
    DUPLICATE_PEAK_EVENT,
    SET_PEAK_ERROR_MESSAGE,
    SET_SAVING_PEAK_FLAG,
    TOGGLE_EDITING_PEAK,
    RESET_NEW_PEAK_EVENT,
    POLL_ACTIVE_PEAK_EVENT,
    STOP_POLLING_ACTIVE_PEAK,
} from './actions';
import {
    ACTIVE_VPP_CHANGED,
} from '../vpps/actions';

const ONE_DAY_MS = 1000 * 60 * 60 * 24;

function* handler() {
    yield all([
        takeEvery(PEAKS_SHOW_WINDOW, handleShowWindow),
        takeEvery(PEAKS_REFRESH_WINDOW, handleWindowRefresh),
        takeEvery(CREATE_PEAK_EVENT, handleCreatePeakEvent),
        takeEvery(UPDATE_PEAK_EVENT, handleUpdatePeakEvent),
        takeEvery(DELETE_PEAK_EVENT, handleDeletePeakEvent),
        takeEvery(DUPLICATE_PEAK_EVENT, handleDuplicatePeakEvent),
        takeLatest([
            POLL_ACTIVE_PEAK_EVENT,
            STOP_POLLING_ACTIVE_PEAK,
        ], pollActivePeakEvent),
        takeEvery(ACTIVE_VPP_CHANGED, handleNewActiveVpp),
    ]);
}

const pollingDelayInterval = 1000 * 30;
const getActivePeakId = (state) => {
    const {
        peaks,
        vpps: {
            vpps,
            activeVpp,
        },
    } = state;
    const {
        activePeak,
    } = peaks[activeVpp] || {};
    if (activePeak && activePeak.startTS) return activePeak.startTS;
    return vpps[activeVpp].peak.activePeakEventId;
};

function* pollActivePeakEvent(action) {
    if (action.type === POLL_ACTIVE_PEAK_EVENT) {
        while (true) {
            const vppId = yield select(getActiveVppId);
            const peakId = yield select(getActivePeakId);
            yield call(handleGetPeakEvent, {
                vppId,
                peakId,
            });
            yield delay(pollingDelayInterval);
        }
    }
}

function* handleGetPeakEvent({
    vppId,
    peakId,
}) {
    const client = container.get(PeakSchedulerDAO);
    try {
        const peak = yield call(client.getEvent, {
            vppId,
            peakId,
        });
        yield put({
            type: GET_PEAK_EVENT_SUCCESS,
            payload: peak,
        });
    } catch {} // eslint-disable-line no-empty
}

function* handleCreatePeakEvent(action) {
    const {
        vppId,
        event,
    } = action.payload;
    const {
        startTS,
        endTS,
        listeners,
        notify,
        peakId,
    } = event;
    yield call(beginPeakEdit, {
        startTS: peakId,
        vppId,
    });
    const client = container.get(PeakSchedulerDAO);
    try {
        const params = {
            vppId,
            startTS,
            endTS,
            listeners,
            notify,
        };

        yield call(client.createEvent, params);
        yield call(peakEditSuccess, {
            startTS: peakId,
            vppId,
        });
        yield put({
            type: RESET_NEW_PEAK_EVENT,
            payload: {
                vppId,
            },
        });
    } catch (err) {
        yield call(handlePeakEditError, {
            message: (err.responseBody || {}).message || 'Unable to create peak',
            startTS: peakId,
            vppId,
        });
    }
}

function* handleDuplicatePeakEvent(action) {
    const { peakId } = action.payload;
    const vppId = yield select(getActiveVppId);
    const id = toPeakId({
        startTS: peakId,
        vppId,
    });
    yield put({
        type: SET_SAVING_PEAK_FLAG,
        payload: {
            peakId: id,
            actionType: 'duplicating',
            vppId,
        },
    });
    const state = yield select();
    const peaks = state.peaks[vppId];
    const {
        startTS,
        endTS,
    } = peaks.peaks[toPeakId({
        startTS: peakId,
        vppId,
    })];
    yield call(handleCreatePeakEvent, {
        payload: {
            vppId,
            event: {
                startTS: startTS + ONE_DAY_MS,
                endTS: endTS + ONE_DAY_MS,
            },
        },
    });
    yield put({
        type: SET_SAVING_PEAK_FLAG,
        payload: {
            peakId: id,
            actionType: null,
            vppId,
        },
    });
}

function* handleUpdatePeakEvent(action) {
    const {
        vppId,
        peakId,
        event,
    } = action.payload;
    const client = container.get(PeakSchedulerDAO);
    yield call(beginPeakEdit, {
        startTS: peakId,
        vppId,
    });
    try {
        yield call(client.updateEvent, {
            vppId,
            peakId,
        }, event);
        yield call(peakEditSuccess, {
            startTS: peakId,
            vppId,
        });
    } catch (err) {
        yield call(handlePeakEditError, {
            startTS: peakId,
            vppId,
            message: (err.responseBody || {}).message || 'Unable to update peak',
        });
    }
}

function* handleDeletePeakEvent(action) {
    let {
        vppId,
        peakId,
    } = action.payload;
    if (!vppId || !peakId) {
        vppId = yield select(getActiveVppId);
        peakId = yield select(getActivePeakId);
    }
    const client = container.get(PeakSchedulerDAO);
    yield call(beginPeakEdit, {
        startTS: peakId,
        vppId,
        actionType: 'deleting',
    });
    try {
        yield call(client.deleteEvent, {
            vppId,
            peakId,
        });
        yield call(peakEditSuccess, {
            startTS: peakId,
            vppId,
            actionType: 'deleted',
        });
    } catch {
        yield call(handlePeakEditError, {
            startTS: peakId,
            vppId,
            message: 'Failed to delete peak',
        });
    }
}

function* beginPeakEdit({
    startTS,
    vppId,
    actionType = 'saving',
}) {
    const id = toPeakId({
        startTS,
        vppId,
    });
    yield all([
        put({
            type: SET_SAVING_PEAK_FLAG,
            payload: {
                peakId: id,
                actionType,
                vppId,
            },
        }),
        put({
            type: SET_PEAK_ERROR_MESSAGE,
            payload: {
                message: null,
                peakId: id,
                vppId,
            },
        }),
    ]);
}

function* peakEditSuccess({
    startTS,
    vppId,
    actionType = null,
}) {
    const id = toPeakId({
        startTS,
        vppId,
    });
    yield all([
        put({
            type: SET_SAVING_PEAK_FLAG,
            payload: {
                peakId: id,
                actionType,
                vppId,
            },
        }),
        put({
            type: TOGGLE_EDITING_PEAK,
            payload: {
                peakId: id,
                editing: false,
                vppId,
            },
        }),
        put({
            type: PEAKS_REFRESH_WINDOW,
        }),
    ]);
}

function* handlePeakEditError({
    startTS,
    vppId,
    message,
}) {
    const id = toPeakId({
        startTS,
        vppId,
    });
    yield all([
        put({
            type: SET_PEAK_ERROR_MESSAGE,
            payload: {
                peakId: id,
                message,
                vppId,
            },
        }),
        put({
            type: SET_SAVING_PEAK_FLAG,
            payload: {
                peakId: id,
                actionType: null,
                vppId,
            },
        }),
    ]);
}

function* handleShowWindow(action) {
    // TODO(jhslinkman): check cached peaks before fetching
    yield handleFetchWindow(action);
}

function* handleFetchWindow(action) {
    const peakSchedulerInterface = container.get(PeakSchedulerDAO);
    const {
        vppId,
        duration,
        anchor,
        padStartMS,
    } = action.payload;
    const {
        lowerTS,
        upperTS,
    } = translateWindowToRange({
        anchor,
        duration,
        padStartMS,
    });
    try {
        const peaks = yield call(peakSchedulerInterface.getEvents, {
            completed: duration.charAt(1) === '-',
            vppId,
            lowerTS,
            upperTS,
        });
        yield put(fetchSucceeded({
            duration,
            anchor,
            vppId,
            peaks,
        }));
    } catch (err) {
        yield put(fetchFailed({
            duration,
            anchor,
            vppId,
        }));
    }
}

function* handleWindowRefresh(action) {
    const fetchAction = Object.assign({}, action);
    if (!fetchAction.payload || Object.keys(fetchAction.payload).length === 0) {
        const state = yield select();
        const vppId = yield select(getActiveVppId);
        const {
            displayedScheduledPeaks: {
                duration,
                anchor,
            },
        } = state.peaks[vppId];
        fetchAction.payload = {
            duration,
            anchor,
            vppId,
        };
    }
    yield call(handleFetchWindow, fetchAction);
}

function* handleNewActiveVpp(action) {
    const {
        vppId,
    } = action.payload;
    if (!window.location.hash.includes('peaks')) return;
    const state = yield select();
    const {
        displayedScheduledPeaks = {},
        displayedCompletedPeaks = {},
    } = state.peaks[vppId] || {};
    yield all([
        put({
            type: PEAKS_SHOW_WINDOW,
            payload: {
                vppId,
                anchor: displayedScheduledPeaks.anchor,
                duration: displayedScheduledPeaks.duration || 'P7D',
            },
        }),
        put({
            type: PEAKS_SHOW_WINDOW,
            payload: {
                vppId,
                anchor: displayedCompletedPeaks.anchor,
                duration: displayedCompletedPeaks.duration || 'P-7D',
            },
        }),
    ]);
}

export {
    handler,
    handleFetchWindow,
};
