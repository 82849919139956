import React from 'react';
import DropdownList from 'react-widgets/lib/DropdownList';

const DeviceSearchDropdown = ({
    label,
    options,
    value,
    onSelect,
    getDisplayName = item => item,
    filter = false,
}) => (
    <div className="row">
        <h5 className='dtwp__inline'>{label}</h5>
        <DropdownList
            data={options}
            value={value}
            filter={filter}
            defaultValue={value || options[0]}
            textField={getDisplayName}
            onChange={item => onSelect(item)}
            className="devices-search__dropdown"
        />
    </div>
);

export {
    DeviceSearchDropdown,
};
