import React from 'react';

import {
    PeakEventViewWrapper,
} from '../../components/peak-event-view-wrapper';

import {
    PeakAction,
} from '../../components/peak-action';

import {
    getHumanReadablePeakState,
    dateFormatter,
    getPushNotificationState,
} from '../../utils';

export const FuturePeakEvent = ({
    startTS,
    endTS,
    state,
    listeners,
    notify,
    handleDelete,
    handleDuplicate,
    handleEdit,
    error = null,
    saveState,
}) => (
    <PeakEventViewWrapper
        columns={[
            getHumanReadablePeakState({
                state,
                saveState,
            }),
            dateFormatter(startTS),
            dateFormatter(endTS),
            getPushNotificationState(listeners, notify),
        ]}
        actions={(
            <div className="peak-scheduler__peak__actions">
                <PeakAction handler={() => handleEdit()} faIconClass="cog" tooltipText="Edit" />
                <PeakAction handler={() => handleDuplicate()} faIconClass="files-o" tooltipText="Duplicate" />
                <PeakAction handler={() => handleDelete()} faIconClass="trash" tooltipText="Delete" />
            </div>
        )}
        error={error}
    />
);
