import React from 'react';
import moment from 'moment';

export const dateFormatter = ts => moment(ts).format('MMM D, YYYY h:mm A');

export function getHumanReadablePeakState({
    label,
    state,
    saveState,
}) {
    if (saveState === 'deleted') return 'Deleted';
    if (saveState) return (<span>{`${captializeState(saveState)} ...`}</span>);
    if (label) return (<span>{ label }</span>);
    return state ? captializeState(state) : null;
}

const PushNotificationText = ({ state, message }) => (
    <div className='peak-scheduler--notification'>
        <span className='peak-scheduler--notification-state'>
            {
                state
                    ? 'Yes'
                    : 'No'
            }

        </span>
        {message != null
            && (
                <>
                    <span className='peak-scheduler--notification-state'> with message:</span>
                    <p className='peak-scheduler--notification-message'>
                        {message}
                    </p>
                </>
            )
        }
    </div>
);

export function getPushNotificationState(listeners = {}, notifyParams = {}) {
    if (listeners.notify == null) return <PushNotificationText state={false} />;
    if (!listeners.notify.includes('send-peak-notifications')) {
        return <PushNotificationText state={false} />;
    }

    if (notifyParams.message == null) {
        return <PushNotificationText state />;
    }

    return <PushNotificationText state message={notifyParams.message} />;
}

function captializeState(state) {
    return `${state.charAt(0).toUpperCase()}${state.slice(1)}`;
}
