import React from 'react';
import { RedirectIfHasVPP } from './RedirectIfHasVPP';

// TODO: Remove hardcoded email address
export const VPPRequiredPage = () => (
    <div>
        <RedirectIfHasVPP />
        <h1>Welcome to Nyle Systems</h1>
        <p>
            Your user has not yet been assigned to a VB.
            <br />
            If this is unexpected, please contact waterservice@nyle.com
        </p>
    </div>
);
