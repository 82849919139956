import {
    FETCH_VPPS_SUCCEEDED,
    VPP_FETCH_FAILED,
    VPP_FETCH_SUCCEEDED,
    VPP_METRICS_FETCH_FAILED,
    VPP_METRICS_FETCH_SUCCEEDED,
    VPP_METRICS_SHOW_WINDOW,
    UPDATE_PERFORMANCE_DISPLAY,
    GET_ENERGY_HISTORY_SUCCESS,
    GET_POWER_HISTORY_SUCCESS,
    GET_BASELINE_ENERGY_SUCCESS,
    UPDATE_VPP_STATE,
    UPDATE_NEXT_PEAK_EVENT,
    GET_DEVICE_COUNT_SUCCESS,
    UPDATE_VPP_MODE_SUCCESS,
    GET_PEAK_INFO_SUCCESS,
    UPDATE_IS_LOADING,
    FETCH_VPP_CONFIG,
    FETCH_VPP_CONFIG_SUCCESS,
    FETCH_VPP_CONFIG_FAILURE,
    FETCH_VPP_VALUE_SUCCESS,
    UPDATE_ACTIVE_VPP,
    GET_VPP_MODE_HISTORY_SUCCESS,
} from './actions';
import {
    FETCH_USER_SUCCESS,
} from '../user/actions';
import {
    GET_PEAK_EVENT_SUCCESS,
} from '../peaks/actions';
import {
    DEFAULT_DURATIONS,
    ENERGY_BUCKETS,
    DISPLAY_INTERVALS,
    DISPLAY_TYPES,
    getEnergyIntervalId,
} from './utils';
import {
    TWO_WEEKS,
    toWindowId,
    NOW,
} from '../../utils/windows';
import { getReadableTimerange } from '../../operator/vpp-operator-chart/settings/utils';

import {
    ASYNC_STATUS,
} from '../../utils/async';

const NO_VPP = Symbol('no-vpp');
const LOADING_VPP = Symbol('loading-vpp');

const INITIAL_AGGREGATE_STATE = {
    displayed: [],
    vpps: {},
    activeVpp: LOADING_VPP,
};

const reducer = (state = INITIAL_AGGREGATE_STATE, action) => {
    switch (action.type) {
        case UPDATE_ACTIVE_VPP: {
            const {
                vppId,
            } = action.payload;
            return {
                ...state,
                activeVpp: vppId,
            };
        }
        case FETCH_VPPS_SUCCEEDED: {
            const newVpps = action.payload.reduce((vpps, vppConfig) => ({
                ...vpps,
                [vppConfig.vppId]: {
                    metrics: generateEmptyMetricsDoc(),
                    name: vppConfig.name || vppConfig.vppId,
                    vppId: vppConfig.vppId,
                    lseId: vppConfig.lseId,
                },
            }), {});
            return {
                ...state,
                vpps: {
                    ...state.vpps,
                    ...newVpps,
                },
            };
        }
        case FETCH_USER_SUCCESS: {
            const { vpp } = action.payload;
            return {
                ...state,
                activeVpp: vpp,
            };
        }
        case FETCH_VPP_CONFIG:
        case FETCH_VPP_CONFIG_SUCCESS:
        case FETCH_VPP_CONFIG_FAILURE: {
            const vppConfig = action.payload || {};
            const vppId = action.payload.vppId || vppConfig.vppId || state.activeVpp;
            if (!vppId) return state;
            return {
                ...state,
                activeVpp: state.activeVpp || vppId,
                vpps: {
                    ...state.vpps,
                    [vppId]: vppReducer(state.vpps[vppId], action),
                },
            };
        }
        case UPDATE_PERFORMANCE_DISPLAY:
        case VPP_FETCH_SUCCEEDED:
        case VPP_FETCH_FAILED:
        case VPP_METRICS_FETCH_FAILED:
        case VPP_METRICS_FETCH_SUCCEEDED:
        case VPP_METRICS_SHOW_WINDOW:
        case GET_ENERGY_HISTORY_SUCCESS:
        case GET_POWER_HISTORY_SUCCESS:
        case GET_BASELINE_ENERGY_SUCCESS:
        case UPDATE_VPP_STATE:
        case UPDATE_NEXT_PEAK_EVENT:
        case GET_DEVICE_COUNT_SUCCESS:
        case UPDATE_VPP_MODE_SUCCESS:
        case GET_PEAK_INFO_SUCCESS:
        case GET_PEAK_EVENT_SUCCESS:
        case UPDATE_IS_LOADING:
        case FETCH_VPP_VALUE_SUCCESS:
        case GET_VPP_MODE_HISTORY_SUCCESS: {
            const vppId = action.payload.vppId || state.activeVpp;
            if (!vppId) return state;
            return {
                ...state,
                vpps: {
                    ...state.vpps,
                    [vppId]: vppReducer(state.vpps[vppId], action),
                },
            };
        }
        default: return state;
    }
};

const generateEmptyMetricsDoc = () => ({
    lastUpdated: null,
    instantaneous: {
        error: false,
        devicesOnline: null,
        devicesDeployed: null,
        energyMarketValue: null,
    },
    activeMetricsWindow: {
        duration: TWO_WEEKS,
        anchor: NOW,
    },
    intervals: {},
});

const INITIAL_METRICS_STATE = generateEmptyMetricsDoc();

const INITIAL_VPP_STATE = {
    vppId: null,
    name: null,
    metrics: INITIAL_METRICS_STATE,
    performanceDisplay: {
        displayType: DISPLAY_TYPES.ENERGY,
        anchor: NOW,
        direction: 'end',
        readableWindow: null,
        bucketSize: ENERGY_BUCKETS.ONE_HOUR,
        duration: DEFAULT_DURATIONS.ENERGY_ONE_HOUR,
        displayIntervals: DISPLAY_INTERVALS.ENERGY_ONE_HOUR,
    },
    pollingDelayInterval: 1000 * 30,
    modeHistory: [],
    deviceCounts: {
        devicesSeen: null,
        totalDevices: null,
    },
    peak: {
        activePeakEventId: null,
        activePeakEvent: null,
        nextPeakEvent: null,
    },
    energy: {},
    power: {
        powerHistory: [],
        powerObservedAt: null,
        currentPowerKW: null,
        currentSetPointKW: null,
    },
    baselineEnergy: [],
    value: {
        averagePeakValue: null,
        avgPeakValuePerDevice: null,
    },
    timezone: 'UTC',
    priceSource: null,

    // async state
    configStatus: ASYNC_STATUS.NOT_STARTED,

    // used on the admin page
    loading: false,

    // VPP specific feature flags
    resizeFor4K: false,
    isFasterThanRealtimeDemo: false,
    displayBaseline: false,
    displayPowerGraph: false,
    showDevicesTab: false,
};

const getDisplayIntervals = ({ displayType, bucketSize, isFaster }) => {
    if (displayType === DISPLAY_TYPES.ENERGY) {
        return bucketSize === ENERGY_BUCKETS.ONE_HOUR
            ? DISPLAY_INTERVALS.ENERGY_ONE_HOUR
            : DISPLAY_INTERVALS.ENERGY_FIVE_MIN;
    }
    return isFaster ? DISPLAY_INTERVALS.FASTER : DISPLAY_INTERVALS.NOT_FASTER;
};

const vppReducer = (state = INITIAL_VPP_STATE, action) => {
    const metrics = metricsReducer(state.metrics, action);
    switch (action.type) {
        case UPDATE_IS_LOADING: {
            return {
                ...state,
                loading: action.payload.loading,
            };
        }
        case FETCH_VPP_CONFIG: {
            return {
                ...state,
                configStatus: ASYNC_STATUS.WAITING,
            };
        }
        case FETCH_VPP_CONFIG_SUCCESS: {
            const {
                isFasterThanRealtimeDemo: isFaster,
                name,
                timezone,
                priceSource,
                resizeFor4K,
                displayBaseline,
                displayPowerGraph,
                showDevicesTab,
            } = action.payload;
            return {
                ...state,
                name,
                timezone,
                priceSource,
                resizeFor4K,
                displayBaseline,
                displayPowerGraph,
                showDevicesTab,
                isFasterThanRealtimeDemo: isFaster,
                pollingDelayInterval: isFaster ? 1000 * 2 : 1000 * 30,
                configStatus: ASYNC_STATUS.SUCCESS,
            };
        }
        case FETCH_VPP_CONFIG_FAILURE: {
            return {
                ...state,
                configStatus: ASYNC_STATUS.FAILURE,
            };
        }
        case UPDATE_PERFORMANCE_DISPLAY: {
            const performanceDisplay = Object.assign(
                {},
                state.performanceDisplay,
                action.payload.performanceDisplay,
            );
            const readableWindow = action.payload.performanceDisplay.readableWindow
                || getReadableTimerange(performanceDisplay);
            const {
                displayType,
                bucketSize,
            } = performanceDisplay;
            const displayIntervals = getDisplayIntervals({
                displayType,
                bucketSize,
                isFaster: state.isFasterThanRealtimeDemo,
            });
            return {
                ...state,
                performanceDisplay: {
                    ...performanceDisplay,
                    readableWindow,
                    displayIntervals,
                },
            };
        }
        case GET_ENERGY_HISTORY_SUCCESS: {
            const intervalId = getEnergyIntervalId(action.payload);
            return {
                ...state,
                energy: {
                    ...state.energy,
                    [intervalId]: action.payload.energyHistory,
                },
            };
        }
        case GET_BASELINE_ENERGY_SUCCESS: {
            return {
                ...state,
                baselineEnergy: action.payload.baselineEnergy,
            };
        }
        case GET_POWER_HISTORY_SUCCESS: {
            const {
                powerHistory,
                isAverage,
            } = action.payload;
            return {
                ...state,
                power: {
                    ...state.power,
                    powerHistory,
                    isAverage,
                },
            };
        }
        case UPDATE_VPP_STATE: {
            const updatedState = formatVPPState({
                oldVPPState: state,
                ...action.payload,
            });
            return {
                ...state,
                ...updatedState,
                deviceCounts: {
                    ...state.deviceCounts,
                    ...updatedState.deviceCounts,
                },
            };
        }
        case UPDATE_NEXT_PEAK_EVENT: {
            return {
                ...state,
                peak: {
                    ...state.peak,
                    nextPeakEvent: action.payload.nextPeakEvent,
                },
            };
        }
        case GET_PEAK_EVENT_SUCCESS: {
            return {
                ...state,
                peak: {
                    ...state.peak,
                    activePeakEvent: action.payload,
                },
            };
        }
        case GET_DEVICE_COUNT_SUCCESS: {
            const {
                maxDevicesSeen,
                devicesInstalled,
            } = action.payload;
            return {
                ...state,
                deviceCounts: {
                    ...state.deviceCounts,
                    devicesSeen: maxDevicesSeen,
                    totalDevices: devicesInstalled,
                },
            };
        }

        case GET_PEAK_INFO_SUCCESS: {
            return {
                ...state,
                peak: {
                    ...state.peak,
                    ...action.payload.value,
                },
            };
        }
        case FETCH_VPP_VALUE_SUCCESS: {
            return {
                ...state,
                value: {
                    ...state.value,
                    ...action.payload.value,
                },
            };
        }
        case GET_VPP_MODE_HISTORY_SUCCESS: {
            return {
                ...state,
                modeHistory: action.payload.modeHistory,
            };
        }
        case VPP_FETCH_SUCCEEDED:
        case VPP_FETCH_FAILED:
        default: return {
            ...state,
            metrics,
        };
    }
};

const formatVPPState = ({
    oldVPPState,
    currentPowerObs,
}) => {
    const updatedVPPState = {};
    if (currentPowerObs) {
        const power = {
            powerObservedAt: currentPowerObs.at,
            currentPowerKW: currentPowerObs.actualKW,
            currentSetPointKW: currentPowerObs.targetKW,
        };
        updatedVPPState.power = Object.assign({}, oldVPPState.power, power);
        const {
            activePeakEventId: activePeakEventIdAsString,
        } = currentPowerObs;
        const peak = {
            activePeakEventId: activePeakEventIdAsString ? Number(activePeakEventIdAsString) : null,
        };
        updatedVPPState.peak = Object.assign({}, oldVPPState.peak, peak);
        updatedVPPState.deviceCounts = {
            baselineCount: currentPowerObs.baselineCount,
        };
    }
    return Object.assign({}, oldVPPState, updatedVPPState);
};

const metricsReducer = (state = INITIAL_METRICS_STATE, action) => {
    const lastUpdated = Date.now();
    const {
        instantaneous,
    } = state;
    switch (action.type) {
        case VPP_FETCH_SUCCEEDED: {
            const {
                deviceCount,
                energyMarketValue,
            } = action.payload;
            return {
                ...state,
                instantaneous: {
                    ...instantaneous,
                    error: false,
                    lastUpdated,
                    devicesOnline: deviceCount,
                    energyMarketValue,
                },
            };
        }
        case VPP_FETCH_FAILED: {
            return {
                ...state,
                instantaneous: {
                    ...instantaneous,
                    lastUpdated,
                    error: true,
                    devicesOnline: null,
                    energyMarketValue: null,
                },
            };
        }
        case VPP_METRICS_FETCH_SUCCEEDED: {
            const intervalId = toWindowId(action.payload.interval);
            return {
                ...state,
                intervals: {
                    ...state.intervals,
                    [intervalId]: action.payload.metrics,
                },
            };
        }
        case VPP_METRICS_FETCH_FAILED: {
            const intervalId = toWindowId(action.payload.interval);
            return {
                ...state,
                intervals: {
                    ...state.intervals,
                    [intervalId]: null,
                },
            };
        }
        case VPP_METRICS_SHOW_WINDOW: {
            const {
                duration,
                anchor,
            } = action.payload;
            return {
                ...state,
                activeMetricsWindow: {
                    duration,
                    anchor,
                },
            };
        }
        default: return state;
    }
};

export {
    reducer,
    INITIAL_VPP_STATE,
    NO_VPP,
    LOADING_VPP,
};
