/* globals window */
import {
    takeEvery,
    put,
    call,
    select,
} from 'redux-saga/effects';
import {
    delay,
} from 'redux-saga';
import { container } from '../../dependency-injection';
import { DeviceDAO } from './device-dao';
import {
    FETCH_DEVICES,
    FETCH_DEVICES_SUCCESS,
    FETCH_DEVICES_FAILURE,
    FETCH_VPP_LIST,
    FETCH_VPP_LIST_SUCCESS,
    UPDATE_PRODUCT_TYPE_AND_VERSION,
    UPDATE_VPP_FILTER,
    UPDATE_CONNECTION_STATUS_FILTER,
    SET_FILTER_BY_INSTALL_DATE,
    UPDATE_INSTALL_DATE_FILTER,
    CLEAR_ALL_FILTERS,
    FETCH_DEVICE,
    FETCH_DEVICE_SUCCESS,
    FETCH_DEVICE_FAILURE,
    REBOOT_DEVICE,
    REBOOT_DEVICE_SUCCESS,
    REBOOT_DEVICE_FAILURE,
    TOGGLE_DEVICE_DEBUGGING,
    TOGGLE_DEVICE_DEBUGGING_FAILURE,
    UPDATE_USER_SETTING,
    UPDATE_USER_SETTING_FAILURE,
    FETCH_DEVICE_GRAPH_DATA,
    FETCH_DEVICE_ENERGY_DATA_SUCCESS,
    FETCH_DEVICE_POWER_DATA_SUCCESS,
    FETCH_DEVICE_DEBUG_DATA_SUCCESS,
    FETCH_DEVICE_CONNECTIONS_DATA_SUCCESS,
    FETCH_DEVICE_ENERGY_DATA_FAILURE,
    FETCH_DEVICE_POWER_DATA_FAILURE,
    FETCH_DEVICE_DEBUG_DATA_FAILURE,
    FETCH_DEVICE_CONNECTIONS_DATA_FAILURE,
    SET_DEVICE_GRAPH_RANGE,
    GET_DEVICE_SHADOW,
    GET_DEVICE_SHADOW_SUCCESS,
    GET_DEVICE_SHADOW_FAILURE,
    LIST_RPCS,
    LIST_RPCS_SUCCESS,
    LIST_RPCS_FAILURE,
    CALL_RPC,
    CALL_RPC_SUCCESS,
    CALL_RPC_FAILURE,
    ACKNOWLEDGE_DEVICE_OFFLINE,
    ACKNOWLEDGE_DEVICE_OFFLINE_FAILURE,
    UPDATE_CUSTOMER_NOTES,
    UPDATE_CUSTOMER_NOTES_FAILURE,
    UPDATE_DEVICE_VPP,
    UPDATE_DEVICE_VPP_SUCCESS,
    UPDATE_DEVICE_VPP_FAILURE,
    UPDATE_FAULTY_DEVICE_FLAG,
    UPDATE_FAULTY_DEVICE_FLAG_FAILURE,
    FETCH_USERS_FOR_DEVICE,
    FETCH_USERS_FOR_DEVICE_SUCCESS,
    FETCH_USERS_FOR_DEVICE_FAILURE,
    UPDATE_DEVICE_PARENT,
    UPDATE_DEVICE_PARENT_SUCCESS,
    UPDATE_DEVICE_PARENT_FAILURE,
    UPDATE_UNINSTALLED_AT,
    UPDATE_UNINSTALLED_AT_SUCCESS,
    UPDATE_UNINSTALLED_AT_FAILURE,
    CLEAR_UNINSTALLED_AT,
    CLEAR_UNINSTALLED_AT_SUCCESS,
    CLEAR_UNINSTALLED_AT_FAILURE,
    FETCH_USER_DEVICES,
    FETCH_USER_DEVICES_SUCCESS,
    FETCH_USER_DEVICES_FAILURE,
} from './actions';
import {
    getActiveVppId,
} from '../vpps/selectors';
import {
    ACTIVE_VPP_CHANGED,
} from '../vpps/actions';
import { NO_VPP } from '../vpps/reducers';

function* handler() {
    yield takeEvery([
        FETCH_DEVICES,
        UPDATE_PRODUCT_TYPE_AND_VERSION,
        UPDATE_VPP_FILTER,
        UPDATE_CONNECTION_STATUS_FILTER,
        SET_FILTER_BY_INSTALL_DATE,
        UPDATE_INSTALL_DATE_FILTER,
        CLEAR_ALL_FILTERS,
    ], handleFetchDevices);
    yield takeEvery(FETCH_USER_DEVICES, handleFetchUserDevices);
    yield takeEvery(FETCH_VPP_LIST, handleFetchVPPs);
    yield takeEvery(FETCH_DEVICE, handleFetchDevice);
    yield takeEvery(GET_DEVICE_SHADOW, handleGetDeviceShadow);
    yield takeEvery(REBOOT_DEVICE, handleRebootDevice);
    yield takeEvery(LIST_RPCS, handleListRpcs);
    yield takeEvery(CALL_RPC, handleCallRpc);
    yield takeEvery(TOGGLE_DEVICE_DEBUGGING, handleToggleDebugging);
    yield takeEvery(UPDATE_USER_SETTING, handleUpdateUserSetting);
    yield takeEvery(FETCH_DEVICE_GRAPH_DATA, handleFetchGraphData);
    yield takeEvery(SET_DEVICE_GRAPH_RANGE, handleGraphRangeChanged);
    yield takeEvery(ACTIVE_VPP_CHANGED, handleNewActiveVpp);
    yield takeEvery(ACKNOWLEDGE_DEVICE_OFFLINE, handleAcknowledgeDeviceOffline);
    yield takeEvery(UPDATE_CUSTOMER_NOTES, handleUpdateCustomerNotes);
    yield takeEvery(UPDATE_DEVICE_VPP, handleUpdateDeviceVpp);
    yield takeEvery(UPDATE_FAULTY_DEVICE_FLAG, handleUpdateFaultyDeviceFlag);
    yield takeEvery(FETCH_USERS_FOR_DEVICE, handleFetchUsersForDevice);
    yield takeEvery(UPDATE_DEVICE_PARENT, handleUpdateDeviceParent);
    yield takeEvery(UPDATE_UNINSTALLED_AT, handleUpdateUninstalledAt);
    yield takeEvery(CLEAR_UNINSTALLED_AT, handleClearUninstalledAt);
}

function* handleFetchDevices(action) {
    const devicesClient = container.get(DeviceDAO);
    const state = yield select();
    const { filters } = state.devices;
    const params = {
        ...filters,
        ...action.payload,
    };
    if (!params.filterByInstalled) {
        delete params.installedAfter;
        delete params.installedBefore;
    }
    let vppId = params.operatorVPP;
    if (!vppId) {
        vppId = yield select(getActiveVppId);
    }
    if (typeof vppId !== 'string') vppId = null;
    try {
        const devices = yield call(devicesClient.getDevices, params, vppId);
        yield put({
            type: FETCH_DEVICES_SUCCESS,
            payload: {
                devices,
            },
        });
    } catch (err) {
        yield put({
            type: FETCH_DEVICES_FAILURE,
        });
    }
}

function* handleFetchUserDevices() {
    const devicesClient = container.get(DeviceDAO);
    try {
        const devices = yield call(devicesClient.getUserDevices);
        yield put({
            type: FETCH_USER_DEVICES_SUCCESS,
            payload: {
                devices,
            },
        });
    } catch (err) {
        yield put({
            type: FETCH_USER_DEVICES_FAILURE,
        });
    }
}

function* handleFetchVPPs() {
    const usersClient = container.get('UserDAO');
    try {
        const vpps = yield call(usersClient.getVPPList);
        yield put({
            type: FETCH_VPP_LIST_SUCCESS,
            payload: {
                vpps,
            },
        });
    } catch (err) {} // eslint-disable-line no-empty
}

function* handleFetchDevice(action) {
    const devicesClient = container.get(DeviceDAO);
    const {
        deviceId,
    } = action.payload;
    const state = yield select();
    const vppId = getActiveDeviceVpp(state, deviceId);

    try {
        const device = yield call(devicesClient.getDevice, deviceId, vppId);
        yield put({
            type: FETCH_DEVICE_SUCCESS,
            payload: device,
        });
    } catch (err) {
        yield put({
            type: FETCH_DEVICE_FAILURE,
            payload: {
                deviceId,
            },
        });
    }
}

function* handleGetDeviceShadow(action) {
    const devicesClient = container.get(DeviceDAO);
    const {
        deviceId,
    } = action.payload;
    const vppId = yield select(getActiveVppId);
    try {
        const shadow = yield call(devicesClient.getShadow, deviceId, vppId);
        yield put({
            type: GET_DEVICE_SHADOW_SUCCESS,
            payload: {
                deviceId,
                shadow,
            },
        });
    } catch (err) {
        yield put({
            type: GET_DEVICE_SHADOW_FAILURE,
            payload: {
                deviceId,
            },
        });
    }
}

function* handleRebootDevice(action) {
    const devicesClient = container.get(DeviceDAO);
    const {
        deviceId,
    } = action.payload;
    const vppId = yield select(getActiveVppId);
    try {
        yield call(devicesClient.rebootDevice, {
            deviceId,
            vppId,
        });
        yield put({
            type: REBOOT_DEVICE_SUCCESS,
            payload: {
                deviceId,
            },
        });
    } catch (err) {
        yield put({
            type: REBOOT_DEVICE_FAILURE,
            payload: {
                deviceId,
            },
        });
    }
}

function* handleListRpcs(action) {
    const devicesClient = container.get(DeviceDAO);
    const {
        deviceId,
    } = action.payload;
    const vppId = yield select(getActiveVppId);
    try {
        const res = yield call(devicesClient.callRPC, {
            vppId,
            deviceId,
            method: 'RPC.List',
        });
        yield put({
            type: LIST_RPCS_SUCCESS,
            payload: res,
        });
    } catch (err) {
        yield put({
            type: LIST_RPCS_FAILURE,
        });
    }
}

function* handleCallRpc(action) {
    const devicesClient = container.get(DeviceDAO);
    const {
        deviceId,
        method,
        args,
    } = action.payload;
    const vppId = yield select(getActiveVppId);
    try {
        const res = yield call(devicesClient.callRPC, {
            vppId,
            deviceId,
            method,
            args,
        });
        yield put({
            type: CALL_RPC_SUCCESS,
            payload: res,
        });
    } catch (err) {
        yield put({
            type: CALL_RPC_FAILURE,
        });
    }
}

function* handleToggleDebugging(action) {
    const devicesClient = container.get(DeviceDAO);
    const {
        deviceId,
    } = action.payload;
    const vppId = yield select(getActiveVppId);
    const state = yield select();
    try {
        const { device } = state.devices.devices[deviceId];
        const isDebugging = device.debugInterval > 0;
        yield call(devicesClient.setDeviceDebugInterval, {
            deviceId,
            debugInterval: isDebugging ? 0 : 30,
            vppId,
        });
        yield call(sleep, 1);
        yield put({
            type: FETCH_DEVICE,
            payload: {
                deviceId,
            },
        });
    } catch (err) {
        yield put({
            type: TOGGLE_DEVICE_DEBUGGING_FAILURE,
            payload: {
                deviceId,
            },
        });
    }
}

function* handleUpdateUserSetting(action) {
    const devicesClient = container.get(DeviceDAO);
    const {
        deviceId,
        setting,
        value,
    } = action.payload;
    const vppId = yield select(getActiveVppId);
    try {
        yield call(devicesClient.setUserSetting, {
            deviceId,
            setting,
            value,
            vppId,
        });
        yield call(sleep, 1);
        yield put({
            type: FETCH_DEVICE,
            payload: {
                deviceId,
            },
        });
    } catch (err) {
        yield put({
            type: UPDATE_USER_SETTING_FAILURE,
            payload: {
                deviceId,
            },
        });
    }
}

function getGraphRange(state, deviceId) {
    const {
        startTS,
        endTS,
    } = state.devices.devices[deviceId].graphRange;
    return [startTS, endTS];
}

function* getEnergyData(params) {
    const devicesClient = container.get(DeviceDAO);
    const { deviceId } = params;
    try {
        const data = yield call(devicesClient.getEnergyData, params);
        yield put({
            type: FETCH_DEVICE_ENERGY_DATA_SUCCESS,
            payload: {
                deviceId,
                data,
            },
        });
    } catch (err) {
        yield put({
            type: FETCH_DEVICE_ENERGY_DATA_FAILURE,
            payload: {
                deviceId,
            },
        });
    }
}

function* getPowerData(params) {
    const devicesClient = container.get(DeviceDAO);
    const { deviceId } = params;
    try {
        const data = yield call(devicesClient.getPowerData, params);
        yield put({
            type: FETCH_DEVICE_POWER_DATA_SUCCESS,
            payload: {
                deviceId,
                data,
            },
        });
    } catch (err) {
        yield put({
            type: FETCH_DEVICE_POWER_DATA_FAILURE,
            payload: {
                deviceId,
            },
        });
    }
}

function* getDebugData(params) {
    const devicesClient = container.get(DeviceDAO);
    const { deviceId } = params;
    try {
        const data = yield call(devicesClient.getDebugData, params);
        yield put({
            type: FETCH_DEVICE_DEBUG_DATA_SUCCESS,
            payload: {
                deviceId,
                data,
            },
        });
    } catch (err) {
        yield put({
            type: FETCH_DEVICE_DEBUG_DATA_FAILURE,
            payload: {
                deviceId,
            },
        });
    }
}

function* getConnectionsData(params) {
    const devicesClient = container.get(DeviceDAO);
    const { deviceId } = params;
    try {
        const data = yield call(devicesClient.getConnectionsData, params);
        yield put({
            type: FETCH_DEVICE_CONNECTIONS_DATA_SUCCESS,
            payload: {
                deviceId,
                data,
            },
        });
    } catch (err) {
        yield put({
            type: FETCH_DEVICE_CONNECTIONS_DATA_FAILURE,
            payload: {
                deviceId,
            },
        });
    }
}

function* handleFetchGraphDataByType({
    dataType,
    ...params
}) {
    switch (dataType) {
        case 'energy': {
            yield call(getEnergyData, params);
            break;
        }
        case 'power': {
            yield call(getPowerData, params);
            break;
        }
        case 'debug': {
            yield call(getDebugData, params);
            break;
        }
        case 'connections': {
            yield call(getConnectionsData, params);
            break;
        }
        default: {
            // pass
            break;
        }
    }
}

function* handleFetchGraphData(action) {
    const {
        deviceId,
        dataType,
    } = action.payload;
    const state = yield select();
    const [startTS, endTS] = getGraphRange(state, deviceId);
    const vppId = getActiveDeviceVpp(state, deviceId);
    yield call(handleFetchGraphDataByType, {
        dataType,
        deviceId,
        startTS,
        endTS,
        vppId,
    });
}

function* handleGraphRangeChanged(action) {
    const vppId = yield select(getActiveVppId);
    yield call(handleFetchGraphDataByType, {
        ...action.payload,
        vppId,
    });
}

function* handleNewActiveVpp(action) {
    const {
        vppId,
    } = action.payload;
    if (!window.location.hash.includes('devices')) return;
    if (window.location.hash === '#/devices') { // refresh search with new VPP's devices
        yield put({
            type: FETCH_DEVICES,
            payload: {
                operatorVPP: vppId,
            },
        });
    }
}

function* handleAcknowledgeDeviceOffline(action) {
    const devicesClient = container.get(DeviceDAO);
    const {
        deviceId,
    } = action.payload;
    try {
        yield call(devicesClient.acknowledgeOffline, deviceId);
        yield call(handleFetchDevice, {
            payload: { deviceId },
        });
    } catch (err) {
        yield put({
            type: ACKNOWLEDGE_DEVICE_OFFLINE_FAILURE,
            payload: {
                deviceId,
            },
        });
    }
}

function* handleUpdateCustomerNotes(action) {
    const devicesClient = container.get(DeviceDAO);
    const {
        deviceId,
        customerNotes,
    } = action.payload;
    try {
        yield call(devicesClient.updateCustomerNotes, {
            deviceId,
            customerNotes,
        });
        yield call(handleFetchDevice, {
            payload: { deviceId },
        });
    } catch (err) {
        yield put({
            type: UPDATE_CUSTOMER_NOTES_FAILURE,
            payload: {
                deviceId,
            },
        });
    }
}

function* handleUpdateDeviceVpp(action) {
    const devicesClient = container.get(DeviceDAO);
    const {
        deviceId,
        vppId,
    } = action.payload;
    const state = yield select();
    const currentVppId = state.vpps?.activeVpp;
    try {
        yield call(devicesClient.updateDeviceVpp, {
            deviceId,
            vppId,
            currentVppId,
        });
        yield put({
            type: UPDATE_DEVICE_VPP_SUCCESS,
            payload: {
                deviceId,
                vppId,
            },
        });
    } catch (err) {
        yield put({
            type: UPDATE_DEVICE_VPP_FAILURE,
            payload: {
                deviceId,
                message: 'Failed to update device virtual battery',
            },
        });
    }
}

function* handleUpdateFaultyDeviceFlag(action) {
    const devicesClient = container.get(DeviceDAO);
    const {
        deviceId,
        faulty,
    } = action.payload;
    try {
        yield call(devicesClient.updateFaultyDeviceFlag, {
            deviceId,
            faulty,
        });
        yield put({
            type: GET_DEVICE_SHADOW,
            payload: {
                deviceId,
            },
        });

        yield delay(5000);
        // fetch again after allowing time for device to update the shadow
        yield put({
            type: GET_DEVICE_SHADOW,
            payload: {
                deviceId,
            },
        });
    } catch (err) {
        yield put({
            type: UPDATE_FAULTY_DEVICE_FLAG_FAILURE,
            payload: {
                deviceId,
            },
        });
    }
}

function* handleFetchUsersForDevice(action) {
    const devicesClient = container.get(DeviceDAO);
    const {
        deviceId,
    } = action.payload;
    try {
        const users = yield call(devicesClient.getUsersForDevice, { deviceId });
        yield put({
            type: FETCH_USERS_FOR_DEVICE_SUCCESS,
            payload: {
                deviceId,
                users,
            },
        });
    } catch (err) {
        yield put({
            type: FETCH_USERS_FOR_DEVICE_FAILURE,
            payload: {
                deviceId,
            },
        });
    }
}

function* handleUpdateDeviceParent(action) {
    const devicesClient = container.get(DeviceDAO);
    const {
        deviceId,
        parent,
    } = action.payload;
    try {
        yield call(devicesClient.setDeviceParent, {
            deviceId,
            parent,
        });
        yield put({
            type: UPDATE_DEVICE_PARENT_SUCCESS,
            payload: {
                deviceId,
                parent,
            },
        });
    } catch {
        yield put({
            type: UPDATE_DEVICE_PARENT_FAILURE,
            payload: {
                deviceId,
                parent,
            },
        });
    }
}

function* handleUpdateUninstalledAt(action) {
    const devicesClient = container.get(DeviceDAO);
    const {
        deviceId,
        uninstalledAt,
    } = action.payload;
    try {
        const vppId = yield select(getActiveVppId);
        yield call(devicesClient.updateUninstalledAt, {
            deviceId,
            uninstalledAt,
            vppId,
        });
        yield put({
            type: UPDATE_UNINSTALLED_AT_SUCCESS,
            payload: {
                deviceId,
                uninstalledAt,
            },
        });
    } catch (error) {
        yield put({
            type: UPDATE_UNINSTALLED_AT_FAILURE,
            payload: {
                deviceId,
                errorMessage: error.description,
            },
        });
    }
}

function* handleClearUninstalledAt(action) {
    const devicesClient = container.get(DeviceDAO);
    const { deviceId } = action.payload;
    try {
        const vppId = yield select(getActiveVppId);
        yield call(devicesClient.clearUninstalledAt, {
            deviceId,
            vppId,
        });
        yield put({
            type: CLEAR_UNINSTALLED_AT_SUCCESS,
            payload: {
                deviceId,
            },
        });
    } catch (error) {
        yield put({
            type: CLEAR_UNINSTALLED_AT_FAILURE,
            payload: {
                deviceId,
                errorMessage: error.description,
            },
        });
    }
}

const getActiveDeviceVpp = (state, deviceId) => {
    let vppId = getActiveVppId(state);
    if (vppId === NO_VPP) {
        const device = state.devices.deviceList.find(d => d.deviceId === deviceId)
            || { vpp: vppId.toString() };
        vppId = device.vpp;
    }
    return vppId;
};

const sleep = waitSec => new Promise(resolve => setTimeout(resolve, waitSec * 1000));

export {
    handler,
};
