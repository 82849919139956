import React from 'react';
import { BAUD_RATES } from '../utils/constants';
import {
    ActionRow,
    DropdownRow,
    InputRow,
} from './settings-rows';

export const BacnetSettings = ({
    device,
    updateUserSetting,
}) => {
    const { user } = device;
    return (
        <div>
            <h2>BACNet IP Settings</h2>
            <table className="table device-detail-table">
                <tbody>
                    <ActionRow
                        handler={() => updateUserSetting('BACNet.IP.enable', !(user.BACNet?.IP?.enable ?? false))}
                        title='Enabled'
                        value={user.BACNet?.IP?.enable ? 'True' : 'False'}
                        buttonTitle={user.BACNet?.IP?.enable ? 'Disable' : 'Enable'}
                        buttonStyleClass='btn-outline-dark'
                    />
                    <InputRow
                        title='Port'
                        value={user.BACNet?.IP?.port}
                        handler={value => updateUserSetting('BACNet.IP.port', Number(value))}
                        inputProps={{
                            type: 'number',
                            min: 0,
                            max: 65535,
                        }}
                        buttonStyleClass='btn-outline-dark'
                    />
                    <InputRow
                        title='Object instance'
                        value={user.BACNet?.IP?.objectInstance}
                        handler={value => updateUserSetting('BACNet.IP.objectInstance', Number(value))}
                        inputProps={{
                            type: 'number',
                            min: 0,
                            max: 255,
                        }}
                        buttonStyleClass='btn-outline-dark'
                    />
                </tbody>
            </table>
            <h2>BACNet MSTP Settings</h2>
            <table className="table device-detail-table">
                <tbody>
                    <ActionRow
                        handler={() => updateUserSetting('BACNet.MSTP.enable', !(user.BACNet?.MSTP?.enable ?? false))}
                        title='Enabled'
                        value={user.BACNet?.MSTP?.enable ? 'True' : 'False'}
                        buttonTitle={user.BACNet?.MSTP?.enable ? 'Disable' : 'Enable'}
                        buttonStyleClass='btn-outline-dark'
                    />
                    <InputRow
                        title='Object instance'
                        value={user.BACNet?.MSTP?.objectInstance}
                        handler={value => updateUserSetting('BACNet.MSTP.objectInstance', Number(value))}
                        inputProps={{
                            type: 'number',
                            min: 0,
                            max: 255,
                        }}
                        buttonStyleClass='btn-outline-dark'
                    />
                    <DropdownRow
                        label='BAUD Rate'
                        value={user.BACNet?.MSTP?.baudRate}
                        handler={value => updateUserSetting('BACNet.MSTP.baudRate', Number(value))}
                        data={BAUD_RATES}
                    />
                </tbody>
            </table>
        </div>
    );
};
