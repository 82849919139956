import React, { Component } from 'react';
import { VPPOperatorChart } from './vpp-operator-chart';
import { getReadableTimerange } from './vpp-operator-chart/settings/utils';
import './operator-page-view.css';
import {
    VPPMetrics,
} from './vpp-state/vpp-metrics';
import {
    asMS,
} from '../utils/time';
import {
    getChartSeries,
} from './utils';

export class OperatorPageView extends Component {
    renderPage() {
        const {
            vppId,
            currentSetPointKW,
            powerHistory,
            baselineEnergy,
            energyHistory,
            modeHistory,
            performanceDisplay,
            deviceCounts,
            fetchingData,
            showBaseline,
            isFasterThanRealtimeDemo,
            createAlert,
            resizeFor4K = false,
            value,
            pastPeaks,
        } = this.props;

        const {
            anchor,
            duration,
            readableWindow,
            displayType,
            bucketSize,
        } = performanceDisplay;

        const seriesData = getChartSeries({
            powerHistory,
            energyHistory,
            baselineEnergy,
            displayType,
        });

        const shadedRegions = [
            ...pastPeaks.map(peak => ({
                ...peak,
                type: peak.type || 'peak',
            })),
            ...modeHistory.filter(m => m.mode === 'netc')
                .map(mode => ({
                    ...mode,
                    type: 'netc',
                })),
        ];

        const chartProps = {
            ...seriesData,
            currentSetpoint: currentSetPointKW || null,
            xOffset: asMS(duration),
            fetchingData,
            showBaseline,
            createAlert,
            anchor,
            duration,
            performanceDisplay,
            displayType,
            bucketSize,
            resizeFor4K,
            isFasterThanRealtimeDemo,
            shadedRegions,
            vppId,
        };

        return (
            <div style={{ padding: '0em' }} className="vpp-operator container-fluid">
                <VPPMetrics
                    deviceCounts={deviceCounts}
                    value={value}
                />
                <div className="operator-widget vpp-history">
                    <h2>
                        VB History&nbsp;
                        <small>
                            {readableWindow || getReadableTimerange(({
                                duration,
                                anchor,
                            }))}
                        </small>
                    </h2>
                    <div>
                        <VPPOperatorChart {...chartProps} />
                    </div>
                </div>
            </div>
        );
    }

    renderLoading() {
        return null;
    }

    render() {
        const { powerHistory } = this.props;
        if (powerHistory) {
            return this.renderPage();
        }
        return this.renderLoading();
    }
}
