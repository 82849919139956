import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DateTimePicker } from '../../../lib/react-widgets';
import { Button, Form, FormGroup } from '../../../lib/bootstrap';
import {
    PeakEventViewWrapper,
} from '../../components/peak-event-view-wrapper';
import './edit-peak-event-form.css';
import {
    getHumanReadablePeakState,
} from '../../utils';

export class PeakSchedulerForm extends Component {
    dateToTimestamp(date) {
        return date.valueOf();
    }

    timestampToDate(timestamp) {
        return new Date(timestamp);
    }

    doneEditingEvent(e) {
        e.preventDefault();
        const { onDone } = this.props;
        onDone();
    }

    startTimeChanged(startTime) {
        const { onStartTSChanged } = this.props;
        onStartTSChanged(this.dateToTimestamp(startTime));
    }

    endTimeChanged(endTime) {
        const { onEndTSChanged } = this.props;
        onEndTSChanged(this.dateToTimestamp(endTime));
    }

    sendNotificationChanged(sendNotification) {
        const { onSendNotificationChanged } = this.props;
        onSendNotificationChanged(sendNotification);
    }

    notificationMessageChanged(notificationMessage) {
        const { onNotificationMessageChanged } = this.props;
        onNotificationMessageChanged(notificationMessage);
    }

    render() {
        const {
            peakId,
            startTS,
            endTS,
            sendNotification,
            notificationMessage,
            saveState,
            state,
            error,
            onCancel,
        } = this.props;
        const saving = saveState === 'saving';
        const startTime = this.timestampToDate(startTS);
        const endTime = this.timestampToDate(endTS);
        return (
            <Form onSubmit={e => this.doneEditingEvent(e)} className="row peak-scheduler__peak">
                <div className="col">
                    <PeakEventViewWrapper
                        columns={[
                            getHumanReadablePeakState({
                                label: peakId ? 'Editing peak event' : 'New peak event',
                                state,
                                saveState,
                            }),
                            <FormGroup>
                                <DateTimePicker
                                    value={startTime}
                                    onChange={val => this.startTimeChanged(val)}
                                    showTimezone={false}
                                />
                            </FormGroup>,
                            <FormGroup>
                                <DateTimePicker
                                    value={endTime}
                                    onChange={val => this.endTimeChanged(val)}
                                    showTimezone={false}
                                />
                            </FormGroup>,
                            <FormGroup>
                                <input
                                    className='peak-scheduler--notification-input'
                                    type='checkbox'
                                    checked={sendNotification}
                                    value={sendNotification}
                                    onChange={
                                        evt => this.sendNotificationChanged(evt.target.checked)
                                    }
                                />
                                {sendNotification
                                    && (
                                        <>
                                            <label htmlFor='notification-message'>Custom Message:</label>
                                            <textarea
                                                id='notification-message'
                                                placeholder='Leave blank to use the default message'
                                                value={notificationMessage}
                                                onChange={evt => this.notificationMessageChanged(
                                                    evt.target.value,
                                                )}
                                            />
                                        </>
                                    )
                                }
                            </FormGroup>,
                        ]}
                        actions={(
                            <div>
                                <Button
                                    color='primary'
                                    type='submit'
                                    disabled={saving}
                                    className='peak-scheduler__peak__action'
                                >
                                Save
                                </Button>
                                <Button
                                    color='light'
                                    onClick={onCancel}
                                    disabled={saving}
                                    className='peak-scheduler__peak__action'
                                >
                                Cancel
                                </Button>
                            </div>
                        )}
                        error={error}
                        className='peak-scheduler__peak--editing'
                    />
                </div>
            </Form>
        );
    }

    static propTypes = {
        startTS: PropTypes.number,
        endTS: PropTypes.number,
        onDone: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        onStartTSChanged: PropTypes.func.isRequired,
        onEndTSChanged: PropTypes.func.isRequired,
    };
}
