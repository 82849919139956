/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

export const PeakListPaginator = ({
    lowerTS,
    upperTS,
    next,
    previous,
    paginateForward = true,
}) => {
    const now = Date.now();
    const atAnchor = (paginateForward && now >= lowerTS) || (!paginateForward && now <= upperTS);
    return (
        <nav className='peak-scheduler__paginator' aria-label="peak-pagination">
            <ul className="pagination">
                <li className={`page-item ${paginateForward && atAnchor ? 'disabled' : ''}`}>
                    <a
                        className="page-link"
                        href="#"
                        aria-label="Previous"
                        onClick={(e) => {
                            e.preventDefault();
                            if (paginateForward) previous();
                            else next();
                        }}
                    >
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                    </a>
                </li>
                <li className="page-item">
                    <a className="page-link">
                        {(new Date(lowerTS)).toLocaleDateString()}
                        {' - '}
                        {(new Date(upperTS)).toLocaleDateString()}
                    </a>
                </li>
                <li className={`page-item ${!paginateForward && atAnchor ? 'disabled' : ''}`}>
                    <a
                        className="page-link"
                        href="#"
                        aria-label="Next"
                        onClick={(e) => {
                            e.preventDefault();
                            if (paginateForward) next();
                            else previous();
                        }}
                    >
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                    </a>
                </li>
            </ul>
        </nav>
    );
};
