import queryString from 'query-string';
import {
    createHashHistory,
} from 'history';

const history = createHashHistory();

function updateHistory(locationUpdates) {
    const currentLocation = history.location;
    const currentSearch = queryString.parse(currentLocation.search);
    const updatedSearchMap = locationUpdates.search
        ? Object.assign({}, currentSearch, locationUpdates.search)
        : currentSearch;
    const updatedSearch = Object.keys(updatedSearchMap).reduce((q, key, ix) => {
        const value = updatedSearchMap[key];
        const kv = (typeof value !== 'undefined') ? `${key}=${value}` : '';
        return `${q}${ix > 0 ? '&' : ''}${kv}`;
    }, '');
    const updatedLocation = Object.assign({}, currentLocation, locationUpdates, {
        search: updatedSearch,
    });
    history.push(updatedLocation);
}

function getParsedQueryString() {
    return queryString.parse(history.location.search);
}

export {
    history,
    updateHistory,
    getParsedQueryString,
};
