import {
    DISPLAY_TYPES,
} from '../models/vpps/utils';
import {
    ONE_HOUR_MS,
} from '../utils/time';
import {
    round,
} from '../utils/math';

const getChartSeries = ({
    powerHistory,
    energyHistory,
    baselineEnergy,
    displayType,
}) => {
    const baselinePowerSeries = getBaselinePowerSeries(baselineEnergy);
    let series;
    if (displayType === DISPLAY_TYPES.POWER) {
        series = getPowerSeries(powerHistory);
    } else {
        series = getEnergySeries(energyHistory);
    }
    return {
        baselinePowerSeries,
        ...series,
    };
};

const getEnergySeries = (energyHistory) => {
    const actualEnergySeries = {
        key: 'Actual Energy',
        x: energyHistory.map(obs => obs.bucket),
        y: energyHistory.map(obs => round(obs.actualkWh)),
    };
    return {
        actualEnergySeries,
    };
};

const getPowerSeries = (powerHistory) => {
    const x = powerHistory.map(obs => obs.at);

    const actualPowerSeries = {
        key: 'Actual Power',
        x,
        y: powerHistory.map(obs => round(obs.actualKW)),
    };
    const targetPowerSeries = {
        key: 'Target Power',
        x,
        y: powerHistory.map(obs => round(obs.targetKW)),
    };

    return {
        actualPowerSeries,
        targetPowerSeries,
    };
};

const getBaselinePowerSeries = (baselineEnergy = []) => {
    const baselinePower = [];
    baselineEnergy.forEach((item) => {
        baselinePower.push({
            timestamp: item.timestamp,
            kW: item.baselinekWh,
        });
        baselinePower.push({
            timestamp: item.timestamp + ONE_HOUR_MS - 1,
            kW: item.baselinekWh,
        });
    });
    return {
        key: 'Baseline Power',
        x: baselinePower.map(item => item.timestamp),
        y: baselinePower.map(item => round(item.kW)),
    };
};

export {
    getChartSeries,
};
