import React from 'react';
import moment from 'moment';
import {
    getCurrentTimezone,
} from '../../utils/time';
import './vpp-state.css';

const VPPState = ({
    nextPeakEvent,
}) => {
    const showNextPeak = nextPeakEvent && nextPeakEvent.startTS;
    return (
        <div className="row align-items-baseline no-gutters vpp-state__main">
            {showNextPeak
                ? (
                    <div className="col-auto">
                        <span className="vpp-state__label">
                            Next peak:
                            &nbsp;
                            <span className="vpp-state__value">{getReadableTimerange(nextPeakEvent)}</span>
                        </span>
                    </div>
                )
                : null
            }
        </div>
    );
};

const getReadableTimerange = ({
    startTS,
    endTS,
}) => {
    const start = moment(startTS).format('MM/DD, h:mm A');
    const end = moment(endTS).format('h:mm A');
    return `${start} - ${end} ${getCurrentTimezone()}`;
};

export {
    VPPState,
};
