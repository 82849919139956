import React from 'react';
import {
    NyleError,
} from '../../../../utils/errors';
import {
    getReadableDirection,
} from '../utils';

const Direction = ({
    direction,
    active,
    disabled,
    handleChange,
}) => (
    <label
        className={`btn btn-primary dtwp__direction ${active ? 'active' : ''} ${disabled ? 'disabled' : ''} `}
        onClick={(e) => {
            e.preventDefault();
            if (disabled) handleChange({ message: 'This direction is disabled.' });
            else handleChange(null, direction);
        }}
    >
        <input type='radio' name='direction' id={`direction-${direction}`} defaultChecked={active} />
        { getReadableDirection(direction) }
    </label>
);

const DirectionPicker = ({
    direction,
    disableStartAndCenter = false,
    handleChange,
    handleFailedChange,
}) => {
    const handleChangeWrapper = (err, dir) => {
        if (err) {
            handleFailedChange('Cannot set a window that overlaps with the future.');
            return;
        }
        if (!dir) throw new NyleError('Must pass a direction');
        handleChange(dir);
    };
    return (
        <div className="btn-group btn-group-toggle dtwp__row dtwp__directions" data-toggle='buttons'>
            <Direction
                direction='start'
                active={direction === 'start'}
                disabled={disableStartAndCenter}
                handleChange={handleChangeWrapper}
            />
            <Direction
                direction='center'
                active={direction === 'center'}
                disabled={disableStartAndCenter}
                handleChange={handleChangeWrapper}
            />
            <Direction
                direction='end'
                active={direction === 'end'}
                disabled={false}
                handleChange={handleChangeWrapper}
            />
        </div>
    );
};

export {
    DirectionPicker,
};
