const DOWNLOAD_CSV_DATA = 'download/DOWNLOAD_CSV_DATA';
const DOWNLOAD_CSV_DATA_SUCCESS = 'download/DOWNLOAD_CSV_DATA_SUCCESS';

const downloadCSVData = ({
    vppId,
    startTS,
    endTS,
    tz,
    interval,
    filename,
}) => ({
    type: DOWNLOAD_CSV_DATA,
    payload: {
        vppId,
        startTS,
        endTS,
        tz,
        interval,
        filename,
    },
});

const GET_API_DOCS_URL = 'download/GET_API_DOCS_URL';
const GET_API_DOCS_URL_SUCCESS = 'download/GET_API_DOCS_URL_SUCCESS';
const GET_API_DOCS_URL_FAILURE = 'download/GET_API_DOCS_URL_FAILURE';
const getAPIDocsUrl = () => ({
    type: GET_API_DOCS_URL,
});

export {
    DOWNLOAD_CSV_DATA,
    DOWNLOAD_CSV_DATA_SUCCESS,
    downloadCSVData,
    GET_API_DOCS_URL,
    GET_API_DOCS_URL_SUCCESS,
    GET_API_DOCS_URL_FAILURE,
    getAPIDocsUrl,
};
