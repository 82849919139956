import React from 'react';
import { connect } from 'react-redux';
import {
    PeakSchedulerForm,
} from '../components/edit-peak-event-form';
import {
    FuturePeakEvent as FuturePeakEventView,
} from '../components/future-peak-event';
import {
    updatePeakEvent,
    createPeakEvent,
    deletePeakEvent,
    duplicatePeakEvent,
    toggleEditingPeak,
    clearPeakErrorMessage,
} from '../../../models/peaks/actions';
import {
    toPeakId,
} from '../../../models/peaks/utils';

class FuturePeakEvent extends React.Component {
    constructor(props) {
        super(props);
        const {
            startTS,
            endTS,
            listeners,
            notify,
            peakId,
            vppId,
            toggleEditing,
            clearErrorMessage,
        } = props;
        this.state = {
            startTS,
            endTS,
            sendNotification: listeners?.notify?.includes('send-peak-notifications'),
            notificationMessage: notify?.message,
        };

        this.editingHandlers = {
            startTSChanged: (start) => {
                this.setState({
                    startTS: start,
                });
            },
            endTSChanged: (end) => {
                this.setState({
                    endTS: end,
                });
            },
            sendNotificationChanged: (send) => {
                this.setState({
                    sendNotification: send,
                });
            },
            notificationMessageChanged: (message) => {
                this.setState({
                    notificationMessage: message,
                });
            },
            editPeakEvent: () => {
                toggleEditing({
                    vppId,
                    peakId,
                });
            },
            done: () => {
                const {
                    updateEvent,
                    createEvent,
                } = this.props;
                if (peakId) {
                    updateEvent({
                        vppId,
                        peakId,
                        event: this.getEventParams(),
                    });
                } else {
                    createEvent(vppId, this.getEventParams());
                }
                this.onDone();
            },
            deletePeakEvent: () => {
                const {
                    startTS: start,
                    deleteEvent,
                } = this.props;
                deleteEvent(vppId, start);
            },
            duplicatePeakEvent: () => {
                const { duplicateEvent } = this.props;
                duplicateEvent(peakId);
                this.onDone();
            },
            cancel: () => {
                const { startTS: start } = this.state;
                const id = peakId || start;
                toggleEditing({
                    vppId,
                    peakId: id,
                });
                clearErrorMessage(id);
                this.onCancel();
            },
        };

        this.getPeakId = () => peakId;
    }

    onDone() {
        const {
            onDone,
        } = this.props;
        if (onDone) onDone();
    }

    onCancel() {
        const { onCancel } = this.props;
        if (onCancel) onCancel();
    }

    onError() {
        const { onError } = this.props;
        if (onError) onError();
    }

    getEventParams() {
        const {
            startTS,
            endTS,
            sendNotification,
            notificationMessage,
        } = this.state;
        const {
            startTS: peakId,
        } = this.props;

        const params = {
            startTS,
            endTS,
            peakId,
        };

        if (sendNotification) {
            params.listeners = {
                notify: ['send-peak-notifications'],
            };
        }

        if (notificationMessage != null && notificationMessage.length > 0) {
            params.notify = {
                message: notificationMessage,
            };
        }

        return params;
    }

    editing() {
        const {
            editing,
            peakId,
        } = this.props;
        return !peakId || editing;
    }

    renderEditForm() {
        const {
            startTS,
            endTS,
            sendNotification,
            notificationMessage,
        } = this.state;
        const {
            error,
            saving,
        } = this.props;
        return (
            <PeakSchedulerForm
                peakId={this.getPeakId()}
                startTS={startTS}
                endTS={endTS}
                sendNotification={sendNotification}
                notificationMessage={notificationMessage}
                saveState={saving}
                error={error ? { message: error } : null}
                onDone={() => this.editingHandlers.done()}
                onCancel={() => this.editingHandlers.cancel()}
                onStartTSChanged={start => this.editingHandlers.startTSChanged(start)}
                onEndTSChanged={end => this.editingHandlers.endTSChanged(end)}
                onSendNotificationChanged={
                    send => this.editingHandlers.sendNotificationChanged(send)
                }
                onNotificationMessageChanged={
                    message => this.editingHandlers.notificationMessageChanged(message)
                }
            />
        );
    }

    renderEvent() {
        const {
            startTS,
            endTS,
            state,
            listeners,
            notify,
            error,
            saving,
        } = this.props;
        return (
            <FuturePeakEventView
                state={state}
                startTS={startTS}
                endTS={endTS}
                listeners={listeners}
                notify={notify}
                error={error ? { message: error } : null}
                saveState={saving}
                handleDelete={() => this.editingHandlers.deletePeakEvent()}
                handleDuplicate={() => this.editingHandlers.duplicatePeakEvent()}
                handleEdit={() => this.editingHandlers.editPeakEvent()}
            />
        );
    }

    render() {
        if (this.editing()) return this.renderEditForm();
        return this.renderEvent();
    }
}

const mapStateToProps = (state, props) => {
    const peakId = toPeakId(props);
    const {
        activeVpp,
    } = state.vpps;
    const peaks = state.peaks[activeVpp] || {};
    return {
        ...(peaks.peaks || {})[peakId],
        ...props,
    };
};

const mapDispatchToProps = dispatch => ({
    updateEvent: ({
        vppId,
        peakId,
        event,
    }) => dispatch(updatePeakEvent({
        vppId,
        peakId,
        event,
    })),
    createEvent: (vppId, event) => dispatch(createPeakEvent(vppId, event)),
    deleteEvent: (vppId, peakId) => dispatch(deletePeakEvent(vppId, peakId)),
    duplicateEvent: peakId => dispatch(duplicatePeakEvent(peakId)),
    toggleEditing: ({
        vppId,
        peakId,
    }) => dispatch(toggleEditingPeak({
        vppId,
        peakId,
    })),
    clearErrorMessage: peakId => dispatch(clearPeakErrorMessage(peakId)),
});

const FuturePeakEventContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FuturePeakEvent);

export {
    FuturePeakEventContainer,
};
