/* globals window */

/**
 * we used to do push state routing and switched to hash based routing,
 * which meant that /operator could become /operator/#/devices which is
 * really confusing. This redirects /operator (anything not /) to / so
 * this cannot happen anymore
 */

if (window.location.pathname !== '/') {
    window.location.href = '/';
}
