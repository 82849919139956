import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Popup() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>

            <Button
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                }}
                onClick={handleShow}
                variant="primary"
            >
            Need Help?
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Not seeing your device or need help?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
          Please go to the Nymbus App and make sure your device is
          connected or contact Nyle support.
                </Modal.Body>
                <Modal.Footer>
                    <a href="https://www.nyle.com/water-heating-systems/contact/" target="_blank" rel="noopener noreferrer" className="btn btn-primary"> Nyle Support</a>
                    <Button variant="danger" onClick={handleClose}> X </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default function ModalDialog() {
    return (
        <Popup />
    );
}
