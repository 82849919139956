import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import DropdownList from 'react-widgets/lib/DropdownList';
import {
    Button,
} from './form';

export const ActionRow = ({
    onMount,
    handler,
    title,
    value,
    buttonTitle,
    buttonStyleClass,
    disabled,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (onMount != null) {
                setIsLoading(true);
                await onMount();
                setIsLoading(false);
            }
        })();
    }, []);

    const handleClick = async () => {
        // TODO (Luke Fredrickson): https://app.zenhub.com/workspaces/cloud-task-board-586ddd109c88915657853f8c/issues/packetizedenergy/device-mgr-v2/424
        setIsLoading(true);
        await handler();
        setIsLoading(false);
    };

    return (
        <tr>
            <td>{title}</td>
            <td>{isLoading ? <Spinner color="dark" size="sm" /> : value}</td>
            <td>
                <Button
                    title={buttonTitle}
                    className={`btn ${buttonStyleClass} btn-sm`}
                    onClick={handleClick}
                    disabled={isLoading || disabled}
                />
            </td>
        </tr>
    );
};

/*
    Displays a row with an editable value. Two states: editing/not editing.
    If not editing, show {displayValue} and a button to edit.
    If editing, show a text input initially populated with {value},
    and buttons to submit/cancel the edit.
*/
export const InputRow = ({
    title,
    value,
    displayValue,
    buttonStyleClass,
    handler,
    inputProps,
}) => {
    const [newValue, setNewValue] = useState(value);
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);

    // TODO (Luke Fredrickson): https://app.zenhub.com/workspaces/cloud-task-board-586ddd109c88915657853f8c/issues/packetizedenergy/device-mgr-v2/424
    const handleOnClick = async () => {
        setEditing(false);
        setLoading(true);
        await handler(newValue);
        setLoading(false);
    };

    return (
        <tr>
            <td>{title}</td>
            <td>
                {editing ? (
                    <input
                        className='form-control'
                        type='text'
                        value={newValue}
                        onChange={e => setNewValue(e.target.value)}
                        {...inputProps}
                    />
                ) : (
                    displayValue ?? newValue
                )}
            </td>
            <td>
                {editing ? (
                    <div className='btn-group'>
                        <Button
                            title='Submit'
                            className={`btn ${buttonStyleClass} btn-sm`}
                            onClick={() => handleOnClick()}
                            disabled={loading}
                        />
                        <Button
                            title='Cancel'
                            className={`btn ${buttonStyleClass} btn-sm`}
                            onClick={() => {
                                setEditing(false);
                                setNewValue(value);
                            }}
                            disabled={loading}
                        />
                    </div>
                ) : (
                    <Button
                        title='Edit'
                        className={`btn ${buttonStyleClass} btn-sm`}
                        onClick={() => {
                            setNewValue(value);
                            setEditing(true);
                        }}
                        disabled={loading}
                    />
                )}
            </td>
        </tr>
    );
};

export const ParentRow = ({ device, onSubmit }) => {
    if (device.parent) {
        return (
            <InputRow
                title="Parent"
                displayValue={(<Link to={`/devices/${device.parent}`}>{device.parent}</Link>)}
                value={device.parent}
                buttonStyleClass='btn-outline-dark btn-sm'
                handler={newParent => onSubmit(newParent)}
            />
        );
    }
    return null;
};

export const ChildRow = ({ device }) => {
    if (device.children) {
        return (
            <tr>
                <td>Children</td>
                <td>
                    <ul style={{ paddingLeft: 0 }}>
                        {device.children.map(child => (
                            <li key={child}>
                                <Link to={`/devices/${child}`}>{child}</Link>
                            </li>
                        ))}
                    </ul>
                </td>
                <td />
            </tr>
        );
    }
    return null;
};

export const DropdownRow = ({
    label,
    value,
    handler,
    disabled = false,
    ...rest
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newValue, setNewValue] = useState(value);
    const textColor = disabled ? 'text-muted' : 'text-dark';

    return (
        <tr>
            <td className={textColor}>{label}</td>
            <td>
                {isEditing
                    ? (
                        <div>
                            <DropdownList
                                defaultValue={value}
                                onSelect={setNewValue}
                                disabled={disabled}
                                {...rest}
                            />
                        </div>
                    )
                    : (
                        <span className={textColor}>{value}</span>
                    )
                }
            </td>
            <td>
                {isEditing
                    ? (
                        <div className="btn-group">
                            <Button
                                title="Save"
                                onClick={async () => {
                                    await handler(newValue);
                                    setIsEditing(false);
                                }}
                                className="btn-outline-dark btn-sm"
                            />
                            <Button
                                title="Cancel"
                                onClick={() => {
                                    setIsEditing(false);
                                    setNewValue(value);
                                }}
                                className="btn-outline-dark btn-sm"
                            />
                        </div>
                    )
                    : (
                        <Button
                            title="Edit"
                            disabled={disabled}
                            onClick={() => {
                                setIsEditing(true);
                            }}
                            className="btn-outline-dark btn-sm"
                        />
                    )
                }
            </td>
        </tr>
    );
};
