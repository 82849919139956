import { Component } from 'react';
import { connect } from 'react-redux';
import {
    history,
} from '../utils/history';

export class RedirectIfHasVPPComponent extends Component {
    componentDidMount() {
        const { vpp } = this.props;
        if (typeof vpp !== 'symbol') {
            history.push('/operator');
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state) => {
    const {
        activeVpp: vpp,
    } = state.vpps;

    return {
        vpp,
    };
};

const mapDispatchToProps = () => ({});

const RedirectIfHasVPP = connect(
    mapStateToProps,
    mapDispatchToProps,
)(RedirectIfHasVPPComponent);

export {
    RedirectIfHasVPP,
};
