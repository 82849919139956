import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Router,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import { OperatorPage } from './operator/operator-page';
import { NyleLayout } from './NyleLayout';
import { DownloadPage } from './download/download-page';
import {
    PeakScheduler,
} from './peak-scheduler';
import { VPPRequiredPage } from './user/VPPRequiredPage';
import {
    DevicesPage,
} from './devices/devices-page';
import ViewDeviceContainer from './devices/ViewDeviceContainer';
import {
    history,
} from './utils/history';
import {
    getActiveVpp,
} from './models/vpps/selectors';
import 'bootstrap/dist/css/bootstrap.css';
import './NyleApp.css';
import {
    ASYNC_STATUS,
    whenReady,
} from './utils/async';
import {
    AuthenticateUser,
} from './user/authenticate-user';
import {
    APIDocsPage,
} from './download/api-docs';
import {
    Nav,
    NavigationLink,
} from './containers/side-navigation';
import { NO_VPP } from './models/vpps/reducers';

import ModalDialog from './components/modal';

const VPPNotFound = () => (<h1>Virtual Battery Not Found</h1>);

const withVpp = whenReady((state) => {
    if (state.vpps.activeVpp === NO_VPP) return ASYNC_STATUS.SUCCESS;
    const activeVpp = state.vpps.vpps[state.vpps.activeVpp];
    if (!!activeVpp && ASYNC_STATUS.isDone(activeVpp.configStatus)) {
        return activeVpp.configStatus;
    }
    return ASYNC_STATUS.WAITING;
}, VPPNotFound);

const NyleRouter = withVpp(({ hasRoles }) => (
    <div
        style={{
            flex: '1 1 auto',
            width: '100%',
        }}
    >
        <Router history={history}>
            <Switch>
                {/* These routes are all under their own conditional because the <Switch />
                    component will only work with immediate children. If they are wrapped
                    in a fragment under one conditional, the routing will break */}
                {hasRoles && (
                    <Route
                        path="/operator"
                        component={OperatorPage}
                        name="operator"
                    />
                )}
                {hasRoles && (
                    <Route
                        path="/download"
                        component={DownloadPage}
                    />
                )}
                {hasRoles && (
                    <Route
                        path="/peaks"
                        component={PeakScheduler}
                    />
                )}
                {hasRoles && (
                    <Route
                        path="/api"
                        component={APIDocsPage}
                    />
                )}
                <Route
                    path="/no-vpp"
                    component={VPPRequiredPage}
                />
                <Route
                    path="/devices/:deviceId"
                    component={ViewDeviceContainer}
                />
                <Route
                    path="/devices"
                    component={DevicesPage}
                />
                <Redirect from="*" to={hasRoles ? '/operator' : '/devices'} />
            </Switch>
        </Router>
        <ModalDialog />
    </div>
));

class NyleAppView extends Component {
    getChildContext() {
        return {
            nyleApp: {},
        };
    }

    render() {
        const {
            isFasterThanRealtimeDemo,
            resizeFor4K,
            showDevicesTab,
            hasRoles,
        } = this.props;
        return (
            <NyleLayout
                appId="nyle-vb"
                isFasterThanRealtimeDemo={isFasterThanRealtimeDemo}
                resizeFor4K={resizeFor4K}
                navMenu={onNavigate => (
                    <NavMenu
                        onNavigate={onNavigate}
                        showDevicesTab={showDevicesTab}
                        hasRoles={hasRoles}
                    />
                )}
                currentApp="operator"
            >
                <NyleRouter hasRoles={hasRoles} />
            </NyleLayout>
        );
    }

    static childContextTypes = {
        nyleApp: PropTypes.object,
    }
}

const NavMenu = ({
    onNavigate,
    showDevicesTab,
    hasRoles,
}) => (
    <Nav navbar vertical>
        {hasRoles && (
            <>
                <NavigationLink
                    to="/operator"
                    title="Dashboard"
                    iconHint="bar-chart"
                    onNavigate={onNavigate}
                />
                <NavigationLink
                    to="/peaks"
                    title="Peaks"
                    imageName="peaks"
                    onNavigate={onNavigate}
                />
                <NavigationLink
                    to="/api"
                    title="API"
                    iconHint="cloud"
                    onNavigate={onNavigate}
                />
                <NavigationLink
                    to="/download"
                    title="Download"
                    iconHint="download"
                    onNavigate={onNavigate}
                />
            </>
        )}
        {(showDevicesTab || !hasRoles) && (
            <NavigationLink
                to="/devices"
                title="Devices"
                iconHint="cubes"
                onNavigate={onNavigate}
            />
        )}
    </Nav>
);

const mapStateToProps = (state) => {
    const {
        isFasterThanRealtimeDemo,
        resizeFor4K,
        showDevicesTab,
    } = getActiveVpp(state);

    const {
        user: {
            roles,
        },
    } = state;
    return {
        isFasterThanRealtimeDemo,
        resizeFor4K,
        showDevicesTab,
        hasRoles: roles.length > 0,
    };
};

const NyleApp = connect(
    mapStateToProps,
)(NyleAppView);

const AuthenticatedNyleApp = () => (
    <AuthenticateUser>
        <NyleApp />
    </AuthenticateUser>
);

export {
    AuthenticatedNyleApp,
};
