/* globals fetch */
const {
    resolve: resolveUrl,
} = require('url');

const {
    REACT_APP_API_BASE_URL,
} = process.env;

class APIError extends Error {}

export class VPPApiClient {
    static inject() {
        return [REACT_APP_API_BASE_URL, 'Authenticator'];
    }

    constructor(baseUrl, authenticator) {
        const normalizedBaseUrl = baseUrl[baseUrl.length - 1] !== '/' ? `${baseUrl}/` : baseUrl;

        /* eslint-disable no-param-reassign */
        this.http = async ({
            method,
            path: argPath,
            body,
            headers,
        }) => {
            const normalizedPath = argPath[0] === '/' ? `.${argPath}` : argPath;
            const url = resolveUrl(normalizedBaseUrl, normalizedPath);
            const bodyString = body ? JSON.stringify(body) : undefined;

            if (!headers) {
                headers = {};
            }
            if (!headers.accept) {
                headers.accept = 'application/json';
            }

            if (bodyString) {
                headers['Content-Type'] = 'application/json';
            }

            try {
                headers.Authorization = `Bearer ${authenticator.getAccessToken()}`;
            } catch {
                throw new APIError('We were unable to renew your authentication session. Usually, we can do this automatically. It may be the case that you have Ad Blocking software enabled for your browser. If so, please whitelist iframes served from the domain https://dev-557huc-d.us.auth0.com.');
            }

            const res = await fetch(url, {
                method,
                headers,
                body: bodyString,
                mode: 'cors',
            });

            let resBody = await res.text();
            try {
                resBody = JSON.parse(resBody);
            } catch {} // eslint-disable-line no-empty
            return {
                status: res.status,
                headers: res.headers,
                body: resBody,
            };
        };
        /* eslint-enable */

        this.get = (path, headers) => this.http({
            method: 'GET',
            path,
            headers,
        });

        this.patch = (path, body) => this.http({
            method: 'PATCH',
            path,
            body,
        });

        this.post = (path, body) => this.http({
            method: 'POST',
            path,
            body,
        });

        this.delete = path => this.http({
            method: 'DELETE',
            path,
        });
    }
}
