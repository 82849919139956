import React from 'react';
import { Icon } from '../../lib/font-awesome';
import { dateFormatter } from '../utils';

export class PeakEventViewWrapper extends React.Component {
    renderError() {
        return (
            <div className="col-12 alert alert-danger" role="alert">
                <Icon hint="warning" />
                &nbsp;
                { this.getErrorMessage() }
            </div>
        );
    }

    getErrorMessage() {
        const {
            error,
        } = this.props;
        if (!error) return null;
        return parseError(error);
    }

    render() {
        const {
            columns = [],
            actions,
            error = null,
            className = '',
            colWeight = 10,
        } = this.props;
        /* eslint-disable react/no-array-index-key */
        return (
            <div className={`row peak-scheduler__peak ${className}`}>
                <div className={`col-${colWeight} row`}>
                    {columns.map((c, i) => <div key={i} className='col'>{c}</div>)}
                </div>
                <div className="col col-auto ml-auto">{actions}</div>
                {error ? <div className="col-12 peak-scheduler__peak__error">{this.renderError()}</div> : null}
            </div>
        );
        /* eslint-enable */
    }
}

function parseError(error) {
    switch (error.name) {
        case 'ConflictingEvents': return parseConflictingEventsError(error);
        default: return error.message || 'Something went wrong. Please try again.';
    }
}

function parseConflictingEventsError({ conflictingEvents }) {
    const defaultMessage = 'This event would conflict with another already scheduled event. Please try again.';
    if (conflictingEvents.length === 0) return defaultMessage;
    const {
        startTS,
        endTS,
        prePositionTS,
        endRecoveryTS,
    } = conflictingEvents[0];
    try {
        return `This event would conflict with an event already scheduled from ${dateFormatter(startTS)} to ${dateFormatter(endTS)}, which starts pre-positioning at ${dateFormatter(prePositionTS)} and recovers until ${dateFormatter(endRecoveryTS)}.`;
    } catch (err) {
        return defaultMessage;
    }
}
