import React from 'react';
import {
    css,
} from '@emotion/core';
import DropdownList from 'react-widgets/lib/DropdownList';

class NewAlertForm extends React.Component {
    constructor(props) {
        super(props);
        const {
            alertType = '',
            message = '',
            displayToUser = false,
            displayName = '',
        } = props;

        this.state = {
            alertTypes: [],
            alertType,
            message,
            displayToUser,
            displayName,
        };
    }

    componentDidMount() {
        const {
            alertTypes,
            editingExistingAlert,
        } = this.props;
        if (!alertTypes || editingExistingAlert) return;
        this.setState({
            alertType: alertTypes[0],
            alertTypes: [...alertTypes],
        });
    }

    handleCreateAlertType = (alertType) => {
        const {
            alertTypes,
        } = this.state;
        this.setState({
            alertType,
            alertTypes: [alertType, ...alertTypes],
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        const {
            onSave,
            toggleEditing,
        } = this.props;
        if (onSave) {
            onSave(this.state);
            if (toggleEditing) toggleEditing();
        }
    }

    cancelEditing = (e) => {
        e.preventDefault();
        const {
            toggleEditing,
        } = this.props;
        if (toggleEditing) toggleEditing();
    }

    render() {
        const {
            alertTypes,
            alertType,
            message,
            displayToUser,
            displayName,
        } = this.state;
        const {
            key,
            editingExistingAlert,
            saving,
        } = this.props;
        return (
            <form onSubmit={this.onSubmit} css={styles.form} key={key} disabled={saving}>
                <div className="form-group">
                    {editingExistingAlert
                        ? (
                            <div className="row no-gutters justify-content-between">
                                <h4>{alertType}</h4>
                                <button
                                    className="btn btn-outline-dark"
                                    onClick={this.cancelEditing}
                                    style={{ marginLeft: '1em' }}
                                >
                                    Cancel
                                </button>
                            </div>
                        )
                        : (
                            <div>
                                <label htmlFor="alertType">Alert Type</label>
                                <DropdownList
                                    id="alertType"
                                    filter
                                    data={alertTypes}
                                    value={alertType}
                                    onChange={type => this.setState({
                                        alertType: type,
                                    })}
                                    allowCreate="onFilter"
                                    onCreate={this.handleCreateAlertType}
                                />
                            </div>
                        )
                    }
                </div>
                <div className="form-group">
                    <label htmlFor="alertMessage">Description:</label>
                    <input
                        id="alertMessage"
                        className="form-control"
                        type="text"
                        value={message}
                        onChange={(e) => {
                            e.preventDefault();
                            this.setState({
                                message: e.target.value,
                            });
                        }}
                        placeholder="Description"
                    />
                </div>
                <div className="form-group" css={styles.checkboxRow}>
                    <label htmlFor="displayToUser">
                        <input
                            id="displayToUser"
                            type="checkbox"
                            checked={displayToUser}
                            onChange={() => this.setState({
                                displayToUser: !displayToUser,
                            })}
                        />
                        &nbsp;Display to device owner
                    </label>
                </div>
                {displayToUser && (
                    <div className="form-group">
                        <label htmlFor="displayName">Display name:</label>
                        <input
                            id="displayName"
                            className="form-control"
                            type="text"
                            value={displayName}
                            onChange={(e) => {
                                e.preventDefault();
                                this.setState({
                                    displayName: e.target.value,
                                });
                            }}
                            placeholder="Display name"
                        />
                    </div>
                )}
                <input
                    type='submit'
                    className="btn btn-dark"
                    value={editingExistingAlert ? 'Save changes' : 'Create Alert'}
                />
            </form>
        );
    }
}

const styles = {
    form: css`
        margin-bottom: 1em;
        background-color: #e9ecef;
        padding: 1em;
        border: 1px solid #ced4da;
        border-radius: 5px;
    `,
    checkboxRow: css`
        margin-bottom: 0.5em;
    `,
};

export {
    NewAlertForm,
};
