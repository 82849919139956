import {
    VPPApiClient,
} from '../../VPPApiClient';
import {
    NyleError,
} from '../../utils/errors';

export class PeakSchedulerDAO {
    static inject() {
        return [VPPApiClient];
    }

    constructor(http) {
        const createEvent = async ({
            vppId,
            ...event
        }) => {
            await handleHTTPError(http.post(`./vpps/${vppId}/peaks`, event));
        };

        const updateEvent = async ({
            vppId,
            peakId,
        }, event) => {
            await handleHTTPError(http.patch(`./vpps/${vppId}/peaks/${peakId}`, event));
        };

        const deleteEvent = async ({
            vppId,
            peakId,
        }) => {
            await handleHTTPError(http.delete(`./vpps/${vppId}/peaks/${peakId}`));
        };

        const getEvent = ({
            vppId,
            peakId,
        }) => http.get(`./vpps/${vppId}/peaks/${peakId}`).then(res => res.body);

        const getEvents = async ({
            vppId,
            lowerTS,
            upperTS,
            completed = true,
        }) => {
            const url = `./vpps/${vppId}/peaks${completed ? '/done' : ''}?lowerTS=${lowerTS}&upperTS=${upperTS}`;
            const peaks = await handleHTTPError(http.get(url));
            return peaks;
        };

        async function handleHTTPError(awaitingResponse) {
            const {
                status,
                body,
            } = await awaitingResponse;
            if (status > 299) {
                throw new PeakSchedulingError(body);
            }
            return body;
        }

        Object.defineProperties(this, {
            createEvent: {
                value: createEvent,
                writable: false,
            },
            updateEvent: {
                value: updateEvent,
                writable: false,
            },
            deleteEvent: {
                value: deleteEvent,
                writable: false,
            },
            getEvent: {
                value: getEvent,
                writable: false,
            },
            getEvents: {
                value: getEvents,
                writable: false,
            },
        });
    }
}

class PeakSchedulingError extends NyleError {
    constructor(responseBody) {
        super(responseBody);
        this.responseBody = responseBody;
    }
}
