/* globals window */
import React from 'react';
import {
    connect,
} from 'react-redux';

const NotReady = () => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: window.innerHeight - 50,
        }}
    >
        <img src="/assets/img/Spinner.gif" alt="loading" height="50" width="50" />
    </div>
);

function whenReady(isReady, Error = null) {
    const mapStateToProps = state => ({
        state: isReady(state),
    });
    return (Ready) => {
        const Wrapper = ({
            state,
            ...props
        }) => {
            if (state === ASYNC_STATUS.SUCCESS) {
                return <Ready {...props} />;
            }
            if (state === ASYNC_STATUS.FAILURE) {
                return Error ? <Error /> : <Ready />;
            }
            return <NotReady />;
        };
        return connect(mapStateToProps)(Wrapper);
    };
}

const ASYNC_STATUS = Object.defineProperties({}, {
    NONE: {
        value: 'ASYNC_NONE',
        writable: false,
    },
    WAITING: {
        value: 'ASYNC_WAITING',
        writable: false,
    },
    SUCCESS: {
        value: 'ASYNC_SUCCESS',
        writable: false,
    },
    FAILURE: {
        value: 'ASYNC_FAILURE',
        writable: false,
    },
    isDone: {
        value: status => (
            (status === ASYNC_STATUS.SUCCESS)
            || (status === ASYNC_STATUS.FAILURE)
        ),
        writable: false,
    },
});

const EMPTY_MESSAGE = '';

/*
 * AsyncValue transforms a plain old javascript value
 * into an object that contains information about that
 * object's async status. The primary use case is storing
 * both the value and the value's async status in redux.
 *
 * For example, instead of storing a device's virtual battery
 * in redux as "vpp": <value>, we can be represent it as
 *
 * ```
 * {
 *     "vpp": {
 *         "value": <value>,
 *         "status": <async-status>,
 *         "message": <optional-error-message>
 *     },
 *     ...
 * }
 * ```
 *
 * We can use `AsyncStatus`'s methods to ease creation and
 * parsing such values. For the above vpp value we might do
 *
 * ```
 * {
 *     "vpp": AsyncStatus.wrap(<value>, <async-status>, <optional-error-message>),
 *     ...
 * }
 * ```
 *
 * We can also use `AsyncStatus.unwrap` to return the value to a more useable form.
 * For example, in some component's `mapStateToProps` we might want to use
 * the value directly (e.g., `{"vpp": <value>}`). We could do:
 *
 * ```
 * return {
 *     "vpp": AsyncStatus.unwrap(state, 'vpp').vpp
 * }
 * ```
 */
class AsyncValue {
    static isWaiting(value) {
        return value.status === ASYNC_STATUS.WAITING;
    }

    static isDone(value) {
        return (
            value.status === ASYNC_STATUS.SUCCESS
            || value.status === ASYNC_STATUS.FAILURE
        );
    }

    static waiting(value) {
        return AsyncValue.wrap(value, ASYNC_STATUS.WAITING);
    }

    static success(value) {
        return AsyncValue.wrap(value, ASYNC_STATUS.SUCCESS);
    }

    static failure(value, message = 'Error') {
        return AsyncValue.wrap(value, ASYNC_STATUS.FAILURE, message);
    }

    static wrap(value, status = ASYNC_STATUS.NONE, message = EMPTY_MESSAGE) {
        return {
            value,
            status,
            message,
        };
    }

    static unwrap(state, key) {
        if (state[key] == null || typeof state[key] !== 'object') {
            return {
                [key]: state[key],
                status: ASYNC_STATUS.NONE,
                message: EMPTY_MESSAGE,
            };
        }
        const {
            value,
            status,
            message,
        } = state[key] || {};
        return {
            [key]: value,
            status,
            message,
        };
    }
}

export {
    ASYNC_STATUS,
    AsyncValue,
    whenReady,
    NotReady,
};
