import {
    takeEvery,
    call,
    put,
    select,
} from 'redux-saga/effects';
import {
    DOWNLOAD_CSV_DATA,
    DOWNLOAD_CSV_DATA_SUCCESS,
    GET_API_DOCS_URL,
    GET_API_DOCS_URL_SUCCESS,
    GET_API_DOCS_URL_FAILURE,
} from './actions';
import {
    CREATE_ALERT,
} from '../alerts/actions';
import {
    VPPPowerDAO,
} from '../vpps/vpp-power-dao';
import {
    DocsDAO,
} from './docs-dao';
import { container } from '../../dependency-injection';

function* handler() {
    yield takeEvery(DOWNLOAD_CSV_DATA, handleDownloadData);
    yield takeEvery(GET_API_DOCS_URL, getAPIDocsUrl);
}

const getCsvId = state => state.download.id;

function* handleDownloadData(action) {
    const {
        vppId,
        startTS,
        endTS,
        tz,
        interval,
        filename,
        type,
    } = action.payload;
    const powerDAO = container.get(VPPPowerDAO);
    yield call(createDownloadAlert, {
        message: 'Starting Download...',
        level: 'info',
        action: 'download-started',
    });
    try {
        const dataFetch = type === 'power' ? 'getPowerHistoryAsCSV' : 'getEnergyHistoryAsCSV';
        const data = yield call(powerDAO[dataFetch], {
            vppId,
            startTS,
            endTS,
            tz,
            interval,
        });
        const currentCsvId = yield select(getCsvId);
        yield put({
            type: DOWNLOAD_CSV_DATA_SUCCESS,
            payload: {
                data,
                filename,
                id: currentCsvId + 1,
            },
        });
    } catch {
        yield call(createDownloadAlert, {
            message: 'Download Failed. Too much data requested',
            level: 'danger',
        });
    }
}

function* createDownloadAlert({
    message,
    level,
    action,
}) {
    yield put({
        type: CREATE_ALERT,
        payload: {
            type: 'download',
            message,
            level,
            action,
        },
    });
}

function* getAPIDocsUrl() {
    const docsDao = container.get(DocsDAO);
    try {
        const url = yield call(docsDao.getAPIDocs);
        yield put({
            type: GET_API_DOCS_URL_SUCCESS,
            payload: {
                url,
            },
        });
    } catch (err) {
        yield put({
            type: GET_API_DOCS_URL_FAILURE,
        });
    }
}

export {
    handler,
};
