import {
    VPPApiClient,
} from '../../VPPApiClient';
import {
    NyleError,
} from '../../utils/errors';

export class DocsDAO {
    static inject() {
        return [VPPApiClient];
    }

    constructor(http) {
        const getAPIDocs = async () => {
            const {
                body,
                status,
            } = await http.get('/docs');
            if (status > 399) throw new NyleError('Failed to get API docs download link');
            return body;
        };

        Object.defineProperties(this, {
            getAPIDocs: {
                value: getAPIDocs,
                writable: false,
            },
        });
    }
}
