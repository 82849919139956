import React from 'react';
import {
    FuturePeakEventList,
} from './components/future-peak-event-list';

export const ScheduledPeaks = ({
    vppId,
    lowerTS,
    upperTS,
    peaks,
    newPeak,
    refresh,
    nextPage,
    previousPage,
    toggleNewPeakEventForm,
}) => (
    <div className="peak-scheduler--upcoming-peaks">
        <div className="row">
            <div className="col-auto">
                <h2>Upcoming Peaks</h2>
            </div>
            <div className="col-auto">
                <button className="btn btn-primary peak-scheduler__add-peak" onClick={() => toggleNewPeakEventForm(false)} disabled={!!newPeak}>Add peak</button>
            </div>
        </div>
        <FuturePeakEventList
            vppId={vppId}
            lowerTS={lowerTS}
            upperTS={upperTS}
            events={peaks}
            refresh={refresh}
            newPeak={newPeak}
            nextPage={nextPage}
            previousPage={previousPage}
            hideNewPeakForm={toggleNewPeakEventForm}
        />
    </div>
);
