const FETCH_DEVICE_ALERTS = 'device-alerts/FETCH_DEVICE_ALERTS';
const FETCH_DEVICE_ALERTS_SUCCESS = 'device-alerts/FETCH_DEVICE_ALERTS_SUCCESS';
const FETCH_DEVICE_ALERTS_FAILURE = 'device-alerts/FETCH_DEVICE_ALERTS_FAILURE';
const fetchDeviceAlerts = ({
    deviceId,
    key,
    value,
}) => ({
    type: FETCH_DEVICE_ALERTS,
    payload: {
        deviceId,
        key,
        value,
    },
});

const SET_DEVICE_ALERT_FILTER = 'device-alerts/SET_DEVICE_ALERT_FILTER';
const setDeviceAlertFilter = (key, value) => ({
    type: SET_DEVICE_ALERT_FILTER,
    payload: {
        key,
        value,
    },
});

const RESOLVE_DEVICE_ALERT = 'device-alerts/RESOLVE_DEVICE_ALERT';
const RESOLVE_DEVICE_ALERT_FAILURE = 'device-alerts/RESOLVE_DEVICE_ALERT_FAILURE';
const resolveDeviceAlert = ({
    deviceId,
    alertType,
}) => ({
    type: RESOLVE_DEVICE_ALERT,
    payload: {
        deviceId,
        alertType,
    },
});

const UNRESOLVE_DEVICE_ALERT = 'device-alerts/UNRESOLVE_DEVICE_ALERT';
const UNRESOLVE_DEVICE_ALERT_FAILURE = 'device-alerts/UNRESOLVE_DEVICE_ALERT_FAILURE';
const unresolveDeviceAlert = ({
    deviceId,
    alertType,
}) => ({
    type: UNRESOLVE_DEVICE_ALERT,
    payload: {
        deviceId,
        alertType,
    },
});

const ACKNOWLEDGE_DEVICE_ALERT = 'device-alerts/ACKNOWLEDGE_DEVICE_ALERT';
const ACKNOWLEDGE_DEVICE_ALERT_FAILURE = 'device-alerts/ACKNOWLEDGE_DEVICE_ALERT_FAILURE';
const acknowledgeDeviceAlert = ({
    deviceId,
    alertType,
}) => ({
    type: ACKNOWLEDGE_DEVICE_ALERT,
    payload: {
        deviceId,
        alertType,
    },
});

const CREATE_DEVICE_ALERT = 'device-alerts/CREATE_DEVICE_ALERT';
const CREATE_DEVICE_ALERT_SUCCESS = 'device-alerts/CREATE_DEVICE_ALERT_SUCCESS';
const CREATE_DEVICE_ALERT_FAILURE = 'device-alerts/CREATE_DEVICE_ALERT_FAILURE';
const createDeviceAlert = ({
    deviceId,
    alertType,
    message,
    displayToUser,
    displayName,
}) => ({
    type: CREATE_DEVICE_ALERT,
    payload: {
        deviceId,
        alertType,
        message,
        displayToUser,
        displayName,
    },
});

const UPDATE_DEVICE_ALERT = 'device-alerts/UPDATE_DEVICE_ALERT';
const UPDATE_DEVICE_ALERT_SUCCESS = 'device-alerts/UPDATE_DEVICE_ALERT_SUCCESS';
const UPDATE_DEVICE_ALERT_FAILURE = 'device-alerts/UPDATE_DEVICE_ALERT_FAILURE';
const updateDeviceAlert = ({
    deviceId,
    alertType,
    message,
    displayToUser,
    displayName,
}) => ({
    type: UPDATE_DEVICE_ALERT,
    payload: {
        deviceId,
        alertType,
        message,
        displayToUser,
        displayName,
    },
});

const UPDATE_DEVICE_ALERTS_MESSAGE = 'device-alerts/UPDATE_DEVICE_ALERTS_MESSAGE';
const updateErrorMessage = message => ({
    type: UPDATE_DEVICE_ALERTS_MESSAGE,
    payload: {
        message,
    },
});

export {
    FETCH_DEVICE_ALERTS,
    FETCH_DEVICE_ALERTS_SUCCESS,
    FETCH_DEVICE_ALERTS_FAILURE,
    fetchDeviceAlerts,
    SET_DEVICE_ALERT_FILTER,
    setDeviceAlertFilter,
    RESOLVE_DEVICE_ALERT,
    RESOLVE_DEVICE_ALERT_FAILURE,
    resolveDeviceAlert,
    UNRESOLVE_DEVICE_ALERT,
    UNRESOLVE_DEVICE_ALERT_FAILURE,
    unresolveDeviceAlert,
    ACKNOWLEDGE_DEVICE_ALERT,
    ACKNOWLEDGE_DEVICE_ALERT_FAILURE,
    acknowledgeDeviceAlert,
    CREATE_DEVICE_ALERT,
    CREATE_DEVICE_ALERT_SUCCESS,
    CREATE_DEVICE_ALERT_FAILURE,
    createDeviceAlert,
    UPDATE_DEVICE_ALERT,
    UPDATE_DEVICE_ALERT_SUCCESS,
    UPDATE_DEVICE_ALERT_FAILURE,
    updateDeviceAlert,
    UPDATE_DEVICE_ALERTS_MESSAGE,
    updateErrorMessage,
};
