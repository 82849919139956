export function round(n, decimals = 2) {
    if (decimals < 0) throw new Error(`Cannot round ${n} to a negative number of decimal places`);
    const scalingFactor = 10 ** decimals;
    return Math.round(n * scalingFactor) / scalingFactor;
}

export function roundAsString(n, decimals = 2) {
    const res = String(round(Number(n), decimals)).split('.');
    if (decimals === 0) { return res[0]; }
    if (res.length < 2) { res.push(''); }
    while (res[1].length !== decimals) {
        res[1] = `${res[1]}0`;
    }
    return res.join('.');
}
