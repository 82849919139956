import React from 'react';
import {
    ENERGY_BUCKETS_MS,
} from '../models/vpps/utils';
import {
    colors,
} from '../styles/colors';
import {
    Chart,
} from './chart';

const EnergyChart = ({
    actualEnergySeries,
    actualEnergySeriesColor = colors.primaryBlue,
    baselinePowerSeries,
    baselinePowerSeriesColor = colors.orange,
    xDomain,
    yDomain,
    bucketSize,
    settingsHandler = () => {},
    adjustForLargeScreen = false,
    showBaseline,
    shadedRegions = [],
}) => {
    const bucketSizeMS = ENERGY_BUCKETS_MS[bucketSize];
    const data = [
        {
            type: 'bar',
            name: showBaseline ? 'Actual' : 'Energy Usage',
            marker: {
                color: actualEnergySeriesColor,
                line: {
                    color: 'white',
                    width: 1,
                },
            },
            width: new Array(actualEnergySeries.x.length).fill(bucketSizeMS),
            y: actualEnergySeries.y,
            x: actualEnergySeries.x,
            offset: 0, // aligns bar to start at its x timestamp (default is centered)
        },
    ];
    if (showBaseline) {
        data.push({
            type: 'line',
            name: 'Baseline',
            mode: 'lines',
            marker: {
                color: baselinePowerSeriesColor,
            },
            line: {
                width: 3,
            },
            ...baselinePowerSeries,
        });
    }

    const yAxisTitle = 'Energy (kWh)';

    const sharedProps = {
        xDomain,
        settingsHandler,
        adjustForLargeScreen,
        shadedRegions,
    };

    return (
        <Chart
            {...sharedProps}
            data={data}
            yDomain={yDomain}
            yAxisTitle={yAxisTitle}
        />
    );
};

export {
    EnergyChart,
};
