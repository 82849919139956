/* global window */
import {
    takeEvery,
    put,
    call,
    all,
} from 'redux-saga/effects';
import {
    history,
} from '../../utils/history';
import { container } from '../../dependency-injection';
import {
    HANDLE_NO_USER,
    FETCH_USER_SUCCESS,
    FETCH_USER,
    CHECK_AUTH_TOKEN,
    LOG_IN,
    LOG_OUT,
} from './actions';
import {
    NO_VPP,
} from '../vpps/reducers';
import {
    getActiveVppFromUser,
} from './utils';

function* handler() {
    yield all([
        takeEvery(FETCH_USER, handleAuthenticateUser),
        takeEvery(CHECK_AUTH_TOKEN, checkAuthToken),
        takeEvery(LOG_IN, handleLogin),
        takeEvery(LOG_OUT, handleLogout),
    ]);
}

function* handleLogout() {
    const authenticator = container.get('Authenticator');
    yield call(authenticator.logout);
    // force page load to evict memory, this SHOULD also redirect to login on its own
    // however, Firefox throws a fit if we don't push the login route first
    history.push('/login');
    window.location.reload();
}

function* handleLogin() {
    const authenticator = container.get('Authenticator');
    yield call(authenticator.login);
}

function* checkAuthToken() {
    const authenticator = container.get('Authenticator');
    const tokenIsFresh = yield call(authenticator.isTokenFresh);
    if (tokenIsFresh) {
        history.push('/operator');
    }
}

function* handleAuthenticateUser({ payload }) {
    const authenticator = container.get('Authenticator');
    try {
        const profile = yield call(authenticator.authenticate);
        if (!profile) {
            yield put({
                type: HANDLE_NO_USER,
            });
            return;
        }
        const vpp = getActiveVppFromUser(profile);
        yield put({
            type: FETCH_USER_SUCCESS,
            payload: {
                user: profile,
                vpp: vpp || NO_VPP,
            },
        });
        redirectIfNecessary(vpp, payload.redirectTo);
    } catch (e) {
        console.log(e);
        yield put({
            type: HANDLE_NO_USER,
        });
    }
}

const redirectIfNecessary = (vpp, redirectTo) => {
    const { pathname } = history.location;
    if (!vpp && pathname !== '/devices') {
        history.push('/devices');
        return;
    }

    if (redirectTo != null) {
        if (pathname !== redirectTo) {
            history.push(redirectTo);
        }
        return;
    }

    if (['/', '/login'].indexOf(pathname) > -1) {
        history.push('/operator');
    }
};

export {
    handler,
};
