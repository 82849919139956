import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import {
    Icon,
} from '../../lib/font-awesome';

const StatusCard = ({
    title = '',
    leftHeader = '',
    leftValue,
    rightHeader = '',
    rightValue,
    tooltipText,
    tooltipId,
}) => (
    <div className="metrics-card__card operator-widget">
        <div className="row">
            <div className="col">
                <h3 className="metrics-card__title">{title}</h3>
            </div>
            {tooltipText
                ? (
                    <div className="col-2">
                        <span id={`vb-value-tooltip-${tooltipId}`} className="metrics-card__tooltip">
                            <Icon hint="info-circle" className="metrics-card__tooltip-icon" />
                        </span>
                        <UncontrolledTooltip
                            target={`vb-value-tooltip-${tooltipId}`}
                            trigger="hover"
                            placement="right-start"
                            innerClassName="metrics-card__tooltip"
                        >
                            {tooltipText.map(text => <p key={`${tooltipId}-${text.slice(0, 8)}`}>{text}</p>)}
                        </UncontrolledTooltip>
                    </div>
                )
                : null
            }
        </div>
        <div className="row align-items-end metrics-card__content">
            <div className="col">
                <h4 className="metrics-card__header">{leftHeader}</h4>
                <p className="metrics-card__value">{leftValue}</p>
            </div>
            <div className="col">
                <h4 className="metrics-card__header">{rightHeader}</h4>
                <p className="metrics-card__value">{rightValue}</p>
            </div>
        </div>
    </div>
);

const ClickableIcon = ({
    onClick,
    hint,
    disabled = false,
    className,
}) => (
    <button
        onClick={disabled ? null : (event) => {
            event.preventDefault();
            onClick();
        }}
        onMouseDown={event => event.preventDefault()}
        disabled={disabled}
        className={`${disabled ? '' : 'vpp-state__icon-button'} ${className}`}
    >
        <Icon hint={hint} />
    </button>
);

export {
    StatusCard,
    ClickableIcon,
};
