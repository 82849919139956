import {
    takeEvery,
    all,
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import {
    delay,
} from 'redux-saga';
import {
    NyleError,
} from '../../utils/errors';

import {
    FETCH_VPPS,
    FETCH_VPPS_SUCCEEDED,
    FETCH_VPPS_FAILED,
    FETCH_DATA_FOR_VPPS,
    FETCH_DATA_FOR_VPPS_FAILED,
    FETCH_DATA_FOR_VPPS_SUCCEEDED,
    VPP_FETCH,
    VPP_FETCH_SUCCEEDED,
    VPP_FETCH_FAILED,
    VPP_METRICS_SHOW_WINDOW,
    VPP_METRICS_FETCH_SUCCEEDED,
    VPP_METRICS_FETCH_FAILED,
    GET_ENERGY_HISTORY,
    GET_ENERGY_HISTORY_SUCCESS,
    UPDATE_PERFORMANCE_DISPLAY,
    GET_POWER_HISTORY,
    GET_POWER_HISTORY_SUCCESS,
    GET_BASELINE_ENERGY,
    GET_BASELINE_ENERGY_SUCCESS,
    POLL_VPP_STATE,
    STOP_POLLING_VPP_STATE,
    GET_VPP_STATE,
    UPDATE_NEXT_PEAK_EVENT,
    UPDATE_VPP_STATE,
    UPDATE_VPP_MODE,
    UPDATE_VPP_MODE_SUCCESS,
    GET_DEVICE_COUNT,
    GET_DEVICE_COUNT_SUCCESS,
    UPDATE_TARGET_KW,
    GET_PEAK_INFO,
    GET_PEAK_INFO_SUCCESS,
    UPDATE_IS_LOADING,
    FETCH_VPP_CONFIG,
    FETCH_VPP_CONFIG_SUCCESS,
    FETCH_VPP_CONFIG_FAILURE,
    UPDATE_ACTIVE_VPP,
    ACTIVE_VPP_CHANGED,
    GET_VPP_MODE_HISTORY,
    GET_VPP_MODE_HISTORY_SUCCESS,
} from './actions';
import {
    FETCH_USER_SUCCESS,
} from '../user/actions';
import {
    PEAKS_SHOW_WINDOW,
} from '../peaks/actions';
import {
    CREATE_ALERT,
} from '../alerts/actions';
import {
    DISPLAY_TYPES,
    ENERGY_BUCKETS,
    getEnergyIntervalId,
} from './utils';
import {
    getActiveVppId,
    getActiveVpp,
    getVpp,
    shouldShowBaseline,
    getWindowBounds,
} from './selectors';

import { VPPDAO } from './vpp-dao';
import { VPPPowerDAO } from './vpp-power-dao';
import { VPPStatsDAO } from './vpp-stats-dao';
import { VPPConfigDAO } from './vpp-config-dao';
import { PeakSchedulerDAO } from '../peaks/peak-scheduler-dao';

import { container } from '../../dependency-injection';

import {
    translateWindowToRange,
    NOW,
    TWO_WEEKS,
} from '../../utils/windows';
import {
    ONE_DAY_MS,
} from '../../utils/time';
import { NO_VPP } from './reducers';

function* handler() {
    yield all([
        takeEvery(FETCH_VPPS, handleFetchVPPs),
        takeEvery(FETCH_DATA_FOR_VPPS, handleFetchDataForVpps),
        takeEvery(VPP_FETCH, handleFetchAdminVPPInfo),
        takeEvery(VPP_METRICS_SHOW_WINDOW, handleShowVPPMetricsWindow),
        takeEvery(GET_ENERGY_HISTORY, handleFetchEnergyHistory),
        takeEvery(GET_POWER_HISTORY, handleFetchPowerHistory),
        takeEvery(GET_BASELINE_ENERGY, handleFetchBaselineEnergy),
        takeEvery(UPDATE_PERFORMANCE_DISPLAY, handlePerformanceDisplayChange),
        takeEvery(GET_VPP_STATE, handleFetchVPPState),
        takeEvery(UPDATE_VPP_MODE, handleUpdateVPPMode),
        takeEvery(UPDATE_TARGET_KW, handleUpdateTargetPower),
        takeEvery(GET_DEVICE_COUNT, handleFetchDeviceCount),
        takeEvery(GET_PEAK_INFO, handleUpdatePeakInfo),
        takeLatest([
            POLL_VPP_STATE,
            STOP_POLLING_VPP_STATE,
        ], handlePollVppState),
        takeEvery([
            FETCH_USER_SUCCESS,
            FETCH_VPP_CONFIG,
        ], fetchVppConfig),
        takeEvery(UPDATE_ACTIVE_VPP, handleNewActiveVpp),
        takeEvery(GET_VPP_MODE_HISTORY, handleFetchModeHistory),
    ]);
}

function* handleFetchModeHistory(action) {
    const {
        vppId,
    } = action.payload;
    const {
        startTS,
        endTS,
    } = yield select(getWindowBounds, vppId);
    const vppStatsClient = container.get(VPPStatsDAO);
    try {
        const res = yield call(vppStatsClient.getModeHistory, {
            vppId,
            lowerTS: startTS,
            upperTS: endTS,
        });
        const modeHistory = res.map((val, i, obs) => ({
            mode: val.mode,
            startTS: val.at,
            // endTS is beginning of next mode, or end of window
            endTS: i === obs.length - 1 ? endTS : obs[i + 1].at,
        }));
        yield put({
            type: GET_VPP_MODE_HISTORY_SUCCESS,
            payload: {
                vppId,
                modeHistory,
            },
        });
    } catch (err) {
        // pass
    }
}

function* handleNewActiveVpp(action) {
    const {
        vppId,
    } = action.payload;
    yield call(fetchVppConfig, {
        payload: {
            vppId,
        },
    });
    yield put({
        type: ACTIVE_VPP_CHANGED,
        payload: {
            vppId,
        },
    });
}

function* fetchVppConfig(action) {
    const vppId = yield call(getVppId, action);
    if (vppId === NO_VPP) return;
    const vppConfigClient = container.get(VPPConfigDAO);
    try {
        const vppConfig = yield call(vppConfigClient.get, {
            vppId,
        });
        yield put({
            type: FETCH_VPP_CONFIG_SUCCESS,
            payload: vppConfig,
        });
    } catch (err) {
        yield put({
            type: FETCH_VPP_CONFIG_FAILURE,
        });
    }
}

function* handleFetchVPPs(action) {
    const vppInterface = container.get(VPPDAO);
    try {
        const vpps = yield call(vppInterface.getVPPs);
        yield put({
            type: FETCH_VPPS_SUCCEEDED,
            payload: vpps,
        });
        if (action.payload.includeDetail) {
            yield put({
                type: FETCH_DATA_FOR_VPPS,
                payload: vpps,
            });
        }
    } catch (err) {
        yield put({
            type: FETCH_VPPS_FAILED,
        });
    }
}

function* handleFetchDataForVpps(action) {
    try {
        yield all(action.payload.map(({
            vppId,
        }) => handleFetchAdminVPPInfo({
            type: VPP_FETCH,
            payload: vppId,
        })));
        yield all(action.payload.map(({
            vppId,
        }) => handleShowVPPMetricsWindow({
            type: VPP_METRICS_SHOW_WINDOW,
            payload: {
                vppId,
                anchor: NOW,
                duration: TWO_WEEKS,
            },
        })));
        yield put({
            type: FETCH_DATA_FOR_VPPS_SUCCEEDED,
        });
    } catch (err) {
        yield put({
            type: FETCH_DATA_FOR_VPPS_FAILED,
        });
    }
}

function* handleFetchAdminVPPInfo(action) {
    const vppInterface = container.get(VPPDAO);
    const vppId = action.payload;
    try {
        const [
            deviceCount,
            energyMarketValue,
        ] = yield all([
            call(vppInterface.getDeviceCount, vppId),
            call(vppInterface.getEnergyMarketValue, {
                vpp: vppId,
            }),
        ]);
        yield put({
            type: VPP_FETCH_SUCCEEDED,
            payload: {
                vppId,
                deviceCount,
                energyMarketValue: energyMarketValue.value,
            },
        });
    } catch (err) {
        yield put({
            type: VPP_FETCH_FAILED,
            payload: {
                vppId,
            },
        });
    }
}

function* handleShowVPPMetricsWindow(action) {
    const vppInterface = container.get(VPPDAO);
    const {
        vppId,
        anchor,
        duration,
    } = action.payload;
    try {
        const {
            lowerTS,
            upperTS,
        } = translateWindowToRange({
            anchor,
            duration,
        });
        const metrics = yield call(vppInterface.getMetrics, {
            vppId,
            lowerTS,
            upperTS,
        });
        yield put({
            type: VPP_METRICS_FETCH_SUCCEEDED,
            payload: {
                vppId,
                metrics,
                interval: {
                    anchor,
                    duration,
                },
            },
        });
    } catch (err) {
        yield put({
            type: VPP_METRICS_FETCH_FAILED,
            payload: {
                vppId,
                interval: {
                    anchor,
                },
            },
        });
    }
}

function* handlePerformanceDisplayChange(action) {
    const {
        vppId,
        performanceDisplay,
    } = action.payload;
    const vpp = yield select(getVpp, vppId);
    const displayType = performanceDisplay.displayType || vpp.performanceDisplay.displayType;
    const payload = { vppId };
    yield all([
        call(displayType === DISPLAY_TYPES.POWER
            ? handleFetchPowerHistory
            : handleFetchEnergyHistory, { payload }),
        call(handleFetchBaselineEnergy, { payload }),
        call(handleFetchPeaksInGraphRange, vppId),
        call(handleFetchModeHistory, { payload }),
    ]);
}

function* handleFetchEnergyHistory(action) {
    const state = yield select();
    const vppId = yield call(getVppId, action);
    if (!state.vpps.vpps[vppId]) return;
    const {
        performanceDisplay,
        energy,
    } = state.vpps.vpps[vppId];
    const intervalId = getEnergyIntervalId(performanceDisplay);
    if (energy[intervalId]) return;

    const vppPowerClient = container.get(VPPPowerDAO);
    try {
        const { startTS, endTS } = yield select(getWindowBounds, vppId);
        const energyHistory = yield call(vppPowerClient.getEnergyHistory, {
            vppId,
            startTS,
            endTS,
            interval: performanceDisplay.bucketSize,
        });
        yield put({
            type: GET_ENERGY_HISTORY_SUCCESS,
            payload: {
                vppId,
                energyHistory: condenseBuckets(energyHistory),
                ...performanceDisplay,
            },
        });
    } catch (err) {
        yield call(showOperatorAlert, 'Failed to fetch energy for the VB. Try reloading the page.');
    }
}

const condenseBuckets = (energyHistory) => {
    const result = [];
    let lastObs = {};
    energyHistory.forEach((obs) => {
        if (obs.bucket === lastObs.bucket) {
            result.splice(-1, 1, Object.assign({}, lastObs, {
                actualkWh: lastObs.actualkWh + obs.actualkWh,
            }));
        } else {
            result.push(obs);
        }
        lastObs = obs;
    });
    return result;
};

function* handleFetchBaselineEnergy(action) {
    const vppId = yield call(getVppId, action);
    const showBaseline = yield select(shouldShowBaseline, vppId);
    if (!showBaseline) return;
    const {
        performanceDisplay: {
            bucketSize,
            displayType,
        },
    } = yield select(getVpp, vppId);
    const { startTS, endTS } = yield select(getWindowBounds, vppId);
    const vppPowerClient = container.get(VPPPowerDAO);
    try {
        const baselineEnergyData = yield call(vppPowerClient.getBaselineEnergy, {
            vppId,
            startTS,
            endTS,
        });
        let baselineEnergy = baselineEnergyData;
        if (displayType === DISPLAY_TYPES.ENERGY && bucketSize === ENERGY_BUCKETS.FIVE_MINUTES) {
            baselineEnergy = baselineEnergyData.map(element => Object.assign(
                {},
                element,
                { baselinekWh: element.baselinekWh / 12 },
            ));
        }
        yield put({
            type: GET_BASELINE_ENERGY_SUCCESS,
            payload: {
                vppId,
                baselineEnergy,
            },
        });
    } catch {
        yield call(showOperatorAlert, 'Failed to fetch baseline energy data. Try reloading the page.');
    }
}

function* handleFetchPowerHistory(action) {
    const vppId = yield call(getVppId, action);
    const {
        startTS,
        endTS,
    } = yield select(getWindowBounds, vppId);
    const fetchAverage = false;
    const {
        power: {
            isAverage: wasShowingAverage,
        },
    } = yield select(getVpp, vppId);
    if (fetchAverage !== wasShowingAverage) {
        yield put({
            type: UPDATE_IS_LOADING,
            payload: {
                vppId,
                loading: true,
            },
        });
    }
    const vppPowerClient = container.get(VPPPowerDAO);
    try {
        const powerHistory = yield call(vppPowerClient.getPowerHistory, {
            vppId,
            startTS,
            endTS,
            average: fetchAverage,
        });
        const virtualLastObs = Object.assign(
            {},
            powerHistory[powerHistory.length - 1],
            {
                at: endTS,
                activePeakEventId: null,
            },
        );
        const extendedPowerHistory = powerHistory.concat(virtualLastObs);
        yield put({
            type: GET_POWER_HISTORY_SUCCESS,
            payload: {
                vppId,
                powerHistory: extendedPowerHistory,
                isAverage: fetchAverage,
            },
        });
        if (fetchAverage !== wasShowingAverage) {
            yield put({
                type: UPDATE_IS_LOADING,
                payload: {
                    vppId,
                    loading: false,
                },
            });
        }
    } catch (err) {
        yield call(showOperatorAlert, 'Failed to fetch power for the VB. Try reloading the page.');
    }
}

function* handleUpdateVPPMode(action) {
    const { mode } = action.payload;
    const vppId = yield call(getVppId, action);
    const vppPowerClient = container.get(VPPPowerDAO);
    try {
        const res = yield call(vppPowerClient.setMode, {
            vppId,
            mode,
        });
        if (res.status < 400) {
            yield put({
                type: UPDATE_VPP_MODE_SUCCESS,
                payload: {
                    vppId,
                    value: mode,
                },
            });
        } else {
            throw new NyleError(`Unable to update mode for ${vppId}: ${JSON.stringify(res)}`);
        }
    } catch (err) {
        yield call(showOperatorAlert, 'Failed to change VB Mode. Try reloading the page.');
    }
}

function* handleFetchDeviceCount(action) {
    const vppId = yield call(getVppId, action);
    const vppStatsClient = container.get(VPPStatsDAO);
    try {
        const stats = yield call(vppStatsClient.getStats, { vppId });
        yield put({
            type: GET_DEVICE_COUNT_SUCCESS,
            payload: {
                vppId,
                ...stats,
            },
        });
    } catch (err) {
        yield call(showOperatorAlert, 'Failed to fetch number of devices connected');
    }
}

function* handleUpdateTargetPower(action) {
    const vppId = yield call(getVppId, action);
    const {
        power: {
            currentSetPointKW,
        },
    } = yield select(getVpp, vppId);
    const { targetKW } = action.payload;
    const vppPowerClient = container.get(VPPPowerDAO);
    try {
        const res = yield call(vppPowerClient.setTargetKW, {
            vppId,
            targetKW,
            previousTargetKW: currentSetPointKW,
        });
        if (res.status >= 400) {
            throw new NyleError(`Unable to update target power for ${vppId}: ${JSON.stringify(res)}`);
        }
        yield call(handleFetchVPPPower, vppId);
    } catch (err) {
        yield call(showOperatorAlert, 'Failed to update target power. Try reloading the page.');
    }
}

function* handleFetchVPPState(action = {}) {
    const vppId = yield call(getVppId, action);
    const isInitialFetch = action.type === GET_VPP_STATE;
    if (isInitialFetch) {
        yield put({
            type: UPDATE_IS_LOADING,
            payload: {
                vppId,
                loading: true,
            },
        });
    }
    yield all([
        call(handleFetchVPPPower, vppId),
    ]);
    if (isInitialFetch) {
        yield put({
            type: UPDATE_IS_LOADING,
            payload: {
                vppId,
                loading: false,
            },
        });
    }
}

function* handleFetchVPPPower(vppId) {
    const vppPowerClient = container.get(VPPPowerDAO);
    try {
        const currentPowerObs = yield call(vppPowerClient.getPower, { vppId });
        yield put({
            type: UPDATE_VPP_STATE,
            payload: {
                vppId,
                currentPowerObs,
            },
        });
    } catch (err) {
        yield call(showOperatorAlert, 'Failed to fetch power for the VB. Try reloading the page.');
    }
}

function* handleUpdatePeakInfo(action) {
    const {
        activePeakEvent,
        nextPeakEvent,
    } = action.payload;
    const vppId = yield call(getVppId, action);
    const vppPowerClient = container.get(VPPPowerDAO);
    try {
        const currentPowerObs = yield call(vppPowerClient.getPower, { vppId });
        const activePeakEventId = Number(currentPowerObs.activePeakEventId);
        yield put({
            type: GET_PEAK_INFO_SUCCESS,
            payload: {
                vppId,
                value: {
                    activePeakEventId,
                    activePeakEvent,
                    nextPeakEvent,
                },
            },
        });
    } catch (err) {
        yield call(showOperatorAlert, 'Failed to update peak');
    }
}

function* handleFetchNextPeak(vpp) {
    const peakSchedulerInterface = container.get(PeakSchedulerDAO);
    const vppId = yield call(getVppId, { payload: { vppId: vpp } });
    const now = Date.now();
    try {
        const peaks = yield call(peakSchedulerInterface.getEvents, {
            completed: false,
            vppId,
            lowerTS: now,
        });
        const nextPeakEvent = peaks.find(peak => peak.prePositionTS > now && peak.state !== 'deleted');
        const {
            startTS,
            endTS,
        } = nextPeakEvent || {};
        yield put({
            type: UPDATE_NEXT_PEAK_EVENT,
            payload: {
                vppId,
                nextPeakEvent: {
                    startTS,
                    endTS,
                },
            },
        });
    } catch {} // eslint-disable-line no-empty
}

function* handlePollVppState(action) {
    if (action.type === POLL_VPP_STATE) {
        const vppId = yield call(getVppId, action);
        while (true) {
            const {
                performanceDisplay: {
                    displayType,
                },
            } = yield select(getVpp, vppId);
            yield all([
                call(handleFetchVPPState, action),
                call(displayType === DISPLAY_TYPES.POWER
                    ? handleFetchPowerHistory
                    : handleFetchEnergyHistory, action),
                call(handleFetchBaselineEnergy, action),
                call(handleFetchNextPeak, vppId),
                call(handleFetchPeaksInGraphRange, vppId),
                call(handleFetchModeHistory, action),
            ]);
            const { pollingDelayInterval } = yield select(getActiveVpp);
            yield delay(pollingDelayInterval);
        }
    }
}

function* handleFetchPeaksInGraphRange(vpp) {
    const vppId = yield call(getVppId, { payload: { vppId: vpp } });
    const {
        endTS,
    } = yield select(getWindowBounds, vppId);
    const {
        performanceDisplay,
    } = yield select(getVpp, vppId);
    yield put({
        type: PEAKS_SHOW_WINDOW,
        payload: {
            vppId,
            duration: performanceDisplay.duration.replace('P', 'P-'),
            anchor: endTS,
            padStartMS: ONE_DAY_MS,
            // convert window to the format expected by the peaks model,
            // using the end timestamp and a negative duration to get completed peaks
            // fetch peaks starting up to a day before the window to catch all peaks
            // that might overlap with the window
        },
    });
}

function* showOperatorAlert(message) {
    yield put({
        type: CREATE_ALERT,
        payload: {
            type: 'operator',
            message,
            level: 'error',
        },
    });
}

function* getVppId(action = { payload: {} }) {
    if (action.payload.vppId) return action.payload.vppId;
    const activeVppId = yield select(getActiveVppId);
    return activeVppId;
}

export {
    handler,
};
