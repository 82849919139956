function shouldShowPeakValues(state) {
    return state.user.admin || false;
}

function userHasRole(state, role, vppId = null) {
    const {
        vpps: {
            activeVpp,
        },
        user: {
            roles,
            admin,
        },
    } = state;
    if (role === 'admin') return admin;
    return admin || roles.indexOf(`${vppId || activeVpp.toString()}:${role}`) > -1;
}

function userIsNyleAdmin(state) {
    return state.user.admin || false;
}

function listVpps(state) {
    return Object.keys(state.user.vpps).reduce((vpps, vpp) => {
        if (vpp.primary) return [vpp, ...vpps];
        return [...vpps, vpp];
    }, []);
}

export {
    shouldShowPeakValues,
    userHasRole,
    userIsNyleAdmin,
    listVpps,
};
