import {
    INITIAL_VPP_STATE,
} from './reducers';
import {
    toWindowId,
} from '../../utils/windows';
import {
    getWindowBounds as getWindowBoundsFromDisplay,
} from './utils';

function getDisplayedVPPs(state) {
    const vppIds = getDisplayedVPPsByID(state);
    return vppIds.map(vppId => ({
        ...state.vpps.vpps[vppId],
        vppId,
    }));
}

function getDisplayedVPPsByID(state) {
    if (!state.vpps || !state.vpps.vpps || typeof state.vpps.vpps !== 'object') return [];
    return Object.keys(state.vpps.vpps);
}

function getVPPInfo(state, vppId) {
    const {
        name,
    } = state.vpps.vpps[vppId] || INITIAL_VPP_STATE;
    return {
        name,
        vppId,
    };
}

function getVPPInstantaneousStats(state, vppId) {
    const {
        metrics: {
            instantaneous,
        },
    } = state.vpps.vpps[vppId] || INITIAL_VPP_STATE;
    return instantaneous;
}

function getDisplayedMetricsWindow(state, vppId) {
    const {
        metrics: {
            activeMetricsWindow,
        },
    } = state.vpps.vpps[vppId] || INITIAL_VPP_STATE;
    return activeMetricsWindow;
}

function getDisplayedMetrics(state, vppId) {
    const {
        metrics: {
            activeMetricsWindow,
            intervals,
        },
    } = state.vpps.vpps[vppId] || INITIAL_VPP_STATE;
    return intervals[toWindowId(activeMetricsWindow)];
}

function getActiveVppId(state) {
    return state.vpps.activeVpp;
}

function getActiveVpp(state) {
    const { activeVpp } = state.vpps;
    if (!activeVpp) return INITIAL_VPP_STATE;
    return state.vpps.vpps[activeVpp] || INITIAL_VPP_STATE;
}

function getVpp(state, vppId) {
    const vpp = vppId || state.vpps.activeVpp;
    return state.vpps.vpps[vpp] || INITIAL_VPP_STATE;
}

function shouldShowBaseline(state, vppId) {
    if (state.user.admin) return true;
    const vppConfig = getVpp(state, vppId || state.vpps.activeVpp) || {};
    return vppConfig.displayBaseline || false;
}

function shouldShowPower(state, vppId) {
    if (state.user.admin) return true;
    const vppConfig = getVpp(state, vppId || state.vpps.activeVpp) || {};
    return vppConfig.displayPowerGraph || false;
}

function getWindowBounds(state, vppId) {
    const {
        performanceDisplay,
    } = getVpp(state, vppId);
    return getWindowBoundsFromDisplay(performanceDisplay);
}

export {
    getDisplayedVPPs,
    getDisplayedVPPsByID,
    getVPPInfo,
    getVPPInstantaneousStats,
    getDisplayedMetrics,
    getDisplayedMetricsWindow,
    getActiveVppId,
    getActiveVpp,
    getVpp,
    shouldShowBaseline,
    shouldShowPower,
    getWindowBounds,
};
