const MARGINS = {
    top: 40,
    bottom: 60,
    left: 60,
    right: 20,
};

export {
    MARGINS,
};
