import {
    FETCH_DEVICE_ALERTS_SUCCESS,
    FETCH_DEVICE_ALERTS_FAILURE,
    SET_DEVICE_ALERT_FILTER,
    RESOLVE_DEVICE_ALERT_FAILURE,
    UNRESOLVE_DEVICE_ALERT_FAILURE,
    ACKNOWLEDGE_DEVICE_ALERT_FAILURE,
    CREATE_DEVICE_ALERT,
    CREATE_DEVICE_ALERT_SUCCESS,
    CREATE_DEVICE_ALERT_FAILURE,
    UPDATE_DEVICE_ALERT,
    UPDATE_DEVICE_ALERT_SUCCESS,
    UPDATE_DEVICE_ALERT_FAILURE,
    UPDATE_DEVICE_ALERTS_MESSAGE,
} from './actions';
import {
    ASYNC_STATUS,
} from '../../../utils/async';

const INITIAL_DEVICE_ALERTS_STATE = {
    alerts: {},
    message: '',
    saving: ASYNC_STATUS.NONE,
    filters: {
        showResolved: false,
        displayToUserOnly: false,
    },
};

const getAlertKey = ({
    deviceId,
    alertType,
}) => `${deviceId}-${alertType}`;

const reducer = (state = INITIAL_DEVICE_ALERTS_STATE, action) => {
    switch (action.type) {
        case FETCH_DEVICE_ALERTS_SUCCESS: {
            const {
                alerts: newAlerts,
            } = action.payload;

            const alerts = {};
            newAlerts.forEach((item) => {
                alerts[getAlertKey(item)] = item;
            });

            return {
                ...state,
                alerts,
            };
        }
        case FETCH_DEVICE_ALERTS_FAILURE: {
            return {
                ...state,
                message: 'Unable to fetch device alerts',
            };
        }
        case SET_DEVICE_ALERT_FILTER: {
            const {
                key,
                value,
            } = action.payload;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    [key]: value,
                },
            };
        }
        case RESOLVE_DEVICE_ALERT_FAILURE: {
            return {
                ...state,
                message: 'Unable to resolve alert',
            };
        }
        case UNRESOLVE_DEVICE_ALERT_FAILURE: {
            return {
                ...state,
                message: 'Unable to unresolve alert',
            };
        }
        case ACKNOWLEDGE_DEVICE_ALERT_FAILURE: {
            return {
                ...state,
                message: 'Unable to acknowledge alert',
            };
        }
        case CREATE_DEVICE_ALERT:
        case UPDATE_DEVICE_ALERT: {
            return {
                ...state,
                saving: ASYNC_STATUS.WAITING,
            };
        }
        case CREATE_DEVICE_ALERT_SUCCESS:
        case UPDATE_DEVICE_ALERT_SUCCESS: {
            return {
                ...state,
                saving: ASYNC_STATUS.SUCCESS,
                message: '',
            };
        }
        case CREATE_DEVICE_ALERT_FAILURE: {
            return {
                ...state,
                saving: ASYNC_STATUS.FAILURE,
                message: 'Unable to create alert',
            };
        }
        case UPDATE_DEVICE_ALERT_FAILURE: {
            return {
                ...state,
                saving: ASYNC_STATUS.FAILURE,
                message: 'Unable to update alert',
            };
        }
        case UPDATE_DEVICE_ALERTS_MESSAGE: {
            return {
                ...state,
                message: action.payload.message,
            };
        }
        default: return state;
    }
};

export {
    reducer,
    INITIAL_DEVICE_ALERTS_STATE,
};
