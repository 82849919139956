import {
    timeDay,
} from 'd3-time';
import {
    PEAKS_FETCH_FAILED,
    PEAKS_FETCH_SUCCEEDED,
    PEAKS_SHOW_WINDOW,
    GET_PEAK_EVENT_SUCCESS,
    SET_PEAK_ERROR_MESSAGE,
    SET_SAVING_PEAK_FLAG,
    TOGGLE_EDITING_PEAK,
    CREATE_NEW_PEAK_EVENT,
    RESET_NEW_PEAK_EVENT,
    GET_PAST_PEAKS_IN_GRAPH_RANGE_SUCCESS,
} from './actions';
import {
    FETCH_USER_SUCCESS,
} from '../user/actions';
import {
    toPeakId,
} from './utils';
import {
    toWindowId,
} from '../../utils/windows';

const SCHEDULED_PEAKS_WINDOW_KEY = 'displayedScheduledPeaks';
const COMPLETED_PEAKS_WINDOW_KEY = 'displayedCompletedPeaks';

const INITIAL_PEAKS_STATE_ALL_VPPS = {};

const reducer = (state = INITIAL_PEAKS_STATE_ALL_VPPS, action) => {
    switch (action.type) {
        case PEAKS_FETCH_SUCCEEDED:
        case PEAKS_FETCH_FAILED:
        case PEAKS_SHOW_WINDOW:
        case GET_PEAK_EVENT_SUCCESS:
        case CREATE_NEW_PEAK_EVENT:
        case RESET_NEW_PEAK_EVENT:
        case SET_PEAK_ERROR_MESSAGE:
        case SET_SAVING_PEAK_FLAG:
        case TOGGLE_EDITING_PEAK:
        case GET_PAST_PEAKS_IN_GRAPH_RANGE_SUCCESS: {
            const { vppId } = action.payload || {};
            if (!vppId) return state;
            return {
                ...state,
                [vppId]: peaksReducer(state[vppId], action),
            };
        }
        case FETCH_USER_SUCCESS: {
            const { vpps } = action.payload.user;
            const initializedVpps = {};
            Object.keys(vpps).forEach((vppId) => {
                initializedVpps[vppId] = peaksReducer(state[vppId], action);
            });
            return {
                ...state,
                ...initializedVpps,
            };
        }
        default: return state;
    }
};

const INITIAL_PEAKS_STATE = {
    intervals: {},
    peaks: {},
    [SCHEDULED_PEAKS_WINDOW_KEY]: {
        duration: 'P7D',
        anchor: timeDay.floor(Date.now()),
    },
    [COMPLETED_PEAKS_WINDOW_KEY]: {
        duration: 'P-7D',
        anchor: timeDay.ceil(Date.now()),
    },
    activePeak: null,
    errors: {},
    editingPeak: {},
    savingPeak: {},
    newPeak: null,
    peaksInGraphRange: [],
};

const peaksReducer = (state = INITIAL_PEAKS_STATE, action) => {
    switch (action.type) {
        case PEAKS_FETCH_SUCCEEDED: {
            const intervalId = toWindowId(action.payload);
            const fetchedPeaks = action.payload.peaks.reduce((peaks, peak) => Object.assign(
                {},
                peaks,
                { [toPeakId(peak)]: peakReducer(peak, action) },
            ), {});
            return {
                ...state,
                intervals: {
                    ...state.intervals,
                    [intervalId]: Object.keys(fetchedPeaks),
                },
                peaks: {
                    ...state.peaks,
                    ...fetchedPeaks,
                },
            };
        }
        case PEAKS_FETCH_FAILED: {
            const intervalId = toWindowId(action.payload);
            return {
                ...state,
                intervals: {
                    ...state.intervals,
                    [intervalId]: null,
                },
            };
        }
        case PEAKS_SHOW_WINDOW: {
            const {
                vppId,
                duration,
                anchor,
            } = action.payload;
            const completed = duration.charAt(1) === '-';
            const displayedWindow = [completed
                ? COMPLETED_PEAKS_WINDOW_KEY
                : SCHEDULED_PEAKS_WINDOW_KEY];
            return {
                ...state,
                [displayedWindow]: {
                    vppId,
                    duration,
                    anchor,
                },
            };
        }
        case GET_PEAK_EVENT_SUCCESS: {
            return {
                ...state,
                activePeak: action.payload,
            };
        }
        case CREATE_NEW_PEAK_EVENT: {
            return {
                ...state,
                newPeak: action.payload,
            };
        }
        case RESET_NEW_PEAK_EVENT: {
            return {
                ...state,
                newPeak: null,
            };
        }
        case SET_PEAK_ERROR_MESSAGE:
        case SET_SAVING_PEAK_FLAG:
        case TOGGLE_EDITING_PEAK: {
            const { peakId } = action.payload;
            return {
                ...state,
                peaks: {
                    ...state.peaks,
                    [peakId]: peakReducer(state.peaks[peakId], action),
                },
            };
        }
        case GET_PAST_PEAKS_IN_GRAPH_RANGE_SUCCESS: {
            return {
                ...state,
                peaksInGraphRange: action.payload.peaks,
            };
        }
        default: return state;
    }
};

const INITIAL_PEAK_STATE = {
    editing: false,
    saving: null,
    error: null,
    startTS: null,
};

const peakReducer = (state = INITIAL_PEAK_STATE, action) => {
    switch (action.type) {
        case SET_PEAK_ERROR_MESSAGE: {
            const {
                message,
            } = action.payload;
            return {
                ...state,
                error: message,
            };
        }
        case SET_SAVING_PEAK_FLAG: {
            const {
                actionType,
            } = action.payload;
            return {
                ...state,
                saving: actionType,
            };
        }
        case TOGGLE_EDITING_PEAK: {
            const { editing } = action.payload;
            return {
                ...state,
                editing: typeof editing === 'boolean' ? editing : !state.editing,
                error: null,
            };
        }
        case PEAKS_FETCH_SUCCEEDED: {
            return {
                ...INITIAL_PEAK_STATE,
                ...state,
            };
        }
        default: return state;
    }
};

export {
    reducer,
    SCHEDULED_PEAKS_WINDOW_KEY,
    COMPLETED_PEAKS_WINDOW_KEY,
};
