/* globals window */
import React from 'react';
import { connect } from 'react-redux';
import {
    DevicesPageView,
} from '../admin/devices/devices-page-view';
import {
    fetchDevices,
    fetchUserDevices,
    updateProduct,
    updateFirmwareFilter,
    updateConnectionStatusFilter,
    setFilterByInstallDate,
    updateInstallDateFilter,
    clearFilters,
} from '../models/devices/actions';
import { NO_VPP } from '../models/vpps/reducers';
import {
    getActiveVppId,
    getActiveVpp,
} from '../models/vpps/selectors';

class DevicesContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString: props.searchString || '',
        };
    }

    componentDidMount() {
        const {
            handleSubmitSearch,
            handleFetchUserDevices,
            activeVpp,
        } = this.props;
        const {
            searchString,
        } = this.state;
        if (activeVpp === NO_VPP) {
            handleFetchUserDevices();
        } else {
            handleSubmitSearch(activeVpp, searchString);
        }
    }

    render() {
        const {
            handleSubmitSearch,
            activeVpp,
            shouldRedirect,
            filterByInstalled,
            toggleFilterByInstallDate,
            selectProductType,
            selectConnectionFilter,
            setInstallationRange,
            clearAllFilters,
        } = this.props;
        if (shouldRedirect) {
            window.location.replace(`${window.location.origin}/#/operator`);
            return null;
        }
        const {
            searchString,
        } = this.state;
        return (
            <DevicesPageView
                {...this.props}
                searchString={searchString}
                handleSearchChange={(s) => {
                    this.setState({
                        searchString: s,
                    });
                }}
                handleSubmitSearch={() => handleSubmitSearch(activeVpp, searchString)}
                toggleFilterByInstallDate={() => {
                    toggleFilterByInstallDate(!filterByInstalled, searchString);
                }}
                selectProductType={(item) => {
                    const {
                        type,
                        version,
                    } = item;
                    selectProductType({
                        productType: type === 'all' ? '' : type,
                        productVersion: version === 'all' ? '' : version,
                        searchString,
                    });
                }}
                selectConnectionFilter={status => selectConnectionFilter(status, searchString)}
                setInstallationRange={(after, before) => {
                    setInstallationRange(after, before, searchString);
                }}
                clearAllFilters={() => {
                    this.setState({
                        searchString: '',
                    });
                    clearAllFilters();
                }}
            />
        );
    }
}

const mapStateToProps = (state) => {
    const {
        deviceList,
        errorMessages,
        filters: {
            searchString,
            productType,
            productVersion,
            firmwareVersion,
            connectionStatus,
            deploymentStatus,
            filterByInstalled,
            installedAfter,
            installedBefore,
        },
    } = state.devices;

    // If the user is not part of a vpp we should show the list of devices
    // which will be the list of their own devices
    const activeVppId = getActiveVppId(state);
    const showDevicesTab = activeVppId === NO_VPP ? true : getActiveVpp(state).showDevicesTab;

    return {
        devices: deviceList,
        message: errorMessages.deviceSearch,
        searchString,
        productType,
        productVersion,
        firmwareVersion,
        connectionStatus,
        deploymentStatus,
        filterByInstalled,
        installedAfter,
        installedBefore,
        activeVpp: getActiveVppId(state),
        shouldRedirect: !showDevicesTab,
        shouldShowSearch: activeVppId !== NO_VPP,
    };
};

const mapDispatchToProps = dispatch => ({
    handleSubmitSearch: (vppId, searchString) => dispatch(fetchDevices(vppId, searchString)),
    handleFetchUserDevices: () => dispatch(fetchUserDevices()),
    selectProductType: ({
        productType,
        productVersion,
        searchString,
    }) => dispatch(updateProduct({
        productType,
        productVersion,
        searchString,
    })),
    handleFirmwareVersionChange: fwVersion => dispatch(updateFirmwareFilter(fwVersion)),
    selectConnectionFilter: ({
        connectionStatus,
        deploymentStatus,
        searchString,
    }) => dispatch(updateConnectionStatusFilter({
        connectionStatus,
        deploymentStatus,
        searchString,
    })),
    toggleFilterByInstallDate: (filter, searchString) => {
        dispatch(setFilterByInstallDate(filter, searchString));
    },
    setInstallationRange: (after, before, searchString) => dispatch(updateInstallDateFilter({
        installedAfter: after,
        installedBefore: before,
        searchString,
    })),
    clearAllFilters: () => dispatch(clearFilters()),
});

const DevicesPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DevicesContainer);

export {
    DevicesPage,
};
