const FETCH_USER_SUCCESS = 'user/FETCH_USER_SUCCESS';
const HANDLE_NO_USER = 'user/HANDLE_NO_USER';

const FETCH_USER = 'users/FETCH_USER';
const fetchUser = redirectTo => ({
    type: FETCH_USER,
    payload: { redirectTo },
});

const CHECK_AUTH_TOKEN = 'users/CHECK_AUTH_TOKEN';
const LOG_IN = 'users/LOG_IN';
const LOG_OUT = 'users/LOG_OUT';
const checkAuthToken = () => ({
    type: CHECK_AUTH_TOKEN,
});
const logInUser = () => ({
    type: LOG_IN,
});
const logOutUser = () => ({
    type: LOG_OUT,
});

export {
    FETCH_USER_SUCCESS,
    HANDLE_NO_USER,
    FETCH_USER,
    fetchUser,
    CHECK_AUTH_TOKEN,
    LOG_IN,
    LOG_OUT,
    checkAuthToken,
    logInUser,
    logOutUser,
};
