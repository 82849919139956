import React from 'react';
import {
    Icon,
} from '../../../lib/font-awesome';
import './error.css';

const ErrorMessage = ({
    errorMessage,
}) => {
    if (!errorMessage) return null;
    return (
        <aside className="error-message">
            <span className="center">
                <Icon hint="exclamation-triangle" />
                {' '}
                {errorMessage}
            </span>
        </aside>
    );
};

export {
    ErrorMessage,
};
