const colors = {
    primaryBlue: '#115D91',
    secondaryBlue: '#75C5F0',
    red: 'red',
    orange: 'orange',
    green: 'green',
    lightgreen: 'lightgreen',
};

export {
    colors,
};
