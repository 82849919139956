const FETCH_VPPS = 'vpps/FETCH_VPPS';
const FETCH_VPPS_SUCCEEDED = 'vpps/FETCH_VPPS_SUCCEEDED';
const FETCH_VPPS_FAILED = 'vpps/FETCH_VPPS_FAILED';

const fetchVPPs = payload => ({
    type: FETCH_VPPS,
    payload,
});

const FETCH_DATA_FOR_VPPS = 'vpps/FETCH_DATA_FOR_VPPS';
const FETCH_DATA_FOR_VPPS_SUCCEEDED = 'vpps/FETCH_DATA_FOR_VPPS_SUCCEEDED';
const FETCH_DATA_FOR_VPPS_FAILED = 'vpps/FETCH_DATA_FOR_VPPS_FAILED';

const fetchDataForVpps = () => ({
    type: FETCH_DATA_FOR_VPPS,
});

const VPP_FETCH = 'vpps/VPP_FETCH';
const VPP_FETCH_SUCCEEDED = 'vpps/VPP_FETCH_SUCCEEDED';
const VPP_FETCH_FAILED = 'vpps/VPP_FETCH_FAILED';

const fetchVPPInfo = vppId => ({
    type: VPP_FETCH,
    payload: vppId,
});

const VPP_METRICS_FETCH = 'vpps/VPP_METRICS_FETCH';
const VPP_METRICS_FETCH_FAILED = 'vpps/VPP_METRICS_FETCH_FAILED';
const VPP_METRICS_FETCH_SUCCEEDED = 'vpps/VPP_METRICS_FETCH_SUCCEEDED';
const VPP_METRICS_SHOW_WINDOW = 'vpps/VPP_METRICS_SHOW_WINDOW';

const showMetricsWindow = ({
    vppId,
    duration,
    anchor,
} = {}) => ({
    type: VPP_METRICS_SHOW_WINDOW,
    payload: {
        vppId,
        duration,
        anchor,
    },
});

const UPDATE_PERFORMANCE_DISPLAY = 'vpps/UPDATE_PERFORMANCE_DISPLAY';
const updatePerformanceDisplay = ({
    vppId,
    performanceDisplay,
}) => ({
    type: UPDATE_PERFORMANCE_DISPLAY,
    payload: {
        vppId,
        performanceDisplay,
    },
});

const GET_ENERGY_HISTORY = 'vpps/GET_ENERGY_HISTORY';
const GET_ENERGY_HISTORY_SUCCESS = 'vpps/GET_ENERGY_HISTORY_SUCCESS';
const fetchEnergyHistory = vppId => ({
    type: GET_ENERGY_HISTORY,
    payload: {
        vppId,
    },
});

const GET_POWER_HISTORY = 'vpps/GET_POWER_HISTORY';
const GET_POWER_HISTORY_SUCCESS = 'vpps/GET_POWER_HISTORY_SUCCESS';
const fetchPowerHistory = vppId => ({
    type: GET_POWER_HISTORY,
    payload: {
        vppId,
    },
});

const GET_BASELINE_ENERGY = 'vpps/GET_BASELINE_ENERGY';
const GET_BASELINE_ENERGY_SUCCESS = 'vpps/GET_BASELINE_ENERGY_SUCCESS';
const fetchBaselineEnergy = vppId => ({
    type: GET_BASELINE_ENERGY,
    payload: {
        vppId,
    },
});

const POLL_VPP_STATE = 'vpps/POLL_VPP_STATE';
const STOP_POLLING_VPP_STATE = 'vpps/STOP_POLLING_VPP_STATE';
const startPollingVppState = vppId => ({
    type: POLL_VPP_STATE,
    payload: {
        vppId,
    },
});
const stopPollingVPPState = () => ({
    type: STOP_POLLING_VPP_STATE,
    payload: {},
});

const GET_VPP_STATE = 'vpps/GET_VPP_STATE';
const UPDATE_VPP_STATE = 'vpps/UPDATE_VPP_STATE';
const fetchVppState = vppId => ({
    type: GET_VPP_STATE,
    payload: {
        vppId,
    },
});

const GET_DEVICE_COUNT = 'vpps/GET_DEVICE_COUNT';
const GET_DEVICE_COUNT_SUCCESS = 'vpps/GET_DEVICE_COUNT_SUCCESS';
const fetchDeviceCount = vppId => ({
    type: GET_DEVICE_COUNT,
    payload: {
        vppId,
    },
});

const UPDATE_VPP_MODE = 'vpps/UPDATE_VPP_MODE';
const UPDATE_VPP_MODE_SUCCESS = 'vpps/UPDATE_VPP_MODE_SUCCESS';
const updateVppMode = (vppId, mode) => ({
    type: UPDATE_VPP_MODE,
    payload: {
        mode,
        vppId,
    },
});

const UPDATE_TARGET_KW = 'vpps/UPDATE_TARGET_KW';
const updateTargetKW = (vppId, targetKW) => ({
    type: UPDATE_TARGET_KW,
    payload: {
        targetKW,
        vppId,
    },
});

const UPDATE_NEXT_PEAK_EVENT = 'vpps/UPDATE_NEXT_PEAK_EVENT';
const GET_PEAK_INFO = 'vpps/GET_PEAK_INFO';
const GET_PEAK_INFO_SUCCESS = 'vpps/GET_PEAK_INFO_SUCCESS';

const UPDATE_IS_LOADING = 'vpps/UPDATE_IS_LOADING';
const updateIsLoading = (vppId, loading) => ({
    type: UPDATE_IS_LOADING,
    payload: {
        vppId,
        loading,
    },
});

const FETCH_VPP_CONFIG = 'vpps/FETCH_VPP_CONFIG';
const FETCH_VPP_CONFIG_SUCCESS = 'vpps/FETCH_VPP_CONFIG_SUCCESS';
const FETCH_VPP_CONFIG_FAILURE = 'vpps/FETCH_VPP_CONFIG_FAILURE';
const fetchVppConfig = vppId => ({
    type: FETCH_VPP_CONFIG,
    payload: {
        vppId,
    },
});

const FETCH_VPP_VALUE_SUCCESS = 'vpps/FETCH_VPP_VALUE_SUCCESS';

const UPDATE_ACTIVE_VPP = 'vpps/UPDATE_ACTIVE_VPP';
const updateActiveVpp = vppId => ({
    type: UPDATE_ACTIVE_VPP,
    payload: {
        vppId,
    },
});
const ACTIVE_VPP_CHANGED = 'vpps/ACTIVE_VPP_CHANGED';

const GET_VPP_MODE_HISTORY = 'vpps/GET_VPP_MODE_HISTORY';
const GET_VPP_MODE_HISTORY_SUCCESS = 'vpps/GET_VPP_MODE_HISTORY_SUCCESS';
const getVppModeHistory = vppId => ({
    type: GET_VPP_MODE_HISTORY,
    payload: {
        vppId,
    },
});

export {
    FETCH_VPPS,
    FETCH_VPPS_FAILED,
    FETCH_VPPS_SUCCEEDED,
    fetchVPPs,
    FETCH_DATA_FOR_VPPS,
    FETCH_DATA_FOR_VPPS_FAILED,
    FETCH_DATA_FOR_VPPS_SUCCEEDED,
    fetchDataForVpps,
    VPP_FETCH,
    VPP_FETCH_FAILED,
    VPP_FETCH_SUCCEEDED,
    fetchVPPInfo,
    VPP_METRICS_FETCH,
    VPP_METRICS_FETCH_FAILED,
    VPP_METRICS_FETCH_SUCCEEDED,
    VPP_METRICS_SHOW_WINDOW,
    showMetricsWindow,
    UPDATE_PERFORMANCE_DISPLAY,
    updatePerformanceDisplay,
    GET_ENERGY_HISTORY,
    GET_ENERGY_HISTORY_SUCCESS,
    fetchEnergyHistory,
    GET_POWER_HISTORY,
    GET_POWER_HISTORY_SUCCESS,
    fetchPowerHistory,
    GET_BASELINE_ENERGY,
    GET_BASELINE_ENERGY_SUCCESS,
    fetchBaselineEnergy,
    POLL_VPP_STATE,
    STOP_POLLING_VPP_STATE,
    startPollingVppState,
    stopPollingVPPState,
    GET_VPP_STATE,
    UPDATE_VPP_STATE,
    fetchVppState,
    GET_DEVICE_COUNT,
    GET_DEVICE_COUNT_SUCCESS,
    fetchDeviceCount,
    UPDATE_VPP_MODE,
    UPDATE_VPP_MODE_SUCCESS,
    updateVppMode,
    UPDATE_TARGET_KW,
    updateTargetKW,
    UPDATE_NEXT_PEAK_EVENT,
    GET_PEAK_INFO,
    GET_PEAK_INFO_SUCCESS,
    UPDATE_IS_LOADING,
    updateIsLoading,
    FETCH_VPP_CONFIG,
    FETCH_VPP_CONFIG_SUCCESS,
    FETCH_VPP_CONFIG_FAILURE,
    fetchVppConfig,
    FETCH_VPP_VALUE_SUCCESS,
    UPDATE_ACTIVE_VPP,
    updateActiveVpp,
    ACTIVE_VPP_CHANGED,
    GET_VPP_MODE_HISTORY,
    GET_VPP_MODE_HISTORY_SUCCESS,
    getVppModeHistory,
};
