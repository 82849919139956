import {
    DOWNLOAD_CSV_DATA_SUCCESS,
    GET_API_DOCS_URL_SUCCESS,
    GET_API_DOCS_URL_FAILURE,
} from './actions';

const NO_URL = Symbol('no-url');
const LOADING_URL = Symbol('loading-url');

const INITIAL_STATE = {
    csv: {},
    apiDocsUrl: LOADING_URL,
};

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case DOWNLOAD_CSV_DATA_SUCCESS: {
            const {
                id,
                data,
                filename,
            } = action.payload;
            return {
                ...state,
                csv: {
                    ...state.csv,
                    id,
                    content: data,
                    filename,
                },
            };
        }
        case GET_API_DOCS_URL_SUCCESS: {
            const {
                url,
            } = action.payload;
            return {
                ...state,
                apiDocsUrl: url,
            };
        }
        case GET_API_DOCS_URL_FAILURE: {
            return {
                ...state,
                apiDocsUrl: NO_URL,
            };
        }
        default: return state;
    }
}

export {
    reducer,
    NO_URL,
    LOADING_URL,
};
