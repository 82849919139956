import React, {
    useEffect,
} from 'react';
import {
    connect,
} from 'react-redux';
import Highlight from 'react-syntax-highlight';
import {
    GET_DEVICE_SHADOW,
} from '../../models/devices/actions';

const Shadow = ({
    deviceId,
    getShadow,
    shadow = null,
    shadowMetadata = null,
}) => {
    useEffect(() => {
        getShadow(deviceId);
    }, []);
    return (
        <div style={{ overflowY: 'hidden' }}>
            <p>Shadow state</p>
            <DisplayJSON data={shadow} />
            <p>Shadow metadata</p>
            <DisplayJSON data={shadowMetadata} />
        </div>
    );
};

const DisplayJSON = ({ data }) => {
    if (!data) return <p>Not found</p>;
    return (
        <Highlight lang="javascript" value={JSON.stringify(data, null, 2)} />
    );
};

const mapStateToProps = (state, props) => {
    const {
        deviceId,
    } = props;
    const {
        shadow = {},
    } = state.devices.devices[deviceId] || {};
    return {
        deviceId,
        shadow: shadow.state,
        shadowMetadata: shadow.metadata,
    };
};

const mapDispatchToProps = dispatch => ({
    getShadow: deviceId => dispatch({
        type: GET_DEVICE_SHADOW,
        payload: {
            deviceId,
        },
    }),
});

const ShadowContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Shadow);

export {
    ShadowContainer,
};
