import React from 'react';
import {
    dateFormatter,
} from '../../utils';
import {
    getCurrentTimezone,
} from '../../../utils/time';
import {
    PeakEventViewWrapper,
} from '../../components/peak-event-view-wrapper';
import {
    FuturePeakEventContainer,
} from '../containers/future-peak-event-container';
import {
    NewFuturePeakEvent,
} from './new-future-peak-event';
import {
    PeakListPaginator,
} from '../../components/peak-list-paginator';

export const FuturePeakEventList = ({
    newPeak,
    events,
    hideNewPeakForm,
    refresh,
    vppId,
    lowerTS,
    upperTS,
    nextPage,
    previousPage,
}) => {
    const timezone = getCurrentTimezone();
    // typeof events === 'undefined' || events === null could be simplified to
    // events == null, but this is more explicit
    const noPeaksMessage = newPeak ? null : (
        <div className='row'>
            <div className='col-12 peak-scheduler__peak'>
                {`${typeof events === 'undefined' || events === null ? 'Fetching' : 'No'} peaks scheduled between ${dateFormatter(lowerTS)} and ${dateFormatter(upperTS)}`}
            </div>
        </div>
    );
    return (
        <div className="peak-scheduler__peak-list">
            <PeakEventViewWrapper
                columns={[
                    'Peak State',
                    `Start (${timezone})`,
                    `End (${timezone})`,
                    'Send Push Notification',
                ]}
                className='peak-scheduler__peak--header'
            />
            {newPeak && (
                <NewFuturePeakEvent
                    vppId={vppId}
                    {...newPeak}
                    onCancel={() => hideNewPeakForm(true)}
                    onDone={() => hideNewPeakForm(false)}
                    refresh={refresh}
                />
            )}
            {events && events.length
                ? events.map(e => (
                    <FuturePeakEventContainer
                        key={e.startTS}
                        peakId={e.startTS}
                        vppId={vppId}
                        {...e}
                        refresh={refresh}
                    />
                ))
                : noPeaksMessage
            }
            <div className='row justify-content-center peak-scheduler__pagination-container'>
                <PeakListPaginator
                    lowerTS={lowerTS}
                    upperTS={upperTS}
                    next={nextPage}
                    previous={previousPage}
                />
            </div>
        </div>
    );
};
