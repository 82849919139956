import React, { Fragment } from 'react';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';
import {
    Icon,
} from '../../../../lib/font-awesome';
import {
    formatTimestamp,
} from '../../../utils';

const AlertCard = ({
    alertType,
    decodedAlertType,
    message,
    resolvedAt,
    lastSeenAt,
    alertCount,
    resolvedBy,
    createdBy,
    displayToUser,
    displayName,
    resolveAlert,
    unresolveAlert,
    deviceId,
    conversationUri,
    acknowledgedAt,
    acknowledgeAlert,
    otherConversationUris = [],
    showDeviceId = true,
    allowEdit,
    toggleEditing,
    showOptions,
}) => (
    <div css={styles.card} className="device-alert__alert-card">
        <div className="row" css={styles.alertBody}>
            <div className="col">
                <div className="row justify-content-between no-gutters">
                    <h4 css={styles.alertHeader}>
                        {showDeviceId
                            ? (
                                <Link to={`/devices/${deviceId}`}>
                                    {deviceId}
                                    &nbsp;-&nbsp;
                                    {alertType}
                                </Link>
                            )
                            : (decodedAlertType || alertType)
                        }
                    </h4>
                    {allowEdit && (
                        <AlertButton
                            title="Edit"
                            onClick={toggleEditing}
                            style={{ marginRight: 0 }}
                        />
                    )}
                </div>
                {displayToUser && (
                    <p css={styles.displayToUserAlert}>
                        <Icon hint="eye" />
                        {` Visible to device owner as "${displayName || alertType}"`}
                    </p>
                )}
                <AlertRow label="Last Seen At">{formatTimestamp(lastSeenAt)}</AlertRow>
                {
                    alertCount > 1 ? (
                        <AlertRow label="Count">{alertCount}</AlertRow>
                    ) : null
                }
                <AlertRow label="Created by">{createdBy}</AlertRow>
                <AlertRow label="Details">
                    <div>
                        {message}
                    </div>
                    {
                        conversationUri ? (
                            <Fragment>
                                <DiscussionLink conversationUri={conversationUri} />
                                {
                                    otherConversationUris
                                        ? otherConversationUris.map(uri => <DiscussionLink key={`discussion-${uri}`} conversationUri={uri} />)
                                        : null
                                }
                            </Fragment>
                        ) : null
                    }
                </AlertRow>
                <AlertRow label="Status">
                    <Status
                        acknowledgedAt={acknowledgedAt}
                        resolvedAt={resolvedAt}
                        resolvedBy={resolvedBy}
                    />
                </AlertRow>
            </div>
        </div>
        <div className="row">
            {showOptions
                && (
                    <div className="col">
                        <AlertButton
                            styleClass={acknowledgedAt ? 'btn-outline-dark' : 'btn-dark'}
                            title={acknowledgedAt ? 'Acknowledged' : 'Acknowledge'}
                            disabled={!!acknowledgedAt}
                            onClick={acknowledgeAlert}
                        />
                        <AlertButton
                            styleClass={resolvedAt ? 'btn-outline-dark' : 'btn-dark'}
                            title={resolvedAt ? 'Unresolve' : 'Resolve'}
                            onClick={() => {
                                if (resolvedAt) {
                                    unresolveAlert();
                                } else {
                                    resolveAlert();
                                }
                            }}
                        />
                    </div>
                )
            }
        </div>
    </div>
);

const AlertRow = ({
    label,
    children = null,
}) => (
    <div className="row" css={styles.alertRow}>
        <div className="col-12 col-lg-3" css={styles.alertLabel}>{label}</div>
        <div className="col-12 col-lg-9">{children}</div>
    </div>
);

const Status = ({
    acknowledgedAt,
    resolvedAt,
    resolvedBy,
}) => {
    if (resolvedAt) {
        return (
            <div>
                Resolved by&nbsp;
                {resolvedBy}
                &nbsp;
                {new Date(resolvedAt).toLocaleString()}
            </div>
        );
    }
    if (acknowledgedAt) {
        return (
            <div>
                Acknowledged at&nbsp;
                {new Date(acknowledgedAt).toLocaleString()}
            </div>
        );
    }
    return 'In alarm';
};

const DiscussionLink = ({
    conversationUri,
}) => (
    <div>
        <a target="_blank" rel="noopener noreferrer" href={conversationUri}>{conversationUri}</a>
    </div>
);

const AlertButton = ({
    styleClass = 'btn-outline-dark',
    title,
    onClick,
    ...props
}) => (
    <button
        className={`btn ${styleClass}`}
        css={styles.alertButton}
        onClick={(event) => {
            event.preventDefault();
            onClick();
        }}
        {...props}
    >
        {title}
    </button>
);

const styles = {
    discussionLinkList: css`
        list-style-type: circle;
        margin: 0 0 1em;
    `,
    card: css`
        margin-bottom: 1em;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 1em;
    `,
    alertHeader: css`
        display: inline-block;
    `,
    alertBody: css`
        margin-bottom: 1em;
    `,
    alertButton: css`
        margin-right: 1em;
    `,
    alertRow: css`
        margin-bottom: .5em;
        overflow-wrap: break-word;
    `,
    alertLabel: css`
        font-variant: small-caps;
    `,
    displayToUserAlert: css`
        color: orange;
        margin-bottom: 0.5em;
    `,
};

export {
    AlertCard,
    AlertButton,
};
