import {
    VPPApiClient,
} from '../../VPPApiClient';
import {
    NyleError,
} from '../../utils/errors';

export class VPPStatsDAO {
    static inject() { return [VPPApiClient]; }

    constructor(httpClient) {
        Object.defineProperty(this, 'httpClient', {
            value: httpClient,
            writable: false,
        });

        this.getStats = ({ vppId }) => this.httpClient.get(`./vpps/${vppId}/stats`).then(res => res.body);

        this.getVppValue = async ({
            vppId,
            lowerTS = null,
            upperTS = null,
        }) => {
            let url = `/vpps/${vppId}/stats/value`;
            if (lowerTS && upperTS) {
                url += `?lowerTS=${lowerTS}&upperTS=${upperTS}`;
            }
            const {
                body,
                status,
            } = await this.httpClient.get(url);
            if (status !== 200) throw new NyleError('Failed to fetch VB value');
            return body;
        };

        this.getModeHistory = async ({
            vppId,
            lowerTS,
            upperTS,
        }) => {
            const url = `/vpps/${vppId}/stats/modes?lowerTS=${lowerTS}&upperTS=${upperTS}`;
            const {
                body,
                status,
            } = await this.httpClient.get(url);
            if (status !== 200) throw new NyleError('Failed to fetch VB mode history');
            return body;
        };
    }
}
