const PRODUCT_TYPES = [
    {
        name: '(show all)',
        type: 'all',
        version: 'all',
    },
    {
        name: 'Mello (all)',
        type: 'Mello',
        version: 'all',
    },
    {
        name: 'Mello 1',
        type: 'Mello',
        version: '1',
    },
    {
        name: 'Mello 1.5',
        type: 'Mello',
        version: '1.5',
    },
    {
        name: 'TurboDX-Adapter',
        type: 'TurboDX-Adapter',
        version: 'all',
    },
    {
        name: 'Nyle',
        type: 'Nyle',
        version: 'all',
    },
];

const CONNECTION_STATUS_SETTINGS = [
    {
        value: {
            deploymentStatus: '',
            connectionStatus: '',
        },
        name: 'All devices',
    },
    {
        value: {
            deploymentStatus: 'installed',
            connectionStatus: '',
        },
        name: 'Online + Offline',
    },
    {
        value: {
            deploymentStatus: 'installed',
            connectionStatus: 'online',
        },
        name: 'Online',
    },
    {
        value: {
            deploymentStatus: 'installed',
            connectionStatus: 'offline',
        },
        name: 'Offline',
    },
    {
        value: {
            deploymentStatus: 'installed',
            connectionStatus: 'offline-unacknowledged',
        },
        name: 'Offline (unacknowledged)',
    },
    {
        value: {
            deploymentStatus: 'uninstalled',
            connectionStatus: '',
        },
        name: 'Uninstalled',
    },
];

const MELLO_CODES_TO_EVENTS = {
    10: 'mech-off-percent-too-high',
    20: 'mech-off-rate-too-high',
    30: 'above-safe-high',
    35: 'upper-temp-too-hot',
    36: 'temp-diff-too-high',
    40: 'thermostat-unknown-state',
    45: 'pem-unknown-state',
    50: 'unknown-op-mode',
    55: 'relay-unknown-state',
    60: 'sensor-failure-unknown-state',
    70: 'mello-sensors-failed',
    75: 'mello-meter-failed',
    80: 'too-many-bad-power-readings',
};

const GENERAL_DEVICE_ALERT_TYPES = [
    'bad-install',
    'faulty-hardware',
    'offline',
];

const MELLO_ALERT_TYPES = [
    ...GENERAL_DEVICE_ALERT_TYPES,
    'water-too-hot',
    'water-too-cold',
    'leak',
    'screen-frozen',
];

const getProductId = ({
    productType,
}) => (productType || 'other');

const getAlertTypes = ({
    deviceType,
    productType,
}) => {
    const productId = getProductId({
        deviceType,
        productType,
    });
    switch (productId) {
        case 'mello': return MELLO_ALERT_TYPES;
        default: return GENERAL_DEVICE_ALERT_TYPES;
    }
};

const getDeviceEventDecoder = ({
    deviceType,
    productType,
}) => {
    const codeMap = (getProductId({
        deviceType,
        productType,
    }) === 'mello')
        ? MELLO_CODES_TO_EVENTS
        : {};
    return (alert) => {
        if (alert.createdBy !== 'iot-rule') return alert;
        // eslint-disable-next-line no-param-reassign
        alert.decodedAlertType = codeMap[alert.alertType];
        return alert;
    };
};

export {
    PRODUCT_TYPES,
    CONNECTION_STATUS_SETTINGS,
    MELLO_CODES_TO_EVENTS,
    GENERAL_DEVICE_ALERT_TYPES,
    MELLO_ALERT_TYPES,
    getAlertTypes,
    getDeviceEventDecoder,
};
