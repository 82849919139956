import React, {
    useEffect,
} from 'react';
import {
    connect,
} from 'react-redux';
import {
    Graph,
    StackedGraph,
} from './base-graphs';
import {
    colors,
} from '../../styles/colors';
import {
    fetchDeviceGraphData,
} from '../../models/devices/actions';
import {
    userHasRole,
    userIsNyleAdmin,
} from '../../models/user/selectors';
import {
    formatHeatmaps,
} from './mode-series';
import {
    withDateRange,
} from '../date-range';

const EnergyGraphView = ({
    energyData,
    getData,
    startTS,
    endTS,
    debugData = [],
    connectionsData = [],
    showModeSeries,
    debugConfig,
    legend,
}) => {
    const formattedCSVData = React.useMemo(() => energyData.map(data => ({
        timestamp: data.bucket,
        kWh: data.kWh,
    })), [energyData]);

    useEffect(() => {
        getData(showModeSeries);
    }, [startTS, endTS]);
    const {
        bucketSizeMS,
        readable,
    } = getBucketSize(energyData);
    const graphData = [formatTrace(energyData, bucketSizeMS)];
    if (showModeSeries) {
        const {
            modeData,
            modeScales,
        } = formatHeatmaps({
            debugData,
            debugConfig,
            connectionsData,
            endTS,
        });
        return (
            <div>
                <StackedGraph
                    exportData={formattedCSVData}
                    legend={legend}
                    upperChartData={graphData}
                    lowerChartData={modeData}
                    yAxisTitleUpper={`Energy (kWh per ${readable})`}
                    xDomain={[startTS, endTS]}
                />
                {modeScales}
            </div>
        );
    }
    return (
        <Graph
            exportData={energyData}
            legend={legend}
            data={graphData}
            yAxisTitle={`Energy (kWh per ${readable})`}
            xDomain={[startTS, endTS]}
        />
    );
};

const FIVE_MINUTES_MS = 1000 * 60 * 5;
const FIFTEEN_MINUTES_MS = FIVE_MINUTES_MS * 3;
const ONE_HOUR_MS = FIFTEEN_MINUTES_MS * 4;
const SIX_HOURS_MS = ONE_HOUR_MS * 6;
const ONE_DAY_MS = ONE_HOUR_MS * 24;

function formatTrace(data, bucketSizeMS = FIVE_MINUTES_MS) {
    /* eslint-disable no-shadow, no-param-reassign */
    const {
        x,
        y,
    } = data.reduce(({
        x,
        y,
        lastBucketMS,
    }, d) => {
        const bucket = getBucket(d.bucket, bucketSizeMS);
        if (bucket !== lastBucketMS) {
            x.push(bucket + (bucketSizeMS / 2));
            y.push(d.kWh);
            return {
                x,
                y,
                lastBucketMS: bucket,
            };
        }
        y[y.length - 1] += d.kWh;
        return {
            x,
            y,
            lastBucketMS,
        };
    }, {
        x: [],
        y: [],
        lastBucketMS: 0,
        lastObs: 0,
    });
    /* eslint-enable */
    return {
        x,
        y,
        type: 'bar',
        name: 'Energy',
        marker: {
            color: colors.primaryBlue,
            line: {
                color: 'white',
                width: 1,
            },
        },
        width: new Array(data.length).fill(bucketSizeMS),
    };
}

function getBucketSize(data) {
    if (data.length <= 1) {
        return {
            bucketSizeMS: FIVE_MINUTES_MS,
            readable: 'five minutes',
        };
    }
    const diffMS = data[data.length - 1].bucket - data[0].bucket;
    if (diffMS < ONE_HOUR_MS) {
        return {
            bucketSizeMS: FIVE_MINUTES_MS,
            readable: 'five minutes',
        };
    }
    if (diffMS < SIX_HOURS_MS) {
        return {
            bucketSizeMS: FIFTEEN_MINUTES_MS,
            readable: 'fifteen minutes',
        };
    }
    if (diffMS < ONE_DAY_MS * 3) {
        return {
            bucketSizeMS: ONE_HOUR_MS,
            readable: 'hour',
        };
    }
    return {
        bucketSizeMS: ONE_DAY_MS,
        readable: 'day',
    };
}

function getBucket(timestamp, bucketSizeMS) {
    return Math.floor(timestamp / bucketSizeMS) * bucketSizeMS;
}

const mapStateToProps = (state, props) => {
    const {
        deviceId,
    } = props;
    const {
        energyData,
        graphRange,
        debugData,
        connectionsData,
        device: {
            debugConfig,
        },
    } = state.devices.devices[deviceId];
    return {
        energyData,
        debugData,
        connectionsData,
        showModeSeries: userHasRole(state, 'oem') || userIsNyleAdmin(state),
        debugConfig,
        ...graphRange,
    };
};

const mapDispatchToProps = (dispatch, props) => ({
    getData: (showModeSeries) => {
        dispatch(fetchDeviceGraphData({
            deviceId: props.deviceId,
            dataType: 'energy',
        }));
        dispatch(fetchDeviceGraphData({
            deviceId: props.deviceId,
            dataType: 'connections',
        }));
        if (showModeSeries) {
            dispatch(fetchDeviceGraphData({
                deviceId: props.deviceId,
                dataType: 'debug',
            }));
        }
    },
});

const EnergyGraph = withDateRange(connect(
    mapStateToProps,
    mapDispatchToProps,
)(EnergyGraphView));

export {
    EnergyGraph,
};
