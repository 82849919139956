import React from 'react';
import {
    DateTimePicker,
} from '../../../lib/react-widgets';
import {
    Button,
} from './form';

const DateRange = ({
    startTS,
    setStartDate,
    endTS,
    setEndDate,
    showInvalidDateError = () => {},
    dividerText = 'to',
    shortcutButtonTitle = 'Now',
    shortcutButtonHandler = null,
    additionalButtons = null,
    disabled = false,
}) => (
    <div className="row" style={{ padding: '10px 0px' }}>
        <div className="col-sm">
            <DateTimePicker
                value={new Date(startTS)}
                onChange={(date) => {
                    if (date) setStartDate(date.getTime());
                    else showInvalidDateError('start');
                }}
                showTimezone={false}
                disabled={disabled}
                style={{ minWidth: 225 }}
            />
        </div>
        <div className="col-xs" style={{ paddingTop: 8 }}>
            <span>{dividerText}</span>
        </div>
        <div className="col-sm">
            <DateTimePicker
                value={new Date(endTS)}
                onChange={(date) => {
                    if (date) setEndDate(date.getTime());
                    else showInvalidDateError('end');
                }}
                showTimezone={false}
                disabled={disabled}
                style={{ minWidth: 200 }}
            />
        </div>
        <div className="col-xs">
            <Button
                title={shortcutButtonTitle}
                onClick={() => {
                    if (shortcutButtonHandler) {
                        shortcutButtonHandler();
                        return;
                    }
                    setEndDate(Date.now());
                }}
                disabled={disabled}
            />
        </div>
        {additionalButtons
            ? (
                <div className="col-xs">
                    {additionalButtons}
                </div>
            ) : null
        }
    </div>
);

export {
    DateRange,
};
