import React, {
    useEffect,
    useState,
} from 'react';
import {
    connect,
} from 'react-redux';
import DropdownList from 'react-widgets/lib/DropdownList';
import Highlight from 'react-syntax-highlight';

import {
    LIST_RPCS,
    CALL_RPC,
} from '../../models/devices/actions';

const RPCs = ({
    deviceId,
    rpcList,
    getRPCList,
    callRpc,
    rpcResponse,
}) => {
    useEffect(() => {
        getRPCList(deviceId);
    }, []);

    const [selectedRpc, setSelectedRpc] = useState(null);
    const [rawArgs, setRawArgs] = useState('');
    const [args, setArgs] = useState(null);
    const [argParseFailure, setArgParseFailure] = useState(false);

    useEffect(() => {
        try {
            setArgs(!rawArgs || JSON.parse(rawArgs));
            setArgParseFailure(false);
        } catch (err) {
            setArgs(null);
            setArgParseFailure(true);
        }
    }, [rawArgs]);

    return (
        <>
            <form>
                <div className="form-group">
                    <label>RPC</label>
                    <DropdownList
                        filter
                        data={rpcList}
                        textField={item => item}
                        onSelect={setSelectedRpc}
                        style={{ width: '55%' }}
                    />
                </div>
                <div className="form-group">
                    <label>Arguments</label>
                    <textarea
                        className="form-control"
                        rows="3"
                        value={rawArgs}
                        onChange={(event) => {
                            event.preventDefault();
                            setRawArgs(event.target.value || '');
                        }}
                    />
                    { argParseFailure && (
                        <small className="form-text text-muted">Arguments must be valid JSON</small>
                    )}
                </div>
                <button
                    type="submit"
                    className="btn btn-outline-dark"
                    disabled={!selectedRpc || argParseFailure}
                    onClick={(event) => {
                        event.preventDefault();
                        callRpc({
                            deviceId,
                            method: selectedRpc,
                            args,
                        });
                    }}
                >
                    Send RPC
                </button>
            </form>
            {
                rpcResponse && (
                    <>
                        <br />
                        <h5>Response</h5>
                        <Highlight lang="json" value={JSON.stringify(rpcResponse, null, 2)} />
                    </>
                )
            }
        </>
    );
};

const mapStateToProps = (state, props) => {
    const {
        deviceId,
    } = props;
    const {
        rpcList,
        rpcResponse,
    } = state.devices || {};
    return {
        deviceId,
        rpcList,
        rpcResponse,
    };
};

const mapDispatchToProps = dispatch => ({
    getRPCList: deviceId => dispatch({
        type: LIST_RPCS,
        payload: {
            deviceId,
        },
    }),
    callRpc: payload => dispatch({
        type: CALL_RPC,
        payload,
    }),
});

const RPCContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(RPCs);

export {
    RPCContainer,
};
