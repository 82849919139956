import React from 'react';
import {
    DropdownRow,
    InputRow,
} from './settings-rows';

const AlertSetting = ({
    alertOptions,
    title,
    alertData,
    alertName,
    updateUserSetting,
}) => (
    <>
        <h2>{title}</h2>
        <table className="table device-detail-table">
            <tbody>
                <DropdownRow
                    label='Register'
                    value={alertData?.register}
                    disabled={alertData?.registerNumber > 0}
                    handler={value => updateUserSetting(`alerts.${alertName}.register`, value)}
                    data={alertOptions}
                    buttonStyleClass='btn-outline-dark'
                />
                <InputRow
                    title='Register override'
                    value={alertData?.registerNumber}
                    handler={value => updateUserSetting(`alerts.${alertName}.registerNumber`, Number(value))}
                    inputProps={{
                        type: 'number',
                        min: 0,
                    }}
                    buttonStyleClass='btn-outline-dark'
                />
                <InputRow
                    title='Lower Bound'
                    value={alertData?.lower}
                    handler={value => updateUserSetting(`alerts.${alertName}.lower`, Number(value))}
                    inputProps={{
                        type: 'number',
                        min: 0,
                        max: 65535,
                    }}
                    buttonStyleClass='btn-outline-dark'
                />
                <InputRow
                    title='Upper Bound'
                    value={alertData?.upper}
                    handler={value => updateUserSetting(`alerts.${alertName}.upper`, Number(value))}
                    inputProps={{
                        type: 'number',
                        min: 0,
                        max: 65535,
                    }}
                    buttonStyleClass='btn-outline-dark'
                />
            </tbody>
        </table>
    </>
);

export default AlertSetting;
