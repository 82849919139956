import React from 'react';
import {
    connect,
} from 'react-redux';
import {
    ScheduledPeaksContainer,
} from './scheduled-peaks/scheduled-peaks-container';
import {
    ScheduledPeaks,
} from './scheduled-peaks/scheduled-peaks-view';
import {
    DonePeaksContainer,
} from './done-peaks/done-peaks-container';
import {
    DonePeaks,
} from './done-peaks/done-peaks-view';
import './peak-scheduler.css';

const PeakSchedulerView = () => (
    <div className="container">
        <div className="peak-scheduler--past-peaks">
            <ScheduledPeaksContainer render={props => <ScheduledPeaks {...props} />} />
            <DonePeaksContainer render={props => <DonePeaks {...props} />} />
        </div>
    </div>
);

const mapStateToProps = (state) => {
    const {
        vpps: {
            activeVpp,
        },
    } = state;
    const {
        name,
    } = state.vpps.vpps[activeVpp];
    return {
        vbName: name,
        vppId: activeVpp,
    };
};

const mapDispatchToProps = () => ({});

const PeakScheduler = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PeakSchedulerView);

export {
    PeakScheduler,
};
