import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import { DownloadView } from './download-view';
import {
    downloadCSVData,
} from '../models/download/actions';
import {
    createAlert,
} from '../models/alerts/actions';

class DownloadContainer extends Component {
    componentDidMount() {
        const defaultEndDate = moment().startOf('hour');
        const defaultStartDate = defaultEndDate.clone().add(-12, 'h');

        this.setState({
            defaultStartDate: defaultStartDate.toDate(),
            defaultEndDate: defaultEndDate.toDate(),
        });
    }

    async onDownload({
        startDate,
        endDate,
        tz = 'UTC',
        type = 'energy',
        interval = 'sixty-minutes',
    }) {
        const {
            getData,
            vppId,
        } = this.props;
        const filename = formatFilename({
            startDate,
            endDate,
            type,
            tz,
        });
        getData({
            vppId,
            startTS: startDate.valueOf(),
            endTS: endDate.valueOf(),
            tz,
            interval,
            filename,
        });
    }

    render() {
        const {
            timezone,
            csv,
            showSuccessAlert,
        } = this.props;
        if (!this.state) return null;

        const {
            defaultStartDate,
            defaultEndDate,
        } = this.state;

        return (
            <DownloadView
                defaultStartDate={defaultStartDate}
                defaultEndDate={defaultEndDate}
                csv={csv}
                onDownload={params => this.onDownload(params)}
                timezone={timezone}
                showSuccessAlert={showSuccessAlert}
            />
        );
    }
}

function formatFilename({
    startDate,
    endDate,
    type,
    tz,
}) {
    const format = 'YYYYMMDDHHmmssZZ';
    const start = moment.tz(startDate, tz).format(format);
    const end = moment.tz(endDate, tz).format(format);
    return `${start}-${end}-${type}.csv`;
}

const mapStateToProps = (state, props) => {
    const {
        activeVpp: vppId,
    } = state.vpps;
    const {
        csv,
    } = state.download;
    const {
        timezone = 'UTC',
    } = state.vpps.vpps[vppId];

    return {
        vppId,
        timezone,
        csv,
        ...props,
    };
};

const mapDispatchToProps = dispatch => ({
    getData: params => dispatch(downloadCSVData(params)),
    showSuccessAlert: () => dispatch(createAlert({
        type: 'download',
        message: 'Data downloaded',
        level: 'success',
    })),
});

const DownloadPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DownloadContainer);

export {
    DownloadPage,
};
