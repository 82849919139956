import {
    CREATE_ALERT,
    CLEAR_ALERT,
} from './actions';

const INITIAL_ALERTS_STATE = {
    operator: null,
    download: null,
};

function reducer(state = INITIAL_ALERTS_STATE, action) {
    switch (action.type) {
        case CREATE_ALERT: {
            const {
                type,
                message,
                level,
                action: actionType = null,
            } = action.payload;
            return {
                ...state,
                [type]: {
                    message,
                    level,
                    action: actionType,
                },
            };
        }
        case CLEAR_ALERT: {
            const { type } = action.payload;
            return {
                ...state,
                [type]: null,
            };
        }
        default: return state;
    }
}

export {
    reducer,
};
