import {
    BehaviorSubject,
} from 'rxjs/BehaviorSubject';

const NO_AUTH = 'NO_AUTH';
const WAITING = 'WAITING';
const PROFILE = 'PROFILE';

export class User {
    constructor(authenticator) {
        const observable = new BehaviorSubject({
            state: NO_AUTH,
            profile: null,
        });
        Object.defineProperties(this, {
            observable: {
                value: observable,
                writable: false,
            },
            authenticator: {
                value: authenticator,
                writable: false,
            },
        });
    }

    async authenticate() {
        this.observable.next({
            loadingState: WAITING,
            profile: null,
        });
        try {
            const profile = await this.authenticator.authenticate();
            this.observable.next({
                loadingState: PROFILE,
                profile,
            });
        } catch (err) {
            this.observable.next({
                loadingState: NO_AUTH,
                profile: null,
            });
        }
    }

    subscribe(subscription) {
        return this.observable.subscribe(subscription);
    }
}
