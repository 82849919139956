import React from 'react';
import { connect } from 'react-redux';
import {
    getAPIDocsUrl,
} from '../models/download/actions';
import {
    NO_URL,
    LOADING_URL,
} from '../models/download/reducers';

const errorMessage = 'Error getting download link. Please try reloading the page or contact info@packetizedenergy.com.';

class APIDocs extends React.Component {
    componentDidMount() {
        const {
            getDownloadUrl,
        } = this.props;
        getDownloadUrl();
    }

    render() {
        const {
            apiDocsUrl,
        } = this.props;
        return (
            <div className="container" style={{ width: '100%' }}>
                {apiDocsUrl === NO_URL
                    ? <p>{errorMessage}</p>
                    : (
                        <div>
                            <h2>API Documentation</h2>
                            <a
                                href={apiDocsUrl === LOADING_URL ? null : apiDocsUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button className="btn btn-dark">Download PDF</button>
                            </a>
                        </div>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        apiDocsUrl,
    } = state.download;

    return {
        apiDocsUrl,
    };
};

const mapDispatchToProps = dispatch => ({
    getDownloadUrl: () => dispatch(getAPIDocsUrl()),
});

const APIDocsPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(APIDocs);

export {
    APIDocsPage,
};
