/* globals localStorage */
import auth0 from 'auth0-js';
import jwtDecode from 'jwt-decode';

import {
    NyleError,
} from '../utils/errors';

const {
    REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_CLIENT_ID,
    REACT_APP_BASE_URL,
    REACT_APP_AUTH0_CLIENT_AUDIENCE,
} = process.env;

const ACCESS_TOKEN = 'accessToken';
const ID_TOKEN = 'idToken';
const EXPIRES_AT = 'expiresAt';

const DEFAULT_USER_INFO = {
    vpps: {},
};

export class Auth0Interface {
    constructor({
        client,
        now = () => Date.now(),
        storage = localStorage,
    } = {}) {
        const _client = client || new auth0.WebAuth({
            domain: REACT_APP_AUTH0_DOMAIN,
            clientID: REACT_APP_AUTH0_CLIENT_ID,
            redirectUri: `${REACT_APP_BASE_URL}/auth0/callback/`,
            audience: REACT_APP_AUTH0_CLIENT_AUDIENCE,
            responseType: 'token id_token',
            scope: 'openid',
        });
        Object.defineProperties(this, {
            auth0: {
                value: _client,
                writable: false,
            },
            now: {
                value: now,
                writable: false,
            },
            storage: {
                value: storage,
                writable: false,
            },
        });
        this.tokenRenewalTimeout = null;

        this.authenticate = () => new Promise((resolve, reject) => {
            try {
                this.getAccessToken();
                const idToken = jwtDecode(this.storage.getItem(ID_TOKEN));
                resolve(userInfoToProfile(idToken));
            } catch (err) {
                this.clearStorage();
                return void reject(err);
            }
        });

        this.isTokenFresh = () => {
            const expiresAt = JSON.parse(this.storage.getItem(EXPIRES_AT));
            return new Date().getTime() < expiresAt;
        };

        this.login = () => {
            this.auth0.authorize();
        };

        this.clearStorage = () => {
            this.storage.removeItem(ACCESS_TOKEN);
            this.storage.removeItem(ID_TOKEN);
            this.storage.removeItem(EXPIRES_AT);
            clearTimeout(this.tokenRenewalTimeout);
        };

        this.logout = async () => {
            this.clearStorage();
            // Sign out of Auth0 SSO
            await this.auth0.logout({
                returnTo: `${REACT_APP_BASE_URL}/#/login/`,
                client_id: REACT_APP_AUTH0_CLIENT_ID,
                federated: true,
            });
        };
    }

    authenticateFromHash(hash) {
        return new Promise((resolve, reject) => {
            this.auth0.parseHash({
                hash,
            }, (err, authResult) => {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    this.setSession(authResult);
                    return void resolve();
                }
                return void reject(err || new Error('Failed to authenticate from hash'));
            });
        });
    }

    setSession(authResult) {
        const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + this.now());
        this.storage.setItem(ACCESS_TOKEN, authResult.accessToken);
        this.storage.setItem(ID_TOKEN, authResult.idToken);
        this.storage.setItem(EXPIRES_AT, expiresAt);
    }

    getAccessToken() {
        const accessToken = this.storage.getItem(ACCESS_TOKEN);
        if (!accessToken) throw new NoTokenError();
        if (!this.isTokenFresh()) throw new ExpiredTokenError();
        return accessToken;
    }

    renewToken() {
        return new Promise((resolve, reject) => {
            this.auth0.checkSession({}, (err, session) => {
                if (err) {
                    this.clearStorage();
                    return void reject(err);
                }
                this.setSession(session);
                this.scheduleTokenRenewal();
            });
        });
    }

    scheduleTokenRenewal() {
        const expiresAt = JSON.parse(this.storage.getItem(EXPIRES_AT));
        const delay = expiresAt - this.now() - 5000;
        if (delay <= 0) return;
        this.tokenRenewalTimeout = setTimeout(() => {
            this.renewToken();
        }, delay);
    }

    static get errors() {
        return {
            ExpiredTokenError,
            NoProfileError,
            NoTokenError,
        };
    }
}

function userInfoToProfile(userInfo) {
    const namespace = 'https://nyle.io';
    const metadataNamespace = 'https://mgr.nyle.com';
    const userInfoUnderNamespace = userInfo[`${namespace}/profile`];
    const appData = `${metadataNamespace}/app_metadata` in userInfo ? userInfo[`${metadataNamespace}/app_metadata`] : [];
    const { devices = [] } = appData;
    return {
        ...(userInfoUnderNamespace || DEFAULT_USER_INFO),
        devices,
    };
}

class ExpiredTokenError extends NyleError {
    constructor() {
        super('Token is expired');
    }
}
class NoTokenError extends NyleError {
    constructor() {
        super('No token found');
    }
}
class NoProfileError extends NyleError {
    constructor() {
        super('Profile not found');
    }
}
