const CREATE_ALERT = 'alerts/CREATE_ALERT';
const CLEAR_ALERT = 'alerts/CLEAR_ALERT';

const createAlert = ({
    type,
    message,
    level,
    action,
}) => ({
    type: CREATE_ALERT,
    payload: {
        type,
        message,
        level,
        action,
    },
});

const clearAlert = type => ({
    type: CLEAR_ALERT,
    payload: {
        type,
    },
});

export {
    CREATE_ALERT,
    CLEAR_ALERT,
    createAlert,
    clearAlert,
};
