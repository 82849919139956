import React from 'react';

const TabPicker = ({
    values,
    handleChange,
    name,
    formatTabLabel = val => val,
    label,
}) => (
    <div>
        {label ? <h5 className='dtwp__inline'>{label}</h5> : null}
        <div className='btn-group btn-group-toggle dtwp__row' data-toggle='buttons'>
            {values.map(item => (
                <label
                    className={`btn btn-primary dtwp__window ${item.active ? 'active' : ''}`}
                    key={item.value}
                >
                    <input
                        type='radio'
                        name={name}
                        id={`${name}-${item.value}`}
                        defaultChecked={item.active}
                        onClick={() => handleChange(item.value)}
                    />
                    {formatTabLabel(item.value)}
                </label>
            ))}
        </div>
    </div>
);

export {
    TabPicker,
};
