const getFaultyDeviceFlag = (state, deviceId) => {
    const shadowState = state.devices.devices[deviceId]?.shadow?.state;
    if (!shadowState) return 'Unknown';
    const reportedFaulty = shadowState?.reported?.user?.faulty_device;
    const desiredFaulty = shadowState?.desired?.user?.faulty_device;

    if (desiredFaulty === reportedFaulty) return reportedFaulty ? 'Yes' : 'No';
    // user has set to true but device has not accepted update
    if (desiredFaulty) return 'Yes (not confirmed by device)';
    if (reportedFaulty) {
        // user has set to false but device still reports true
        if (desiredFaulty != null) return 'No (not confirmed by device)';
        // user has not specified, device reports true
        return 'Yes';
    }
    // both desired and reported are false or undefined
    return 'No';
};

export {
    getFaultyDeviceFlag,
};
