/* globals window, document */
import React from 'react';
import 'reflect-metadata';
import {
    render,
} from 'react-dom';
import {
    createStore,
    applyMiddleware,
    compose,
} from 'redux';
import {
    Provider,
} from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import './redirectInsteadOf404';
import {
    AuthenticatedNyleApp,
} from './NyleApp';
import {
    container,
} from './dependency-injection';

import {
    reducer,
} from './root-reducer';
import {
    handler as peaksSaga,
} from './models/peaks/sagas';
import {
    handler as vppsSaga,
} from './models/vpps/sagas';
import {
    handler as userSaga,
} from './models/user/sagas';
import {
    handler as downloadSaga,
} from './models/download/sagas';
import {
    handler as devicesSaga,
} from './models/devices/sagas';
import {
    handler as deviceAlertsSaga,
} from './models/devices/alerts/sagas';
import {
    fetchUser,
} from './models/user/actions';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancers(
    applyMiddleware(sagaMiddleware),
));
container.registerInstance('DataStore', store);

sagaMiddleware.run(peaksSaga);
sagaMiddleware.run(vppsSaga);
sagaMiddleware.run(userSaga);
sagaMiddleware.run(downloadSaga);
sagaMiddleware.run(devicesSaga);
sagaMiddleware.run(deviceAlertsSaga);

store.dispatch(fetchUser());

render(
    <Provider store={store}>
        <AuthenticatedNyleApp />
    </Provider>,
    document.getElementById('root'),
);
