import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    clearAlert,
} from '../models/alerts/actions';
import './alert.css';

class AlertComponent extends Component {
    getAlertClass() {
        const { type } = this.props;
        const customClass = type === 'operator' ? 'operator-alert' : '';
        return `alert alert-${this.getLevel()} alert-dismissible ${customClass} fade show`;
    }

    getContainerClass() {
        const { type } = this.props;
        switch (type) {
            case 'download': return 'd-inline-flex';
            case 'operator': return 'text-center';
            default: return 'text-center p-2';
        }
    }

    getLevel() {
        const {
            alert: {
                level,
            },
        } = this.props;
        switch (level) {
            case 'info':
            case 'success':
            case 'warning':
            case 'danger':
            case 'error': return level;
            default: return 'info';
        }
    }

    render() {
        const {
            alert,
            close,
            type,
        } = this.props;

        let alertHTML = null;
        if (alert) {
            if (type === 'download' && alert.action === 'download-started') {
                alertHTML = (
                    <div>
                        <img src="/assets/img/Spinner.gif" alt="loading" height="50" width="50" />
                    </div>
                );
            } else {
                alertHTML = (
                    <div className={this.getAlertClass()} role="alert">
                        <span>
                            {alert.message}
                            {' '}
                            <Icon
                                onClick={() => close(type)}
                                type={type}
                            />
                        </span>
                    </div>
                );
            }
        }

        return (
            <div className={this.getContainerClass()} style={styles[type].container}>
                {alertHTML}
            </div>
        );
    }
}

const styles = {
    operator: {
        icon: {
            cursor: 'pointer',
            verticalAlign: 'baseline',
        },
    },
    download: {
        icon: {
            cursor: 'pointer',
        },
        container: {
            marginTop: 10,
        },
    },
};

const Icon = ({
    onClick,
    type,
}) => (
    <i
        className="fa fa-times fa-lg"
        style={styles[type].icon}
        onClick={onClick}
    />
);

const mapStateToProps = (state, props) => {
    const { alerts } = state;
    const { type } = props;
    return {
        ...props,
        alert: alerts[type],
    };
};

const mapDispatchToProps = dispatch => ({
    close: type => dispatch(clearAlert(type)),
});

const Alert = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AlertComponent);

export {
    Alert,
};
