import moment from 'moment-timezone';
import {
    NOW,
} from '../../../utils/windows';
import {
    ONE_HOUR_MS,
    ONE_MINUTE_MS,
    ONE_DAY_MS,
    asMS,
    getCurrentTimezone,
} from '../../../utils/time';

const readableDirectionMapping = {
    start: 'Starting at',
    center: 'Centered on',
    end: 'Ending at',
};

const formatTimestamp = (duration, showPlural = true) => {
    const interval = asMS(duration);
    if (interval < ONE_HOUR_MS) return `${round10(interval / ONE_MINUTE_MS)} min`;
    if (interval <= ONE_DAY_MS * 2) return `${round10(interval / ONE_HOUR_MS)} hr`;
    return `${round10(interval / ONE_DAY_MS)} day${showPlural ? 's' : ''}`;
};

const round10 = number => Math.round(number * 10) / 10;

const getReadableTimerange = ({
    duration,
    anchor,
    direction = 'start',
}) => {
    const window = asMS(duration);
    let readableAnchor;
    let readableDirection;
    if (anchor === NOW) {
        readableAnchor = 'now';
        readableDirection = 'ending';
    } else {
        const timezone = getCurrentTimezone();
        readableAnchor = moment(anchor).format(`M/D/YYYY h:mm:ss A [${timezone}]`);
        readableDirection = getReadableDirection(direction).toLowerCase();
    }
    return `${formatTimestamp(Math.abs(window), false)} window ${readableDirection} ${readableAnchor}`;
};

const getReadableDirection = direction => readableDirectionMapping[direction];

const DISPLAY_TABS = {
    ENERGY_ONE_HOUR: '1 hr energy',
    ENERGY_FIVE_MINUTES: '5 min energy',
    POWER: 'Power',
};

const formatTabValues = (values, activeValue) => values.map(value => ({
    value,
    active: value === activeValue,
}));

export {
    getReadableTimerange,
    round10,
    formatTimestamp,
    getReadableDirection,
    DISPLAY_TABS,
    formatTabValues,
};
