import React from 'react';

const TextInput = ({
    value,
    onChange,
    onSave,
    onCancel,
    label = null,
    className = '',
}) => (
    <div className={`input-group device-detail-input ${className}`}>
        {label
            ? (
                <div className="input-group-prepend">
                    <span className="input-group-text">{label}</span>
                </div>
            )
            : null
        }
        <textarea
            className="form-control"
            value={value || ''}
            onChange={(event) => {
                onChange(event.target.value);
            }}
        />
        <div className="input-group-append">
            <Button title="Save" onClick={() => onSave(value)} />
            <Button title="Cancel" onClick={onCancel} />
        </div>
    </div>
);

const Button = ({
    title,
    onClick,
    className = 'btn-outline-dark',
    ...rest
}) => (
    <button
        className={`btn ${className}`}
        onClick={(event) => {
            event.preventDefault();
            return onClick();
        }}
        {...rest}
    >
        {title}
    </button>
);

export {
    TextInput,
    Button,
};
