// binary search
const findClosestIndex = (goal, array) => {
    const n = array.length;
    let i = 0;
    let j = n - 1;
    if (goal <= array[i]) return i;
    if (goal >= array[j]) return j;
    let mid;
    while (i < j) {
        mid = Math.floor((i + j) / 2);
        if (array[mid] === goal) return mid;
        if (goal < array[mid]) {
            if (mid > 0 && goal > array[mid - 1]) {
                return getClosestIndex({
                    lower: array[mid - 1],
                    upper: array[mid],
                    lowerIndex: mid - 1,
                    upperIndex: mid,
                    goal,
                });
            }
            j = mid;
        } else {
            if (mid < n - 1 && goal < array[mid + 1]) {
                return getClosestIndex({
                    lower: array[mid],
                    upper: array[mid + 1],
                    lowerIndex: mid,
                    upperIndex: mid + 1,
                    goal,
                });
            }
            i = mid + 1;
        }
    }
    return mid;
};

const getClosestIndex = ({
    lower,
    upper,
    lowerIndex,
    upperIndex,
    goal,
}) => {
    if (goal - lower >= upper - goal) return upperIndex;
    return lowerIndex;
};

export {
    findClosestIndex,
};
