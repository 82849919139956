import {
    FETCH_DEVICES_SUCCESS,
    FETCH_DEVICES_FAILURE,
    FETCH_USER_DEVICES_SUCCESS,
    FETCH_USER_DEVICES_FAILURE,
    FETCH_VPP_LIST_SUCCESS,
    UPDATE_PRODUCT_TYPE_AND_VERSION,
    UPDATE_FIRMWARE_FILTER,
    UPDATE_VPP_FILTER,
    UPDATE_CONNECTION_STATUS_FILTER,
    SET_FILTER_BY_INSTALL_DATE,
    UPDATE_INSTALL_DATE_FILTER,
    CLEAR_ALL_FILTERS,
    FETCH_DEVICE_SUCCESS,
    FETCH_DEVICE_FAILURE,
    REBOOT_DEVICE_SUCCESS,
    REBOOT_DEVICE_FAILURE,
    TOGGLE_DEVICE_DEBUGGING_FAILURE,
    UPDATE_USER_SETTING_FAILURE,
    FETCH_DEVICE_ENERGY_DATA_SUCCESS,
    FETCH_DEVICE_POWER_DATA_SUCCESS,
    FETCH_DEVICE_DEBUG_DATA_SUCCESS,
    FETCH_DEVICE_CONNECTIONS_DATA_SUCCESS,
    FETCH_DEVICE_ENERGY_DATA_FAILURE,
    FETCH_DEVICE_POWER_DATA_FAILURE,
    FETCH_DEVICE_DEBUG_DATA_FAILURE,
    FETCH_DEVICE_CONNECTIONS_DATA_FAILURE,
    SET_DEVICE_GRAPH_RANGE,
    SET_DEVICE_ERROR_MESSAGE,
    GET_DEVICE_SHADOW_SUCCESS,
    GET_DEVICE_SHADOW_FAILURE,
    LIST_RPCS,
    LIST_RPCS_FAILURE,
    LIST_RPCS_SUCCESS,
    CALL_RPC,
    CALL_RPC_FAILURE,
    CALL_RPC_SUCCESS,
    ACKNOWLEDGE_DEVICE_OFFLINE_FAILURE,
    UPDATE_CUSTOMER_NOTES_FAILURE,
    UPDATE_DEVICE_VPP,
    UPDATE_DEVICE_VPP_SUCCESS,
    UPDATE_DEVICE_VPP_FAILURE,
    UPDATE_FAULTY_DEVICE_FLAG_FAILURE,
    FETCH_USERS_FOR_DEVICE,
    FETCH_USERS_FOR_DEVICE_SUCCESS,
    FETCH_USERS_FOR_DEVICE_FAILURE,
    UPDATE_DEVICE_PARENT_SUCCESS,
    UPDATE_DEVICE_PARENT_FAILURE,
    UPDATE_UNINSTALLED_AT,
    UPDATE_UNINSTALLED_AT_SUCCESS,
    UPDATE_UNINSTALLED_AT_FAILURE,
    CLEAR_UNINSTALLED_AT,
    CLEAR_UNINSTALLED_AT_SUCCESS,
    CLEAR_UNINSTALLED_AT_FAILURE,
} from './actions';
import {
    ACTIVE_VPP_CHANGED,
} from '../vpps/actions';
import {
    FETCH_DEVICE_ALERTS_SUCCESS,
    FETCH_DEVICE_ALERTS_FAILURE,
    RESOLVE_DEVICE_ALERT_FAILURE,
    UNRESOLVE_DEVICE_ALERT_FAILURE,
    ACKNOWLEDGE_DEVICE_ALERT_FAILURE,
    CREATE_DEVICE_ALERT,
    CREATE_DEVICE_ALERT_SUCCESS,
    CREATE_DEVICE_ALERT_FAILURE,
    UPDATE_DEVICE_ALERT,
    UPDATE_DEVICE_ALERT_SUCCESS,
    UPDATE_DEVICE_ALERT_FAILURE,
    SET_DEVICE_ALERT_FILTER,
    UPDATE_DEVICE_ALERTS_MESSAGE,
} from './alerts/actions';
import {
    reducer as alertsReducer,
    INITIAL_DEVICE_ALERTS_STATE,
} from './alerts/reducers';
import {
    AsyncValue,
    ASYNC_STATUS,
} from '../../utils/async';

const EARLIEST_DATE = 1420088400000; // 1/1/2015 12:00 AM EST

const getInitialFilters = () => ({
    productType: '',
    productVersion: '',
    firmwareVersion: '',
    vppId: '',
    connectionStatus: '',
    deploymentStatus: '',
    filterByInstalled: false,
    installedAfter: EARLIEST_DATE,
    installedBefore: Date.now(),
});

const INITIAL_STATE = {
    deviceList: [],
    validVPPs: [],
    errorMessages: {
        deviceSearch: '',
    },
    filters: getInitialFilters(),
    devices: {},
    rpcList: [],
    rpcResponse: null,
    alerts: INITIAL_DEVICE_ALERTS_STATE,
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_DEVICES_SUCCESS: {
            const {
                devices,
            } = action.payload;
            return {
                ...state,
                deviceList: devices,
                errorMessages: {
                    ...state.errorMessages,
                    deviceSearch: '',
                },
            };
        }
        case FETCH_DEVICES_FAILURE: {
            return {
                ...state,
                deviceList: [],
                errorMessages: {
                    ...state.errorMessages,
                    deviceSearch: 'Error fetching devices',
                },
            };
        }
        case FETCH_USER_DEVICES_SUCCESS: {
            const {
                devices,
            } = action.payload;
            return {
                ...state,
                deviceList: devices,
            };
        }
        case FETCH_USER_DEVICES_FAILURE: {
            return {
                ...state,
                deviceList: [],
                errorMessages: {
                    ...state.errorMessages,
                    deviceSearch: 'Error fetching devices',
                },
            };
        }
        case FETCH_VPP_LIST_SUCCESS: {
            const {
                vpps,
            } = action.payload;
            return {
                ...state,
                validVPPs: vpps,
            };
        }
        case UPDATE_PRODUCT_TYPE_AND_VERSION: {
            const {
                productType,
                productVersion,
            } = action.payload;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    productType,
                    productVersion,
                },
            };
        }
        case UPDATE_FIRMWARE_FILTER: {
            const {
                firmwareVersion,
            } = action.payload;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    firmwareVersion,
                },
            };
        }
        case UPDATE_VPP_FILTER: {
            const {
                vppId,
            } = action.payload;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    vppId,
                },
            };
        }
        case UPDATE_CONNECTION_STATUS_FILTER: {
            const {
                connectionStatus,
                deploymentStatus,
            } = action.payload;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    connectionStatus,
                    deploymentStatus,
                },
            };
        }
        case SET_FILTER_BY_INSTALL_DATE: {
            const {
                filterByInstalled,
            } = action.payload;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    filterByInstalled,
                },
            };
        }
        case UPDATE_INSTALL_DATE_FILTER: {
            const {
                installedAfter,
                installedBefore,
            } = action.payload;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    installedAfter,
                    installedBefore,
                },
            };
        }
        case CLEAR_ALL_FILTERS: {
            return {
                ...state,
                filters: getInitialFilters(),
            };
        }
        case ACTIVE_VPP_CHANGED: {
            return {
                ...state,
                devices: {},
            };
        }
        case LIST_RPCS_SUCCESS: {
            return {
                ...state,
                rpcList: action.payload,
            };
        }
        case LIST_RPCS:
        case LIST_RPCS_FAILURE: {
            return {
                ...state,
                rpcList: [],
            };
        }
        case CALL_RPC_SUCCESS: {
            return {
                ...state,
                rpcResponse: action.payload,
            };
        }
        case CALL_RPC:
        case CALL_RPC_FAILURE: {
            return {
                ...state,
                rpcResponse: null,
            };
        }
        case FETCH_DEVICE_SUCCESS:
        case FETCH_DEVICE_FAILURE:
        case GET_DEVICE_SHADOW_SUCCESS:
        case GET_DEVICE_SHADOW_FAILURE:
        case REBOOT_DEVICE_SUCCESS:
        case REBOOT_DEVICE_FAILURE:
        case TOGGLE_DEVICE_DEBUGGING_FAILURE:
        case UPDATE_USER_SETTING_FAILURE:
        case FETCH_DEVICE_ENERGY_DATA_SUCCESS:
        case FETCH_DEVICE_POWER_DATA_SUCCESS:
        case FETCH_DEVICE_DEBUG_DATA_SUCCESS:
        case FETCH_DEVICE_CONNECTIONS_DATA_SUCCESS:
        case FETCH_DEVICE_ENERGY_DATA_FAILURE:
        case FETCH_DEVICE_POWER_DATA_FAILURE:
        case FETCH_DEVICE_DEBUG_DATA_FAILURE:
        case FETCH_DEVICE_CONNECTIONS_DATA_FAILURE:
        case SET_DEVICE_GRAPH_RANGE:
        case SET_DEVICE_ERROR_MESSAGE:
        case ACKNOWLEDGE_DEVICE_OFFLINE_FAILURE:
        case UPDATE_CUSTOMER_NOTES_FAILURE:
        case UPDATE_DEVICE_VPP:
        case UPDATE_DEVICE_VPP_SUCCESS:
        case UPDATE_DEVICE_VPP_FAILURE:
        case UPDATE_FAULTY_DEVICE_FLAG_FAILURE:
        case FETCH_USERS_FOR_DEVICE:
        case FETCH_USERS_FOR_DEVICE_SUCCESS:
        case FETCH_USERS_FOR_DEVICE_FAILURE:
        case UPDATE_DEVICE_PARENT_SUCCESS:
        case UPDATE_DEVICE_PARENT_FAILURE:
        case UPDATE_UNINSTALLED_AT:
        case UPDATE_UNINSTALLED_AT_SUCCESS:
        case UPDATE_UNINSTALLED_AT_FAILURE:
        case CLEAR_UNINSTALLED_AT:
        case CLEAR_UNINSTALLED_AT_SUCCESS:
        case CLEAR_UNINSTALLED_AT_FAILURE: {
            const { deviceId } = action.payload;
            return {
                ...state,
                devices: {
                    ...state.devices,
                    [deviceId]: deviceReducer(state.devices[deviceId], action),
                },
            };
        }
        case FETCH_DEVICE_ALERTS_SUCCESS:
        case FETCH_DEVICE_ALERTS_FAILURE:
        case RESOLVE_DEVICE_ALERT_FAILURE:
        case UNRESOLVE_DEVICE_ALERT_FAILURE:
        case ACKNOWLEDGE_DEVICE_ALERT_FAILURE:
        case CREATE_DEVICE_ALERT:
        case CREATE_DEVICE_ALERT_SUCCESS:
        case CREATE_DEVICE_ALERT_FAILURE:
        case UPDATE_DEVICE_ALERT:
        case UPDATE_DEVICE_ALERT_SUCCESS:
        case UPDATE_DEVICE_ALERT_FAILURE:
        case SET_DEVICE_ALERT_FILTER:
        case UPDATE_DEVICE_ALERTS_MESSAGE: {
            return {
                ...state,
                alerts: alertsReducer(state.alerts, action),
            };
        }
        default: return state;
    }
};

const ONE_HOUR_MS = 1000 * 60 * 60;

const getInitialGraphRange = () => {
    const now = Date.now();
    return {
        startTS: now - (12 * ONE_HOUR_MS),
        endTS: now,
    };
};

const INITIAL_DEVICE_STATE = {
    vpp: AsyncValue.wrap(null),
    uninstalledAt: AsyncValue.wrap(null),
    device: null,
    powerData: [],
    energyData: [],
    debugData: [],
    connectionsData: [],
    graphRange: getInitialGraphRange(),
    message: '',
    shadow: {},
    isFaultyDevice: null,
    users: {
        loading: ASYNC_STATUS.NONE,
        data: [],
        message: '',
    },
};

const deviceReducer = (state = INITIAL_DEVICE_STATE, action) => {
    switch (action.type) {
        case FETCH_DEVICE_SUCCESS: {
            return {
                ...state,
                vpp: AsyncValue.success(action.payload.vpp),
                uninstalledAt: AsyncValue.success(action.payload.uninstalledAt),
                device: action.payload,
                message: '',
            };
        }
        case FETCH_DEVICE_FAILURE: {
            return {
                ...state,
                message: 'Error fetching device',
            };
        }
        case GET_DEVICE_SHADOW_SUCCESS: {
            return {
                ...state,
                shadow: action.payload.shadow,
                message: '',
            };
        }
        case GET_DEVICE_SHADOW_FAILURE: {
            return {
                ...state,
                shadow: {},
                message: 'Error fetching device shadow',
            };
        }
        case REBOOT_DEVICE_SUCCESS: {
            return {
                ...state,
                message: 'Reboot successful',
            };
        }
        case REBOOT_DEVICE_FAILURE: {
            return {
                ...state,
                message: 'Error rebooting device',
            };
        }
        case TOGGLE_DEVICE_DEBUGGING_FAILURE: {
            return {
                ...state,
                message: 'Failed to modify debug interval',
            };
        }
        case UPDATE_USER_SETTING_FAILURE: {
            return {
                ...state,
                message: 'Error updating user setting',
            };
        }
        case FETCH_DEVICE_ENERGY_DATA_SUCCESS: {
            return {
                ...state,
                energyData: action.payload.data,
                message: '',
            };
        }
        case FETCH_DEVICE_POWER_DATA_SUCCESS: {
            return {
                ...state,
                powerData: action.payload.data,
                message: '',
            };
        }
        case FETCH_DEVICE_DEBUG_DATA_SUCCESS: {
            return {
                ...state,
                debugData: action.payload.data,
                message: '',
            };
        }
        case FETCH_DEVICE_CONNECTIONS_DATA_SUCCESS: {
            return {
                ...state,
                connectionsData: action.payload.data,
                message: '',
            };
        }
        case FETCH_DEVICE_ENERGY_DATA_FAILURE: {
            return {
                ...state,
                message: 'Unable to fetch energy data',
            };
        }
        case FETCH_DEVICE_POWER_DATA_FAILURE: {
            return {
                ...state,
                message: 'Unable to fetch power data',
            };
        }
        case FETCH_DEVICE_DEBUG_DATA_FAILURE: {
            return {
                ...state,
                message: 'Unable to fetch debug data',
            };
        }
        case FETCH_DEVICE_CONNECTIONS_DATA_FAILURE: {
            return {
                ...state,
                message: 'Unable to fetch connections data',
            };
        }
        case SET_DEVICE_GRAPH_RANGE: {
            const {
                startTS,
                endTS,
            } = action.payload;
            return {
                ...state,
                graphRange: {
                    startTS,
                    endTS,
                },
            };
        }
        case SET_DEVICE_ERROR_MESSAGE: {
            return {
                ...state,
                message: action.payload.message,
            };
        }
        case ACKNOWLEDGE_DEVICE_OFFLINE_FAILURE: {
            return {
                ...state,
                message: 'Error acknowledging device offline',
            };
        }
        case UPDATE_CUSTOMER_NOTES_FAILURE: {
            return {
                ...state,
                message: 'Error updating customer notes',
            };
        }
        case UPDATE_DEVICE_VPP: {
            return {
                ...state,
                vpp: AsyncValue.waiting(state.vpp.value, action.payload.message),
            };
        }
        case UPDATE_DEVICE_VPP_SUCCESS: {
            return {
                ...state,
                vpp: AsyncValue.success(action.payload.vppId),
            };
        }
        case UPDATE_DEVICE_VPP_FAILURE: {
            return {
                ...state,
                vpp: AsyncValue.failure(state.vpp.value, action.payload.message),
            };
        }
        case UPDATE_FAULTY_DEVICE_FLAG_FAILURE: {
            return {
                ...state,
                message: 'Failed to update bad install flag',
            };
        }
        case FETCH_USERS_FOR_DEVICE: {
            return {
                ...state,
                users: {
                    ...state.users,
                    loading: ASYNC_STATUS.WAITING,
                    message: '',
                },
            };
        }
        case FETCH_USERS_FOR_DEVICE_SUCCESS: {
            return {
                ...state,
                users: {
                    data: action.payload.users,
                    loading: ASYNC_STATUS.SUCCESS,
                    message: '',
                },
            };
        }
        case FETCH_USERS_FOR_DEVICE_FAILURE: {
            return {
                ...state,
                users: {
                    ...state.users,
                    loading: ASYNC_STATUS.FAILURE,
                    message: 'Failed to fetch users associated with this device',
                },
            };
        }
        case UPDATE_DEVICE_PARENT_SUCCESS: {
            const { parent } = action.payload;
            return {
                ...state,
                device: {
                    ...state.device,
                    parent,
                },
                message: '',
            };
        }
        case UPDATE_DEVICE_PARENT_FAILURE: {
            return {
                ...state,
                message: 'Failed to update device parent',
            };
        }
        case UPDATE_UNINSTALLED_AT: {
            const { uninstalledAt } = AsyncValue.unwrap(state, 'uninstalledAt');
            return {
                ...state,
                uninstalledAt: AsyncValue.waiting(uninstalledAt),
            };
        }
        case UPDATE_UNINSTALLED_AT_SUCCESS: {
            const { uninstalledAt } = action.payload;
            return {
                ...state,
                uninstalledAt: AsyncValue.success(uninstalledAt),
                device: {
                    ...state.device,
                    uninstalledAt,
                },
            };
        }
        case UPDATE_UNINSTALLED_AT_FAILURE: {
            const {
                uninstalledAt,
                errorMessage,
            } = action.payload;
            return {
                ...state,
                uninstalledAt: AsyncValue.failure(uninstalledAt, errorMessage),
            };
        }
        case CLEAR_UNINSTALLED_AT: {
            const { uninstalledAt } = AsyncValue.unwrap(state, 'uninstalledAt');
            return {
                ...state,
                uninstalledAt: AsyncValue.waiting(uninstalledAt),
            };
        }
        case CLEAR_UNINSTALLED_AT_SUCCESS: {
            return {
                ...state,
                uninstalledAt: AsyncValue.success(null),
            };
        }
        case CLEAR_UNINSTALLED_AT_FAILURE: {
            const { uninstalledAt } = AsyncValue.unwrap(state, 'uninstalledAt');
            const { errorMessage } = action.payload;
            return {
                ...state,
                uninstalledAt: AsyncValue.failure(uninstalledAt, errorMessage),
            };
        }
        default: return state;
    }
};

export {
    reducer,
    EARLIEST_DATE,
};
