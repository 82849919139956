import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    pollActivePeak,
    stopPollingActivePeak,
    deletePeakEvent,
} from '../../models/peaks/actions';
import {
    ActivePeakEventBanner,
} from './banner';

class ActivePeakEventContainerComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDeleting: false,
        };
    }

    componentDidMount() {
        this.delete = () => {
            const {
                activePeak,
                deleteEvent,
            } = this.props;
            if (!activePeak) return;

            this.setState({
                isDeleting: true,
            });
            deleteEvent();
        };

        const { pollEvent } = this.props;
        pollEvent();
    }

    componentWillUnmount() {
        const { stopPolling } = this.props;
        stopPolling();
    }

    render() {
        if (!this.state) return null;
        const {
            isDeleting,
        } = this.state;
        const {
            activePeak,
        } = this.props;
        if (activePeak) {
            const event = {
                ...activePeak,
                delete: this.delete,
                isDeleting,
            };
            return (
                <div className="peak-event-banner-wrapper">
                    <ActivePeakEventBanner {...event} />
                </div>
            );
        }
        return null;
    }
}

const mapStateToProps = (state, props) => {
    const {
        activeVpp,
    } = state.vpps;
    const {
        activePeak,
    } = state.peaks[activeVpp] || {};

    return {
        ...props,
        activePeak,
    };
};

const mapDispatchToProps = dispatch => ({
    deleteEvent: () => dispatch(deletePeakEvent()),
    pollEvent: () => dispatch(pollActivePeak()),
    stopPolling: () => dispatch(stopPollingActivePeak()),
});

const ActivePeakEventContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ActivePeakEventContainerComponent);

export {
    ActivePeakEventContainer,
};
