import React from 'react';

const FirmwareVersionInput = ({
    firmwareVersion,
    handleFirmwareVersionChange,
    handleSubmitSearch,
}) => (
    <div className="row devices-search__filter-row">
        <h5 className="dtwp__inline">Firmware version: </h5>
        <input
            className="form-control devices-search__input"
            type="text"
            value={firmwareVersion}
            onChange={(event) => {
                event.preventDefault();
                handleFirmwareVersionChange(event.target.value);
            }}
            onKeyPress={(event) => {
                if (event.key === 'Enter') handleSubmitSearch();
            }}
            placeholder="x.y.z"
        />
    </div>
);

export {
    FirmwareVersionInput,
};
