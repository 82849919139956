import React from 'react';
import { Tooltip } from '../../lib/rc-slider';
import 'rc-tooltip/assets/bootstrap.css';

export const PeakAction = ({
    handler,
    faIconClass,
    disabled = false,
    tooltipText,
}) => (
    <Tooltip overlay={<span>{tooltipText}</span>} placement="bottom" mouseLeaveDelay={0}>
        <button className="btn btn-light peak-scheduler__peak__action" onClick={handler} disabled={disabled}>
            <i className={`fa fa-${faIconClass}`} />
        </button>
    </Tooltip>
);
