/* globals window */
import React from 'react';
import { connect } from 'react-redux';
import {
    fetchDevice,
    getShadow,
    rebootDevice,
    toggleDeviceDebugging,
    updateUserSetting,
    fetchDeviceGraphData,
    setDeviceGraphRange,
    setDeviceErrorMessage,
    acknowledgeDeviceOffline,
    updateCustomerNotes as updateDeviceCustomerNotes,
    updateFaultyDeviceFlag,
} from '../models/devices/actions';
import {
    getFaultyDeviceFlag,
} from '../models/devices/selectors';
import {
    getActiveVppId,
    getActiveVpp,
} from '../models/vpps/selectors';
import { userIsNyleAdmin, userHasRole } from '../models/user/selectors';
import DeviceDetails from '../admin/devices/DeviceDetails';
import {
    ActionContainer,
} from './action-container';
import { NO_VPP } from '../models/vpps/reducers';

class ViewDeviceContainer extends React.Component {
    componentDidMount() {
        const {
            deviceId,
            getDevice,
            getDeviceShadow,
            hasAdminRole,
            hasOemRole,
        } = this.props;
        getDevice(deviceId);
        if (hasAdminRole || hasOemRole) getDeviceShadow(deviceId);
    }

    rebootDevice() {
        // eslint-disable-next-line no-alert
        if (window.confirm('Are you sure you want to reboot this device?')) {
            const {
                reboot,
                deviceId,
            } = this.props;
            reboot(deviceId);
        }
    }

    render() {
        const {
            deviceId,
            device,
            message,
            powerData,
            energyData,
            debugData,
            graphRange,
            toggleDebugging,
            setUserSetting,
            getGraphData,
            setGraphRange,
            setErrorMessage,
            shouldRedirect,
            userHasAdminAccess,
            hasOemRole,
            acknowledgeOffline,
            updateCustomerNotes,
            getDeviceShadow,
            isFaultyDevice,
            setFaultyDeviceFlag,
            shadow,
            isDeviceOwner,
        } = this.props;
        if (shouldRedirect) {
            window.location.replace(`${window.location.origin}/#/operator`);
            return null;
        }
        if (!device) return <p />;

        const alertOptions = Object.keys(shadow?.state?.reported?.debug?.modbus ?? {});

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-6">
                        <DeviceDetails
                            isDeviceOwner={isDeviceOwner}
                            userHasAdminAccess={userHasAdminAccess}
                            userHasOemAccess={hasOemRole}
                            operatorPage={true}
                            device={device}
                            toggleDebugging={() => toggleDebugging(deviceId)}
                            rebootDevice={() => this.rebootDevice()}
                            alertOptions={alertOptions}
                            updateUserSetting={(setting, value) => setUserSetting({
                                deviceId,
                                setting,
                                value,
                            })}
                            acknowledgeOffline={() => {
                                if (!userHasAdminAccess) return;
                                acknowledgeOffline(deviceId);
                            }}
                            editCustomerNotes={(notes) => {
                                if (!userHasAdminAccess) return;
                                updateCustomerNotes(deviceId, notes);
                            }}
                            isFaultyDevice={isFaultyDevice}
                            setFaultyDeviceFlag={(faulty) => {
                                if (!userHasAdminAccess) return;
                                setFaultyDeviceFlag(deviceId, faulty);
                            }}
                            getFaultyDeviceFlag={() => {
                                if (!userHasAdminAccess) return;
                                getDeviceShadow(deviceId);
                            }}
                            errorMessage={message}
                            // picking the lesser of many evils here with disabling the max-len rule
                            /* eslint-disable max-len */
                            plcConnectivity={shadow?.state?.reported?.debug?.systemState?.PLCConnectivity ?? 0}
                            compRunTime={shadow?.state?.reported?.debug?.systemState?.compRunTime ?? 'N/A'}
                            /* eslint-enable max-len */
                        />
                    </div>
                    <div className="col-6">
                        <ActionContainer
                            deviceId={deviceId}
                            deviceReportedDebugInterval={device.debugInterval}
                            deviceType={device.deviceType}
                            productType={device.productType}
                            debugConfig={device.debugConfig}
                            powerData={powerData}
                            energyData={energyData}
                            debugData={debugData}
                            graphRange={graphRange}
                            setGraphRange={({
                                startTS,
                                endTS,
                                dataType,
                            }) => setGraphRange({
                                startTS,
                                endTS,
                                dataType,
                                deviceId,
                            })}
                            showInvalidDateError={(position = 'start or end') => {
                                setErrorMessage({
                                    deviceId,
                                    message: `Invalid ${position} date`,
                                });
                            }}
                            getGraphData={dataType => getGraphData({
                                deviceId,
                                dataType,
                            })}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {
        match: {
            params: {
                deviceId,
            },
        },
    } = props;
    const { devices } = state.user;
    const deviceInfo = state.devices.devices[deviceId] || {};

    // If the user is not part of a vpp we should show the list of devices
    // which will be the list of their own devices
    const activeVppId = getActiveVppId(state);
    const showDevicesTab = activeVppId === NO_VPP ? true : getActiveVpp(state).showDevicesTab;

    return {
        deviceId,
        ...deviceInfo,
        shouldRedirect: !showDevicesTab,
        userHasAdminAccess: userIsNyleAdmin(state),
        hasOemRole: userHasRole(state, 'oem'),
        hasAdminRole: userHasRole(state, 'admin'),
        isDeviceOwner: devices.includes(deviceId),
        isFaultyDevice: getFaultyDeviceFlag(state, deviceId),
    };
};

const mapDispatchToProps = dispatch => ({
    getDevice: deviceId => dispatch(fetchDevice(deviceId)),
    getDeviceShadow: deviceId => dispatch(getShadow(deviceId)),
    reboot: deviceId => dispatch(rebootDevice(deviceId)),
    toggleDebugging: deviceId => dispatch(toggleDeviceDebugging(deviceId)),
    setUserSetting: params => dispatch(updateUserSetting(params)),
    getGraphData: params => dispatch(fetchDeviceGraphData(params)),
    setGraphRange: params => dispatch(setDeviceGraphRange(params)),
    setErrorMessage: params => dispatch(setDeviceErrorMessage(params)),
    acknowledgeOffline: deviceId => dispatch(acknowledgeDeviceOffline(deviceId)),
    updateCustomerNotes: (deviceId, notes) => dispatch(updateDeviceCustomerNotes({
        deviceId,
        customerNotes: notes,
    })),
    setFaultyDeviceFlag: (deviceId, faulty) => dispatch(updateFaultyDeviceFlag({
        deviceId,
        faulty,
    })),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ViewDeviceContainer);
