import {
    timeDay,
} from 'd3-time';
import {
    nextWindow as utilNextWindow,
    previousWindow as utilPreviousWindow,
} from '../../utils/windows';
import {
    toPeakId,
} from './utils';

const PEAKS_FETCH = 'peaks/PEAKS_FETCH';
const PEAKS_FETCH_FAILED = 'peaks/PEAKS_FETCH_FAILED';
const PEAKS_FETCH_SUCCEEDED = 'peaks/PEAKS_FETCH_SUCCEEDED';
const PEAKS_SHOW_WINDOW = 'peaks/PEAKS_SHOW_WINDOW';
const PEAKS_REFRESH_WINDOW = 'peaks/PEAKS_REFRESH_WINDOW';

const showWindow = ({
    vppId,
    duration = 'P-7D',
    anchor: _anchor = null,
} = {}) => {
    const completed = duration.charAt(1) === '-';
    let anchor = _anchor;
    if (!_anchor && completed) {
        anchor = timeDay.ceil(Date.now());
    } else if (!_anchor && !completed) {
        anchor = timeDay.floor(Date.now());
    }
    return {
        type: PEAKS_SHOW_WINDOW,
        payload: {
            vppId,
            duration,
            anchor,
        },
    };
};

const nextWindow = ({
    vppId,
    duration,
    anchor,
}) => ({
    type: PEAKS_SHOW_WINDOW,
    payload: {
        ...utilNextWindow({
            duration,
            anchor,
            vppId,
        }),
    },
});

const previousWindow = ({
    vppId,
    duration,
    anchor,
}) => ({
    type: PEAKS_SHOW_WINDOW,
    payload: {
        ...utilPreviousWindow({
            duration,
            anchor,
            vppId,
        }),
    },
});

const fetchSucceeded = peaks => ({
    type: PEAKS_FETCH_SUCCEEDED,
    payload: peaks,
});

const fetchFailed = peaks => ({
    type: PEAKS_FETCH_FAILED,
    payload: peaks,
});

const refreshWindow = interval => ({
    type: PEAKS_REFRESH_WINDOW,
    payload: interval,
});

const GET_PEAK_EVENT_SUCCESS = 'peaks/GET_PEAK_EVENT_SUCCESS';
const CREATE_PEAK_EVENT = 'peaks/CREATE_PEAK_EVENT';
const UPDATE_PEAK_EVENT = 'peaks/UPDATE_PEAK_EVENT';
const DELETE_PEAK_EVENT = 'peaks/DELETE_PEAK_EVENT';
const DUPLICATE_PEAK_EVENT = 'peaks/DUPLICATE_PEAK_EVENT';

const createPeakEvent = (vppId, event) => ({
    type: CREATE_PEAK_EVENT,
    payload: {
        vppId,
        event,
    },
});
const updatePeakEvent = ({
    vppId,
    peakId,
    event,
}) => ({
    type: UPDATE_PEAK_EVENT,
    payload: {
        vppId,
        peakId,
        event,
    },
});
const deletePeakEvent = (vppId, peakId) => ({
    type: DELETE_PEAK_EVENT,
    payload: {
        vppId,
        peakId,
    },
});
const duplicatePeakEvent = peakId => ({
    type: DUPLICATE_PEAK_EVENT,
    payload: {
        peakId,
    },
});

const SET_PEAK_ERROR_MESSAGE = 'peaks/SET_PEAK_ERROR_MESSAGE';
const clearPeakErrorMessage = peakId => ({
    type: SET_PEAK_ERROR_MESSAGE,
    payload: {
        message: null,
        peakId,
    },
});

const SET_SAVING_PEAK_FLAG = 'peaks/SET_SAVING_PEAK_FLAG';
const TOGGLE_EDITING_PEAK = 'peaks/TOGGLE_EDITING_PEAK';
const toggleEditingPeak = ({
    vppId,
    peakId,
}) => ({
    type: TOGGLE_EDITING_PEAK,
    payload: {
        peakId: toPeakId({
            vppId,
            startTS: peakId,
        }),
        vppId,
    },
});
const CREATE_NEW_PEAK_EVENT = 'peaks/CREATE_NEW_PEAK_EVENT';
const RESET_NEW_PEAK_EVENT = 'peaks/RESET_NEW_PEAK_EVENT';
const createNewPeakEvent = params => ({
    type: CREATE_NEW_PEAK_EVENT,
    payload: {
        ...params,
    },
});
const resetNewPeakEvent = vppId => ({
    type: RESET_NEW_PEAK_EVENT,
    payload: {
        vppId,
    },
});

const POLL_ACTIVE_PEAK_EVENT = 'peaks/POLL_ACTIVE_PEAK_EVENT';
const STOP_POLLING_ACTIVE_PEAK = 'peaks/STOP_POLLING_ACTIVE_PEAK';
const pollActivePeak = () => ({
    type: POLL_ACTIVE_PEAK_EVENT,
});
const stopPollingActivePeak = () => ({
    type: STOP_POLLING_ACTIVE_PEAK,
});

const GET_PAST_PEAKS_IN_GRAPH_RANGE_REQUEST = 'peaks/GET_PAST_PEAKS_IN_GRAPH_RANGE_REQUEST';
const GET_PAST_PEAKS_IN_GRAPH_RANGE_SUCCESS = 'peaks/GET_PAST_PEAKS_IN_GRAPH_RANGE_SUCCESS';

export {
    PEAKS_FETCH,
    PEAKS_FETCH_FAILED,
    PEAKS_FETCH_SUCCEEDED,
    PEAKS_SHOW_WINDOW,
    PEAKS_REFRESH_WINDOW,
    showWindow,
    nextWindow,
    previousWindow,
    fetchFailed,
    fetchSucceeded,
    refreshWindow,
    GET_PEAK_EVENT_SUCCESS,
    CREATE_PEAK_EVENT,
    UPDATE_PEAK_EVENT,
    DELETE_PEAK_EVENT,
    DUPLICATE_PEAK_EVENT,
    duplicatePeakEvent,
    createPeakEvent,
    updatePeakEvent,
    deletePeakEvent,
    SET_PEAK_ERROR_MESSAGE,
    clearPeakErrorMessage,
    SET_SAVING_PEAK_FLAG,
    TOGGLE_EDITING_PEAK,
    toggleEditingPeak,
    CREATE_NEW_PEAK_EVENT,
    RESET_NEW_PEAK_EVENT,
    createNewPeakEvent,
    resetNewPeakEvent,
    POLL_ACTIVE_PEAK_EVENT,
    STOP_POLLING_ACTIVE_PEAK,
    pollActivePeak,
    stopPollingActivePeak,
    GET_PAST_PEAKS_IN_GRAPH_RANGE_REQUEST,
    GET_PAST_PEAKS_IN_GRAPH_RANGE_SUCCESS,
};
