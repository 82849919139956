import momentLocalizer from 'react-widgets-moment';
import moment from 'moment';
import RWDateTimePicker from 'react-widgets/lib/DateTimePicker';
import {
    getCurrentTimezone,
} from '../utils/time';

import 'react-widgets/dist/css/react-widgets.css';
import './react-widgets.css';

momentLocalizer(moment);

const DateTimePicker = ({
    showTimezone,
    ...props
}) => {
    const baseFormat = 'MMM D, YYYY h:mm A';
    const timezoneName = getCurrentTimezone();
    return (
        <RWDateTimePicker
            format={showTimezone ? `${baseFormat} [(${timezoneName})]` : baseFormat}
            editFormat={baseFormat}
            {...props}
        />
    );
};

export { DateTimePicker };
