import {
    VPPApiClient,
} from '../../VPPApiClient';
import {
    NyleError,
} from '../../utils/errors';

export class VPPDAO {
    static inject() {
        return [VPPApiClient];
    }

    constructor(http) {
        const queryServer = async (url, message, desiredStatus = 200) => {
            const {
                body,
                status,
            } = await http.get(url);
            if (status !== desiredStatus) throw new NyleError(message);
            return body;
        };

        const getConfig = async vppId => this.queryServer(`/vpps/${vppId}/`, 'Failed to fetch VB config');

        const getDeviceCount = async (vpp) => {
            const url = `/vpps/${vpp}/devices/_count`;
            const result = await this.queryServer(url, 'Failed to fetch device count');
            return result;
        };

        const getEnergyMarketValue = async ({
            vpp,
            interval = 'all',
            lowerTS = null,
            upperTS = null,
        }) => {
            let url = `/vpps/${vpp}/aggregates/value?interval=${interval}`;
            if (lowerTS && upperTS) {
                url += `&lowerTS=${lowerTS}&upperTS=${upperTS}`;
            }
            const result = await this.queryServer(url, 'Failed to fetch energy market value');
            return result;
        };

        const getMetrics = async ({
            vppId,
            interval = 'one-day',
            lowerTS = null,
            upperTS = null,
        }) => {
            let url = `/vpps/${vppId}/aggregates?interval=${interval}`;
            if (lowerTS && upperTS) {
                url += `&lowerTS=${lowerTS}&upperTS=${upperTS}&tzOffset=-5`;
            }
            const result = await this.queryServer(url, 'Failed to fetch vpp value');
            return result;
        };

        const getVPPValueGraphData = async ({
            vpp, lowerTS, upperTS, interval = 'five-minutes',
        }) => {
            const url = `/vpps/${vpp}/aggregates/value?lowerTS=${lowerTS}&upperTS=${upperTS}&interval=${interval}`;
            const result = await this.queryServer(url, 'Failed to fetch vpp value data');
            return result;
        };

        const getPeaksList = async ({ vpp, lowerTS, upperTS }) => {
            const peakUrl = `/vpps/${vpp}/peaks/done?lowerTS=${lowerTS}&upperTS=${upperTS}`;
            const peakList = await this.queryServer(peakUrl, 'Failed to find done peaks');
            const completedPeaks = peakList.filter(peak => peak.state === 'completed');

            const baselinePeaks = await Promise.all(completedPeaks.map(async (peak) => {
                const baselineUrl = `/vpps/${vpp}/aggregates/baseline/${peak.startTS}-${peak.endTS}`;
                const baselineData = await this.queryServer(baselineUrl, 'Failed to fetch baseline data for peak');
                return {
                    startTS: peak.startTS,
                    endTS: peak.endTS,
                    baselineData,
                };
            }));
            const actualPeaks = await Promise.all(completedPeaks.map(async (peak) => {
                const actualUrl = `/vpps/${vpp}/energy/actual/${peak.startTS}-${peak.endTS}`;
                const actualData = await this.queryServer(actualUrl, 'Failed to fetch actual energy data for peak');
                return {
                    startTS: peak.startTS,
                    endTS: peak.endTS,
                    actualData,
                };
            }));
            const peaks = baselinePeaks.map((baselinePeak, index) => {
                const actualPeak = actualPeaks[index];
                return {
                    startTS: baselinePeak.startTS,
                    endTS: baselinePeak.endTS,
                    baselineHourly: baselinePeak.baselineData,
                    actualHourly: actualPeak.actualData,
                };
            });

            const result = peaks.map((peak) => {
                const hourlyData = peak.baselineHourly.map((baselineItem) => {
                    const baseline = baselineItem.baselinekWh;
                    const actualObsIndex = peak.actualHourly.findIndex(obj => obj.mode === 'peak' && obj.bucket === baselineItem.timestamp);
                    const actual = actualObsIndex >= 0
                        ? peak.actualHourly[actualObsIndex].actualkWh
                        : 0;
                    return {
                        timestamp: baselineItem.timestamp,
                        hour: baselineItem.hour,
                        baseline,
                        actual,
                        deltaP: baseline - actual,
                    };
                });
                return {
                    startTS: peak.startTS,
                    endTS: peak.endTS,
                    hourlyData: hourlyData.filter(item => item.timestamp !== peak.endTS),
                };
            });
            return result;
        };

        const getPowerGraphData = async ({ vpp, lowerTS, upperTS }) => {
            let url = `/vpps/${vpp}/power/${lowerTS}-${upperTS}`;
            const actualResult = await this.queryServer(url, 'Failed to fetch power history data');
            url = `/vpps/${vpp}/aggregates/baseline?lowerTS=${lowerTS}&upperTS=${upperTS}`;
            const baselineResult = await this.queryServer(url, 'Failed to fetch baseline data');

            const baselineData = [];
            let prev = baselineResult[0].baselinekWh;
            baselineResult.forEach((item) => {
                baselineData.push({
                    timestamp: item.timestamp - 1,
                    value: prev,
                });
                baselineData.push({
                    timestamp: item.timestamp,
                    value: item.baselinekWh,
                });
                prev = item.baselinekWh;
            });

            const actualData = [];
            actualResult.forEach((item) => {
                actualData.push({
                    timestamp: item.at,
                    value: item.actualKW,
                });
            });
            return {
                actualData,
                baselineData,
            };
        };

        const getOperators = async (vppId) => {
            const url = `/admin/vpps/${vppId}/operators`;
            const {
                body,
                status,
            } = await http.get(url);
            if (status !== 200) throw new NyleError('Failed to fetch VB value');
            return body;
        };

        const getVPPs = async () => {
            const url = '/vpps';
            const {
                body,
                status,
            } = await http.get(url);
            if (status !== 200) throw new NyleError('Unable to get VBs associated with user');
            return body;
        };

        const createVpp = async ({
            vppId,
            name,
            timezone,
            priceSource,
        }) => {
            const {
                status,
            } = await http.post('/admin/vpps', {
                vppId,
                name,
                timezone,
                priceSource,
            });
            if (status !== 201) throw new NyleError('Unable to create VB');
        };

        Object.defineProperties(this, {
            queryServer: {
                value: queryServer,
                writable: false,
            },
            getVPPs: {
                value: getVPPs,
                writable: false,
            },
            getConfig: {
                value: getConfig,
                writable: false,
            },
            getDeviceCount: {
                value: getDeviceCount,
                writable: false,
            },
            getEnergyMarketValue: {
                value: getEnergyMarketValue,
                writable: false,
            },
            getMetrics: {
                value: getMetrics,
                writable: false,
            },
            getVPPValueGraphData: {
                value: getVPPValueGraphData,
                writable: false,
            },
            getPeaksList: {
                value: getPeaksList,
                writable: false,
            },
            getPowerGraphData: {
                value: getPowerGraphData,
                writable: false,
            },
            getOperators: {
                value: getOperators,
                writable: false,
            },
            createVpp: {
                value: createVpp,
                writable: false,
            },
        });
    }
}
