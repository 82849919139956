import React from 'react';
import moment from 'moment';
import {
    connect,
} from 'react-redux';

import {
    showWindow,
    nextWindow,
    previousWindow,
    refreshWindow,
    createNewPeakEvent,
    resetNewPeakEvent,
} from '../../models/peaks/actions';
import {
    getCurrentWindow,
    getDisplayedScheduledPeaks,
    getWindowAsBounds,
} from '../../models/peaks/selectors';
import {
    shouldShowPeakValues,
} from '../../models/user/selectors';

class _ScheduledPeaksContainer extends React.Component {
    componentDidMount() {
        const {
            onMount,
            vppId,
        } = this.props;
        onMount({
            vppId,
        });
    }

    toggleNewPeakEventForm = (reset = false) => {
        const {
            createPeak,
            resetPeak,
            vppId,
        } = this.props;
        if (reset) resetPeak(vppId);
        else createPeak(this.generateNewPeakEvent(vppId));
    }

    generateNewPeakEvent(vppId) {
        let startMoment = moment()
            .startOf('hour')
            .add(2, 'hour');

        // if there is an event that is active / in the future, default value might conflict
        //  simplest path to avoid conflicting default dates is to choose a
        //  default start time after the last event
        const { peaks } = this.props;
        const relevantEvents = peaks ? peaks.filter(e => e.endTS >= Date.now()) : [];
        if (relevantEvents.length > 0) {
            startMoment = moment(relevantEvents[relevantEvents.length - 1].endTS)
                .add(2, 'hour')
                .startOf('hour');
        }

        return {
            startTS: startMoment.toDate().valueOf(),
            endTS: startMoment.add(1, 'hour')
                .toDate()
                .valueOf(),
            sendNotification: false,
            notificationMessage: '',
            peakId: null,
            vppId,
        };
    }

    render() {
        const {
            render,
            interval,
            nextPage,
            previousPage,
            refresh,
            newPeak,
        } = this.props;

        return render({
            ...this.props,
            nextPage: () => nextPage(interval),
            previousPage: () => previousPage(interval),
            refresh: () => refresh(interval),
            newPeak,
            toggleNewPeakEventForm: this.toggleNewPeakEventForm,
        });
    }
}

const mapStateToProps = (state) => {
    const {
        vpps: {
            activeVpp,
        },
    } = state;
    return {
        interval: getCurrentWindow(state, false),
        ...getWindowAsBounds(state, false),
        peaks: getDisplayedScheduledPeaks(state),
        showPeakValues: shouldShowPeakValues(state),
        vppId: activeVpp,
        newPeak: (state.peaks[activeVpp] || {}).newPeak,
    };
};

const mapDispatchToProps = dispatch => ({
    onMount: ({
        vppId,
    }) => dispatch(showWindow({
        vppId,
        duration: 'P7D',
    })),
    nextPage: ({
        vppId,
        anchor,
        duration,
    }) => dispatch(nextWindow({
        vppId,
        anchor,
        duration,
    })),
    previousPage: ({
        vppId,
        anchor,
        duration,
    }) => dispatch(previousWindow({
        vppId,
        anchor,
        duration,
    })),
    refresh: ({
        vppId,
        anchor,
        duration,
    }) => dispatch(refreshWindow({
        vppId,
        anchor,
        duration,
    })),
    createPeak: params => dispatch(createNewPeakEvent(params)),
    resetPeak: vppId => dispatch(resetNewPeakEvent(vppId)),
});

const ScheduledPeaksContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_ScheduledPeaksContainer);

export {
    ScheduledPeaksContainer,
};
