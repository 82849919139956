import React from 'react';
import {
    round,
} from '../../../utils/math';
import {
    makeReadableTime,
    formatTimestamp,
} from '../../utils';
import {
    Button,
} from './form';

const PLCEnum = {
    0: 'No Current Data',
    1: 'PLC Not Connected',
    2: 'PLC Connected',
};

const ConnectionStatusRow = ({
    device,
    onAcknowledgeOffline,
    rebootDevice,
    plcConnectivity,
    compRunTime,
    showActions,
}) => {
    const {
        productType,
        connectedAt,
        disconnectedAt,
        firstConnectedAt,
    } = device;
    let connectionStatus;
    let timeSinceStatusChange;
    let momentOfStatusChange;
    let possiblyOnline = false;
    if (!disconnectedAt || connectedAt > disconnectedAt) {
        connectionStatus = 'Online';
        timeSinceStatusChange = makeReadableTime(Date.now() - connectedAt);
        momentOfStatusChange = device.connectedAt;
    } else {
        connectionStatus = 'Offline';
        timeSinceStatusChange = makeReadableTime(Date.now() - disconnectedAt);
        momentOfStatusChange = device.disconnectedAt;
        possiblyOnline = (disconnectedAt - connectedAt) < 6000;
    }

    const acknowledgedOffline = disconnectedAt % 1 !== 0;

    const possiblyOnlineMessage = possiblyOnline
        ? (
            <p className="italic">
                This device is possibly online. It disconnected
                {' '}
                {round((disconnectedAt - connectedAt) / 1000)}
                {' '}
                seconds after we recieved a connected message.
            </p>
        )
        : null;

    return (
        <>
            <div className="row">
                <div className="col">Status</div>
                <div className="col">
                    <p>
                        {connectionStatus}
                        {' for '}
                        {timeSinceStatusChange}
                    </p>
                    <p>{`(since ${formatTimestamp(momentOfStatusChange)}) `}</p>
                    {possiblyOnlineMessage}
                </div>
                <div className="col">
                    {showActions
                        && (connectionStatus === 'Online'
                            ? (
                                <Button
                                    title="Reboot"
                                    className="btn btn-danger btn-sm"
                                    onClick={rebootDevice}
                                />
                            )
                            : !!onAcknowledgeOffline && (
                                <Button
                                    title={`Acknowledge${acknowledgedOffline ? 'd' : ''} offline`}
                                    onClick={onAcknowledgeOffline}
                                    className="btn-warning btn-sm"
                                    disabled={acknowledgedOffline}
                                />
                            )
                        )
                    }
                </div>
            </div>
            <div className="row">
                <div className="col">First connected</div>
                <div className="col">{formatTimestamp(firstConnectedAt)}</div>
                <div className="col" />
            </div>
            {productType === 'NyleComm' && <>
                <div className="row">
                    <div className="col">Compressor Runtime</div>
                    <div className="col">{`${compRunTime} minutes`}</div>
                    <div className="col" />
                </div>
                <div className="row">
                    <div className="col">PLC Connectivity</div>
                    <div className="col">{PLCEnum[plcConnectivity]}</div>
                    <div className="col" />
                </div>
            </>}
        </>
    );
};

export default ConnectionStatusRow;
