import React from 'react';
import { BAUD_RATES } from '../utils/constants';
import {
    ActionRow,
    DropdownRow,
    InputRow,
} from './settings-rows';

const MODE_LOOKUP = {
    1: 'SGD',
    2: 'UCM',
};

const REVERSE_MODE_LOOKUP = {
    SGD: 1,
    UCM: 2,
};

export const CTA2045Settings = ({
    device,
    updateUserSetting,
}) => {
    const { user } = device;
    return (
        <div>
            <h2>CTA2045 Settings</h2>
            <table className="table device-detail-table">
                <tbody>
                    <ActionRow
                        handler={() => updateUserSetting('CTA2045.enable', !(user.CTA2045?.enable ?? false))}
                        title='Enabled'
                        value={user.CTA2045?.enable ? 'True' : 'False'}
                        buttonTitle={user.CTA2045?.enable ? 'Disable' : 'Enable'}
                        buttonStyleClass='btn-outline-dark'
                    />
                    <DropdownRow
                        label='Bitrate'
                        value={user.CTA2045?.bitRate}
                        handler={value => updateUserSetting('CTA2045.bitRate', Number(value))}
                        data={BAUD_RATES}
                    />
                    <DropdownRow
                        label='Mode'
                        value={MODE_LOOKUP[user.CTA2045?.mode]}
                        handler={value => updateUserSetting('CTA2045.mode', REVERSE_MODE_LOOKUP[value])}
                        data={['SGD', 'UCM']}
                    />
                    <InputRow
                        title='Command'
                        value={user.CTA2045?.command}
                        handler={value => updateUserSetting('CTA2045.command', Number(value))}
                        inputProps={{
                            type: 'number',
                            min: 0,
                            max: 255,
                        }}
                        buttonStyleClass='btn-outline-dark'
                    />
                    <InputRow
                        title='Parameter'
                        value={user.CTA2045?.parameter}
                        handler={value => updateUserSetting('CTA2045.parameter', Number(value))}
                        inputProps={{
                            type: 'number',
                            min: 0,
                            max: 255,
                        }}
                        buttonStyleClass='btn-outline-dark'
                    />
                </tbody>
            </table>
        </div>
    );
};
