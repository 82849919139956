import React, { Component } from 'react';
import PropTypes from 'prop-types';

import 'font-awesome/css/font-awesome.min.css';

/**
 * Simple wrapper on font-awesome icons that does 2 things
 * 1) provides an entry point to insert styles - so they don't need to be imported on every page that needs them
 * 2) abstracts away font-awesome syntax
 *
 * This could be thought of as an PacketizedIcon where by we could change out the implementation underneath if desired, but no care has been made to
 * create our own icon hints
 *
 * If this gets more complex, consider importing a library that handles this - but as simple as this is, it isn't worht it yet
 */
export class Icon extends Component {
    render() {
        return (<i className={`fa fa-${this.props.hint} ${this.props.className}`} />);
    }

    static propTypes = {
        hint: PropTypes.string.isRequired,
        className: PropTypes.string.isRequired,
    };

    static defaultProps = {
        className: '',
    };
}
