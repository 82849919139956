import React, { Component } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Icon } from '../../lib/font-awesome';
import { Button } from '../../lib/bootstrap';
import {
    getCurrentTimezone,
} from '../../utils/time';
import './banner.css';

/**
 * Renders the current state of the peak event, likely on top of the
 * VB Operator chart so they know why power is extreme and the slider is disabled.
 * Renders all states but only states  pre-positioning and holding-reduced are
 * expected to show on top of the VB operator chart (while its an active event).
 *
 * Also could end up displaying the error state
 *
 */
export class ActivePeakEventBanner extends Component {
    renderStates = {
        scheduled: () => {
            const { prePositionTS } = this.props;
            return (
                <span>
                    {this.renderEvent()}
                    {' is scheduled for '}
                    <strong>pre-positioning</strong>
                    {' starting at '}
                    <strong>{this.renderTime(prePositionTS)}</strong>
                </span>
            );
        },
        default: () => this.renderEvent(),
        'pre-positioning': () => (
            <span>
                <strong>Pre-positioning</strong>
                {' power for the '}
                {this.renderEvent()}
                {' '}
                {this.renderEndButton()}
            </span>
        ),
        'holding-reduced': () => (
            <span>
                {'Holding power '}
                <strong>reduced</strong>
                {' for the '}
                {this.renderEvent()}
                {' '}
                {this.renderEndButton()}
            </span>
        ),
        recovery: () => (
            <span>
                {'Allowing VB power to '}
                <strong>recover</strong>
                {' after the '}
                {this.renderEvent()}
                {' '}
                {this.renderEndButton()}
            </span>
        ),
        completed: () => (
            <span>
                <strong>Completed</strong>
                {' the '}
                {this.renderEvent()}
            </span>
        ),
        failed: () => (
            <span>
                {'Uh oh, something went wrong during the '}
                {this.renderEvent()}
                {'. We\'re looking into it to prevent it from happening in the future. Feel free to adjust the power as needed.'}
            </span>
        ),
    };

    renderEndButton() {
        const {
            isDeleting,
            delete: deleteEvent,
        } = this.props;
        if (isDeleting) {
            return (
                <span>
                    <br />
                    <Icon hint="warning" />
                    {' This event is ending, please allow one minute for it to transition back to normal.'}
                </span>
            );
        }
        return (
            <Button color="danger" size="sm" onClick={deleteEvent}>
                End
            </Button>
        );
    }

    renderEvent() {
        const {
            startTS,
            endTS,
        } = this.props;
        return (
            <span>
                {'Peak Event scheduled from '}
                <strong>{this.renderTime(startTS, true)}</strong>
                {' to '}
                <strong>{this.renderTime(endTS)}</strong>
            </span>
        );
    }

    renderTime(timestamp, hideTimezone = false) {
        if (hideTimezone) return moment(timestamp).format('LT');
        return moment(timestamp).format(`LT [${getCurrentTimezone()}]`);
    }

    render() {
        const { state } = this.props;
        const renderableState = this.renderStates[state] ? state : 'default';

        return (
            <aside className="peak-event-banner">
                <Icon hint="calendar" />
                {this.renderStates[renderableState]()}
            </aside>
        );
    }

    static propTypes = {
        startTS: PropTypes.number.isRequired,
        endTS: PropTypes.number.isRequired,
    };
}
