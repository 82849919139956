import {
    combineReducers,
} from 'redux';

import {
    reducer as peaksReducer,
} from './models/peaks/reducers';
import {
    reducer as vppsReducer,
} from './models/vpps/reducers';
import {
    reducer as userReducer,
} from './models/user/reducers';
import {
    reducer as alertsReducer,
} from './models/alerts/reducers';
import {
    reducer as downloadReducer,
} from './models/download/reducers';
import {
    reducer as devicesReducer,
} from './models/devices/reducers';

const reducer = combineReducers({
    peaks: peaksReducer,
    vpps: vppsReducer,
    user: userReducer,
    alerts: alertsReducer,
    download: downloadReducer,
    devices: devicesReducer,
});

export {
    reducer,
};
