import React, {
    useEffect,
} from 'react';
import {
    connect,
} from 'react-redux';
import {
    get as _get,
} from 'lodash';
import {
    fetchDeviceGraphData,
    setDeviceErrorMessage,
} from '../../models/devices/actions';
import {
    userIsNyleAdmin,
    userHasRole,
} from '../../models/user/selectors';
import {
    withDateRange,
} from '../date-range';
import {
    formatHeatmaps,
} from './mode-series';
import {
    Graph,
    StackedGraph,
} from './base-graphs';
import {
    colors,
} from '../../styles/colors';

const TimeSeriesGraphView = ({
    deviceId,
    timeseriesKey,
    debugData = [],
    connectionsData = [],
    startTS,
    endTS,
    getData,
    debugConfig,
    showModeSeries,
    showErrorMessage,
}) => {
    const nyleCSVData = React.useMemo(() => debugData.filter(data => data.temps !== undefined)
        .map(filteredData => ({
            timestamp: filteredData.timestamp,
            ...filteredData.temps,
        })), [debugData]);

    useEffect(() => {
        getData();
    }, [startTS, endTS]);
    let traceFormat;
    try {
        traceFormat = formatTrace(
            debugData,
            timeseriesKey,
            debugConfig,
        );
    } catch (e) {
        showErrorMessage({
            deviceId,
            message: `Timeseries config for '${timeseriesKey}' not found.`,
        });
        return null;
    }
    const {
        yAxisTitle,
        graphData,
    } = traceFormat;
    const legend = {
        orientation: 'h',
        xanchor: 'center',
        x: 0.5,
        yanchor: 'top',
        y: -0.25,
    };
    if (showModeSeries) {
        const {
            modeData,
            modeScales,
        } = formatHeatmaps({
            debugData,
            debugConfig,
            connectionsData,
            endTS,
        });
        return (
            <div>
                <StackedGraph
                    exportData={nyleCSVData}
                    upperChartData={graphData}
                    lowerChartData={modeData}
                    yAxisTitleUpper={yAxisTitle}
                    xDomain={[startTS, endTS]}
                    legend={legend}
                />
                {modeScales}
            </div>
        );
    }
    return (
        <Graph
            exportData={nyleCSVData}
            data={graphData}
            yAxisTitle={yAxisTitle}
            xDomain={[startTS, endTS]}
            title={deviceId}
            legend={legend}
        />
    );
};

const formatTrace = (
    debugData,
    timeseriesKey,
    debugConfig = {},
) => {
    const timestamps = [];
    const timeseriesConfig = debugConfig[`@timeseries/${timeseriesKey}`];
    const {
        yAxisTitle = '',
        spans = [],
    } = timeseriesConfig;
    const graphData = [];
    spans.forEach((span) => {
        const {
            color,
            name,
            variable,
        } = span;
        const trace = {
            x: timestamps,
            y: [],
            type: 'line',
            name,
            marker: {
                color: _get(colors, color),
            },
            hoverinfo: 'y',
            showlegend: true,
            variable,
        };
        graphData.push(trace);
    });
    debugData.forEach((d) => {
        timestamps.push(d.timestamp);
        graphData.forEach((trace) => {
            const value = _get(d, trace.variable);
            if (value == null) return;
            trace.y.push(value);
        });
    });
    return {
        yAxisTitle,
        graphData,
    };
};

const mapStateToProps = (state, props) => {
    const {
        deviceId,
    } = props;
    const {
        debugData,
        connectionsData,
        graphRange,
        device: {
            debugConfig,
        },
    } = state.devices.devices[deviceId] || {};
    return {
        deviceId,
        debugData,
        connectionsData,
        showModeSeries: userHasRole(state, 'oem') || userIsNyleAdmin(state),
        debugConfig,
        ...graphRange,
    };
};

const mapDispatchToProps = (dispatch, props) => ({
    getData: () => {
        dispatch(fetchDeviceGraphData({
            deviceId: props.deviceId,
            dataType: 'debug',
        }));
        dispatch(fetchDeviceGraphData({
            deviceId: props.deviceId,
            dataType: 'connections',
        }));
    },
    showErrorMessage: params => dispatch(setDeviceErrorMessage(params)),
});

const TimeSeriesGraph = withDateRange(connect(
    mapStateToProps,
    mapDispatchToProps,
)(TimeSeriesGraphView));

export {
    TimeSeriesGraph,
};
