import React from 'react';

const ONE_DAY_MS = 1000 * 60 * 60 * 24;

const FixedRangeButton = ({
    numDays,
    label,
    setRange,
    disabled,
}) => (
    <button
        className="btn btn-outline-dark devices-search__range-button"
        onClick={() => {
            const now = Date.now();
            setRange(now - (numDays * ONE_DAY_MS), now);
        }}
        disabled={disabled}
    >
        {label}
    </button>
);

export {
    FixedRangeButton,
};
