/* globals document */
import React from 'react';
import {
    Plot,
    Plotly,
} from '../lib/plotly';
import {
    getCurrentTimezone,
} from '../utils/time';
import {
    icon as cogIcon,
} from './cog-icon';
import {
    MARGINS,
} from './config';
import './chart.css';

const defaultMargin = {
    t: MARGINS.top,
    r: MARGINS.right,
    l: MARGINS.left,
    b: MARGINS.bottom,
};

const mainChartRatio = 2.5;
const lowerChartProportion = 1 - (mainChartRatio / (mainChartRatio + 1));
const buffer = 0.03;

const legends = {
    side: {
        bgcolor: 'white',
        bordercolor: 'lightgrey',
        borderwidth: 1,
        x: 1.05,
        y: (lowerChartProportion + buffer + 1) / 2,
        xanchor: 'left',
        yanchor: 'middle',
    },
    top: {
        x: 0.02,
        y: 1,
        xanchor: 'left',
        yanchor: 'top',
        bgcolor: 'white',
        bordercolor: 'lightgrey',
        borderwidth: 1,
    },
};

const verticalCursor = {
    type: 'line',
    yref: 'paper',
    x0: 0,
    y0: 0,
    x1: 0,
    y1: 1,
    fillcolor: '#d3d3d3',
    opacity: 0.2,
};

const shadedRegionStyles = {
    peak: {
        color: '#d3d3d3',
        opacity: 0.3,
    },
    resilience: {
        color: '#8bccd6',
        opacity: 0.3,
    },
    netc: {
        color: '#e6b895',
        opacity: 0.3,
    },
};
const shadedRegionNames = {
    peak: 'Scheduled Peak',
    resilience: 'Resilience Peak',
    netc: 'Network Constraint Mode',
};

const getShadedRegionShapes = regions => regions.map(({
    startTS,
    endTS,
    type,
}) => ({
    type: 'rect',
    xref: 'x',
    yref: 'paper',
    x0: startTS,
    y0: 0,
    x1: endTS,
    y1: 1,
    fillcolor: shadedRegionStyles[type].color,
    opacity: shadedRegionStyles[type].opacity,
    line: {
        width: 0,
    },
    layer: 'below',
}));

// Add dummy data series so shaded regions show up in legend
const getShadedRegionLegendEntries = xDomain => Object.keys(shadedRegionStyles)
    .map(type => ({
        name: shadedRegionNames[type],
        type: 'bar',
        marker: {
            ...shadedRegionStyles[type],
            line: {
                width: 0,
            },
        },
        xaxis: 'x',
        yaxis: 'y',
        x: [xDomain[0] - 1000],
        y: [0],
        width: [0],
        hoverinfo: 'skip',
    }));

const StackedChart = ({
    mainChartData,
    externalChartData,
    xDomain,
    yDomainMain,
    yDomainExternal,
    yAxisTitleMain,
    yAxisTitleExternal,
    tickvals,
    settingsHandler = () => {},
    adjustForLargeScreen = false,
    shadedRegions,
    showSettings,
}) => {
    const data = [
        ...mainChartData.map(series => ({
            ...series,
            xaxis: 'x',
            yaxis: 'y',
        })),
        ...externalChartData.map(series => ({
            ...series,
            xaxis: 'x',
            yaxis: 'y2',
            showlegend: false,
        })),
        ...getShadedRegionLegendEntries(xDomain),
    ];

    return (
        <BasicPlot
            divId="stacked-chart"
            data={data}
            settingsHandler={settingsHandler}
            adjustForLargeScreen={adjustForLargeScreen}
            heightFactor={1.5}
            showSettings={showSettings}
            onHover={(hoverData) => {
                const point = hoverData.points[0];
                if (typeof point === 'undefined') return;
                const x = point.data.x[point.pointIndex];
                const gd = document.getElementById('stacked-chart');
                if (!gd) return;
                // return early with a lot of data to minimize crashing
                const tooLong = gd.data.reduce((res, d) => res || d.x.length > 10000, false);
                if (tooLong) return;
                const points = gd.data.map(obj => obj.x).map((xVals, i) => {
                    const closestX = findClosest(x, xVals);
                    return {
                        curveNumber: i,
                        pointNumber: xVals.findIndex(val => val === closestX),
                    };
                }).filter(obj => obj.pointNumber >= 0);
                if (!points.length) return;
                Plotly.Fx.hover(gd, points, ['xy', 'xy2']);

                Plotly.relayout(gd, {
                    'shapes[0].x0': x,
                    'shapes[0].x1': x,
                });
            }}
            layout={{
                grid: {
                    rows: 2,
                    columns: 1,
                    subplots: ['xy', 'xy2'],
                },
                xaxis: {
                    range: xDomain,
                    fixedrange: true,
                    type: 'date',
                    title: `Time (${getCurrentTimezone()})`,
                    tickvals,
                    ticks: 'outside',
                    showline: true,
                },
                yaxis: {
                    range: yDomainMain,
                    fixedrange: true,
                    title: yAxisTitleMain,
                    domain: [lowerChartProportion + buffer, 1],
                    ticks: 'outside',
                    showline: true,
                },
                yaxis2: {
                    range: yDomainExternal,
                    fixedrange: true,
                    title: yAxisTitleExternal,
                    domain: [0, lowerChartProportion - buffer],
                    ticks: 'outside',
                    showline: true,
                },
                legend: legends.top,
                shapes: [
                    verticalCursor,
                    ...getShadedRegionShapes(shadedRegions),
                ],
                autosize: true,
            }}
            useResizeHandler={true}
        />
    );
};

const findClosest = (goal, array) => {
    if (!array.length) return null;
    return array.reduce((best, current) => {
        const absDiff = val => Math.abs(val - goal);
        return absDiff(current) < absDiff(best) ? current : best;
    });
};

const Chart = ({
    data,
    xDomain,
    yDomain,
    yAxisTitle,
    tickvals,
    settingsHandler = () => {},
    adjustForLargeScreen = false,
    shadedRegions,
    showSettings,
    includeShadedData = true,
}) => {
    const allData = [...data];
    if (includeShadedData) {
        allData.push(...getShadedRegionLegendEntries(xDomain));
    }
    return (
        <BasicPlot
            data={allData}
            settingsHandler={settingsHandler}
            adjustForLargeScreen={adjustForLargeScreen}
            showSettings={showSettings}
            layout={{
                xaxis: {
                    range: xDomain,
                    fixedrange: true,
                    type: 'date',
                    title: `Time (${getCurrentTimezone()})`,
                    tickvals,
                },
                yaxis: {
                    range: yDomain,
                    fixedrange: true,
                    title: yAxisTitle,
                },
                shapes: getShadedRegionShapes(shadedRegions),
            }}
        />
    );
};

const BasicPlot = ({
    data,
    settingsHandler,
    adjustForLargeScreen,
    heightFactor = 1,
    layout,
    margins,
    showSettings = true,
    ...props
}) => {
    const height = (adjustForLargeScreen ? 1000 : 450) * heightFactor;
    const modeBarButtons = [['toImage']];
    if (showSettings) {
        modeBarButtons[0].push({
            name: 'Settings',
            icon: cogIcon,
            click: settingsHandler,
        });
    }
    return (
        <Plot
            data={data}
            useResizeHandler={true}
            config={{
                displaylogo: false,
                displayModeBar: true,
                modeBarButtons,
                responsive: true,
            }}
            style={{
                width: '100%',
                height: '100%',
                minHeight: height,
                maxHeight: height,
                minWidth: 300,
                // min width/height must be set to prevent unwanted scroll behavior:
                // without this, page will jump to the top when display type changes
            }}
            layout={{
                margin: {
                    ...defaultMargin,
                    ...margins,
                },
                legend: {
                    orientation: 'h',
                    xanchor: 'center',
                    x: 0.5,
                    y: -0.2,
                },
                font: {
                    size: adjustForLargeScreen ? 18 : 11,
                },
                ...layout,
            }}
            {...props}
        />
    );
};

export {
    StackedChart,
    Chart,
};
