import React from 'react';
import AlertSetting from './AlertSetting';

const AlertSettings = ({
    alertOptions,
    device,
    alertRegisterKeys,
    updateUserSetting,
    getDeviceShadow,
}) => {
    const { user } = device;
    React.useEffect(() => {
        getDeviceShadow();
    }, []);

    return (
        <div>
            <AlertSetting
                alertOptions={alertOptions}
                title="Alert 1 Settings"
                alertData={user.alerts?.alert_0}
                alertName="alert_0"
                alertRegisterKeys={alertRegisterKeys}
                updateUserSetting={updateUserSetting}
            />
            <AlertSetting
                alertOptions={alertOptions}
                title="Alert 2 Settings"
                alertData={user.alerts?.alert_1}
                alertName="alert_1"
                alertRegisterKeys={alertRegisterKeys}
                updateUserSetting={updateUserSetting}
            />
            <AlertSetting
                alertOptions={alertOptions}
                title="Alert 3 Settings"
                alertData={user.alerts?.alert_2}
                alertName="alert_2"
                alertRegisterKeys={alertRegisterKeys}
                updateUserSetting={updateUserSetting}
            />
            <AlertSetting
                alertOptions={alertOptions}
                title="Alert 4 Settings"
                alertData={user.alerts?.alert_3}
                alertName="alert_3"
                alertRegisterKeys={alertRegisterKeys}
                updateUserSetting={updateUserSetting}
            />
            <AlertSetting
                alertOptions={alertOptions}
                title="Alert 5 Settings"
                alertData={user.alerts?.alert_4}
                alertName="alert_4"
                alertRegisterKeys={alertRegisterKeys}
                updateUserSetting={updateUserSetting}
            />
            <AlertSetting
                alertOptions={alertOptions}
                title="Alert 6 Settings"
                alertData={user.alerts?.alert_5}
                alertName="alert_5"
                alertRegisterKeys={alertRegisterKeys}
                updateUserSetting={updateUserSetting}
            />
            <AlertSetting
                alertOptions={alertOptions}
                title="Alert 7 Settings"
                alertData={user.alerts?.alert_6}
                alertName="alert_6"
                alertRegisterKeys={alertRegisterKeys}
                updateUserSetting={updateUserSetting}
            />
            <AlertSetting
                alertOptions={alertOptions}
                title="Alert 8 Settings"
                alertData={user.alerts?.alert_7}
                alertName="alert_7"
                alertRegisterKeys={alertRegisterKeys}
                updateUserSetting={updateUserSetting}
            />
            <AlertSetting
                alertOptions={alertOptions}
                title="Alert 9 Settings"
                alertData={user.alerts?.alert_8}
                alertName="alert_8"
                alertRegisterKeys={alertRegisterKeys}
                updateUserSetting={updateUserSetting}
            />
            <AlertSetting
                alertOptions={alertOptions}
                title="Alert 10 Settings"
                alertData={user.alerts?.alert_9}
                alertName="alert_9"
                alertRegisterKeys={alertRegisterKeys}
                updateUserSetting={updateUserSetting}
            />
        </div>
    );
};

export default AlertSettings;
