/* globals document */
import React, { Component } from 'react';
import { SettingsContainer } from './settings';
import { EnergyChart } from '../../charts/energy';
import { PowerChart } from '../../charts/power';
import {
    max as d3Max,
    min as d3Min,
} from '../../lib/d3';
import './index.css';
import {
    DISPLAY_TYPES,
    ENERGY_BUCKETS,
    getWindowBounds,
} from '../../models/vpps/utils';
import {
    getFiveMinuteFloor,
    getHourFloor,
} from '../../utils/time';
import {
    colors,
} from '../../styles/colors';

const looksLike4K = () => document.body.clientWidth > 3000;

class VPPOperatorChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSettings: props.showSettings || false,
        };

        this.renderPowerChart = this.renderPowerChart.bind(this);
        this.renderEnergyChart = this.renderEnergyChart.bind(this);
    }

    getMaxKW() {
        const MAX_KW_PADDING = 2;
        const {
            actualPowerSeries,
            targetPowerSeries,
            baselinePowerSeries,
        } = this.props;
        if (this.isFetchingData() || this.hasNoData()) return 15 + MAX_KW_PADDING;
        return Math.max(
            15,
            d3Max(actualPowerSeries.y) || 0,
            d3Max(targetPowerSeries.y) || 0,
            d3Max(baselinePowerSeries.y) || 0,
        ) + MAX_KW_PADDING;
    }

    hasNoData() {
        const {
            displayType,
            actualPowerSeries,
            targetPowerSeries,
            actualEnergySeries,
        } = this.props;
        if (displayType === DISPLAY_TYPES.POWER) {
            return seriesHasNoData(actualPowerSeries)
                || seriesHasNoData(targetPowerSeries);
        }
        return seriesHasNoData(actualEnergySeries);
    }

    isFetchingData() {
        const { fetchingData } = this.props;
        return !!fetchingData;
    }

    renderOverlay() {
        if (!this.isFetchingData() && !this.hasNoData()) return null;
        return (
            <div className='operator-chart__overlay'>
                <div className='operator-chart__overlay__background' />
                <div className='operator-chart__overlay__text'>
                    <div>
                        {this.isFetchingData() ? 'Fetching data' : 'No data found'}
                    </div>
                </div>
            </div>
        );
    }

    renderSettings() {
        const { showSettings } = this.state;
        const { vppId } = this.props;
        return (
            <div className={`vpp-operator__window-control ${showSettings ? 'vpp-operator__window-control--shown' : ''}`}>
                <SettingsContainer
                    handleClose={() => this.setState({ showSettings: false })}
                    vppId={vppId}
                />
            </div>
        );
    }

    renderPowerChart({
        adjustForLargeScreen,
    }) {
        const {
            performanceDisplay,
        } = this.props;
        const windowBounds = getWindowBounds(performanceDisplay);
        return (
            <PowerChart
                {...this.props}
                xDomain={[windowBounds.startTS, windowBounds.endTS]}
                yDomain={[0, this.getMaxKW()]}
                settingsHandler={() => {
                    this.setState({
                        showSettings: true,
                    });
                }}
                adjustForLargeScreen={adjustForLargeScreen}
            />
        );
    }

    getEnergyYDomain() {
        const {
            actualEnergySeries,
            baselinePowerSeries,
        } = this.props;
        return [
            d3Min([0, ...actualEnergySeries.y, ...baselinePowerSeries.y]),
            d3Max([1, ...actualEnergySeries.y, ...baselinePowerSeries.y]),
        ];
    }

    renderEnergyChart({
        adjustForLargeScreen,
    }) {
        const {
            performanceDisplay,
        } = this.props;
        const windowBounds = getWindowBounds(performanceDisplay);
        const getFloor = performanceDisplay.bucketSize === ENERGY_BUCKETS.FIVE_MINUTES
            ? getFiveMinuteFloor
            : getHourFloor;
        return (
            <EnergyChart
                {...this.props}
                xDomain={[getFloor(windowBounds.startTS), getFloor(windowBounds.endTS)]}
                yDomain={this.getEnergyYDomain()}
                settingsHandler={() => this.setState({ showSettings: true })}
                adjustForLargeScreen={adjustForLargeScreen}
            />
        );
    }

    render() {
        const {
            resizeFor4K,
        } = this.props || false;
        const { displayType } = this.props;
        const adjustForLargeScreen = resizeFor4K && looksLike4K();
        const renderChart = displayType === DISPLAY_TYPES.POWER
            ? this.renderPowerChart
            : this.renderEnergyChart;
        return (
            <div className='operator-chart-wrapper'>
                <div className='operator-chart-padding' />
                <div className='operator-chart-content'>
                    {this.renderSettings()}
                    {renderChart({
                        adjustForLargeScreen,
                    })}
                    {this.renderOverlay()}
                </div>
            </div>
        );
    }

    static defaultProps = {
        actualPowerColor: colors.primaryBlue,
        targetPowerColor: colors.secondaryBlue,
        maxKW: () => 15,
    };
}

const seriesHasNoData = series => (!series || !series.x || !series.x.length
        || !series.y || !series.y.length);

export {
    VPPOperatorChart,
};
