import React from 'react';
import {
    connect,
} from 'react-redux';
import {
    get as _get,
} from 'lodash';
import {
    Icon,
} from '../../lib/font-awesome';
import {
    ShadowContainer,
} from './shadow';
import {
    RPCContainer,
} from './call-rpcs';
import {
    UsersForDevice,
} from './device-users';
import {
    DeviceAlerts,
} from '../../admin/devices/alerts/device-alerts';

import {
    EnergyGraph,
} from '../graphs/energy';
import {
    PowerGraph,
} from '../graphs/power';
import {
    TimeSeriesGraph,
} from '../graphs/time-series';
import {
    userHasRole,
    userIsNyleAdmin,
} from '../../models/user/selectors';
import './action-container.css';

const MenuItem = ({
    target,
    handleClick,
    active = false,
}) => (
    <li className="nav-item" key={`${target}-menu`}>
        <span
            className={`nav-link ${active ? 'active' : ''}`}
            onClick={() => handleClick(target)}
        >
            {target}
        </span>
    </li>
);

const IoTConsoleLink = ({
    deviceId,
}) => (
    <li className="nav-item" key="iot-console-link">
        <a
            href={`https://console.aws.amazon.com/iot/home?region=us-east-1#/thing/${deviceId}`}
            target="_blank"
            className="nav-link"
            rel="noopener noreferrer"
        >
            IoT Console&nbsp;
            <Icon hint="external-link" />
        </a>
    </li>
);

const ActionContainerView = ({
    children,
    isNyleAdmin,
    deviceId,
}) => {
    const firstNonNullChildIndex = React.Children.map(children, Boolean).findIndex(c => !!c);
    const [activeItem, setActiveItem] = React.useState(children[firstNonNullChildIndex].key);

    return (
        <div id="action-container">
            <div className="row">
                <div className="col">
                    <ul className="nav nav-pills action-menu">
                        {
                            React.Children.map(children, (n, i) => n && (
                                <MenuItem
                                    target={n.key}
                                    handleClick={setActiveItem}
                                    active={activeItem === n.key || (!activeItem && i === 0)}
                                />
                            ))
                        }
                        {isNyleAdmin && (
                            <IoTConsoleLink
                                deviceId={deviceId}
                            />
                        )}
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {
                        React.Children.map(children, (n, i) => {
                            if (!n) return null;
                            if (activeItem === n.key || (!activeItem && i === 0)) return n;
                            return null;
                        })
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    const {
        deviceId,
    } = props;
    const { devices } = state.user;
    const isDeviceOwner = devices.includes(deviceId);
    const hasOemRole = userHasRole(state, 'oem');
    const hasAdminRole = userHasRole(state, 'admin');
    const {
        debugConfig,
    } = state.devices.devices[deviceId].device || {};
    const timeseriesKeys = getTimeseriesKeys(debugConfig);
    return {
        deviceId,
        timeseriesKeys,
        canCallRPCs: hasOemRole,
        canViewShadow: hasOemRole,
        canViewUsers: hasAdminRole,
        canViewAlerts: hasAdminRole || hasOemRole || isDeviceOwner,
        isNyleAdmin: userIsNyleAdmin(state),
        isDeviceOwner,
    };
};

const ActionContainer = connect(
    mapStateToProps,
)(({
    deviceId,
    deviceType,
    productType,
    setErrorMessage,
    timeseriesKeys,
    canViewShadow,
    canCallRPCs,
    canViewUsers,
    canViewAlerts,
    isNyleAdmin,
}) => {
    const timeseriesGraphs = timeseriesKeys.filter(timeseries => timeseries.key !== undefined)
        .map(timeseries => (
            <TimeSeriesGraph
                key={timeseries.key}
                legend={timeseries.key}
                timeseriesKey={timeseries.timeseriesKey}
                deviceId={deviceId}
                showNoConfigFoundError={setErrorMessage}
            />
        ));
    return (
        <ActionContainerView isNyleAdmin={isNyleAdmin} deviceId={deviceId}>
            {canViewAlerts && (
                <DeviceAlerts
                    key="Alerts"
                    singleDeviceView={true}
                    deviceId={deviceId}
                />
            )}
            <PowerGraph
                key="Power"
                legend="Power"
                deviceId={deviceId}
                deviceType={deviceType}
                productType={productType}
            />
            <EnergyGraph
                key="Energy"
                legend="Energy"
                deviceId={deviceId}
            />
            {timeseriesGraphs}
            {canViewShadow && (
                <ShadowContainer
                    key="Shadow"
                    deviceId={deviceId}
                />
            )}
            {canViewUsers && (
                <UsersForDevice
                    key="Users"
                    deviceId={deviceId}
                />
            )}
            {canCallRPCs && (
                <RPCContainer
                    key="RPCs"
                    deviceId={deviceId}
                />
            )}
        </ActionContainerView>
    );
});

const getTimeseriesKeys = (debugConfig) => {
    const {
        timeseries = [],
    } = debugConfig || {};
    if (timeseries) {
        return timeseries.map((timeseriesKey) => {
            const key = _get(debugConfig, `@timeseries/${timeseriesKey}.key`);
            return (
                {
                    key, // displayed in UI
                    timeseriesKey, // for fetching timeseries config
                }
            );
        });
    }
    return [];
};

export {
    ActionContainer,
};
